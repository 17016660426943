import { Component } from '@angular/core';
import { ContactUsLibComponent } from 'libreria-tienda/shared/contact-us/contact-us.component';



@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [ContactUsLibComponent],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.css'
})
export class ContactUsComponent {

}
