import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService, ItemCar, JsonTextService, ManejadorEventosService, ShoppingCartService, SioServicesService, TextResponse, User, UserService, items } from 'libreria-tienda';
import { TipoCompraComponent } from '../resumen/tipo-compra/tipo-compra.component';

declare var propiedades: any | undefined;

@Component({
  selector: 'lib-cart-right',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, MatTooltipModule],
  templateUrl: './cart-right.component.html',
  styleUrl: './cart-right.component.css'
})
export class CartRightComponent implements OnInit {

  propiedades: any;

  categoria: string;

  headerJson: TextResponse[];

  user: User = new User();

  items: ItemCar[] = new Array<ItemCar>()
  lastProduct: ItemCar = new ItemCar();

  totalProducts: number;
  newProduct: boolean = false;

  public jsonService = inject(JsonTextService)
  public cartService = inject(ShoppingCartService)
  public sioservice = inject(SioServicesService)
  public configService = inject(ConfigService)
  public manejadorEventos = inject(ManejadorEventosService)
  public userService = inject(UserService);
  // public searchHistoy = inject(SearchHistoryService)

  constructor(private router: Router, public dialogo: MatDialog) {
    this.propiedades = propiedades;
  }

  ngOnInit(): void {
    // this.jsonService.downloadJson(this.configService._config.URLHEADERJSON).subscribe(res => {
    //   this.headerJson = res;
    // })
    this.getCart()
    this.totalProducts = this.cartService.getTotalProducts()
    this.manejadorEventos.producto.subscribe(newP => {
      this.newProduct = true
      this.lastProduct = newP
      this.totalProducts = this.cartService.getTotalProducts()
      this.getCart()

    })
    this.manejadorEventos.newProducto.subscribe(newp => {
      this.newProduct = false
      this.totalProducts = this.cartService.getTotalProducts()
    })
  }

  getName(name: string) {
    return this.headerJson?.find(x => x.Nombre == name)?.Valor
  }

  routing(url: string) {
    this.router.navigate([url])
  }

  redirect(url: string) {
    this.manejadorEventos.lanzarEvento('goCart')
    // this.router.navigate(['shopping-cart'])

    if (url == 'shippings') {
      // this.cartService.saveCarritoCompra(this.carrito);
      if (this.userService.userLogueado()) {
        // Acá va la petición de la información complata para la compra
        // this.user = this.userService.getUserLocal();
        this.userService.getUser().then((res) => {
          if (res.correoElectronico !== undefined) {
            this.router.navigate(["/shippings"]);
          } else {
            this.router.navigate(['/login']).then(() => {
              window.scrollTo(0, 0);
            });
          }
        }).catch(() => {
          this.router.navigate(['/login']).then(() => {
            window.scrollTo(0, 0);
          });
        });
      } else {
        console.log('No esta logueado');
        this.openDialogMessage('Es una prueba', 'Hola', 5)
      }
    }else{
      this.router.navigate(['/' + url])
    }
  }

  openDialogMessage(titulo: string, mensaje: string, tipo: number): void {
    const dialogRef = this.dialogo.open(TipoCompraComponent, { data: { title: titulo, msg: mensaje, tipo: tipo } })

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result.valor == 'login') {
        this.router.navigate(["/login"], {
          queryParams: {
            shopping: true,
          },
        })
      } else {
        this.router.navigate(["/compra-rapida"])
      }
    });
  }

  cerrar() {
    this.newProduct = false
    console.log('cerrar cart lateral');
    this.manejadorEventos.lanzarEvento('goCatalogo')
    this.router.navigate(['home'])
  }

  getCart() {
    this.items = this.cartService.getCart()
  }

  volver() {
    let aux = JSON.parse(localStorage.getItem("categoria") || '{}');
    console.log(aux.categoria)
    if (aux.categoria !== undefined) {
      this.redirect('catalogue/' + aux.categoria);
    } else {
      // this.redirect('catalogue/' + this.items[this.items.length - 1].categoria);
      this.redirect('');
    }
  }

}
