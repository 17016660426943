import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService, JsonTextService, LoadingService, TextResponse } from 'libreria-tienda';
import { DevolucionRetencionComponent } from 'libreria-tienda/shared/formularios-pqrs/devolucion-retencion/devolucion-retencion.component';
import { EntregaProdNoReclamadoComponent } from 'libreria-tienda/shared/formularios-pqrs/entrega-prod-no-reclamado/entrega-prod-no-reclamado.component';
import { FacturaComponent } from 'libreria-tienda/shared/formularios-pqrs/factura/factura.component';
import { FelicitacionesComponent } from 'libreria-tienda/shared/formularios-pqrs/felicitaciones/felicitaciones.component';
import { InconformidadComponent } from 'libreria-tienda/shared/formularios-pqrs/inconformidad/inconformidad.component';
import { PiedrasPreciosasComponent } from 'libreria-tienda/shared/formularios-pqrs/piedras-preciosas/piedras-preciosas.component';

@Component({
  selector: 'app-pqrs',
  standalone: true,
  imports: [CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatDividerModule,
    PiedrasPreciosasComponent,
    FelicitacionesComponent,
    DevolucionRetencionComponent,
    InconformidadComponent,
    EntregaProdNoReclamadoComponent,
    FacturaComponent],
  templateUrl: './pqrs.component.html',
  styleUrl: './pqrs.component.css'
})
export class PqrsComponent implements OnInit {

  panelOpenState = false;
  pqrs: any;
  opciones: any;
  tienda: boolean = false;
  opcion: string = '';

  visibleIndex: number = -1;

  jsonDetalle: TextResponse[] = [];
  formulario: any;

  public load = inject(LoadingService)
  public jsonService = inject(JsonTextService);
  public configService = inject(ConfigService);
  private route = inject(ActivatedRoute)

  constructor(private el: ElementRef, private router: Router) { }

  ngOnInit(): void {
    this.load.cargar(true)
    this.jsonService.downloadJson(this.configService._config.URLJSONPQRS).subscribe(res => {
      if (res !== undefined) {
        // console.log(res);
        this.jsonDetalle = res;
        this.load.cargar(false);
        this.pqrs = this.getName('pqrs');
        // console.log(this.pqrs);
      }
    }).closed
    this.route.queryParamMap.subscribe(params => {
      const formParam = params.get('form');
      if (formParam) {
        this.formulario = formParam;
        // console.log(this.formulario);
      } else {
        this.formulario = null
      }

    });
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  viendoPregunta(index: number) {
    // console.log(this.visibleIndex);
    // console.log(index);

    this.opciones = this.pqrs[index].opciones
    // console.log(this.opciones);
    if (this.visibleIndex == index) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = index;
    }
    // console.log(this.visibleIndex);

  }

  viendoOpcion(index: number) {
    console.log(index);
    console.log(this.opciones[index].seccion);
    if (this.opciones[index].seccion !== 'tienda') {
      this.router.navigate(['/pqrs'], { queryParams: { form: this.opciones[index].seccion } }).then(() => {
        // window.location.reload();
        window.scrollTo(0, 0);
      });
    } else {
      this.opcion = this.opciones[index].texto
      this.tienda = true
    }

  }

  viendotienda(){
    this.tienda = false
  }

  onPanelOpened(panelIndex: number): void {
    this.panelOpenState = true
    const panel = this.el.nativeElement.querySelectorAll('.mat-expansion-panel')[panelIndex];
  }

  navigate(ruta: string) {
    this.router.navigate([`/${ruta}`]).then(() => {
      window.scrollTo(0, 0);
    });
  }
}
