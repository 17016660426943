<div class="w-100 d-none d-sm-none d-md-none d-lg-block white">
  <div class="contenedor center">
    <div class="paso">
      <span class="texto-paso">PASO 1</span>
      <div class="cuadro-dorado"></div>
    </div>
    @if (paso ==1) {
    <span class="texto-paso-dos"><strong>Selecciona</strong> el largo de tú Pulsera</span>
    }@else if (paso==2) {
    <span class="texto-paso-dos"><strong>Selecciona</strong> la Pulsera que más te guste</span>
    }
  </div>
  @if (paso==1) {
  <div class="largos">
    @for (medida of medidas; track $index) {
    <div class="contenedor-medidas" (click)="pasoDos(medida)">
      {{medida}}
    </div>
    }
  </div>
  }@else if (paso==2) {
  <div class="pulseras" [ngStyle]="{'grid-template-columns': 'repeat(' +columnas + ', 1fr)'}">
    @for (pulsera of pulseras; track $index) {
    <lib-card-pulsera-venezia [producto]="pulsera" />
    }
  </div>
  }
</div>
