import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { ImgResponse, TextResponse } from '../interfaces/json-text-response';
import { MemoryCache, caching } from 'cache-manager';

interface State {
  textos: TextResponse[];
  loading: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class JsonTextService {
  private memoryCache:MemoryCache;
  private http = inject(HttpClient);

  #state = signal<State>({
    loading: true,
    textos: []
  })

  public textos = computed(() => this.#state().textos)
  public loading = computed(() => this.#state().loading)

  constructor() {
  }

  private async getMemory():Promise<MemoryCache>{
    if(this.memoryCache==undefined)
    {
      this.memoryCache = await caching('memory', {
        max: 1,
        ttl: 10 * 1000 /*milliseconds*/,
      });
    }

    return this.memoryCache;
  }

  // async downloadJson2(url: string): Promise<Observable<TextResponse[]>> {
  //   const ttl = 60 * 1000; /*milliseconds*/
  //   const memoria = await this.getMemory();
  //   return await memoria.wrap(url, () => this.downloadJson(url), ttl);
  // }

  downloadJsonImg (url: string): Observable<ImgResponse[]> {
    return this.http.get<ImgResponse[]>(url, { responseType: 'json' });
  }

  downloadJson(url: string): Observable<TextResponse[]> {
    return this.http.get<TextResponse[]>(url, { responseType: 'json' });
  }

  private downloadJsonImgInterno(url: string): Observable<ImgResponse[]> {
    return this.http.get<ImgResponse[]>(url, { responseType: 'json' });
  }
}
