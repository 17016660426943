import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-update-user',
  standalone: true,
  imports: [],
  templateUrl: './update-user.component.html',
  styleUrl: './update-user.component.css'
})
export class UpdateUserComponent implements OnInit {

  constructor(public dialogo: MatDialogRef<UpdateUserComponent>) { }

  ngOnInit(): void {
  }
  close(){
    this.dialogo.close()
  }

}
