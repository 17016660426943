<div class="d-none d-sm-block d-md-block d-lg-block">
  <div class="contenedor rotated-text" (click)="mail()">
    <a class="align-center" [href]="propiedades['boton_contactanos']['correo']">
      <i class="fa-regular fa-envelope pr-2"></i>
      <span class="text-banner" [innerHTML]="propiedades['boton_contactanos']['texto']"></span>
    </a>
  </div>
</div>
<div class="d-block d-sm-none d-md-none d-lg-none">
  <div class="contenedor" (click)="mail()">
    <a class="align-center" [href]="propiedades['boton_contactanos']['correo']">
      <i class="fa-regular fa-message imagen-girada"></i>
    </a>
  </div>
</div>
