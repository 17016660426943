<div class="center d-flex-column code-confirm-user-container">
  <div class="w-100 flex-end">
      <span (click)="close()" class="close-b">x</span>
  </div>
  <span class="text pt-3 pb-3">Hemos enviado un codigo al {{cellOrEmail}} : {{replace(cellOrEmail)}} . Por favor
      ingrésalo a continuación</span>
  <div class="d-flex-row gap-1rem pb-3">
      <form [formGroup]="formCode" action="" class="gap-form">
          <input [ngClass]="{'input-error' : submit && formCode.controls['primero'].value == '', 'input' :  !submit || submit}"  id="primero" (input)="tabFunction(0)" tabindex="0" class="input" type="text" formControlName="primero">
          <input [ngClass]="{'input-error' : submit && formCode.controls['segundo'].value == '', 'input' :  !submit || submit}" id="segundo" (input)="tabFunction(1)" tabindex="0" class="input" type="text" formControlName="segundo">
          <input [ngClass]="{'input-error' : submit && formCode.controls['tercero'].value == '', 'input' :  !submit || submit}" id="tercero" (input)="tabFunction(2)" tabindex="0" class="input" type="text" formControlName="tercero">
          <input [ngClass]="{'input-error' : submit && formCode.controls['cuarto'].value == '', 'input' :  !submit || submit}" id="cuarto" (input)="tabFunction(3)" tabindex="0" class="input" type="text" formControlName="cuarto">
      </form>
  </div>
  <div class="pb-3 w-100">
      <button id="but" (click)="confirmCode()" class="button-primary">Comprobar</button>
  </div>
  <button (click)="reSendCode()" class="button-secondary">No me ha llegado el código</button>
</div>
