import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-salesforce-chat',
  standalone: true,
  template: `
    <div>
      <script type="text/javascript">
        function initEmbeddedMessaging() {
          try {
            embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

            embeddedservice_bootstrap.init(
              '00DD6000000VW5y',
              'bot_web_prueba',
              'https://site-site-5635--uat.sandbox.my.site.com/ESWbotwebprueba1724769480070',
              {
                scrt2URL: 'https://site-site-5635--uat.sandbox.my.salesforce-scrt.com'
              }
            );
          } catch (err) {
            console.error('Error loading Embedded Messaging: ', err);
          }
        };
      </script>
      <script type="text/javascript"
              src="https://site-site-5635--uat.sandbox.my.site.com/ESWbotwebprueba1724769480070/assets/js/bootstrap.min.js"
              onload="initEmbeddedMessaging()"></script>
    </div>
  `
})
export class SalesforceChatComponent {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
    (function() {  
    window.initEmbeddedMessaging = function() {
        try {
          embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

          embeddedservice_bootstrap.init(
            '00DD6000000VW5y',
            'bot_web_prueba',
            'https://site-site-5635--uat.sandbox.my.site.com/ESWbotwebprueba1724769480070',
            {
              scrt2URL: 'https://site-site-5635--uat.sandbox.my.salesforce-scrt.com'
            }
          );
        } catch (err) {
          console.error('Error loading Embedded Messaging: ', err);
        }
      };
      })();
    `;
    this.elementRef.nativeElement.appendChild(script);

    const scriptSrc = document.createElement('script');
    scriptSrc.type = 'text/javascript';
    scriptSrc.src = 'https://site-site-5635--uat.sandbox.my.site.com/ESWbotwebprueba1724769480070/assets/js/bootstrap.min.js';
    scriptSrc.onload = () => {
      // Wait for the bootstrap.min.js script to finish loading
      setTimeout(() => {
        (window as any).initEmbeddedMessaging();
      }, 1000);
    };
    this.elementRef.nativeElement.appendChild(scriptSrc);
  }
}
