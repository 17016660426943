import { Injectable, signal } from '@angular/core';
// import { algoliasearch } from 'algoliasearch';
import { IndexWidget, Widget } from 'instantsearch.js';
import history from 'instantsearch.js/es/lib/routers/history';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';
import { Router } from '@angular/router';

// const searchClient = algoliasearch(
//   'LXXVGREM4N',
//   '8b723a5051f0dac1d89f7fbc56395695'
// );

@Injectable({
  providedIn: 'root'
})
export class InstantSearchService {

  public instantSearchInstance: InstantSearch;
  currentPage: number = 0;
  itemsTotal: number = 0;
  public resultados = signal<any[]>([]); // Signal compartido entre componentes

  constructor(private router: Router) {
    // this.instantSearchInstance = new InstantSearch({
    //   searchClient,
    //   indexName: 'ProductoCaracteristicasJson',
    //   future: { preserveSharedStateOnUnmount: true },
    //   routing: {
    //     router: history({
    //       getLocation: () => {
    //         if (typeof window === 'undefined') {
    //           // no other way to get this in constructor
    //           return new URL(
    //             router['location']._locationStrategy._platformLocation.href
    //           ) as unknown as Location;
    //         }
    //         return window.location;
    //       },
    //       cleanUrlOnDispose: false,
    //     }),
    //   },
    // });
  }

  start() {
    this.instantSearchInstance.start();
  }

  dispose() {
    this.instantSearchInstance.dispose();
  }

  addWidgets(widgets: Array<Widget | IndexWidget>) {
    this.instantSearchInstance.addWidgets(widgets);
  }

  removeWidgets(widgets: Array<Widget | IndexWidget>) {
    this.instantSearchInstance.removeWidgets(widgets);
  }

  redirectToSearchResults() {
    this.router.navigate(['/catalogo/busqueda']);
  }

  actualizarResultados(nuevosResultados: any[], totalItems: number) {
    this.itemsTotal = totalItems;
    this.resultados.set(nuevosResultados); // Actualizamos la signal con nuevos datos
  }

  // Método para volver a la página anterior
  otherPage(pagina: number = 0) {
    if (pagina == 0) {
      this.currentPage++;
    } else {
      this.currentPage = pagina
    }
    this.instantSearchInstance.helper?.setQueryParameter('page', this.currentPage).search();
  }
}
