<!-- <p>miga-pan works!</p> -->
@if (indicador == 'compra') {
<div class="start">
  <span class="text-header-catalog pr-10 cursor-pointer" (click)="routing('')">Inicio</span>
  /
  <span class="text-header-catalog pll-10 pr-10 cursor-pointer" (click)="routing('/catalogo/' + migas[0])">{{ migas[0] | titlecase }}</span>
  @if (migas.length==2) {
  /
  <span class="text-header-catalog pll-10 cursor-pointer">{{ migas[1] }}</span>
  }
  @if (migas.length>2) {
  /
  <span class="text-header-catalog pll-10 pr-10 cursor-pointer" (click)="producto(); pop()">{{
    migas[1] }}</span>
  /
  <span class="text-header-catalog pll-10 cursor-pointer">{{ migas[2] }}</span>
  }
</div>
}
