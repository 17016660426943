<div class="back-img d-flex-center d-flex-column pb-6 pd-r-l h70 height-min">
  <div class="d-flex-column d-flex-center pb-5 ">
      <div class="d-flex-row d-flex-center">
          <span class="icon-Contactanos font-size-24"></span>
          <h2 class="title-principal pl-1rem">CONTÁCTANOS</h2>
      </div>
      <span class="sub-title pt-3">Estos son nuestros canales de atención para tus solicitudes</span>
  </div>
  <div class="column row gap-6">
      <div class="card-contact d-flex-column d-flex-center">
          <span class="icon-Chat icon-color-size"></span>
          <span class="title">Asistente virtual</span>
          <button (click)="openChat()" class="button-primary">Ir al chat</button>
          <span class="text">Lunes a Viernes - 8:00 am - 6:00 pm <br>
              Sábados - 8:00 am - 3:00 pm</span>
      </div>
      <div class="card-contact d-flex-column d-flex-center">
          <span class="icon-Telefono icon-color-size"></span>
          <span class="title">Teléfonos de contacto</span>
          <span class="text">Nacional 01-8000-180-188 <br>
              Bogotá: (1) 742 6010<br>
              Lunes a Viernes - 8:00 am - 6:00 pm<br>
              Sábados - 8:00 am - 3:00 pm.</span>
      </div>
      <div class="card-contact d-flex-column d-flex-center">
          <span class="icon-Mail icon-color-size"></span>
          <span class="title">Email</span>
          <span class="text">{{email}}</span>
      </div>
  </div>
</div>
