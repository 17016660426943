import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ConfigService, ExpressService, ItemCar, JsonTextService, ShoppingCartService, TextResponse, caracteristicas, infoVenecia, items } from 'libreria-tienda';
import { FavoritoCorazonComponent } from 'libreria-tienda/sfa/components/favorito-corazon/favorito-corazon.component';
import { EtiquetaDescuentoComponent } from 'libreria-tienda/shared/etiqueta-descuento/etiqueta-descuento.component';
import { ComplementoComponent } from './complemento/complemento.component';
import { NumeroPuntoPipe } from 'libreria-tienda';

@Component({
  selector: 'lib-detalles-producto-venezia',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatDividerModule,
    FavoritoCorazonComponent, ReactiveFormsModule, MatFormFieldModule, EtiquetaDescuentoComponent, ComplementoComponent, NumeroPuntoPipe],
  templateUrl: './detalles-producto-venezia.component.html',
  styleUrl: './detalles-producto-venezia.component.css'
})
export class DetallesProductoVeneziaComponent implements OnInit {

  @Input() codigo: string;
  // caracteristicas: caracteristicas[];
  @Input() item: items;
  @Input() complementos: infoVenecia[];

  selectSize: boolean = true;
  open: boolean = false;
  submited: boolean = false;
  unico: boolean = false;

  complementosSelecd: boolean[] = [];

  jsonDetalle: TextResponse[];

  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)
  public cartService = inject(ShoppingCartService)
  public expressService = inject(ExpressService)

  constructor() { }

  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLJSONDETALLE).subscribe(res => {
      this.jsonDetalle = res;
    })
    this.item.cantidad = 1
    if (this.complementos) {
      this.complementosSelecd = new Array(this.complementos.length).fill(false)
    }
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  onPanelClosed() {
    this.open = false
  }

  onPanelOpened() {
    this.open = true
  }

  mas() {
    if (this.item.tallascanti[0] >= (this.item.cantidad + 1)) {
      this.item.cantidad++
    }
    else {
      this.unico = true
    }
  }

  menos() {
    if ((this.item.cantidad - 1) > 0) {
      this.item.cantidad--
      this.unico = false
    }
  }

  agregarCarrito() {
    if (this.item.cantidad == this.item.tallascanti[0]) {
      this.unico = true
    } else {
      this.unico = false
      this.agregarComplentosCarrito()
      let aux: ItemCar = new ItemCar();
      aux.cantidad = 1;
      aux.idProducto = this.item.codigo
      aux.imagen = this.item.imagen
      aux.tallaId = -1;
      aux.descWeb = this.item.descripcion;
      aux.descTalla = '';
      this.cartService.addItemCart(aux)

    }
  }

  donwloadPolitics() {
    window.open(this.configService._config.URLPDFTERMINOS, '_blank');
  }

  handleKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  complemtoSele(i: number, e: any) {
    this.complementosSelecd[i] = e
  }

  agregarComplentosCarrito() {
    for (let index = 0; index < this.complementosSelecd.length; index++) {
      if (this.complementosSelecd[index]) {
        let aux: ItemCar = new ItemCar();
        aux.cantidad = 1;
        aux.idProducto = this.complementos[index].idProducto
        aux.imagen = this.complementos[index].imagen
        aux.tallaId = -1;
        aux.descWeb = '';
        aux.descTalla = '';
        this.cartService.addItemCart(aux)
      }
    }

  }


}
