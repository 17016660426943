export class detalle {
  categoria: string;
  referencia: string;
  referenceProv: string;
  gia: boolean;
  busqueda: string;
  precio_min: string
}

export class imagenesAux {
  img: string;
  error: boolean = false
}
