<div class="d-none d-sm-none d-md-none d-lg-block back-img">
  <lib-pasos-compra [paso]="4" />
  <div *ngIf="aproved" class="d-flex-column center pt-5 pb-5 pl-pr-100">
    <span class="icon-Icono_aprobacion apro  size-15"></span>
    <h2 class="apro apro-text">{{getName('title')}}</h2>
    <h2 class="client">{{getName('subtitle')}} {{user.nombre | titlecase}}!</h2>
    <span class="sub-text pt-5 pb-5">{{getName('info')}}</span>
    <div class="pb-5 payment-container d-flex-column">
      <div class="payment-container-header d-flex-row space-bet align-center">
        <span class="order font-size-18 pl-2rem">Orden # {{idOrdenDeVenta}}</span>
        <div class="d-flex-column">
          <span class="fecha-price font-size-16">Fecha de compra</span>
          <span class="order font-size-16">{{respuesta.order.ordenDeVenta.Cabecera.FechaSistema |
            date:'shortDate':'IST'}}</span>
        </div>
        <div class="d-flex-column pr-2rem">
          <span class="fecha-price font-size-16">Precio total</span>
          <span class="order font-size-18">${{totalPrice() | number}}</span>
        </div>
      </div>
      <div *ngFor="let product of productos" class="d-flex-row space-bet align-center gap-r pt-3 pr-2rem">
        <div class="pl-2rem">
          <img class="img-size" src="{{product.imagen}}" alt="">
        </div>
        <div class="d-flex-coumn w-50">
          <span class="ref">Ref. {{product.codigo}}</span>
          <span class="des">{{product.descripcion}}</span>
        </div>
        <div>
          <span class="talla-canti">Talla : <span class="talla-canti" *ngIf="product.talla != null">
              {{product.talla}}</span> <span class="talla-canti" *ngIf="product.talla == null"> No tiene</span></span>
        </div>
        <div>
          <span class="talla-canti">Cantidad: </span>
          <span class="talla-canti">{{product.cantidad}}</span>
        </div>
        <div class="d-flex-column">
          <span class="unit-value">Valor unitario</span>
          <span class="price-unit">${{product.precioAutorizado | number}}</span>
        </div>
      </div>
      <div class="d-flex-row pl-2rem pt-3 space-bet pr-2rem">
        <div *ngIf="respuesta.order.tipoEnvio.metodo == 'DOMICILIO'" class="d-flex-column pb-3">
          <span class="envios">Datos de envio</span>
          <span class="titles-envio">Domicilio</span>
          <span class="titles-envio">Destinatario: <span class="sub-text-info"
              *ngIf="respuesta.order.tipoEnvio.yoRecibo">{{respuesta.order.tipoEnvio.nombreUser | titlecase}}</span>
            <span class="sub-text-info"
              *ngIf="!respuesta.order.tipoEnvio.yoRecibo">{{respuesta.order.tipoEnvio.nombreTercero |
              titlecase}}</span> </span>
          <span class="titles-envio">Celular: <span
              class="sub-text-info">{{respuesta.order.tipoEnvio.numeroContacto}}</span></span>
          <span class="titles-envio">Direccion de envio: <span
              class="sub-text-info">{{respuesta.order.tipoEnvio.direccion | titlecase}}
              {{respuesta.order.tipoEnvio.nombreDepto | titlecase }}-{{respuesta.order.tipoEnvio.nombreMunicipio |
              titlecase}}</span></span>
        </div>
        <div *ngIf="respuesta.order.tipoEnvio.metodo == 'RETIRO EN TIENDA'" class="d-flex-column pb-3">
          <span class="envios">Datos de recogida</span>
          <span class="titles-envio">Destinatario <span class="sub-text-info">{{respuesta.order.tipoEnvio.direccion |
              titlecase}}</span></span>
          <span class="titles-envio">Tienda <span class="sub-text-info"> {{respuesta.order.tipoEnvio.nombreTienda |
              titlecase}}</span></span>
        </div>
        <div class="d-flex-column center">
          <div class="pb-3">
            <button class="button-secondary">Ver detalles del pedido</button>
          </div>
          <div>
            <button (click)="goBuy()" class="button-primary">continuar comprando</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!aproved" class="center d-flex-column pt-5 pb-5">
    <mat-icon class="color-red size-15 size-icon">error_outline</mat-icon>
    <h2 class="title-fail color-red">{{getName('title')}}</h2>
    <span class="sub-text pb-5">{{getName('subtitle')}}</span>
    <div class="pl-pr pb-5">
      <div class="fail-container center d-flex-column">
        <span class="fail-subtext pb-3">{{getName('info')}}</span>
        <div>
          <button (click)="openChat()" class="button-primary">{{getName('but-text')}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="d-block d-sm-block d-md-block d-lg-none back-img">
  <div class="header-phone d-flex-row center-space space-around">
    <span class="icon-shoppingCart color-white font-size-22"></span>
    <span class="icon-Icono_envio color-white font-size-22"></span>
    <span class="icon-Icono_pago color-white font-size-22"></span>
    <span class="icon-Icono_aprobacion color-white font-size-22"></span>
  </div>
  <div *ngIf="aproved" class="d-flex-column center pt-3 padd-mob">
    <span class="icon-Icono_aprobacion apro size-15"></span>
    <h2 class="apro apro-text">{{getName('title')}}</h2>
    <h2 class="client">{{getName('subtitle')}} {{user.nombre | titlecase}}!</h2>
    <span class="sub-text pt-3 pb-3">{{getName('info')}}</span>
    <span class="order-mobile pb-3">Orden # {{idOrdenDeVenta}}</span>
    <div class="payment-container-header-mobile d-flex-row w-100 space-around align-center">
      <div class="d-flex-column">
        <span class="title-mobile font-size-14">Fecha de compra</span>
        <span class="title-mobile font-size-14">{{respuesta.order.ordenDeVenta.Cabecera.FechaSistema |
          date:'shortDate':'IST'}}</span>
      </div>
      <div class="d-flex-column">
        <span class="title-mobile font-size-14">Precio total</span>
        <span class="title-mobile font-size-16">${{totalPrice() | number}}</span>
      </div>
    </div>
    <div *ngFor="let product of productos" class="pt-3 d-flex-column padd-mob w-100 ">
      <div class="d-flex-row">
        <div>
          <img class="img-size" src="{{product.imagen}}" alt="">
        </div>
        <div class="d-flex-column pl-1rem gap08">
          <span class="info-mobile">Ref. {{product.codigo}}</span>
          <span class="info-mobile">{{product.descripcion}}</span>
          <span class="info-mobile">Talla : <span class="" *ngIf="product.talla != null"> {{product.talla}}</span> <span
              class="" *ngIf="product.talla == null"> No tiene</span></span>
          <span class="info-mobile">Cantidad: {{product.cantidad}}</span>
          <span class="price-mobile">${{product.precioAutorizado | number}}</span>
        </div>
      </div>
    </div>
    <div class="d-flex-column pl-2rem pt-3 space-bet pr-2rem w-100 pb-3">
      <div *ngIf="respuesta.order.tipoEnvio.metodo == 'DOMICILIO'" class="d-flex-column pb-3">
        <span class="envios">Datos de envio</span>
        <span class="titles-envio">Domicilio</span>
        <span class="titles-envio">Destinatario: <span class="sub-text-info"
            *ngIf="respuesta.order.tipoEnvio.isReceiveByMe">{{respuesta.order.tipoEnvio.nameUser | titlecase}}</span>
          <span class="sub-text-info"
            *ngIf="respuesta.order.tipoEnvio.isReceiveByOther">{{respuesta.order.tipoEnvio.nameOther |
            titlecase}}</span> </span>
        <span class="titles-envio">Celular: <span
            class="sub-text-info">{{respuesta.order.tipoEnvio.cellphone}}</span></span>
        <span class="titles-envio">Direccion de envio: <span class="sub-text-info">{{respuesta.order.tipoEnvio.direccion
            | titlecase}} {{respuesta.order.tipoEnvio.nameDepto | titlecase}}-{{respuesta.order.tipoEnvio.nameMunicipe |
            titlecase}}</span></span>
      </div>
      <div *ngIf="respuesta.order.tipoEnvio.metodo == 'RETIRO EN TIENDA'" class="d-flex-column pb-3">
        <span class="envios">Datos de recogida</span>
        <span class="titles-envio">Destinatario <span
            class="sub-text-info">{{respuesta.order.tipoEnvio.direccion}}</span></span>
        <span class="titles-envio">Tienda <span class="sub-text-info">
            {{respuesta.order.tipoEnvio.nameStore}}</span></span>
      </div>
      <div class="d-flex-column center">
        <div class="pb-3 w-100 center">
          <button class="button-secondary">Ver detalles del pedido</button>
        </div>
        <div class="w-100 center">
          <button (click)="goBuy()" class="button-primary">continuar comprando</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!aproved" class="center d-flex-column pt-5 pb-5 pl-pr-1rem">
    <mat-icon class="color-red size-15 size-icon center">error_outline</mat-icon>
    <h2 class="title-fail color-red margin-0 text-center">{{getName('title')}}</h2>
    <span class="sub-text pt-3 pb-3 p-rejected text-center">{{getName('subtitle')}}</span>
    <div class="fail-container center d-flex-column">
      <span class="fail-subtext pb-3">{{getName('info')}}</span>
      <button class="button-primary">{{getName('but-text')}}</button>
    </div>
  </div>
</div> -->
