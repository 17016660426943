import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { Cart, ConfigService, ExpressService, JsonTextService, LoadingService, ShoppingCartService, SioServicesService, TextResponse, User, items } from 'libreria-tienda';
import { compraMercadoPago } from 'libreria-tienda/src/public-api';
import { PasosCompraComponent } from '../pasos-compra/pasos-compra.component';

@Component({
  selector: 'lib-payment-confirmation',
  standalone: true,
  imports: [CommonModule, MatIconModule, PasosCompraComponent],
  templateUrl: './payment-confirmation.component.html',
  styleUrl: './payment-confirmation.component.css'
})
export class PaymentConfirmationComponent implements OnInit {

  shoppingCart: Cart = new Cart();
  aproved: boolean = false;
  mensaje: string;
  productos: items[] = new Array<items>();
  respuesta: compraMercadoPago = new compraMercadoPago();
  reference_externa: string;
  idMercadoPago: string;
  idOrdenDeVenta: number;
  user: User = new User();
  urlJsonStatus: string = "";
  jsonStatus: TextResponse[];

  public jsonService = inject(JsonTextService)
  public expressService = inject(ExpressService)
  public cartService = inject(ShoppingCartService)
  public configService = inject(ConfigService)
  public load = inject(LoadingService)


  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params["status"] == "approved") {
        this.urlJsonStatus = this.configService._config.URLRESPONSESUCCESS;
        this.download(this.urlJsonStatus)
        this.aproved = true
        this.expressService.getOrderApproved(params["external_reference"]).subscribe(res => {
          this.reference_externa = params["external_reference"]
          this.idMercadoPago = res.order.id
          this.idOrdenDeVenta = res.order.idOrdenDeVenta
          console.log(res);

          if (res.order.idOrdenDeVenta == undefined) {
            setTimeout(() => {
              // location.reload()
            }, 5000);
          }
          this.productos = res.order.products
          this.respuesta = res
          if (typeof localStorage !== 'undefined') {
            if (localStorage.getItem("userLogged") != undefined) {
              // this.user = JSON.parse(localStorage.getItem("userLogged") || "")
              console.log('Usuario no log');
            }
          }
        })
        if (typeof localStorage !== 'undefined') {
          if ((localStorage.getItem("shoppingCart") != undefined)) {
            // this.cartService.cleanCart()
            console.log('limpiar carro');

          }
        }
      } else {
        this.aproved = false;
        this.urlJsonStatus = this.configService._config.URLRESPONSEFAIL;
        this.download(this.urlJsonStatus)
      }
      this.load.cargar(false)
    })
  }
  totalPrice() {
    let total = 0
    this.productos.forEach(item => {
      total += parseFloat(item.precioAutorizado)
    })
    return total
  }
  goBuy() {
    this.router.navigate(['home']).then(() => {
      window.location.reload();
    });
  }
  download(link: string) {
    this.jsonService.downloadJson(link).subscribe(res => {
      this.jsonStatus = res
    })
  }
  getName(name: string) {
    return this.jsonStatus?.find(x => x.Nombre == name)?.Valor
  }
  openChat() {
    // if (screen.width > 1023) {
    //   Tawk_API.toggle();
    // } else {
    //   const fileUrl = "https://api.whatsapp.com/send?phone=573186338303&";
    //   window.open(fileUrl, '_blank');
    // }
    console.log('Open Chat');

  }



}
