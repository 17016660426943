<div class="container" (click)="select()" (change)="select()">
  <label class="checkbox-container check">
    <input type="checkbox" class="checkbox" [checked]="isChecked" >
    <span class="checkmark"></span>
  </label>
  <img src="{{info.imagen}}" alt="" class="img">
</div>
<span class="circle"></span>
<p class="texto">{{info.descripcion}}</p>
<div class="d-flex-column center cursor w-100">
  <p class="priceSaleFull">${{info.precioFull | number}}</p>
  <div class="d-flex-row">
    <p class="prices" [ngClass]="{'descuento': info.porcentaje > 10}">${{info.precioAutorizado | number}}</p>
    <!-- @if (info.porcentaje !== 0) {
      <lib-etiqueta-descuento [porcentaje]="info.porcentaje" />
    } -->
  </div>
</div>
