import { CommonModule } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { DomicilioComponent } from 'libreria-tienda/shopping-cart/domicilio/domicilio.component';
import { MessageBoxComponent } from 'libreria-tienda/shopping-cart/domicilio/message-box/message-box.component';
import { PasosCompraComponent } from 'libreria-tienda/shopping-cart/pasos-compra/pasos-compra.component';
import { ResumenComponent } from 'libreria-tienda/shopping-cart/resumen/resumen.component';
import { RetiroTiendaComponent } from 'libreria-tienda/shopping-cart/retiro-tienda/retiro-tienda.component';
import { SendcodeComponent } from 'libreria-tienda/shopping-cart/sendcode/sendcode.component';
import { ConfigService, ControlSesionService, ItemCar, ItemCompra, LoadingService, TipoEnvio, userlogged, UserService } from 'libreria-tienda';
import { AddressService } from 'libreria-tienda';
import { BuyServiceService } from 'libreria-tienda';
import { Cart, CommonLista, ExpressService, JsonTextService, ListaProductos, ShoppingCartService, SioServicesService, TextResponse, User, calculateTime, ciudades, direccion, puntosKevins, tiempoEntregaPeticion, time } from 'libreria-tienda';
import { PasoCompraMobileComponent } from 'libreria-tienda/shopping-cart/paso-compra-mobile/paso-compra-mobile.component';

@Component({
  selector: 'app-shippings',
  standalone: true,
  imports: [CommonModule, MatTabsModule, ResumenComponent, PasosCompraComponent, DomicilioComponent, RetiroTiendaComponent, PasoCompraMobileComponent],
  templateUrl: './shippings.component.html',
  styleUrl: './shippings.component.css'
})
export class ShippingsComponent implements OnInit {

  open = false;
  costoEnvio: number = 0

  userLog: userlogged = new userlogged()

  user: User = new User();
  // @ViewChildren('checkBox') checkBox: QueryList<any>;
  isReceiveByMe: boolean = true;
  // isReceiveByOther: boolean;
  // isSendNormal: boolean = true;
  // isSendExpress: boolean;
  // shoppingCart: ItemCar[] = [];
  direccion: direccion = new direccion();
  // direcciones: Array<direccion> = new Array<direccion>();
  message: any = {
    textBool: false,
    texto: null
  }
  // listCities: ciudades[] = new Array<ciudades>();
  // listDocuments: CommonLista[] = new Array<CommonLista>();
  // listPoints: puntosKevins[] = new Array<puntosKevins>();
  // pointKevinsSelected: puntosKevins;
  // TiempoEntregaPeticion: calculateTime = new calculateTime()
  // tiempoentregape: tiempoEntregaPeticion = new tiempoEntregaPeticion()
  // listaproductos: ListaProductos[] = new Array<ListaProductos>()
  // product: ListaProductos = new ListaProductos();
  // time: time = new time()
  tabLabel = "DOMICILIO"
  // envio = 1;
  // phone = false;
  isDireccion = false;
  formDomicilioBool: boolean = false;
  // formTiendaBool: boolean = false;
  // formSelectedStore: FormGroup;
  tipoEnvio: TipoEnvio = new TipoEnvio()
  // section: number = 1
  // jsonShippings: TextResponse[]
  // ciudad: string = ""

  listItemsCompra: ItemCompra[] = [];
  listItemsShoppingCart: ItemCar[] = [];

  // jsonService = inject(JsonTextService)
  // expressService = inject(ExpressService)
  sesionService = inject(ControlSesionService)
  cartService = inject(ShoppingCartService)
  sioService = inject(SioServicesService)
  // configService = inject(ConfigService)
  buyService = inject(BuyServiceService)
  load = inject(LoadingService)

  userService = inject(UserService)

  // addresService = inject(AddressService)
  // _router = inject(Router)

  constructor(public dialogo: MatDialog, private fb3: FormBuilder) {
    //   this.formSelectedStore = this.fb3.group({
    //     ciudad: ['', Validators.required],
    //     punto: ['', Validators.required],
    //   })
  }

  ngOnInit() {
    this.listItemsShoppingCart = this.cartService.getCart();
    this.informacionCarrito();

    this.userLog = this.userService.getUserLocal();

    this.userService.getUser().then(res => {
      this.user = res;
    }).catch(err => {
      this.sesionService.cerrarSesion();
    });
    //   if (this.listItemsCompra.length == 0) {
    //     this.load.cargar(true)
    //   }
    //   this.jsonService.downloadJson(this.configService._config.URLSHIPPINGSJSON).subscribe(res => {
    //     this.jsonShippings = res
    //   });
    //   this.sendType.metodo = this.tabLabel
    //   this.sendType.message = null;
    //   this.sendType.sendNormal = true
    //   this.sendType.sendExpress = false
    //   this.shoppingCart = this.cartService.getCart()
    //   for (const id of this.shoppingCart) {
    //     this.sioService.GetProductoExistenciasCiaByIdCommand(id.idProducto).subscribe(result => {
    //       // id.precioAutorizado = result.infoPrecio[0].precioAutorizado
    //       // id.precioFull = result.infoPrecio[0].precioFull
    //     }, error => {
    //       console.log(error)
    //     })

    this.buyService.newBuy.subscribe(buy => {
      if (buy) {
        // usuario
        this.buyService.sendType.nombreUser = this.userLog.nombre;
        this.buyService.sendType.apellidoUser = this.userLog.apellido;
        this.buyService.sendType.tipoDocumentoUser = this.user.tipoDocumento;
        this.buyService.sendType.numeroDocumentoUser = this.user.numeroDocumento;
        this.buyService.sendType.numeroContacto = this.user.celular;
        this.buyService.sendType.correo = this.user.correoElectronico;

        this.buyService.sendType.yoRecibo = this.isReceiveByMe;

        this.buyService.sendType.mensajePersonalizado = this.message;

        // locacion
        this.buyService.sendType.idDepto = this.direccion.departamento.idDepto;
        this.buyService.sendType.idMunicipio = this.direccion.ciudad.idMunicipio;
        this.buyService.sendType.nombreDepto = this.direccion.departamento.departamentoNombre;
        this.buyService.sendType.nombreMunicipio = this.direccion.ciudad.municipioNombre;


        if (this.tabLabel == "DOMICILIO") {
          if (this.isDireccion) {
            this.buyService.sendType.metodo = this.tabLabel;
            this.buyService.sendType.direccion = this.direccion.direccion;
            this.buyService.sendType.envioNormal = true; // this.isSendNormal
            this.buyService.sendType.envioExpress = false; // this.isSendExpress


            if (this.isReceiveByMe) {
              this.buyService.sendType.idCentroCosto = null;
              this.buyService.sendType.yoRecibo = true;
              this.buyService.sendType.nombreTercero = null;
              this.buyService.sendType.apellidoTercero = null;
              this.buyService.sendType.tipoDocumentoTercero = null;
              this.buyService.sendType.numeroDocumentoTercero = null;
              this.buyService.sendType.mensajePersonalizado = this.message.textBool ? this.message.texto : "";

              // console.log('Servicio', this.buyService.sendType);

              // this.buyService.setSendStype(this.tipoEnvio)
              this.buyService.formValid(true, "")
            } else if (!this.isReceiveByMe && this.formDomicilioBool) {
              this.buyService.formValid(true, "")
            } else {
              // this.openDialogMessage("Formulario invalido","Por favor llene bien los datos del formulario")
              this.buyService.formValid(false, "Formulario invalido, Por favor llene bien los datos del formulario")
            }
          } else {
            // this.openDialogMessage("No tiene dirección","Para continuar con el proceso agregue una dirección")
            this.buyService.formValid(false, "No tiene dirección, para continuar con el proceso agregue una dirección")
          }
        }
        // Retiro en tienda
        //  else {
        //         if (this.isDireccion) {
        //           this.sendType.metodo = this.tabLabel
        //           this.sendType.direccion = null
        //           this.sendType.idDepto = null
        //           this.sendType.idMunicipe = null
        //           this.sendType.nameDepto = null
        //           this.sendType.nameMunicipe = null
        //           this.sendType.sendNormal = null
        //           this.sendType.sendExpress = null
        //           if (!(Object.entries(this.pointKevinsSelected).length === 0)) {
        //             if (this.isReceiveByMe) {
        //               this.sendType.isReceiveByMe = true
        //               this.sendType.isReceiveByOther = false;
        //               this.sendType.nameOther = null
        //               this.sendType.lastNameOther = null
        //               this.sendType.typeOfDocumentOther = null
        //               this.sendType.numberDocumentOther = null
        //               this.sendType.idCcosto = this.pointKevinsSelected.idCcosto
        //               this.sendType.message = this.message
        //               this.sendType.nameStore = this.pointKevinsSelected.nombrePunto
        //               this.buyService.setSendStype(this.sendType)
        //               this.buyService.formValid(true, "")
        //             }
        //             else if (this.isReceiveByOther && this.formTiendaBool) {
        //               this.sendType.isReceiveByMe = false
        //               this.sendType.isReceiveByOther = true;
        //               this.sendType.idCcosto = this.pointKevinsSelected.idCcosto
        //               this.sendType.message = this.message
        //               this.buyService.setSendStype(this.sendType)
        //               this.buyService.formValid(true, "")
        //             }
        //             else {
        //               // this.openDialogMessage("Formulario invalido","Por favor llene bien los datos del formulario")
        //               this.buyService.formValid(false, "Formulario invalido, Por favor llene bien los datos del formulario")
        //             }
        //           } else {
        //             // this.openDialogMessage("No ha seleccionado una tienda","Para continuar con el proceso seleccione la tienda donde recogerá sus productos")
        //             this.buyService.formValid(false, "No ha seleccionado una tienda, para continuar con el proceso seleccione la tienda donde recogerá sus productos")
        //           }
        //         } else {
        //           // this.openDialogMessage("No tiene dirección","Para continuar con el proceso agregue una dirección")
        //           this.buyService.formValid(false, "No tiene dirección de facturación, para continuar con el proceso agregue una dirección")
        //         }
        //       }
      }

    })

  }

  //   // this.direcciones.push(new direccion())

  //   this.userService.getUserDireccion().then((res) => {
  //     if (res.correoElectronico !== undefined) {
  //       this.user = res
  //       this.sendType.nameUser = this.user.nombre
  //       this.sendType.lastNameUser = this.user.apellido
  //       this.sendType.numberDocumentUser = this.user.numeroDocumento
  //       this.sendType.typeOfDocumentUser = this.user.tipoDocumento
  //       this.direcciones = this.user.direcciones
  //       if (this.shoppingCart.length <= 0) {
  //         this._router.navigate(["/shopping-cart"])
  //       }
  //       // localStorage.setItem("userLogged", JSON.stringify(this.user));
  //       if (this.user.direcciones.length > 0) {
  //         let dire = this.user.direcciones.filter(x => x.isPrincipal === true)
  //         this.user.direcciones.forEach((dire, i) => {
  //           if (dire.isPrincipal) {
  //             this.direccion = dire
  //             this.direcciones.splice(i, 1)
  //             this.isDireccion = true
  //             this.sendType.direccion = dire.direccion
  //             this.sendType.idMunicipe = dire.ciudad.id.toString()
  //             this.sendType.idDepto = dire.departamento.idDepto
  //             this.sendType.nameDepto = dire.departamento.departamentoNombre
  //             this.sendType.nameMunicipe = dire.ciudad.municipioNombre
  //             this.ciudad = dire.ciudad.municipioNombre
  //             this.addresService.setDirection(this.ciudad)
  //             this.sendType.cellphone = dire.celular
  //           }
  //         })
  //         this.direcciones.unshift(dire[0])
  //       }
  //       if (Object.entries(this.direcciones).length === 0) {
  //         this.isDireccion = false
  //         this.direcciones.push(new direccion())
  //       }
  //       this.GetTiempoEntregaProductosQuery()
  //     } else {
  //       this._router.navigate(['/login']).then(() => {
  //         window.scrollTo(0, 0);
  //       });
  //     }
  //   }).then((error) => {
  //     this._router.navigate(['/login']).then(() => {
  //       window.scrollTo(0, 0);
  //     });
  //   });

  //   this.sioService.GetCiudadesKevins().subscribe(list => {
  //     this.listCities = list
  //   })

  //   this.sioService.GetListaPorCodigo("TPDOC").subscribe(list => {
  //     this.listDocuments = list
  //   })

  // }

  recibo(e: any) {
    this.isReceiveByMe = e;
  }

  newMensaje(e: any) {
    if (e.textBool) {
      this.message = e;
    } else {
      this.message.textBool = false;
    }
  }

  direccionPrin(e: any) {
    if (e.direccion) {
      this.isDireccion = true;
      this.direccion = e
    }
  }

  informacionCarrito() {
    this.sioService.getInfoArticulosCarrito(this.listItemsShoppingCart).then((resultado) => {
      if (resultado.exitoso) {
        this.listItemsCompra = resultado.valor;
        // console.log(this.listItemsCompra);
        this.cartService.saveCarritoCompra(this.listItemsCompra)
        this.load.cargar(false);
      }
    }).catch((error) => {
      window.location.reload();
    });
  }

  // mensaje(event: string) {
  //   this.message = event
  // }

  // sendTypeEmit(event: SendType) {
  //   this.sendType = event
  // }

  // sectionEmit(event: number) {
  //   this.section = event
  //   if (this.section == 1) {
  //     this.formDomicilioBool = true
  //     this.formTiendaBool = false
  //   } else {
  //     this.formDomicilioBool = false
  //     this.formTiendaBool = true
  //   }
  // }

  // recibeYo(event: boolean) {
  //   this.isReceiveByMe = event
  //   this.isReceiveByOther = false
  // }

  // recibeOtro(event: boolean) {
  //   this.isReceiveByMe = false
  //   this.isReceiveByOther = event
  // }

  // openDialogMessage(titulo: string, mensaje: string): void {
  //   const dialogRef = this.dialogo.open(MessageBoxComponent, { data: { title: titulo, msg: mensaje } })
  // }

  // onChangeEntrega(event: any) {
  //   if (this.isSendNormal) {
  //     this.isSendExpress = false
  //     this.sendType.sendNormal = this.isSendNormal
  //   }
  //   else {
  //     this.isSendExpress = true
  //     this.sendType.sendExpress = this.isSendExpress
  //   }
  //   this.envio = 1
  // }

  // onChangeEntrega2(event: any) {
  //   if (this.isSendExpress) {
  //     this.isSendNormal = false
  //     this.sendType.sendExpress = this.isSendExpress
  //   }
  //   else {
  //     this.isSendNormal = true
  //     this.sendType.sendNormal = this.isSendNormal
  //   }
  //   this.envio = 2
  // }

  // tipoDoc(event: any) {
  // }

  // citieSelected(event: any) {
  //   this.sioService.GetCentrodeCostoCommand(event.value.idCiudad).subscribe(list => {
  //     this.listPoints = list
  //   })
  // }

  // pointSelected(event: any) {
  //   this.pointKevinsSelected = event.value
  //   this.sendType.idCcosto = this.pointKevinsSelected.idCcosto;
  // }

  // routing() {
  //   this.openDialogMessageSendcode()
  // }

  // openDialogMessageSendcode(): void {
  //   const dialogRef = this.dialogo.open(SendcodeComponent, { data: { user: this.user }, panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res => {
  //     if (res)
  //       this._router.navigate(["/modify-address"])
  //   })
  // }

  // GetTiempoEntregaProductosQuery() {
  //   this.shoppingCart.forEach(item => {
  //     this.product = new ListaProductos()
  //     this.product.Cantidad = item.cantidad
  //     // this.product.CodTalla = item.talla || ""
  //     this.product.IdProducto = item.idProducto
  //     this.listaproductos.push(this.product)
  //   })
  //   this.tiempoentregape.IdCiudad = "0";
  //   console.log();

  //   this.tiempoentregape.CodDaneMunicipio = this.direcciones[0].ciudad.idDepto + this.direcciones[0].ciudad.idMunicipio;
  //   this.tiempoentregape.TipoEntrega = 0;
  //   this.tiempoentregape.ListaProductos = this.listaproductos;
  //   this.TiempoEntregaPeticion.TiempoEntregaPeticion = this.tiempoentregape;
  //   this.sioService.GetTiempoEntregaProductosQuery(this.TiempoEntregaPeticion).subscribe(res => {
  //     this.time = res
  //   })
  // }

  // getName(name: string) {
  //   return this.jsonShippings?.find(x => x.Nombre == name)?.Valor
  // }

  onTabChange(event: MatTabChangeEvent) {
    this.tabLabel = event.tab.textLabel
    // console.log(this.tabLabel);

    // if (event.tab.textLabel == "RETIRO EN TIENDA") {
    //   this.section = 2
    //   this.buyService.sectionEmit.emit(this.section)
    //   this.message = ""
    //   this.sendType.metodo = event.tab.textLabel
    //   this.sendType.direccion = null
    //   this.sendType.idMunicipe = null
    //   this.sendType.nameDepto = null
    //   this.sendType.nameMunicipe = null
    //   this.sendType.idDepto = null
    //   this.sendType.cellphone = null
    //   this.envio = -1
    //   this.buyService.messageInvalid.emit("")
    // }
    // if (event.tab.textLabel == "DOMICILIO") {
    //   this.message = ""
    //   this.section = 1
    //   this.buyService.sectionEmit.emit(this.section)
    //   this.formSelectedStore.reset()
    //   this.pointKevinsSelected = new puntosKevins()
    //   this.sendType.idCcosto = this.pointKevinsSelected.idCcosto;
    //   this.sendType.metodo = event.tab.textLabel
    //   this.buyService.messageInvalid.emit("")
    //   if (this.direcciones.length > 0) {
    //     this.sendType.direccion = this.direcciones[0].direccion
    //     this.sendType.idMunicipe = this.direcciones[0].ciudad.idMunicipio
    //     this.sendType.nameDepto = this.direcciones[0].departamento.departamentoNombre
    //     this.sendType.nameMunicipe = this.direcciones[0].ciudad.municipioNombre
    //     this.sendType.idDepto = this.direcciones[0].departamento.idDepto
    //     this.sendType.cellphone = this.direcciones[0].celular
    //   }
    //   if (this.isSendNormal) {
    //     this.envio = 1
    //     this.sendType.sendNormal = this.isSendNormal
    //   }
    //   else if (this.isSendExpress) {
    //     this.envio = 2
    //     this.sendType.sendExpress = this.isSendExpress
    //   }
    //   else
    //     this.envio = -1
    // }
  }

  onPanelClosed() {
    this.open = false
  }
  onPanelOpened() {
    this.open = true
  }

  changePrice(event: any) {
    this.costoEnvio = event
  }

}
