import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { env } from "../interfaces/env";


@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  prueba: string = 'Esto es una prueba'

  private configSubject = new Subject<any>();
  config$ = this.configSubject.asObservable();

  _config: env

  // = {
  //   urlCardJson: "https://qastorageorafa.blob.core.windows.net/json-components/tiempo-cartas.json",
  //   urlService_Sio: "https://qa.apis.orafa.com.co/sio-p",
  //   urlJsonImage: "https://qastorageorafa.blob.core.windows.net/json-components/image-slider-home.json",
  //   urlJsonGia: "https://qastorageorafa.blob.core.windows.net/json-components/gia.json",
  //   urlJsonAbout: "https://qastorageorafa.blob.core.windows.net/json-components/about-us.json",
  //   urlJsonImgMarcas: "https://qastorageorafa.blob.core.windows.net/json-components/imagenes-marcas-home.json",
  //   urlJsonCategories: "https://qastorageorafa.blob.core.windows.net/json-components/categories-home.json",
  //   urlJsonServices: "https://qastorageorafa.blob.core.windows.net/json-components/kevin-services.json",
  //   urlJsonLogin: "https://qastorageorafa.blob.core.windows.net/json-components/login.json",
  //   urlJsonCreate: "https://qastorageorafa.blob.core.windows.net/json-components/create-account.json",
  //   urlTerminos: "https://drive.google.com/uc?id=1FTk6Iu82aubi50VzAb_ecuPZ8J5MixT2",
  //   urlJsonDetalle: "https://qastorageorafa.blob.core.windows.net/json-components/detalle.json",
  //   urlPdfTallas: "https://qastorageorafa.blob.core.windows.net/pdf/",
  //   urlPdfTerminos: "https://drive.google.com/uc?id=1TOR-1_cTCcp-90jnPROtGThIc67TSvfo",
  //   whatsApp: "https://api.whatsapp.com/send?phone=573186338303&",
  //   emailWeb: "sistemas9@orafa.com.co",
  //   urlExpress: "https://qa.apiplaxd.orafa.com.co",
  //   urlBaseImg: "https://kevins.com.co/img/productos/",
  //   keyGooglereCAPTCHA: "6LdyBUUpAAAAAOlCJcTkU3k8w2hgBMGxnAIE6d02",
  //   secretkey: "11122023",
  //   urlResumenJson: "https://qastorageorafa.blob.core.windows.net/json-components/resumen.json",
  //   urlShippingsJson: "https://qastorageorafa.blob.core.windows.net/json-components/envio.json",
  //   urlResponseSuccess: "https://qastorageorafa.blob.core.windows.net/json-components/approved.json",
  //   urlResponseFail: "https://qastorageorafa.blob.core.windows.net/json-components/rejected.json",
  //   urlImgGIA: "https://kevins.com.co/img/gia_logo_sm.png",
  //   urlGarantiasJson: "https://qastorageorafa.blob.core.windows.net/json-components/garantias.json",
  //   urlDeliveryJson: "https://qastorageorafa.blob.core.windows.net/json-components/delivery-methods.json",
  //   urlWorkJson: "https://qastorageorafa.blob.core.windows.net/json-components/trabaja-con-nosotros.json"
  // };

  setConfig(config: any) {
    this._config = config;
    this.configSubject.next(config);
  }

  // getConfig(): any {
  //   return this._config;
  // }

  insercionVariable(variables: env) {
    this._config = variables
  }
}
