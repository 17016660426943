import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { JsonTextService, LoadingService, TextResponse } from 'libreria-tienda';
import { environment } from '../../../environments/environment';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-gana-kilates',
  standalone: true,
  imports: [MatDividerModule],
  templateUrl: './gana-kilates.component.html',
  styleUrl: './gana-kilates.component.css'
})
export class GanaKilatesComponent implements OnInit, AfterViewInit {

  ganaKilates: TextResponse[] = [];

  titulo: any;
  video: any;
  queEs: any;
  acumulado: any;
  redimir: any;

  public jsonService = inject(JsonTextService)
  public load = inject(LoadingService)

  ngOnInit(): void {
    this.jsonService.downloadJson(environment.URLJSONGANAKILATES).subscribe(res => {
      this.ganaKilates = res
      console.log(this.ganaKilates);
      this.titulo = this.getName('titulo')
      this.video = this.getName('video')
      this.queEs = this.getName('que es')
      this.acumulado = this.getName('acumulo')
      this.redimir = this.getName('redimir')
      this.load.cargar(false)
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  getName(name: string) {
    return this.ganaKilates?.find(x => x.Nombre == name)?.Valor
  }
}
