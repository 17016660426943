import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Deptos, ExpressService, LoadingService, Municipes, SioServicesService, Store } from 'libreria-tienda';
import { ResumenCompraRapidaComponent } from 'libreria-tienda/shopping-cart/resumen/resumen-compra-rapida/resumen-compra-rapida.component';
import { MatDialog } from '@angular/material/dialog';
import { PopUpErrorFormulariosComponent } from 'libreria-tienda/shared/pop-up-error-formularios/pop-up-error-formularios.component';

@Component({
  selector: 'app-compra-rapida',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, ResumenCompraRapidaComponent],
  templateUrl: './compra-rapida.component.html',
  styleUrl: './compra-rapida.component.css'
})
export class CompraRapidaComponent implements OnInit, AfterViewInit {

  public sioService = inject(SioServicesService)
  public expressService = inject(ExpressService)
  public load = inject(LoadingService)

  fileName: string = '';

  formulariosLlenos: any;

  formularioFacturacion: FormGroup;
  formularioEntrega: FormGroup;
  formularioTercero: FormGroup;
  formularioTerminos: FormGroup;

  listDeptos: Deptos[] = new Array<Deptos>();
  deptoini: Deptos;
  listMunicipes: Municipes[] = new Array<Municipes>();

  formulariosIncompletos: string[] = [];

  constructor(private fb: FormBuilder, private router: Router, public dialog: MatDialog) {
    this.formularioFacturacion = this.fb.group({
      nombres: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z ]+$') // Solo letras y espacios
      ]],
      apellidos: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z ]+$') // Solo letras y espacios
      ]],
      tipoDocumento: ['', Validators.required], // Debe seleccionarse una opción válida
      documento: ['', [
        Validators.required,
        Validators.minLength(5), // Dependerá del tipo de documento
        // Validators.maxLength(20), // Límite de longitud para evitar documentos inusualmente largos
        // Validators.pattern('^[0-9]+$') // Solo números
      ]],
      celular: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{7,10}$') // Número de 7 a 10 dígitos
      ]],
      correoelectronico: ['', [
      // correoelectronico: ['development@orafa.com.co', [
        Validators.required,
        Validators.email // Valida que sea un correo electrónico válido
      ]],
      archivo: ['', []]
    });
    this.formularioEntrega = this.fb.group({
      entrega: ['domicilio', Validators.required],
      direccion: ['', [
        Validators.required,
        Validators.minLength(8), // Longitud mínima para una buena descripción
        Validators.maxLength(250) // Longitud máxima para evitar descripciones demasiado largas
      ]],
      direccionAux: ['', [
        Validators.minLength(8), // Longitud mínima para una buena descripción
        Validators.maxLength(50) // Longitud máxima para evitar descripciones demasiado largas
      ]],
      departamento: ['', Validators.required], // Debe seleccionarse una opción válida
      ciudad: ['', Validators.required], // Debe seleccionarse una opción válida
      tienda: [''], // Debe seleccionarse una opción válida
    })
    this.formularioTercero = this.fb.group({
      tercero: ['no', Validators.required], // Debe seleccionarse una opción válida
      nombres: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z ]+$') // Solo letras y espacios
      ]],
      apellidos: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z ]+$') // Solo letras y espacios
      ]],
      // tipoDocumento: ['', Validators.required], // Debe seleccionarse una opción válida
      documento: ['', [
        Validators.required,
        Validators.minLength(5), // Dependerá del tipo de documento
        Validators.maxLength(20), // Límite de longitud para evitar documentos inusualmente largos
        Validators.pattern('^[0-9]+$'), // Solo números
        this.onlyNumbersValidator()
      ]],
      celular: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{7,10}$') // Número de 7 a 10 dígitos
      ]]
    });
    this.formularioTerminos = this.fb.group({
      especial: ['no', Validators.required], // Debe seleccionarse una opción válida
      solicitud: ['', [
        // Validators.required,
        // Validators.minLength(10), // Longitud mínima para una buena descripción
        // Validators.maxLength(250) // Longitud máxima para evitar descripciones demasiado largas
      ]],
      newsletter: [false], // Debe seleccionarse una opción válida
      terminos: [null, Validators.required] // Debe seleccionarse una opción válida
    })
  }

  stores: Store[] = [];
  listCities: string[] = [];
  listStore: string[] = [];

  ngOnInit(): void {
    // console.log('cargar');
    this.load.cargar(false);

    this.formularioFacturacion.get('tipoDocumento')?.valueChanges.subscribe((tipo) => {
      // console.log(tipo);
      const doc = this.formularioFacturacion.get('documento');
      const archivo = this.formularioFacturacion.get('archivo');
      doc?.clearValidators();
      archivo?.clearValidators();
      switch (tipo) {
        case 'nit':
          doc?.setValidators([Validators.required, Validators.minLength(8), this.onlyNumbersValidator()]);
          archivo?.setValidators([Validators.required, this.fileValidator()]);
          break;
        case 'cc':
          doc?.setValidators([Validators.required, Validators.minLength(8), this.onlyNumbersValidator()]);
          break;
        case 'pasaporte':
          doc?.setValidators([Validators.required, Validators.minLength(8), this.alphanumericValidator()]);
          break;

        default:
          doc?.setValidators([Validators.required]);
          break;
      }
      doc?.updateValueAndValidity();
      archivo?.updateValueAndValidity();

    })
    this.formularioEntrega.get('entrega')?.valueChanges.subscribe((tipo) => {
      const direccion = this.formularioEntrega.get('direccion');
      const direccionAux = this.formularioEntrega.get('direccionAux');
      const departamento = this.formularioEntrega.get('departamento');
      const ciudad = this.formularioEntrega.get('ciudad');
      const tienda = this.formularioEntrega.get('tienda');
      // console.log(tipo);
      if (tipo == 'domicilio') {
        console.log('domicilio');

        tienda?.clearValidators();

        direccion?.setValidators([Validators.required, Validators.required, Validators.minLength(8), Validators.maxLength(250)]);
        direccionAux?.setValidators([Validators.required, Validators.required, Validators.minLength(8), Validators.maxLength(250)]);
        departamento?.setValidators([Validators.required]);
        ciudad?.setValidators([Validators.required]);

      } else if (tipo == 'tienda') {
        console.log('tienda');

        direccion?.clearValidators();
        direccionAux?.clearValidators();
        departamento?.clearValidators();
        ciudad?.clearValidators();

        ciudad?.setValidators([Validators.required]);
        tienda?.setValidators([Validators.required]);
      }
      direccion?.updateValueAndValidity();
      direccionAux?.updateValueAndValidity();
      departamento?.updateValueAndValidity();
      ciudad?.updateValueAndValidity();
      tienda?.updateValueAndValidity();
    });
    this.formularioEntrega.get('departamento')?.valueChanges.subscribe((dept: Deptos | "") => {
      if (dept !== '') {
        if (this.formularioEntrega.get('entrega')?.value == "domicilio") {
          this.deptoSelected(dept)
        }
      }
    })
    this.formularioEntrega.get('ciudad')?.valueChanges.subscribe((city) => {
      if (city !== '') {
        if (this.formularioEntrega.get('entrega')?.value == "tienda") {
          this.citieSelected(city)
        }
      }
    })
    this.formularioTerminos.get('especial')?.valueChanges.subscribe((valor) => {
      console.log(valor);
      if (valor == 'si') {

      }
    })
    this.expressService.getStores().subscribe(store => {
      store.stores.forEach((sto: any) => {
        if (sto.slug != "ventas-corp") {
          this.stores.push(sto)
        }
        this.stores.forEach((store) => {
          this.listCities.push(store.ciudad)
        })
        this.listCities = this.listCities.filter((item, index) => {
          return this.listCities.indexOf(item) === index;
        })
      })
      this.listCities = this.listCities.filter(palabra => palabra !== undefined);
    })

    this.sioService.GetDataListCommand("Departamentos", "", []).subscribe((deptos: Deptos[]) => {
      deptos.forEach(dep => {
        this.deptoini = new Deptos();
        this.deptoini = dep
        this.listDeptos.push(this.deptoini)
      })
      this.deptoini = new Deptos();


    });
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  onSubmitFacturacion() {
    if (this.formularioFacturacion.valid) {
      console.log(this.formularioFacturacion.value);
    } else {
      console.log('Formulario no válido');
    }
  }

  navigate(url: string) {
    this.router.navigate(['/' + url]).then(() => {
      window.scrollTo(0, 0);
    });
  }

  // Validador personalizado para solo números
  onlyNumbersValidator() {
    return (control: any) => {
      const value = control.value;
      const isValid = /^[0-9]*$/.test(value);
      return isValid ? null : { onlyNumbers: true };
    };
  }

  // Validador personalizado para letras y números
  alphanumericValidator() {
    return (control: any) => {
      const value = control.value;
      const isValid = /^[a-zA-Z0-9]*$/.test(value);
      return isValid ? null : { alphanumeric: true };
    };
  }

  // Validador personalizado para el tipo de archivo
  fileValidator() {
    return (control: any) => {
      const file = control.value;
      if (file) {
        const extension = file.split('.').pop().toLowerCase();
        if (extension !== 'pdf' && extension !== 'jpg') {
          return { invalidFileType: true };
        }
      }
      return null;  // Archivo válido
    };
  }

  // Método que se ejecuta al seleccionar un archivo
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.fileName = file.name;  // Guardar el nombre del archivo seleccionado
      this.formularioFacturacion.patchValue({
        archivo: file
      });
      this.formularioFacturacion.get('archivo')?.updateValueAndValidity();  // Recalcular las validaciones
    } else {
      this.fileName = '';  // Limpiar el nombre si no hay archivo
    }
  }

  citieSelected(city: string) {
    // console.log(city);
    this.listStore = []
    this.stores.forEach((store) => {
      if (store.ciudad == city) {
        this.listStore.push(store.nombre)
      }
    })
    // console.log(this.listStore);
  }

  pago(e: any) {
    let formularios: any;
    let estado: boolean = false;
    if (this.formularioTercero.get('tercero')?.value == 'si') {
      // console.log('formularioFacturacion', this.formularioFacturacion.valid);
      // console.log('formularioEntrega', this.formularioEntrega.valid);
      // console.log('formularioTercero', this.formularioTercero.valid);
      // console.log('formularioTerminos', this.formularioTerminos.valid);

      // console.log(
      //   this.formularioFacturacion.valid &&
      //   this.formularioEntrega.valid &&
      //   this.formularioTercero.valid &&
      //   this.formularioTerminos.valid
      // );
      formularios = {
        'facturacion': this.formularioFacturacion.value,
        'entrega': this.formularioEntrega.value,
        'tercero': this.formularioTercero.value,
        'terminos': this.formularioTerminos.value,
        'articulos':[]
      }
      estado = this.formularioFacturacion.valid && this.formularioEntrega.valid && this.formularioTercero.valid && this.formularioTerminos.valid;
      // console.log(formularios);

      let estadoFormularios = [
        {
          estado: this.formularioFacturacion,
          nombre: "Facturacion"
        },
        {
          estado: this.formularioEntrega,
          nombre: "Entrega"
        },
        {
          estado: this.formularioTercero,
          nombre: "Terceros"
        },
        {
          estado: this.formularioTerminos,
          nombre: "Terminos y condiciones"
        }
      ]

      if (!estado) {
        this.formulariosIncompletos = [];
        this.formularioFacturacion.markAllAsTouched()
        this.formularioEntrega.markAllAsTouched()
        this.formularioTercero.markAllAsTouched()
        this.formularioTerminos.markAllAsTouched()
        // console.log("Tus datos de : ");
        estadoFormularios.forEach(form => {
          if (!form.estado.valid) {
            // console.log("-", form.nombre);
            this.formulariosIncompletos.push(form.nombre)
          }
        });
        // console.log("Están incompletos");
      }

    } else {
      // console.log('formularioFacturacion', this.formularioFacturacion.valid);
      // console.log('formularioEntrega', this.formularioEntrega.valid);
      // console.log('formularioTerminos', this.formularioTerminos.valid);
      // console.log(
      //   this.formularioFacturacion.valid &&
      //   this.formularioEntrega.valid &&
      //   this.formularioTerminos.valid
      // );
      estado = this.formularioFacturacion.valid && this.formularioEntrega.valid && this.formularioTerminos.valid;
      formularios = {
        'facturacion': this.formularioFacturacion.value,
        'entrega': this.formularioEntrega.value,
        'terminos': this.formularioTerminos.value,
        'articulos':[]
      }

      let estadoFormularios = [
        {
          estado: this.formularioFacturacion,
          nombre: "Facturacion"
        },
        {
          estado: this.formularioEntrega,
          nombre: "Entrega"
        },
        {
          estado: this.formularioTerminos,
          nombre: "Terminos y condiciones"
        }
      ]

      if (!estado) {
        this.formulariosIncompletos = [];
        this.formularioFacturacion.markAllAsTouched()
        this.formularioEntrega.markAllAsTouched()
        this.formularioTerminos.markAllAsTouched()
        // console.log("Tus datos de : ");
        estadoFormularios.forEach(form => {
          if (!form.estado.valid) {
            // console.log("-", form.nombre);
            this.formulariosIncompletos.push(form.nombre)
          }
        });
        // console.log("Están incompletos");
      }
    }
    // console.log(estado);
    // console.log(formularios);

    if (!estado) {
      this.openNew();
    }

    this.formulariosLlenos = estado == false ? false : formularios;


  }


  openNew() {
    this.dialog.open(PopUpErrorFormulariosComponent, {
      data: {
        mensaje: 'Hace falta información de: ',
        formularios: this.formulariosIncompletos
      }
    })
  }

  deptoSelected(depto: Deptos) {
    this.load.cargar(true)
    this.sioService.GetDataListCommandMunicipe("Municipios", "IdDepto = @0", [depto.idDepto]).subscribe((municipe: Municipes[]) => {
      this.listMunicipes = municipe
      this.load.cargar(false)
    })
  }


}
