import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { AboutUsComponent } from 'libreria-tienda/shared/about-us/about-us.component';
import { CardProductComponent } from 'libreria-tienda/shared/card-product/card-product.component';
import { CategoriesComponent } from 'libreria-tienda/shared/categories/categories.component';
import { GiaComponent } from 'libreria-tienda/shared/gia/gia.component';
import { ServicesComponent } from 'libreria-tienda/shared/services/services.component';
import { SliderCardComponent } from 'libreria-tienda/shared/slider-card/slider-card.component';
import { SliderImgComponent } from 'libreria-tienda/shared/slider-img/slider-img.component';

import { Subscription } from 'rxjs';
import { Banner, DispositivoService, JsonTextService, LoadingService, Product, TextResponse } from 'libreria-tienda';
import { SliderBannerComponent } from 'libreria-tienda/shared/slider-logo/slider-banner/slider-banner.component';
import { BannerHomeComponent } from 'libreria-tienda/shared/banner-home/banner-home.component';
import { VerificacionEmailComponent } from '../verificacion-email/verificacion-email.component';


@Component({
  selector: 'app-home',
  standalone: true,
  // host: {ngSkipHydration: 'true'},
  imports: [
    CardProductComponent,
    SliderCardComponent,
    AboutUsComponent,
    SliderImgComponent,
    GiaComponent,
    CategoriesComponent,
    ServicesComponent,
    SliderBannerComponent,
    BannerHomeComponent,
    VerificacionEmailComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit, AfterViewInit {

  products: Product[] = new Array<Product>();
  products2: Product[] = new Array<Product>();
  showBanner: boolean = true;
  padding = false;
  categoria: string;
  banners: Banner[] = new Array<Banner>()
  navigation = true;
  loop = true;
  onlyBanner = false;
  resizeSubscription$: Subscription
  lastSearch: string = "";
  public jsonService = inject(JsonTextService)
  public dipositivoService = inject(DispositivoService)
  public load = inject(LoadingService)

  ngOnInit() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.load.cargar(false)
      }, 500);
    }
  }

}
