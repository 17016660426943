import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ConfigService, ExpressService, GoogleMapsServiceService, JsonTextService, LoadingService, Marker, TextResponse, position } from 'libreria-tienda';
import { Store } from 'libreria-tienda';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-joyerias',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, GoogleMapsModule],
  templateUrl: './joyerias.component.html',
  styleUrl: './joyerias.component.css'
})
export class JoyeriasComponent implements OnInit, AfterContentInit {

  public load = inject(LoadingService)
  public googleService = inject(GoogleMapsServiceService)
  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)
  public expressService = inject(ExpressService)

  stores: Store[] = [];
  markers: Marker[] = [];
  citie: string = ""
  store: Store = new Store()
  listCities: string[] = [];
  position: position = new position()
  marker: Marker = new Marker()
  latitudCiudad: number = 0;
  longitudCiudad: number = 0;
  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 4.5709,
    lng: -74.2973
  };
  zoom = 6;
  emtpyob = true
  map: google.maps.Map;
  icon: string = "";
  markers2: google.maps.Marker[] = [];
  jsonjoyerias: TextResponse[];

  ngOnInit(): void {
    this.load.cargar(false)
    this.jsonService.downloadJson(environment.URLJSONJOYERIAS).subscribe(res => {
      this.jsonjoyerias = res
    })
    this.expressService.getIcon().subscribe(res => {
      this.icon = res.icon.icon
    })

    this.expressService.getStores().subscribe(store => {
      store.stores.forEach((sto: any) => {
        if (sto.slug != "ventas-corp") {
          this.marker = new Marker()
          this.position = new position()
          this.position.lat = sto.latitud
          this.position.lng = sto.longitud
          this.marker.position = this.position
          this.marker.store = sto;
          this.markers.push(this.marker)
          this.stores.push(sto)
        }
        this.stores.forEach((store) => {
          this.listCities.push(store.ciudad)
        })
        this.listCities = this.listCities.filter((item, index) => {
          return this.listCities.indexOf(item) === index;
        })
      })
      this.listCities = this.listCities.filter(palabra => palabra !== undefined);
    })

    this.googleService.loadGoogleMaps()
      .then(() => {
        // Aquí puedes inicializar el mapa una vez que el script esté cargado
        this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          center: { lat: 4.5709, lng: -74.2973 },
          zoom: this.zoom,
          styles: [
            // Estilos del mapa para ocultar etiquetas
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }]
            },
            {
              featureType: "road",
              elementType: "labels",
              stylers: [{ visibility: "off" }]
            },
          ]
        });
        this.setMapOnAll()
      })
      .catch((error) => {
        console.error('Error al cargar Google Maps', error);
      });
  }

  ngAfterContentInit(): void {

  }

  citieSelected(event: any) {
    this.citie = event.value
    console.log(this.stores);

    this.markers = []
    let lat = 0
    let lon = 0
    this.emtpyob = true
    this.stores.forEach(store => {
      if (store.ciudad == this.citie && store.mostrarEnPagina == 1 && store.visible == 1) {
        lat = store.latitud_ciudad;
        lon = store.longitud_ciudad;
        this.position = new position()
        this.marker = new Marker()
        this.position.lat = store.latitud
        this.position.lng = store.longitud
        this.marker.position = this.position
        this.marker.store = store;
        this.markers.push(this.marker)
      }
    })
    if (screen.width < 767) {
      this.zoom = 10
    } else {
      this.zoom = 12
    }
    this.changelatlon(lat, lon, this.zoom)
  }

  pointSelected(event: any) {
    this.store = event.value
    this.emtpyob = false
    if (screen.width < 767) {
      this.zoom = 12
    } else {
      this.zoom = 16
    }
    this.changelatlon(this.store.latitud, this.store.longitud, 16)
  }

  changelatlon(lat: number, lgn: number, zoom: number) {
    this.latitudCiudad = lat
    this.longitudCiudad = lgn
    this.zoom = zoom
    this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: { lat: parseFloat(this.latitudCiudad.toString()), lng: parseFloat(this.longitudCiudad.toString()) },
      zoom: zoom,
      styles: [
        // Estilos del mapa para ocultar etiquetas
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }]
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [{ visibility: "off" }]
        },
      ]
    });
    this.setMapOnAll()

  }

  setMapOnAll() {
    if (this.icon != "") {
      var image = {
        url: this.icon,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25)
      };
      this.markers.forEach(mark => {
        new google.maps.Marker({
          position: { lat: parseFloat(mark.position.lat.toString()), lng: parseFloat(mark.position.lng.toString()) },
          map: this.map,
          // label: mark.store.nombre,
          title: "Hello World!",
          icon: image,
        });
      })
    } else {
      this.markers.forEach(mark => {
        new google.maps.Marker({
          position: { lat: parseFloat(mark.position.lat.toString()), lng: parseFloat(mark.position.lng.toString()) },
          map: this.map,
          // label: mark.store.nombre,
          title: "Hello World!",
        });
      })
    }
  }

  openWa() {
    const fileUrl = this.store.whts;
    window.open(fileUrl, '_blank');
  }

  getName(name: string) {
    return this.jsonjoyerias?.find(x => x.Nombre == name)?.Valor
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }


}
