import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numeroPunto',
  standalone: true
})
export class NumeroPuntoPipe implements PipeTransform {

  transform(value: string | number): string {
    if (value === null || value === undefined) {
      return '';
    }
    let numericValue: number;

    // Verificamos si el valor es un número o una cadena
    if (typeof value === 'string') {
      // Intentamos convertir el string a un número
      numericValue = parseFloat(value);
      // Si no es un número válido, devolvemos el valor original sin formatear
      if (isNaN(numericValue)) {
        return value; // Retornamos la cadena original si no es un número válido
      }
    } else {
      numericValue = value; // Si ya es un número
    }

    // Formateamos el número usando la localización con puntos para miles
    return numericValue.toLocaleString('de-DE');
  }

}
