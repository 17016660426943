import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PaymentConfirmationComponent } from 'libreria-tienda/shopping-cart/payment-confirmation/payment-confirmation.component';

@Component({
  selector: 'app-response-payment',
  standalone: true,
  imports: [CommonModule,PaymentConfirmationComponent],
  templateUrl: './response-payment.component.html',
  styleUrl: './response-payment.component.css'
})
export class ResponsePaymentComponent {

}
