import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { ControlSesionService, PantallaSaleService, User } from 'libreria-tienda';

@Component({
  selector: 'lib-login',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatDividerModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  login: boolean = false;
  iniciales: string = "-";
  user: User = new User();

  sale: boolean;

  private sesionService = inject(ControlSesionService)
  private pantallaSaleService = inject(PantallaSaleService)

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.usuarioLogin()
    if (this.user.nombre != undefined && this.user.apellido != undefined) {
      this.login = true;
      this.iniciales = this.user.nombre.charAt(0) + this.user.apellido.charAt(0)
    } else {
      this.login = false;
      this.iniciales = "-"
    }
    this.pantallaSaleService.estado$.subscribe(valor => {
      this.sale = valor;
    });
  }

  usuarioLogin() {
    if (typeof localStorage !== 'undefined') {
      if ((localStorage.getItem("userLogged")) != undefined) {
        this.user = JSON.parse(localStorage.getItem("userLogged") || '')
      }
    }
  }

  redirectPage(page: string) {
    this.router.navigate(['/' + page]).then(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    });
  }

  cerrarSesion() {
    this.sesionService.cerrarSesion()
  }
}
