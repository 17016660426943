import { Component } from '@angular/core';

declare var propiedades: any | undefined;

@Component({
  selector: 'lib-boton-contactanos',
  standalone: true,
  imports: [],
  templateUrl: './boton-contactanos.component.html',
  styleUrl: './boton-contactanos.component.css'
})
export class BotonContactanosComponent {

  propiedades: any;

  email: string

  constructor() {
    this.propiedades = propiedades;

    this.email = this.propiedades['boton_contactanos']['correo']
  }

  mail() {
    window.open(`${this.email}`, '_blank');
  }

}
