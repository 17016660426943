import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { CommonLista, ConfigService, ExpressService, LoadingService, ShoppingCartService, SioServicesService, User, userlogged, UserService } from 'libreria-tienda';
import { UpdateUserComponent } from 'libreria-tienda/shopping-cart/update-user/update-user.component';

@Component({
  selector: 'app-edit-perfil',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, MatExpansionModule],
  templateUrl: './edit-perfil.component.html',
  styleUrl: './edit-perfil.component.css'
})
export class EditPerfilComponent implements OnInit {

  userLog: userlogged = new userlogged();
  user: User = new User();
  form: FormGroup;
  form2: FormGroup;
  submited = false;
  oldPass = true;
  listDocuments: CommonLista[] = new Array<CommonLista>();
  document: CommonLista;
  open = false;
  hide: boolean = true;
  hide2: boolean = true;
  hide3: boolean = true;
  equal: boolean = false;
  loader: boolean = true;

  userService = inject(UserService)
  expressService = inject(ExpressService)
  sioService = inject(SioServicesService)
  cartService = inject(ShoppingCartService)
  configService = inject(ConfigService)
  load = inject(LoadingService)

  constructor(private fb: FormBuilder, private fb2: FormBuilder, private router: Router, public dialogo: MatDialog) {
    this.form = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      tipoDocumento: ['', Validators.required],
      numeroDocumento: ['', Validators.required],
      celular: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    })
    this.form.controls['email'].disable();

    this.form2 = this.fb2.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&-])[A-Za-z\\d@$!%*?&-]{8,}$")]],
      confirmpassword: ['', Validators.required],
      checkbox: [false, Validators.required],
    })
    this.form2.addValidators(
      this.matchValidator(this.form2.get('newpassword'), this.form2.get('confirmpassword'))
    );
  }

  ngOnInit(): void {
    this.userLog = this.userService.getUserLocal();
    this.userService.getUser().then(res => {
      // console.log(res);
      this.user = res;
    }).catch(error => {

    })
    this.sioService.GetListaPorCodigo("TPDOC").subscribe(list => {
      this.listDocuments = list
      this.document = this.listDocuments.find(x => x.codLista.trim() == this.user.tipoDocumento) || this.document
      this.form.get("tipoDocumento")?.setValue(this.document)
    });
    this.userService.getUserDireccion().then((res) => {
      if (res !== undefined) {
        // this.user = res;
        this.form.get("nombres")?.setValue(this.user.nombre);
        this.form.get("apellidos")?.setValue(this.user.apellido);
        this.form.get("numeroDocumento")?.setValue(this.user.numeroDocumento);
        this.form.get("email")?.setValue(this.user.correoElectronico);
        this.form.get("password")?.setValue(this.user.password);
        this.form.get("celular")?.setValue(this.user.celular);
      } else {
        this.router.navigate(['/login']).then(() => {
          window.scrollTo(0, 0);
        });
      }
      this.load.cargar(false);
    }).catch((error) => {
      this.router.navigate(['/login']).then(() => {
        window.scrollTo(0, 0);
      });
      this.load.cargar(false);
    })
  }

  editUser() {
    this.submited = true
    if (this.form.valid) {
      this.user.nombre = this.form.get('nombres')?.value;
      this.user.apellido = this.form.get('apellidos')?.value;
      this.user.numeroDocumento = this.form.get('numeroDocumento')?.value;
      this.user.celular = this.form.get('celular')?.value;
      if (this.form2.get("checkbox")?.value) {
        if (this.form2.valid) {
          this.load.cargar(true);
          this.expressService.editUser(this.user, this.encrypt(this.form2.get('oldpassword')?.value), this.encrypt(this.form2.get('newpassword')?.value)).subscribe({
            next: (res) => {
              if (res) {
                this.oldPass = true
                if (typeof localStorage !== 'undefined') {
                  localStorage.setItem("userLogged", JSON.stringify(this.user));
                }
                this.openDialogMessage()
              }
              else {
                this.oldPass = false
                console.log("no se pudo actualizar")
              }
              this.load.cargar(false);
            }, error: (error) => {
              if (error.status == 403 || error.status == 401) {
                this.user = new User()
                this.user.nombre = "-"
                this.userService.setUser(this.user)
                this.router.navigate(["/login"])
                this.load.cargar(false);
              }
            }
          })
        }
      } else {
        this.load.cargar(true);
        this.expressService.editUser(this.user, null, null).subscribe({
          next: (res) => {
            if (res) {
              if (typeof localStorage !== 'undefined') {
                localStorage.setItem("userLogged", JSON.stringify(this.user));
              }
              this.openDialogMessage()
            }
            else {
              console.log("no se pudo actualizar")
            }
            this.load.cargar(false);
          }, error: (error) => {
            if (error.status == 403 || error.status == 401) {
              this.user = new User()
              this.user.nombre = "-"
              this.userService.setUser(this.user)
              this.router.navigate(["/login"])
              this.load.cargar(false);
            }
          }
        })
      }
    }
  }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.configService._config.SECRETKEY).toString();
  }

  openDialogMessage(): void {
    const dialogRef = this.dialogo.open(UpdateUserComponent, { panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res2 => {
      this.router.navigate(["profile-main"])
    })
  }

  documentSelected(event: any) {
    this.form.get("tipoDocumento")?.setValue(event.value)
    this.user.tipoDocumento = this.form.get('tipoDocumento')?.value.codLista;
  }

  onPanelOpened() {
    this.open = true
    this.form2.get("checkbox")?.setValue(true)
  }

  onPanelClosed() {
    this.open = false
    this.form2.get("checkbox")?.setValue(false)
  }

  isHide() {
    this.hide = !this.hide
  }

  isHide2() {
    this.hide2 = !this.hide2
  }

  isHide3() {
    this.hide3 = !this.hide3
  }

  matchValidator(
    control: AbstractControl | any,
    controlTwo: AbstractControl | any
  ): ValidatorFn {
    return () => {
      if (control.value !== controlTwo.value) {
        this.equal = false
        return { match_error: 'Value does not match' };
      } else {
        this.equal = true
        return null;
      }
    };
  }

}
