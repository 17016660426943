import { Injectable } from '@angular/core';
import { filtroAplicado, FiltrosDisponibles, PreciosFiltros } from '../class/filtrosAplicados';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstadosFiltrosService {

  private _preciosConsulta = new BehaviorSubject<PreciosFiltros>(new PreciosFiltros);
  private _filtrosAplicados = new BehaviorSubject<filtroAplicado>(new filtroAplicado);

  private _filtrosDisponibles = new BehaviorSubject<FiltrosDisponibles[]>([new FiltrosDisponibles]);

  private _idsFiltrosAplicados = new BehaviorSubject<number[]>([]);


  // private _preciosSeleccion = new BehaviorSubject<PreciosFiltros>(new PreciosFiltros);

  // Observable que los componentes pueden suscribirse para recibir actualizaciones
  preciosConsulta$ = this._preciosConsulta.asObservable();
  filtrosAplicados$ = this._filtrosAplicados.asObservable();
  filtrosDisponibles$ = this._filtrosDisponibles.asObservable();

  idsFiltrosAplicados$ = this._idsFiltrosAplicados.asObservable();

  // preciosSeleccion$ = this._preciosSeleccion.asObservable();


  // Método para actualizar el valor compartido
  updatePreciosConsulta(min: number, max: number) {
    let aux: PreciosFiltros = new PreciosFiltros ();
    aux.precioMax = max;
    aux.precioMin = min;
    this._preciosConsulta.next(aux);
    // console.log(aux);
  }

  updateFiltrosDispopnibles(filtros: FiltrosDisponibles[]) {
    this._filtrosDisponibles.next(filtros);
  }

  updateIdsFiltrosAplicados(filtros: number[]) {
    this._idsFiltrosAplicados.next(filtros);
  }

  // updatePrecioSeleccion(min: number, max: number) {
  //   let aux: PreciosFiltros = new PreciosFiltros ();
  //   aux.precioMax = max;
  //   aux.precioMin = min;
  //   this._preciosSeleccion.next(aux);
  //   console.log(aux);

  // }

  updateFiltrosAplicados(newValue: filtroAplicado) {
    this._filtrosAplicados.next(newValue);
    // console.log(newValue);
  }
}
