<div class="contenedor">
  <div class="contenedor-dos-columnas">
    <div class="contenedor-titulo">
      <h1>Registro Compra</h1>
      <span (click)="navigate('login')" class="pointer">Iniciar sesión</span>
    </div>
    <div class="contendor-facturacion">
      <div class="sub-titulo">
        <h2>Datos de facturación</h2>
      </div>
      <form [formGroup]="formularioFacturacion" (submit)="onSubmitFacturacion()">
        <div class="dos-columnas">
          <div class="columna-uno columna">
            <!-- Nombres -->
            <div class="contenedor-campo">
              <label for="nombres" class="label">Nombres*</label>
              <input id="nombres" type="text" formControlName="nombres" placeholder="Escriba su nombre completo"
                class="custom-input">
              <div
                *ngIf="formularioFacturacion.get('nombres')?.invalid && formularioFacturacion.get('nombres')?.touched">
                <small *ngIf="formularioFacturacion.get('nombres')?.errors?.['required']">
                  El nombre es requerido.</small>
                <small *ngIf="formularioFacturacion.get('nombres')?.errors?.['minlength']">
                  El nombre debe tener al menos 3 caracteres.</small>
                <small *ngIf="formularioFacturacion.get('nombres')?.errors?.['pattern']">
                  Solo se permiten letras y espacios.</small>
              </div>
            </div>
          </div>
          <div class="columna-dos columna">
            <!-- Apellidos -->
            <div class="contenedor-campo">
              <label for="apellidos" class="label">Apellidos*</label>
              <input id="apellidos" type="text" formControlName="apellidos" placeholder="Escriba su nombre completo"
                class="custom-input">
              <div
                *ngIf="formularioFacturacion.get('apellidos')?.invalid && formularioFacturacion.get('apellidos')?.touched">
                <small *ngIf="formularioFacturacion.get('apellidos')?.errors?.['required']">Los apellidos son
                  requerido.</small>
                <small *ngIf="formularioFacturacion.get('apellidos')?.errors?.['minlength']">Los apellidos deben tener
                  al menos 3 caracteres.</small>
                <small *ngIf="formularioFacturacion.get('apellidos')?.errors?.['pattern']">Solo se permiten
                  letras y espacios.</small>
              </div>
            </div>
          </div>
        </div>
        <div class="dos-columnas">
          <div class="columna-uno columna">
            <!-- Tipo de Documento -->
            <div class="contenedor-campo">
              <label for="tipoDocumento" class="label">Tipo de Documento*</label>
              <select id="tipoDocumento" formControlName="tipoDocumento" placeholder>
                <option value="">Seleccione un tipo de documento</option>
                <option value="cc">Cédula de ciudadanía</option>
                <option value="nit">NIT</option>
                <option value="pasaporte">Pasaporte</option>
              </select>
              <div
                *ngIf="formularioFacturacion.get('tipoDocumento')?.invalid && formularioFacturacion.get('tipoDocumento')?.touched">
                <small>Debe seleccionar un tipo de documento.</small>
              </div>
            </div>
          </div>
          <div class="columna-dos columna">
            <!-- Documento -->
            <div class="contenedor-campo">
              <label *ngIf="formularioFacturacion.get('tipoDocumento')?.value === ''" for="documento"
                class="label">Documento*</label>
              <label *ngIf="formularioFacturacion.get('tipoDocumento')?.value === 'cc'" for="documento"
                class="label">Cédula de ciudadanía*</label>
              <label *ngIf="formularioFacturacion.get('tipoDocumento')?.value === 'nit'" for="documento"
                class="label">NIT*</label>
              <label *ngIf="formularioFacturacion.get('tipoDocumento')?.value === 'pasaporte'" for="documento"
                class="label">Pasaporte*</label>
              <input id="documento" type="text" formControlName="documento" placeholder="Escriba su documento"
                class="custom-input">
              <div
                *ngIf="formularioFacturacion.get('documento')?.invalid && formularioFacturacion.get('documento')?.touched">
                <small *ngIf="formularioFacturacion.get('documento')?.errors?.['required']">El número de documento es
                  requerido.</small> <br />
                <small *ngIf="formularioFacturacion.get('documento')?.errors?.['minlength']">El documento debe tener
                  al menos 5 caracteres.</small> <br />
                <small *ngIf="formularioFacturacion.get('documento')?.hasError('onlyNumbers')">El documento debe
                  contener
                  solo números.</small> <br />
                <small *ngIf="formularioFacturacion.get('documento')?.hasError('alphanumeric')">El documento debe
                  contener
                  letras y números.</small> <br />
              </div>
            </div>
          </div>
        </div>
        @if (formularioFacturacion.get('tipoDocumento')?.value === 'nit') {
        <div class="dos-columnas">
          <div class="columna-uno columna">
            <div class="contenedor-archivo">
              <div class="texto-archivo">
                <span class="aviso">A continuación debes adjuntar el RUT actualizado de la Empresa para soportar la
                  facturación
                  electrónica</span>
              </div>
              <div class="archivo-input">
                <label for="archivo" class="label">RUT*</label>
                <!-- Input para cargar archivos -->
                <div class="input-file">
                  <input type="file" formControlName="archivo" id="archivo" accept=".pdf, .jpg"
                    (change)="onFileSelected($event)" class="file-input" #fileInput>

                  <!-- Botón personalizado que activa el input de archivo -->
                  <button type="button" class="custom-button" (click)="fileInput.click()">Cargar archivo</button>

                  <!-- Mostrar nombre del archivo seleccionado -->
                  <div *ngIf="fileName" class="file-name">
                    {{ fileName }}
                  </div>
                  <br>
                  <!-- Mensajes de error -->
                  <small *ngIf="formularioFacturacion.get('archivo')?.hasError('required')">El archivo es
                    obligatorio.</small>
                  <small *ngIf="formularioFacturacion.get('archivo')?.hasError('invalidFileType')">
                    Solo se permiten archivos PDF o JPG.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="columna-dos columna"></div>
        </div>
        }
        <div class="dos-columnas">
          <div class="columna-uno columna">
            <!-- Celular -->
            <div class="contenedor-campo">
              <label for="celular" class="label">Celular*</label>
              <input id="celular" type="text" formControlName="celular" placeholder="Escribe tu numero de celular"
                class="custom-input">
              <div
                *ngIf="formularioFacturacion.get('celular')?.invalid && formularioFacturacion.get('celular')?.touched">
                <small *ngIf="formularioFacturacion.get('celular')?.errors?.['required']">El número de celular es
                  requerido.</small>
                <small *ngIf="formularioFacturacion.get('celular')?.errors?.['pattern']">Debe contener entre 7 y 10
                  dígitos
                  numéricos.</small>
              </div>
            </div>
          </div>
          <div class="columna-dos columna">
            <!-- Correo -->
            <div class="contenedor-campo">
              <label for="correoelectronico" class="label">Correo Electrónico*</label>
              <input id="correoelectronico" type="email" formControlName="correoelectronico" placeholder="Escribe tu correo electrónico"
                class="custom-input">
              <div *ngIf="formularioFacturacion.get('correoelectronico')?.invalid && formularioFacturacion.get('correoelectronico')?.touched">
                <small *ngIf="formularioFacturacion.get('correoelectronico')?.errors?.['required']">El correo es
                  requerido.</small>
                <small *ngIf="formularioFacturacion.get('correoelectronico')?.errors?.['email']">Debe ser un correo electrónico
                  válido.</small>
              </div>
            </div>
          </div>
        </div>
        <!-- Botón de envío
        {{formularioFacturacion.invalid}}
        <div class="enviar">
          <button type="submit" [disabled]="formularioFacturacion.invalid">Enviar</button>
        </div> -->
      </form>
    </div>
    <div class="contenedor-entrega">
      <div class="sub-titulo">
        <h2>Opciones de entrega</h2>
      </div>

      <form [formGroup]="formularioEntrega">

        <!-- Tipo entrega -->
        <div class="dos-columnas">
          <div class="columna-uno columna">
            <!-- Tipo entrega -->
            <div class="contenedor-campo">
              <label for="entrega" class="label">Tipo de Envío*</label>
              <select id="entrega" formControlName="entrega" placeholder>
                <option value="domicilio" selected>Envío a domicilio</option>
                <option value="tienda">Recoge en tienda</option>
              </select>
              <div *ngIf="formularioEntrega.get('entrega')?.invalid && formularioEntrega.get('entrega')?.touched">
                <small>Debe seleccionar un tipo de entrega.</small>
              </div>
            </div>
          </div>
          <div class="columna-dos columna">
          </div>
        </div>

        @if (formularioEntrega.get('entrega')?.value === 'domicilio') {
        <div class="dos-columnas">
          <div class="columna-uno columna">
            <!-- Dirección -->
            <div class="contenedor-campo">
              <label for="direccion" class="label">Dirección*</label>
              <input id="direccion" type="text" formControlName="direccion" placeholder="Escribe su dirección"
                class="custom-input">
              <div *ngIf="formularioEntrega.get('direccion')?.invalid && formularioEntrega.get('direccion')?.touched">
                <small *ngIf="formularioEntrega.get('direccion')?.errors?.['required']">La dirección es
                  requerida.</small>
                <small *ngIf="formularioEntrega.get('direccion')?.errors?.['minlength']">La dirección debe tener
                  al menos 8 caracteres.</small>
              </div>
            </div>
          </div>
          <div class="columna-dos columna">
            <!-- Departamento -->
            <div class="contenedor-campo">
              <label for="departamento" class="label">Departamento*</label>
              <select id="departamento" formControlName="departamento" placeholder>
                <option value="">Escoge departamento</option>
                @for (depto of listDeptos; track $index) {
                  <option [ngValue]="depto">{{depto.departamentoNombre}}</option>
                }
              </select>
              <div
                *ngIf="formularioEntrega.get('departamento')?.invalid && formularioEntrega.get('departamento')?.touched">
                <small>Debe seleccionar un departamento.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="dos-columnas">
          <div class="columna-uno columna">
            <!-- Dirección Aux-->
            <div class="contenedor-campo">
              <label for="direccionAux" class="label">Dirección secundaria*</label>
              <input id="direccionAux" type="text" formControlName="direccionAux"
                placeholder="Dirección secundaría(Int To. Apto. Casa)" class="custom-input">
              <div
                *ngIf="formularioEntrega.get('direccionAux')?.invalid && formularioEntrega.get('direccionAux')?.touched">
                <!-- <small *ngIf="formularioEntrega.get('direccionAux')?.errors?.['required']">La dirección es
                    requerida.</small> -->
                <small *ngIf="formularioEntrega.get('direccionAux')?.errors?.['minlength']">La dirección debe
                  tener al menos 8 caracteres.</small>
              </div>
            </div>
          </div>
          <div class="columna-dos columna">
            <!-- Ciudad -->
            <div class="contenedor-campo">
              <label for="ciudad" class="label">Ciudad*</label>
              <select id="ciudad" formControlName="ciudad" placeholder>
                <option value="">Escoge ciudad</option>
                @for (municipe of listMunicipes; track $index) {
                  <option [ngValue]="municipe">{{municipe.municipioNombre}}</option>
                }
              </select>
              <div *ngIf="formularioEntrega.get('ciudad')?.invalid && formularioEntrega.get('ciudad')?.touched">
                <small>Debe seleccionar una ciudad.</small>
              </div>
            </div>
          </div>
        </div>

        }@else {

        <div class="dos-columnas">
          <div class="columna-uno columna">
            <!-- Ciudad -->
            <div class="contenedor-campo">
              <label for="ciudad" class="label">Escoge una ciudad*</label>
              <select id="ciudad" formControlName="ciudad">
                <option value="">Escoge ciudad</option>
                @for (citie of listCities; track $index) {
                <option [value]="citie"> {{citie | titlecase}} </option>
                }
              </select>
              <div *ngIf="formularioEntrega.get('ciudad')?.invalid && formularioEntrega.get('ciudad')?.touched">
                <small>Debe seleccionar una ciudad.</small>
              </div>
            </div>
          </div>
          <div class="columna-dos columna">
            <!-- Tienda -->
            @if (formularioEntrega.get('ciudad')?.value !== '') {
            <div class="contenedor-campo">
              <label for="tienda" class="label">Escoge una de nuestras tiendas*</label>
              <select id="tienda" formControlName="tienda">
                @if (listStore.length !== 1) {
                <option value="">Escoge tienda</option>
                }
                @for (citie of listStore; track $index) {
                @if (listStore.length == 1) {
                <option [value]="citie" selected> {{citie | titlecase}} </option>
                }@else {
                <option [value]="citie"> {{citie | titlecase}} </option>
                }
                }
              </select>
              <div *ngIf="formularioEntrega.get('tienda')?.invalid && formularioEntrega.get('tienda')?.touched">
                <small>Debe seleccionar un tienda.</small>
              </div>
            </div>
            }
          </div>
        </div>

        }
      </form>
    </div>
    <form [formGroup]="formularioTercero">

      <div class="contenedor-tercero">
        <span>¿Recibe una persona diferente a la que factura?</span>
        <div class="tercero">
          <label>
            Si
            <input type="radio" formControlName="tercero" value="si" class="custom-radio">
          </label>
          <label>
            No
            <input type="radio" formControlName="tercero" value="no" class="custom-radio">
          </label>
        </div>
      </div>
      @if (formularioTercero.get('tercero')?.value === 'si') {
      <div class="dos-columnas">
        <div class="columna-uno columna">
          <!-- Nombres -->
          <div class="contenedor-campo">
            <label for="nombres" class="label">Nombres*</label>
            <input id="nombres" type="text" formControlName="nombres" placeholder="Escriba su nombre completo"
              class="custom-input">
            <div *ngIf="formularioTercero.get('nombres')?.invalid && formularioTercero.get('nombres')?.touched">
              <small *ngIf="formularioTercero.get('nombres')?.errors?.['required']">
                El nombre es requerido.</small>
              <small *ngIf="formularioTercero.get('nombres')?.errors?.['minlength']">
                El nombre debe tener al menos 3 caracteres.</small>
              <small *ngIf="formularioTercero.get('nombres')?.errors?.['pattern']">
                Solo se permiten letras y espacios.</small>
            </div>
          </div>
        </div>
        <div class="columna-dos columna">
          <!-- Apellidos -->
          <div class="contenedor-campo">
            <label for="apellidos" class="label">Apellidos*</label>
            <input id="apellidos" type="text" formControlName="apellidos" placeholder="Escriba su nombre completo"
              class="custom-input">
            <div *ngIf="formularioTercero.get('apellidos')?.invalid && formularioTercero.get('apellidos')?.touched">
              <small *ngIf="formularioTercero.get('apellidos')?.errors?.['required']">Los apellidos son
                requerido.</small>
              <small *ngIf="formularioTercero.get('apellidos')?.errors?.['minlength']">Los apellidos deben tener
                al menos 3 caracteres.</small>
              <small *ngIf="formularioTercero.get('apellidos')?.errors?.['pattern']">Solo se permiten
                letras y espacios.</small>
            </div>
          </div>
        </div>
      </div>
      <div class="dos-columnas">
        <div class="columna-uno columna">
          <!-- Documento -->
          <div class="contenedor-campo">
            <label for="documento" class="label">Cédula*</label>
            <input id="documento" type="text" formControlName="documento" placeholder="Escriba su documento"
              class="custom-input">
            <div *ngIf="formularioTercero.get('documento')?.invalid && formularioTercero.get('documento')?.touched">
              <small *ngIf="formularioTercero.get('documento')?.errors?.['required']">El número de documento es
                requerido.</small> <br />
              <small *ngIf="formularioTercero.get('documento')?.errors?.['minlength']">El documento debe tener
                al menos 5 caracteres.</small> <br />
              <small *ngIf="formularioTercero.get('documento')?.hasError('onlyNumbers')">El documento debe
                contener
                solo números.</small> <br />
            </div>
          </div>
        </div>
        <div class="columna-dos columna">
          <div class="contenedor-campo">
            <label for="celular" class="label">Celular*</label>
            <input id="celular" type="text" formControlName="celular" placeholder="Escribe tu numero de celular"
              class="custom-input">
            <div *ngIf="formularioTercero.get('celular')?.invalid && formularioTercero.get('celular')?.touched">
              <small *ngIf="formularioTercero.get('celular')?.errors?.['required']">El número de celular es
                requerido.</small>
              <small *ngIf="formularioTercero.get('celular')?.errors?.['pattern']">Debe contener entre 7 y 10
                dígitos
                numéricos.</small>
            </div>
          </div>
        </div>
      </div>
      }
    </form>
    <!-- {{formularioTercero.invalid}} -->
    <form [formGroup]="formularioTerminos" class="top">
      <div class="contenedor-tercero">
        <span>¿Solicitudes especiales?</span>
        <div class="tercero">
          <label>
            Si
            <input type="radio" formControlName="especial" value="si" class="custom-radio">
          </label>
          <label>
            No
            <input type="radio" formControlName="especial" value="no" class="custom-radio">
          </label>
        </div>
        <textarea name="solicitud" id="solicitud" class="textarea"></textarea>
      </div>
      <div class="terminos">
        <label class="aviso">
          <input type="checkbox" formControlName="newsletter" class="checkbox">
          Suscribete a nuestro newsletter y recibe información actualizada
        </label>
        <label class="alinear-terminos">
          <input type="checkbox" formControlName="terminos" class="checkbox">
          <p class="aviso">
            *Autorizo el tratamiento de mis datos personales <br>
            conforme a la política de <span class="link" (click)="navigate('tratamiento-datos')">tratamiento de datos
              personales</span>
          </p>
        </label>
        <div *ngIf="formularioTerminos.get('terminos')?.invalid && formularioEntrega.get('tienda')?.touched">
          <small>Debe autorizar el tratamiento de datos</small>
        </div>
      </div>
    </form>
    <div class="informativo">
      <span>*Campo Obligatorio</span>
      <span class="aviso">**Aviso: Para las entregas en Punto de Venta, y a domicilio en la ciudad de Bogotá y Soacha,
        nuestro
        personal de mensajería solitará el documento original. Para más información validar Términos y Condiciones <span
          class="link" (click)="navigate('terminos-condiciones')">aquí</span></span>
    </div>
  </div>
  <div>
    <lib-resumen-compra-rapida (validacionPago)="pago($event)" [formularios]="formulariosLlenos" />
  </div>
</div>
