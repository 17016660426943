import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AddressComponent } from 'libreria-tienda/shopping-cart/address/address.component';
import { AddressService, Deptos, direccion, ExpressService, LoadingService, Municipes, ShoppingCartService, SioServicesService, User, userlogged, UserService } from 'libreria-tienda';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserComponent } from 'libreria-tienda/shopping-cart/update-user/update-user.component';
import { CommonModule, Location } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-modify-address',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatPseudoCheckboxModule,
    FormsModule, ReactiveFormsModule, MatIconModule, MatSelectModule],
  templateUrl: './modify-address.component.html',
  styleUrl: './modify-address.component.css'
})
export class ModifyAddressComponent implements OnInit {

  // usuario: User = new User()
  userLog: userlogged = new userlogged()
  direcciones: direccion[] = [];
  direccion: direccion = new direccion();
  form: FormGroup;
  submited = false;

  actualizoPrincipal: boolean = false

  expressService = inject(ExpressService)
  sioService = inject(SioServicesService)
  cartService = inject(ShoppingCartService)
  addresService = inject(AddressService)
  load = inject(LoadingService)
  userService = inject(UserService)


  constructor(private router: Router, private fb: FormBuilder, public dialogo: MatDialog, private _location: Location) {
    this.form = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      celular: ['', Validators.required],
      departamento: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      informacionAdi: [''],
    })
  }

  expandedPanelIndex: number | null = null;
  listDeptos: Deptos[] = new Array<Deptos>();
  deptoini: Deptos;
  listMunicipes: Municipes[] = new Array<Municipes>();
  panelOpenState = false;
  open = false;
  isPanelOpen: boolean[];

  ngOnInit(): void {
    // this.addresService.setDireccion(new direccion())
    // this.addresService.setIndex(-1)
    this.userLog = this.userService.getUserLocal()
    this.userService.getUserDireccion().then((res) => {
      // console.log(res);
      this.direcciones = res
      this.inicializar()
      this.load.cargar(false)
    })
  }

  inicializar(): void {
    if (this.direcciones.length > 0) {
      let i = this.direcciones.findIndex(x => x.isPrincipal === true)
      let aux = this.direcciones[i]
      this.direcciones.splice(i, 1)
      this.direcciones.unshift(aux)
    }

    this.isPanelOpen = this.direcciones.map(() => false);

    this.sioService.GetDataListCommand("Departamentos", "", []).subscribe((deptos: Deptos[]) => {
      deptos.forEach(dep => {
        this.deptoini = new Deptos();
        this.deptoini = dep
        this.listDeptos.push(this.deptoini)
      })
      this.deptoini = new Deptos();
    });

  }

  newDirection() {
    this.addresService.setIndex(-1);
    this.router.navigate(["add-Address"]);
  }

  editDirection(i: number) {
    this.direcciones[i].editando = true;
    this.addresService.setDireccion(this.direcciones[i])
    this.addresService.setIndex(i)
    this.actualizarDirecciones();
    this.router.navigate(["edit-Address"])
  }

  onPanelOpened(index: number) {
    this.expandedPanelIndex = index;
    this.isPanelOpen[index] = true
    this.open = true
  }

  municipeSelected(event: any) {
    this.form.controls['ciudad'].setValue(event.value);
  }

  deptoSelected(event: any) {
    this.form.controls['departamento'].setValue(event.value);
    this.load.cargar(true)
    this.sioService.GetDataListCommandMunicipe("Municipios", "IdDepto = @0", [event.value.idDepto]).subscribe((municipe: Municipes[]) => {
      this.listMunicipes = municipe
      this.load.cargar(false)
    })
  }

  expandPanelAddDirection() {
    this.panelOpenState = true
  }

  closePanelAddDirection() {
    this.panelOpenState = false
  }

  onPanelClosed(i: number) {
    this.open = false
    this.isPanelOpen[i] = false
  }

  routing() {
    this.router.navigate(["/shippings"])
  }

  saveAddress() {
    this.submited = true
    if (this.form.valid) {
      this.load.cargar(true)
      this.direccion = new direccion()
      this.direccion.nombres = this.form.get('nombres')?.value
      this.direccion.apellidos = this.form.get('apellidos')?.value
      this.direccion.celular = this.form.get('celular')?.value
      this.direccion.departamento = this.form.get('departamento')?.value
      this.direccion.ciudad = this.form.get('ciudad')?.value
      this.direccion.direccion = this.form.get('direccion')?.value
      if (this.form.get('informacionAdi')?.value != '')
        this.direccion.descripcion = this.form.get('informacionAdi')?.value
      else
        this.direccion.descripcion = "Dirección"
      if (this.direcciones !== undefined) {
        if (this.direcciones.length > 0) {
          this.direccion.isPrincipal = false
        } else {
          this.direccion.isPrincipal = true
        }
      }
      console.log(this.direccion);
      this.direcciones.push(this.direccion)
      this.actualizarDirecciones();
    }
  }

  actualizarDirecciones() {
    this.expressService.editDireccionesUser(this.userLog.correoElectronico, this.direcciones).then(res => {
      if (res) {
        this.form.reset()
        this.panelOpenState = false
        this.openDialogMessage()
        this.load.cargar(false)
      }
    }).catch(error => {
      console.error('Error al editar direcciones');
      this.load.cargar(false)
    })
  }

  deleteAddres(i: number) {
    if (this.direcciones.length > 1) {
      if (this.direcciones[i].isPrincipal) {
        this.direcciones.shift()
        this.direcciones[0].isPrincipal = true
        this.direcciones = this.direcciones
      } else {
        this.direcciones.splice(i, 1)
      }
    } else {
      this.direcciones.shift()
    }
    this.expressService.editDireccionesUser(this.userLog.correoElectronico, this.direcciones).then(res => {
      if (res) {
        this.openDialogMessage()
      }
    })
  }

  openDialogMessage(): void {
    const dialogRef = this.dialogo.open(UpdateUserComponent, { panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res2 => {
      if (this.actualizoPrincipal) {
        this._location.back()
        this.actualizoPrincipal = false;
      }
      // console.log('Back');

    })
  }

  newPrincipal(i: number) {
    this.direcciones[0].isPrincipal = false
    this.direcciones[i].isPrincipal = true
    let aux = this.direcciones[i];
    this.direcciones.splice(i, 1)
    this.direcciones.unshift(aux)
    this.actualizoPrincipal = true;
    this.expressService.editDireccionesUser(this.userLog.correoElectronico, this.direcciones).then(res => {
      if (res) {
        this.openDialogMessage()
      }
    })
  }

}
