import { Injectable, inject } from '@angular/core';
import { direccion, User, userlogged } from '../class/user';
import { ExpressService } from './express.service';
import { Router } from '@angular/router';
import { favorite } from '../class/product';
import { ItemCar } from '../class/carro-compras';
import { ControlSesionService } from './control-sesion.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userLog: userlogged = new userlogged();
  user: User = new User();

  constructor() {
    this.getUser()
  }

  sesionService = inject(ControlSesionService);
  expressService = inject(ExpressService);
  router = inject(Router);


  getUserLocal(): userlogged {
    if (this.userLogueado()) {
      return this.userLog;
    }
    return this.userLog
  }

  userLogueado(): boolean {
    if (localStorage.getItem("userLogged") !== null) {
      this.userLog = JSON.parse(localStorage.getItem("userLogged") || "")
      return this.userLog.correoElectronico != undefined;
    } else {
      return false;
    }
  }

  setUser(user: userlogged) {
    localStorage.removeItem("userLogged");
    localStorage.setItem("userLogged", JSON.stringify(user));
  }

  getUserDireccion(): Promise<direccion[]> {
    return new Promise((resolve, reject) => {
      if (typeof localStorage !== 'undefined') {
        if (localStorage.getItem("userLogged") !== undefined) {
          this.user = JSON.parse(localStorage.getItem("userLogged") || "");

          // Realizamos la llamada a expressService para obtener la dirección
          this.expressService.getUserDireccion(this.user.correoElectronico).then((res) => {
            // console.log(res);
            // Revolvemos la promesa con userDireccion
            resolve(res.direcciones);
          }).catch((error) => {
            console.error('Error en la consulta');
            // Desloguear
            // Rechazamos la promesa en caso de error
            reject(error);
          });
        } else {
          // En caso de que no haya un usuario logueado en localStorage
          reject(new Error('No user logged in'));
        }
      } else {
        // En caso de que localStorage no esté disponible
        reject(new Error('LocalStorage not available'));
      }
    });
  }

  async getUser(): Promise<User> {
    try {
      if (typeof localStorage !== 'undefined' && localStorage.getItem("userLogged")) {
        this.user = JSON.parse(localStorage.getItem("userLogged") || "");
        // Obtener información actualizada del usuario
        const res = await this.expressService.getUser(this.user.correoElectronico);
        this.user = res.user;
      }
    } catch (error) {
      console.error(error);
      this.sesionService.cerrarSesion();
    }

    // Devolver el usuario
    return this.user;
  }

  // Se tiene que depurar este metodo
  sincronizarUserNube() {
    this.expressService.editFavCarrito(this.user).then((resp) => {
      console.log(resp);
      this.user.favoritos = resp;
      // localStorage.setItem("userLogged", JSON.stringify(this.user));
    }).catch((error) => {
      console.log('se debe eliminar la sesión');
    });
  }

  combinarArraysFav(array1: favorite[], array2: favorite[]): favorite[] {
    const mapaUnicos = new Map<string | number, favorite>();

    const agregarAlMapa = (objeto: favorite): void => {
      const id = objeto.esGia ? objeto.referenciaProveedor : objeto.idProducto;
      if (!mapaUnicos.has(id)) {
        mapaUnicos.set(id, objeto);
      }
    };

    array1.forEach(objeto => agregarAlMapa(objeto));

    array2.forEach(objeto => agregarAlMapa(objeto));

    return Array.from(mapaUnicos.values());
  }

  combinarArraysCart(array1: ItemCar[], array2: ItemCar[]): ItemCar[] {
    const map = new Map();

    array1.forEach(item => map.set(item.idProducto, item));
    array2.forEach(item => {
      if (!map.has(item.idProducto)) {
        map.set(item.idProducto, item);
      }
    });
    return Array.from(map.values());
  }

  getInfoLocal(atributo: string): favorite[] | ItemCar[] {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem("logged") != undefined) {
        let logget = JSON.parse(localStorage.getItem("logged") || "")
        if (logget.login) {
          this.user = JSON.parse(localStorage.getItem("userLogged") || "")
        } else {
          this.user = JSON.parse(localStorage.getItem("user") || "")
        }
      } else if (localStorage.getItem("user") != undefined) {
        this.user = JSON.parse(localStorage.getItem("user") || "")
      } else {
        return [];
      }
      if (atributo == 'fav') {
        return this.user.favoritos
      } else if (atributo == 'carrito') {
        // Cambiar cuando este definida la metodología del carrito
        return this.user.favoritos
      } else {
        return []
      }
    } else {
      return [];
    }
  }
}
