import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ConfigService, JsonTextService, TextResponse } from 'libreria-tienda';

@Component({
  selector: 'lib-services',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent implements OnInit {

  jsonServices: TextResponse[]
  jsonService = inject(JsonTextService)
  configService = inject(ConfigService)
  private router = inject(Router)


  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLJSONSERVICES).subscribe(res => {
      this.jsonServices = res
    })

  }

  getName(name: string) {
    return this.jsonServices?.find(x => x.Nombre == name)?.Valor
  }

  navigate(url: string) {
    this.router.navigate(['/' + url],).then(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    });
  }

}
