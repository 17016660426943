@if (favoritos) {
<div class="container text-center">
  <div class="row align-items-center">
    @for (product of favoritos; track $index) {
    <lib-card-product class="col-12 col-md-3 grilla" [product]="product" [pagina]="pagina" [favoritos]="true"/>
    }
  </div>
</div>
@if(favoritos.length>0){
<lib-paginador [categoria]="'Favoritos'" [fav]="fav" (paginaOut)="paginaChange($event)" />
}@else {
<div class="center payment-container">
  <h2 class="pt-5 pb-5">No tiene articulos en favoritos </h2>
</div>
}
}
