import { Component, OnInit, inject } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { ConfigService, JsonTextService, TextResponse } from 'libreria-tienda';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import { PopUpSuscribeteComponent } from '../pop-up-suscribete/pop-up-suscribete.component';

declare var propiedades: any | undefined;

@Component({
  selector: 'lib-footer',
  standalone: true,
  imports: [MatExpansionModule, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, MatFormFieldModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export default class FooterComponent implements OnInit {
  panelOpenState = false;
  panelOpenState3 = false;
  panelOpenState4 = false;
  footerJson: TextResponse[];
  footerJs: any;

  propiedades: any;

  jsonService = inject(JsonTextService)
  configService = inject(ConfigService)

  fileUrl: any;
  logo: any;
  rutaActual: string;


  constructor(private router: Router, private fb: FormBuilder, public dialog: MatDialog) {
    this.propiedades = propiedades;
    this.new_kevins = this.initForm()
  }


  new_kevins: FormGroup;


  ngOnInit(): void {
    if (this.propiedades) {
      this.footerJs = this.propiedades['footer']
      // console.log(this.footerJs);
    }
  }

  donwloadFiles() {
    window.open(this.fileUrl, '_blank');
  }

  getName(name: string) {
    return this.footerJson?.find(x => x.Nombre == name)?.Valor
  }

  redirectToHome() {
    this.rutaActual = this.router.url
    if (this.rutaActual != "/home") {
      this.router.navigate(['/home']).then(() => {
        window.scrollTo(0, 0);
      });
    }
  }

  naviagte(url: string) {
    this.router.navigate([`/${url}`]).then(() => {
      window.scrollTo(0, 0);
    });
  }

  initForm(): FormGroup {
    // Inicializa el formulario y define los controles
    return this.fb.group({
      nombreFull: ['', Validators.required],
      fechaNacimiento: ['', Validators.required],
      estadoCivil: ['', Validators.required],
      genero: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    });
  }

  saveNews() {
    if (this.new_kevins.valid) {
      console.log('ya');
    }
    console.log('no');

  }

  goPandape() {
    window.open(this.footerJs['pandape'], '_blank');
  }

  openNew() {
    this.dialog.open(PopUpSuscribeteComponent, {
      position: { bottom: '0', left: '0' }, // Posición en la parte inferior izquierda
      width: '100%', // Ancho del popup para ocupar toda la pantalla
      maxWidth: '100%'
    }).afterClosed().subscribe(res => {
      console.log(res);
    });

  }

}
