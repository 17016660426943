<div class="contenedor">
  <h1 class="titulo">Cuidado de Joyas y Relojes</h1>
  <p class="texto-principal">Aquí podrás encontrarás toda la información sobre los cuidados de joyas <br> y relojes que
    debes tener para que
    disfrutes tu producto por más tiempo.</p>
  <div class="cuadro">
    <div class="contenedor-columnas">
      <div class="columna1">
        <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_oro.png" alt="">
        <p class="cuidado">Manual de cuidado<br>Joyas en</p>
        <p class="joyas">Oro</p>
        <a href="" class="descargar" (click)="descargarArchivos(0)">Descargar</a>
      </div>
      <div class="columna2">
        <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_p&a.png" alt="">
        <p class="cuidado">Manual de cuidado<br>Joyas en</p>
        <p class="joyas">Plata y Acero</p>
        <a href="" class="descargar" (click)="descargarArchivos(1)">Descargar</a>
      </div>
      <div class="columna3">
        <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_reloj.png" alt="">
        <p class="cuidado">Manual de cuidado<br>en</p>
        <p class="joyas">Relojes</p>
        <a href="" class="descargar" (click)="descargarArchivos(2)">Descargar</a>
      </div>
    </div>
  </div>
</div>
