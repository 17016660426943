<p>retiro-tienda works!</p>
<!-- <div class="d-flex-column">
  <form [formGroup]="formSelectedStore" action="">
    <div class="d-flex-row">
      <div class="pl-35px pt-3 pr-15px w-50">
        <mat-form-field appearance="fill" class="w-cities pl-15px pr-15px">
          <mat-select formControlName="ciudad" (selectionChange)="citieSelected($event)"
            placeholder="Selecciona tu ciudad" required>
            <mat-option *ngFor="let doc of listCities" [value]="doc">
              {{doc.nombreCiudad | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="pl-15 pt-3 pb-3 pr-15px w-50">
        <mat-form-field appearance="fill" class="w-cities pl-15px pr-15px">
          <mat-select formControlName="punto" (selectionChange)="pointSelected($event)"
            placeholder="Selecciona la tienda" required>
            <mat-option *ngFor="let doc of listPoints" [value]="doc">
              {{doc.nombrePunto | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="pt-3 pb-3 row-column-responsive pl-35px" *ngIf="pointKevinsSelected.direccion != undefined">
    <div class="grid-points pr-1">
      <div class="icon-grid pr-1">
        <span class="icon-Icono_Tienda icon-size-22px"></span>
      </div>
      <div class="name-point">
        <span>{{pointKevinsSelected.nombrePunto}}</span>
      </div>
      <div class="direccion">
        <span>{{pointKevinsSelected.direccion}}</span>
      </div>
    </div>
    <div class="grid-points">
      <div class="icon-grid pr-1">
        <span class="icon-Icono_reloj icon-size-22px"></span>
      </div>
      <div class="name-point">
        <span>Horarios de atención</span>
      </div>
      <div class="direccion">
        <span>L-V de 11:00 a.m a 7:30 p.m.</span>
      </div>
      <div class="local">
        <span>L-V de 11:00 a.m a 7:30 p.m.</span>
      </div>
    </div>
  </div>
  <app-receive-by [user]="user" [recibeYo]="getName('recoge-yo')" [recibeOtro]="getName('recoge-otro')"
    [quienRecibe]="getName('quien-recoge')" [guardar]="getName('but-guardar')" [section]="section"
    (sectionEmit)="sectionEmit($event)" (sendTypeEmit)="sendTypeEmit($event)"
    (recibeYoEmit)="recibeYo($event)" (recibeOtroEmit)="recibeOtro($event)"></app-receive-by>
  <mat-divider></mat-divider>
  <div>
    <app-message [user]="user" [mensaje]="getName('mensaje')" (mensajeEmit)="mensaje($event)">
    </app-message>
  </div>
  <mat-divider></mat-divider>
  <h2 class="titles pt-3 pb-3 margin-0">{{getName('dire-fac')}}</h2>
  <div *ngIf="isDireccion" class="d-flex-row d-flex-start pl-6  shadowh h-90 space-bet">
    <div class="d-flex-column start">
      <div class=" d-flex-center pb-2">
        <span class="titles">{{direccion.descripcion}}</span>
      </div>
      <div class="d-flex-center">
        <span class="titles">{{direccion.direccion}}</span>
      </div>
    </div>
    <div class="d-flex-center d-flex-column start">
      <div>
        <span class="info">{{direccion.nombres}} </span>
        <span class="info">{{direccion.apellidos}}</span>
      </div>
      <div>
        <span class="info">Cel: {{direccion.celular}}</span>
      </div>
    </div>
    <div class="d-flex-center">
      <span class="info">{{direccion.ciudad.municipioNombre}}
        {{direccion.departamento.departamentoNombre}}</span>
    </div>
    <div class="d-flex-center pr-10">
      <span (click)="editAddres(0)" class="icon-Editar font-size-22 cursor pr-1"></span>
      <span (click)="deleteAddres(0)" class="icon-Eliminar font-size-22 cursor"></span>
    </div>
  </div>
  <div *ngIf="!isDireccion">
    <h2 class="titles">{{getName('no-dire')}}</h2>
  </div>
  <div class="d-flex-space-bet pt-3 pb-3 pl-35px">
    <div class="w-30">
      <button *ngIf="direccion.nombres != undefined" (click)="routing()"
        class="titles button-secondary pl-0">{{getName('camb-dire-fac')}}</button>
      <button *ngIf="direccion.nombres == undefined" (click)="routing()"
        class="titles button-secondary pl-0">{{getName('add-dire-fac')}}</button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="d-flex-row pl-35px pt-3 pb-3">
    <div class="pr-1 d-flex-center">
      <span class="icon-gift font-size-40 pr-1"></span>
      <span>{{getName('max-dias')}}</span>
    </div>
    <div class="pr-1 d-flex-center pt-3 pb-3">
      <span class="icon-Icono_mano font-size-40 pr-1"></span>
      <span>{{getName('no-cost')}}</span>
    </div>
  </div>
</div> -->
