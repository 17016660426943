import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Product, favorite } from 'libreria-tienda';
import { FavoritoCorazonComponent } from 'libreria-tienda/sfa/components/favorito-corazon/favorito-corazon.component';

@Component({
  selector: 'lib-card-pulsera-venezia',
  standalone: true,
  imports: [CommonModule, FavoritoCorazonComponent],
  templateUrl: './card-pulsera-venezia.component.html',
  styleUrl: './card-pulsera-venezia.component.css'
})
export class CardPulseraVeneziaComponent {

  router = inject(Router)
  articulo: Product;
  fav: favorite = new favorite();
  @Input() set producto(value: Product) {
    this.articulo = value;
    this.fav.esGia = false;
    this.fav.idProducto = value.idProducto;
    this.fav.peso = value.pesoPiedraCentral;
    this.fav.referenciaProveedor = value.referenciaProveedor;
  }

  redirect(url: string) {
    const card: Product[] = [];
    card.push(this.articulo)
    if (localStorage.getItem("artVenezia") != undefined) {
      localStorage.removeItem("artVenezia")
    }
    localStorage.setItem("artVenezia", JSON.stringify({ 'artVenezia': card }));
    this.router.navigate([`/` + url])
  }

}
