import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ControlSesionService {

  constructor(private router: Router) { }



  cerrarSesion() {
    if (typeof localStorage !== 'undefined') {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['']).then(() => {
        window.scrollTo(0, 0);
        window.location.reload();
      });
    }
  }
}
