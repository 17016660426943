import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgResponse } from 'libreria-tienda';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-slider-siete',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './slider-siete.component.html',
  styleUrl: './slider-siete.component.css',
})
export class SliderSieteComponent implements OnInit {

  @Input() marcas: ImgResponse[]

  @ViewChild('carousel') carousel!: ElementRef;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.startAutoPlay()
  }

  old: number;

  autoPlayTimer: any;

  scrollCarousel(direction: string): void {
    const carouselElement = this.carousel.nativeElement as HTMLElement;
    const firstCardWidth = (carouselElement.querySelector('.card') as HTMLElement)?.offsetWidth || 0;
    const scrollAmount = direction === 'left' ? -firstCardWidth : firstCardWidth;
    if (direction == 'right') {
      if (((carouselElement.scrollLeft - this.old) < firstCardWidth) && carouselElement.scrollLeft !== 0) {
        carouselElement.scrollLeft = 0
      } else {
        carouselElement.scrollLeft += scrollAmount;
        this.old = carouselElement.scrollLeft
      }
    } else {
      if (carouselElement.scrollLeft == 0) {
        carouselElement.scrollLeft = carouselElement.scrollWidth
      } else {
        carouselElement.scrollLeft -= firstCardWidth
      }
    }

  }

  stopAutoPlay(): void {
    clearTimeout(this.autoPlayTimer);
  }

  startAutoPlay(): void {
    this.autoPlayTimer = setInterval(() => this.scrollCarousel('right'), 2000);
  }

  navegar(marca: string) {
    console.log(marca);
    this.router.navigateByUrl(`/${marca}`).then(() => {
      window.scrollTo(0, 0);
    });
  }
}
