import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ExpressService, Product, User } from 'libreria-tienda';
import { favorite, FavoritoService } from 'libreria-tienda';

@Component({
  selector: 'lib-favorito-corazon',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './favorito-corazon.component.html',
  styleUrl: './favorito-corazon.component.css'
})
export class FavoritoCorazonComponent implements OnInit, AfterViewInit {

  fav: favorite = new favorite()
  favorite: boolean = false
  idProductoUrl: string | '0';
  @Input() esGia: boolean;
  @Input() idProducto: string = 'Nada';
  @Input() peso: number;
  @Input() referenciaProveedor: string;

  @Input() size: string = 'heart-size1'

  favoritoService = inject(FavoritoService)
  private route = inject(ActivatedRoute)
  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.idProductoUrl = (params.get('producto') ?? '0');
      this.fav.esGia = this.esGia;
      this.fav.idProducto = this.idProductoUrl == '0' ? this.idProducto : this.idProductoUrl;
      this.fav.peso = this.peso == 1 ? 1.0 : this.peso;
      this.fav.referenciaProveedor = this.referenciaProveedor;
      this.favorite = this.favoritoService.buscarFav(this.fav)
    })

    this.favorite = false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.favorite = this.favoritoService.buscarFav(this.fav)
    }, 100);
  }

  handleLikeChange2() {
    this.favorite = this.favoritoService.estado(this.fav)
  }

}
