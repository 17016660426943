import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DispositivoService, EstadosFiltrosService, InstantSearchService, JsonTextService, LoadingService, SioServicesService, favorite } from 'libreria-tienda';

class pagina {
  id: number;
  select: boolean = false
}

@Component({
  selector: 'lib-paginador',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './paginador.component.html',
  styleUrl: './paginador.component.css'
})
export class PaginadorComponent implements OnInit, AfterViewInit {

  @Output() paginaOut = new EventEmitter<number>();
  @Input() categoria: string;
  @Input() fav: favorite[];

  _filtros: any[] = [];
  @Input() set filtros(value: any) {
    if (value !== this._filtros) {
      this.pagina = 1
      this.paginasMostrar = [];
      this.contarPaginas(40, value);
      this._filtros = value;
    }
  }
  total: number = 0;

  pagina: number;

  max: number;

  paginas: pagina[];

  paginasMostrar: pagina[] = [];

  diamante: string = "https://qastorageorafa.blob.core.windows.net/icono-paginador/diamond_ico.svg"

  public instantSearchService = inject(InstantSearchService)
  public estadosFiltrosService = inject(EstadosFiltrosService)
  public jsonService = inject(JsonTextService)
  public dipositivoService = inject(DispositivoService)
  public sioservice = inject(SioServicesService)
  public load = inject(LoadingService)

  constructor() {
  }

  ngOnInit() {
    // this.load.cargar(true);
    if (typeof localStorage !== undefined) {
      if (this.categoria == 'busqueda') {
        this.contarPaginaPaso2(this.instantSearchService.itemsTotal)
      } else {
        if (localStorage.getItem("posicion") != undefined) {
          var posicion = JSON.parse(localStorage.getItem("posicion") || "")
          this.pagina = posicion.pagina;
        } else {
          this.pagina = 1;
          // console.log('No hay posicion');
        }
        // if (localStorage.getItem("columns") != undefined) {
        //   this.contarPaginas(JSON.parse(localStorage.getItem("columns") || ""), this._filtros)
        // } else {
        this.contarPaginas(40, this._filtros)
        // }
      }
    } else {
      this.pagina = 1;
      this.contarPaginas(40, this._filtros)
    }
  }

  ngAfterViewInit(): void {

  }

  contarPaginas(columnas: number = 40, filtros: any[] = []) {
    if (this.categoria == 'Favoritos') {
      this.contarPaginaPaso2(this.fav.length, columnas)
    } else {
      this.load.cargar(true)
      this.sioservice.getResumenArticulosPaginados(this.categoria, filtros).then((res) => {
        // console.log(res);
        if (res.exitoso) {
          // console.log(res.valor);
          this.estadosFiltrosService.updatePreciosConsulta(res.valor.precioMinimo, res.valor.precioMaximo)
          // if (res.valor.filtros !== null) {
            this.estadosFiltrosService.updateFiltrosDispopnibles(res.valor.filtros);
            // }
            this.contarPaginaPaso2(res.valor.totalArticulosAgrupadosReferenciaProveedorYPiedra, columnas);
          } else {
            console.log('Error en la consulta');
          }
          this.load.cargar(false)
      }).catch((error) => {
        console.error(error);
        this.load.cargar(false);
      });
    }

  }

  contarPaginaPaso2(total: number, columnas: number = 40) {
    // console.log(total, columnas);
    if (total > 0) {
      this.total = 0;
      this.total = total
      this.max = 0;
      this.max = Math.ceil(this.total / columnas);
      this.paginas = [];
      this.paginas = Array.from({ length: this.max }, (_, index) => {
        const nuevaPagina = new pagina();
        nuevaPagina.id = index + 1;
        return nuevaPagina;
      });

      this.paginas[this.pagina - 1].select = true
      this.paginasAmostrar();
    } else {
      console.log('No hay productos para mostrar');

    }
  }

  paginasAmostrar() {
    if (this.pagina >= 1 && this.pagina <= this.max) {
      // console.log('SI sed ejecvuta');
      this.paginasMostrar = [];
      if ((this.pagina) <= 2) {
        this.paginasMostrar = this.paginas.slice(0, 5)
      } else {
        this.paginasMostrar = this.paginas.slice(this.pagina - 3, this.pagina + 2);
      }
    }
  }

  async pageSelect(i: number) {
    if (i != this.pagina) {
      this.paginas[this.pagina - 1].select = false;
      this.pagina = i
      this.paginaOut.emit(this.pagina)
      console.log(this.pagina);

      await this.paginasAmostrar()
      this.paginas[this.pagina - 1].select = true;
    }
    window.scrollTo(0, 0);
  }

  async next() {
    if (this.pagina <= this.max - 1) {
      this.paginas[this.pagina - 1].select = false;
      this.pagina++;
      this.paginaOut.emit(this.pagina)
      window.scrollTo(0, 0);
      await this.paginasAmostrar()
      this.paginas[this.pagina - 1].select = true
    }
  }

  async back() {
    if (this.pagina - 1 !== 0) {
      this.paginas[this.pagina - 1].select = false;
      this.pagina--;
      window.scrollTo(0, 0);
      this.paginaOut.emit(this.pagina)
      await this.paginasAmostrar()
      this.paginas[this.pagina - 1].select = true
    }
  }

}
