<div class="w-100 d-none d-sm-none d-md-none d-lg-block">
  <div class="nosotros">
    <div class="contenedor center">
      <div class="box box1">
        <span [innerHTML]="propiedades['about-us']['titulo']" class="titulo"></span>
        <span [innerHTML]="propiedades['about-us']['info']" class="parrafo"></span>
        <div class="center-padre pointer" (click)="navigate()">
          <span [innerHTML]="propiedades['about-us']['boton']" class="parrafo boton"></span>
          <mat-icon class="boton">chevron_right</mat-icon>
        </div>
      </div>
      <div class="box box2">
        <video oncanplay="this.play()" onloadedmetadata="this.muted = true" class="video" muted loop autoplay
          playsinline>
          <source [src]="propiedades['about-us']['video']" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</div>

<div class="d-block d-sm-block d-md-block d-lg-none block-small show-mobile">
  <div class="contenedor-img-mobile" [ngStyle]="{'background-image': 'url(' + imagen_fondo_mobile + '?v=' + version + ')'}">
    <div class="contenedor-caja">
      <span [innerHTML]="propiedades['about-us']['titulo']" class="titulo"></span>
      <span [innerHTML]="propiedades['about-us']['info']" class="parrafo texto_separado"></span>
      <div class="center-padre pointer" (click)="navigate()">
        <span [innerHTML]="propiedades['about-us']['boton']" class="parrafo boton"></span>
        <mat-icon class="boton">chevron_right</mat-icon>
      </div>
    </div>
  </div>
</div>
