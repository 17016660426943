import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-solicitud-exito-vacante',
  standalone: true,
  imports: [],
  templateUrl: './solicitud-exito-vacante.component.html',
  styleUrl: './solicitud-exito-vacante.component.css'
})
export class SolicitudExitoVacanteComponent {

  constructor(public dialogo: MatDialogRef<SolicitudExitoVacanteComponent>) { }

  close(){
    this.dialogo.close()
  }

}
