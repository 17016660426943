export class Store {
    id: number;
    codPunto: number;
    codPuntoChr: number;
    zona: number;
    ip: string;
    nombre: string;
    slug: string;
    local: string;
    idCiudad: string;
    ciudad: string;
    slugCiudad: string;
    email:string;
    latitud_ciudad: number;
    longitud_ciudad: number;
    indicativo:number;
    telefono1: number;
    telefono2: string;
    direccion: string;
    horario: string;
    horario_covid: string;
    horario_semanaSanta: string;
    latitud: number;
    longitud: number;
    joyero: number;
    relojero: number;
    compraOro: number;
    visible: number;
    mostrarEnPagina: number;
    disponible: number;
    aperturaCovid: number;
    abierto: number;
    embedMap: string;
    whts: string;
}
export class Marker{
    position: position;
    store: Store = new Store()
}
export class position{
    lat: number;
    lng: number;
}
