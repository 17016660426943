import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService, Product, User, favorite } from 'libreria-tienda';
import { detalle } from 'libreria-tienda';
import { FavoritoCorazonComponent } from 'libreria-tienda/sfa/components/favorito-corazon/favorito-corazon.component';
import { CarruselImgCardComponent } from './carrusel-img-card/carrusel-img-card.component';
import { EtiquetaDescuentoCardComponent } from '../etiqueta-descuento/etiqueta-descuento-card/etiqueta-descuento-card.component';
import { NumeroPuntoPipe } from 'libreria-tienda';

@Component({
  selector: 'lib-card-product',
  standalone: true,
  imports: [CommonModule, FavoritoCorazonComponent, EtiquetaDescuentoCardComponent, CarruselImgCardComponent, NumeroPuntoPipe],
  templateUrl: './card-product.component.html',
  styleUrl: './card-product.component.css'
})
export class CardProductComponent implements OnInit {

  scrollY: number = 0;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollY = window.scrollY; // Captura la posición vertical del scroll
  }
  _producto: Product;

  seAbrio: boolean = false;

  favorito: favorite = new favorite();
  constructor(private router: Router) { }

  @Input() set product(value: Product) {
    this._producto = value;
    this.errorImgProduct = value.errorImagen;
    this.favorito.esGia = this._producto.esGIA;
    this.favorito.idProducto = this._producto.idProducto;
    this.favorito.peso = this._producto.pesoPiedraCentral;
    this.favorito.referenciaProveedor = this._producto.referenciaProveedor;
    this.manejaTalla = this._producto.tipoProducto == 'Anillo' || this._producto.tipoProducto == 'Gia' ? true : false
    this.cantidadMaximaNumber = parseInt(this._producto.cantidad);
    // console.log(value);

  };
  categoria: any;
  @Input() pagina: number;
  @Input() favoritos: boolean = false;

  @Input() isHovered: boolean = false;

  @Output() hover = new EventEmitter<void>();
  @Output() leave = new EventEmitter<void>();
  public configService = inject(ConfigService)
  private route = inject(ActivatedRoute)

  cantidadMaximaNumber: number;

  errorImgProduct: boolean = false;
  // isHovered: boolean = false;

  imgGIA: string;

  price: string = 'price';
  desc: boolean = true;
  user: User = new User();
  isFav: boolean = false;
  manejaTalla: boolean = false;

  item: detalle = new detalle();

  ngOnInit(): void {
    this.imgGIA = this.configService._config.URLIMGGIA;
    this.isHovered = false

    this.route.paramMap.subscribe(params => {
      this.categoria = params.get('busqueda');
      // console.log(this.categoria);
    });
  }

  detalle() {
    // console.log('aca ??');
    if (this.categoria == null) {
      this.route.queryParamMap.subscribe(params => {
        this.categoria = (params.get('catalogo') ?? '0');
        console.log(this.categoria);
      })
    }

    if (this._producto.cantidad + '' !== '0') {
      localStorage.removeItem("categoria");
      localStorage.setItem("categoria", JSON.stringify({ 'categoria': this.categoria }));
      this.router.navigate(['/detalle'], { queryParams: { catalogo: this.categoria, producto: this._producto.esGIA ? '' : this._producto.idProducto, GIA: this._producto.esGIA, tipoProducto: this._producto.tipoProducto, pesoPiedra: this._producto.pesoPiedraCentral, referenciaProveedor: this._producto.referenciaProveedor } }).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    }
  }

  manejarErrorDeImagen(img: string) {
    this.errorImgProduct = true
  }

  guardarPosicion() {
    const posicion = {
      y: this.scrollY,
      pagina: this.pagina,
      codigo: this.categoria
    }
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem("posicion", JSON.stringify(posicion));
    }
  }

  onMouseEnter() {
    this.isHovered = true;
    this.hover.emit();
  }

  onMouseLeave() {
    if (!this.seAbrio) {
      this.isHovered = false;
      this.seAbrio = false
      this.leave.emit();
    }
  }

  seleccionado(e: any) {
    this.detalle();
    this.guardarPosicion();
  }

  abierto(e: any) {
    console.log(e);
    if (e) {
      this.seAbrio = true
      this.isHovered = true
    } else {
      this.seAbrio = false
    }
  }

}
