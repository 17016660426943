@if (banners) {
<div class="container">
  <div class="wrapper">
    @if (!isMobile || section!=='home') {
    <video oncanplay="this.play()" onloadedmetadata="this.muted = true" width="100%" height="100%" muted loop autoplay
      playsinline class="video-home" [style.background]="propiedades[section]['color']">
      <source [src]="propiedades[section]['video1']" type="video/mp4">
    </video>
    }@else {
    <img class="banner" [src]="propiedades[section]['mobile'] + '?v=' + version " alt="">
    }
  </div>
</div>
}@else {
<div class="container">
  <div class="wrapper-skeleton">
    <ngx-skeleton-loader class="skeleton-img" count="1" appearance="circle" [theme]="{
      'width.%': 80,
      'height.rem': 40,
      'border-radius': '10px'
    }"></ngx-skeleton-loader>
  </div>
</div>
}
