<div class="d-none d-sm-none d-md-none d-lg-block">
  <!-- Pasos para realizar el pago -->
  <lib-pasos-compra [paso]="1"></lib-pasos-compra>

  <div class="d-flex-row pt-5 pb-5 space-around back-img padding-container">
    <div class="w-60">
      @if (shoppingCart) {
      <lib-carrito-vacio />
      }@else {
      <lib-carrito [carrito]="listItemsCompra" (indexDelet)="EliminarItem($event)"></lib-carrito>
      }
    </div>
    <div>
      <lib-resumen [carrito]="listItemsCompra" [section]="1" [envio]="-1" />
      <!-- <app-resume [section]="1" [shoppingCart]="shoppingCart" [envio]="-1"></app-resume> -->
    </div>
  </div>
</div>

<!-- <div class="d-block d-sm-block d-md-block d-lg-none">
  <lib-paso-compra-mobile [paso]="1" />
  <div>
    <lib-carrito [carrito]="listItemsCompra" (indexDelet)="EliminarItem($event)"></lib-carrito>
    <lib-resumen [carrito]="listItemsCompra" [section]="1" [envio]="-1" />
    <app-resume [section]="1" [shoppingCart]="shoppingCart" [envio]="-1"></app-resume>
  </div>
</div> -->
