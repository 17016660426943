import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { items } from '../class/cart';
import { ItemCar } from '../class/carro-compras';

@Injectable({
  providedIn: 'root'
})
export class ManejadorEventosService {

  constructor(private router: Router) { }

  estilosColumna: string = ""

  @Output() main = new EventEmitter<string>();
  @Output() producto = new EventEmitter<ItemCar>();
  @Output() newProducto = new EventEmitter<boolean>();
  @Output() classColunm = new EventEmitter<string>();

  lanzarEvento(e: string, i?: ItemCar) {
    const rutaActual = this.router.url
    switch (e) {
      case 'addItemCart':
        if (rutaActual != '/shopping-cart') {
          this.producto.emit(i)
          this.main.emit('abrirDerecha')
          // console.log(i);
        }
        break;
      case 'goCart':
        this.main.emit('abrirDerecha')
        break;
      case 'goCatalogo':
        this.main.emit('cerrar')
        break;
      case 'changeColumns':
        console.log('changeColumns');
        break;
      case 'openCart':
        this.newProducto.emit(false)
        console.log('pasa algo');
        break;
      default:
        break;
    }
  }

}
