<div class="contenedor">
  <div class="titulo">
    <div class="raya"></div>
    <h2>Solicitud de Certificado de Piedras Preciosas</h2>
  </div>
  <div class="contenedor-formulario">
    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
    <!--  If necessary, please modify the charset parameter to specify the        -->
    <!--  character set of your HTML page.                                        -->
    <!--  ----------------------------------------------------------------------  -->

    <META HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8">
    <script src="https://www.google.com/recaptcha/api.js"></script>
    <script>
      function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") { var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value); elems["ts"] = JSON.stringify(new Date().getTime()); document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);
      function obtenerValor() {
        // Obtiene el elemento select por su id
        var select = document.getElementById("00ND600000Sn0Tl");
        // Obtiene el valor seleccionado
        var valorSeleccionado = select.value;
        // Muestra el valor seleccionado en el elemento con id "resultado"
        document.getElementById("resultado").innerText = "Valor seleccionado: " + valorSeleccionado;
      }
    </script>

    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: Please add the following <FORM> element to your page.             -->
    <!--  ----------------------------------------------------------------------  -->

    <form
      action="https://site-site-5635--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DD6000000VW5y"
      method="POST">

      <input type=hidden name='captcha_settings'
        value='{"keyname":"Web_to_case_key_pair","fallback":"true","orgId":"00DD6000000VW5y","ts":""}'>
      <input type=hidden name="orgid" value="00DD6000000VW5y">
      <input type=hidden name="retURL" value="https://kevins.com.co/">

      <!--  ----------------------------------------------------------------------  -->
      <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
      <!--  these lines if you wish to test in debug mode.                          -->
      <!--  <input type="hidden" name="debug" value=1>                              -->
      <!--  <input type="hidden" name="debugEmail"                                  -->
      <!--  value="juan.naranjo@ceiba.com.co">                                      -->
      <!--  ----------------------------------------------------------------------  -->
      <div class="columnas">
        <div class="columna-uno">
          <div class="none">
            <div class="contenedor-campo">
              <label class="label" for="type">Tipo de documento</label>
              <select id="type" name="type">
                <option value="">--Ninguno--</option>
                <option value="Product">Producto o Servicio</option>
                <option value="Request" selected>Solicitudes</option>
                <option value="Customer service comments">Comentarios servicio al cliente</option>
                <option value="Product status">Estado de producto</option>
                <option value="Contact">Contacto</option>
              </select>
            </div>
            <div class="contenedor-campo">
              <label class="label" for="00ND600000Sn0UP">Subtipo:</label>
              <select id="00ND600000Sn0UP" name="00ND600000Sn0UP" title="Subtipo">
                <option value="">--Ninguno--</option>
                <option value="RequestProductReviewJewelry">Solicitud revisión de producto joyas</option>
                <option value="RequestProductReviewWatches">Solicitud revisión de producto relojes</option>
                <option value="RequestTechnicalServiceReview">Solicitud revisión de servicio técnico joyas</option>
                <option value="InvoiceRequest">Solicitud de factura</option>
                <option value="MoneyRefundRequest">Solicitud de devolución de dinero</option>
                <option value="PreciousStonesCertificateRequest" selected>Solicitud de certificado de piedras preciosas
                </option>
                <option value="Congratulations">Felicitaciones</option>
                <option value="Discontent">Inconformidad</option>
                <option value="UnclaimedProductDeliveryInTechnicalService">Entrega de producto no reclamado en servicio
                  técnico
                </option>
                <option value="PointOfSaleInformation">Información puntos de venta</option>
                <option value="GoldPurchase">Compra de oro</option>
                <option value="CustomJewelry">Joyas personalizadas</option>
                <option value="WorkWithUs">Trabaja con nosotros</option>
                <option value="JewelryStoreLocation">Ubicación de una joyería</option>
                <option value="RequestTechnicalServiceReviewWatches">Solicitud revisión de servicio técnico relojes
                </option>
                <option value="WithholdingReturn">Devolución de Retención</option>
                <option value="Certificados Tributarios">Certificados Tributarios</option>
                <option value="Certificado de Autenticidad de Relojes">Certificado de Autenticidad de Relojes</option>
                <option value="Venta digital">Venta digital</option>
                <option value="Servicio">Servicio</option>
                <option value="DeliveryTracking">Seguimiento de entrega</option>
              </select>
            </div>
          </div>
          <div class="contenedor-campo">
            <label class="label" for="name">Nombre del contacto</label>
            <input class="custom-input" id="name" maxlength="80" name="name" size="20" type="text" placeholder="Escribe tu nombre completo" />
          </div>
          <div class="contenedor-campo">
            <label for="00ND600000Sn0TK" class="label">Tipo de documento:</label>
            <select id="00ND600000Sn0TK" name="00ND600000Sn0TK" title="Tipo de documento">
              <option value="">--Ninguno--</option>
              <option value="Cedula de ciudadania">Cédula de ciudadania</option>
              <option value="Cedula Extranjera">Cédula Extranjera</option>
              <option value="Pasaporte">Pasaporte</option>
              <option value="NIT">NIT</option>
            </select>
          </div>
          <div class="contenedor-campo">
            <label for="00ND600000Sn0Te" class="label">Documento del titular de la compra:</label>
            <input class="custom-input" id="00ND600000Sn0Te" maxlength="20" name="00ND600000Sn0Te" size="20"
              type="text" placeholder="Escribe tu documneto" />
          </div>
          <div class="contenedor-campo">
            <label class="label" for="email">Correo electrónico</label>
            <input class="custom-input" id="email" maxlength="80" name="email" size="20" type="text" placeholder="Escribe tu correo electrónico" />
          </div>
          <div class="contenedor-campo">
            <label for="00ND600000Sn0TL" class="label">Número de factura:</label>
            <input class="custom-input" id="00ND600000Sn0TL" maxlength="20" name="00ND600000Sn0TL" size="20"
              type="text" placeholder="Escribe tu numero de factura"/>
          </div>
        </div>

        <div class="columna-dos">
          <div class="contenedor-campo">
            <label for="00ND600000Sn0Su" class="label">Categoría del Producto:</label>
            <select id="00ND600000Sn0Su" multiple="multiple" name="00ND600000Sn0Su" title="Categoría del Producto">
              <option value="GIA">GIA</option>
              <option value="Diamante">Diamante</option>
              <option value="Esmeralda">Esmeralda</option>
              <option value="Rubi">Rubí</option>
              <option value="Zafiro">Zafiro</option>
            </select>
          </div>
          <div class="contenedor-campo">
            <label class="label" for="description">Descripción</label>
            <textarea name="description" placeholder="Escribe una descripción..."></textarea>
          </div>
          <div class="contenedor-campo">
            <label for="00ND600000Sn0Tl" class="label">Requiere Autorización a Terceros:</label>
            <div class="contenedor-radio">
              <!-- <label>
                <input type="radio" value="Si" class="custom-radio"> Si
              </label> -->
              <label>
                <input type="radio" id="aux" name="aux" value="SI" (change)="onSelectChange($event)" class="custom-radio"> Si
              </label>
              <label>
                <input type="radio" id="aux" name="aux" value="NO" (change)="onSelectChange($event)" class="custom-radio" checked> No
              </label>
            </div>
            <select id="00ND600000Sn0Tl" name="00ND600000Sn0Tl" title="Requiere Autorización a Terceros" class="none" #selectElem>
              <option value="">--Ninguno--</option>
              <option value="SI">SI</option>
              <option value="NO">NO</option>
            </select>
          </div>
        </div>
      </div>
      @if (selectedValue == 'SI') {
      <mat-divider></mat-divider>
      <div class="columnas">
        <div class="columna-uno">
          <div class="contenedor-campo">
            <label for="00ND600000Sn0TT" class="label">Nombre Tercero:</label>
            <input class="custom-input" id="00ND600000Sn0TT" maxlength="100" name="00ND600000Sn0TT" size="20"
              type="text" />
          </div>
          <div class="contenedor-campo">
            <label for="00ND600000Sn0T3" class="label">Correo del Tercero:</label>
            <input class="custom-input" id="00ND600000Sn0T3" maxlength="80" name="00ND600000Sn0T3" size="20"
              type="text" />
          </div>
        </div>

        <div class="columna-dos">
          <div class="contenedor-campo">
            <label for="00ND600000Sn0T8" class="label">Documento Identidad Tercero:</label>
            <input class="custom-input" id="00ND600000Sn0T8" name="00ND600000Sn0T8" size="20" type="text" />
          </div>
          <div class="contenedor-campo">
            <label for="00ND600000Sn0TX" class="label">Número Teléfono Tercero:</label>
            <input class="custom-input" id="00ND600000Sn0TX" maxlength="15" name="00ND600000Sn0TX" size="20"
              type="text" />
          </div>
        </div>
      </div>
      }


      <div class="g-recaptcha" data-sitekey="6LfbIw0qAAAAAAHNPav5Lbjp1ryJlayoomAsFJBc"></div>
      <input type="submit" name="submit" class="button">

    </form>
  </div>
</div>
