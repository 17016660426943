import { Tallascantidad } from '../interfaces/cart';
import { InfoGia } from "./product";

export class items {
  codigo: string;
  descripcion: string;
  cantidad: number;
  talla: string | null;
  precioAutorizado: string;
  precioFull: string;
  imagen: string;
  tallascanti: number[];
  tallasmap: string[];
  codTalla: string;
  porcentaje: number;
  categoria: string;
  NomColorPiedra: string;
  NomClaridad: string;
  refProveedor: string;
  idsTallas: Array<InfoGia[]> = new Array<Array<InfoGia>>();
  referencesGia: Array<InfoGia> = new Array<InfoGia>();
  tallaGiaComplete: boolean;
  codTallas: Array<string> = new Array<string>();
  giA_PrecioMinimo: string;
  giaComplete: boolean;
  idFavorito: string;
  submited: boolean;
  idLinea: string;
  esGia: boolean;
  constructor() {
    this.tallaGiaComplete = false;
    this.giaComplete = false;
    this.submited = false;
  }
}

export class Cart {
  idUsario: string;
  idSesion: string;
  items: items[] = new Array<items>();
}

export class filtro {
  talla: string[] = new Array<string>();
  activo: boolean[] = new Array<boolean>();
}


// Nuevas clases de reestructuración

export class Item {
  idProducto: string;
  cantidadDisponible: number;
  cantidad: number;
  porcentaje: number;
  precioFull: number;
  precioAutorizado: number;
  imagen: string;
  categoria: string;
  tipoProducto: string;
  descripcion: string;
  caracteristicas: any;
  catalogo: string;
}
export class Anillo {
  TallasCantidad: Tallascantidad[];
  TallaSeleccionada: string;
  TallaIdSeleccionada: number;
  CantidadSeleccionada: number;
  CantidadDisponibleSeleccionada: number;
}

export class Gia {
  idProducto: string;
  ReferenciaProveedor: string;
  porcentaje: number;
  precioFull: number;
  precioAutorizado: number;
  imagen: string;
  categoria: string;
  tipoProducto: string;
  pesoCentral: number;
  claridad: string;
  color: string;
  talla: string;
  tallaId: number;
  descripcion: string;
  caracteristicas: any;
  catalogo: string;
}
