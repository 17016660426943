<div class="container-colecciones">
  <div class="d-none d-sm-none d-md-none d-lg-block d-flex-center pt-3 pr-5-rem">
    <span class="title-section">{{getName('title')}}</span>
  </div>

  <div class="d-none d-sm-none d-md-none d-lg-block justy-eve pl-pr-100 g-2 d-flex-important showpc">
    @for (cate of jsonImgCategories; track $index) {
    <div class="card-container d-flex-column" (click)="redirect(cate.link)">
      <div class="center">
        <img class="img-size" [src]="cate.img" alt="">
      </div>
      <div class="end">
        <span class="end-text">{{cate.marca}}</span>
      </div>
    </div>
    }
  </div>

  <div class="d-flex-column d-block d-sm-block d-md-block d-lg-none d-flex-row justy-eve g-2 phone-wrap showmob">
    <div class="d-flex-center pt-3 pr-5-rem">
      <span class="title-section">{{getName('title_mobile')}}</span>
    </div>
    @for (cate of jsonImgCategoriesMobile; track $index) {
    <div class="contendor-secciones" (click)="redirect(cate.link)">
      <div class="seccion" >
        <img [src]="cate.img_mobile + '?v=' + version" alt="" class="img-categoria">
      </div>
    </div>
    }
  </div>
</div>
