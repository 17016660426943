import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { Gia, InfoGia, items } from 'libreria-tienda';

@Component({
  selector: 'lib-referencias',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './referencias.component.html',
  styleUrl: './referencias.component.css'
})
export class ReferenciasComponent implements OnInit {


  @Input() tallaSelectd: string;
  @Input() submited: boolean;
  @Input() tallas: Gia[];
  @Input() codTallas: string[];

  @Output() ItemData = new EventEmitter<number>();

  item: items = new items();

  referenciaSelecionada: Gia;

  ngOnInit(): void {
    if (this.tallaSelectd) {
      // console.log(this.tallaSelectd);
      this.referenciaSelecionada = this.tallas.find(item => item.idProducto === this.tallaSelectd) ?? new Gia;
    }
  }

  talla(e: any) {
    // console.log(e);
    this.tallas.forEach((element, i) => {
      if (element.idProducto == e.value.idProducto) {
        this.ItemData.emit(i)
      }
    });

    // this.ItemData.emit(e.value.idProducto)
  }
}
