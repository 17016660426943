import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ConfigService, ExpressService, Item, ItemCar, JsonTextService, ManejadorEventosService, Product, SelectAderezo, ShoppingCartService, TextResponse, caracteristicas, favorite, infoVenecia, items } from 'libreria-tienda';
import { FavoritoCorazonComponent } from 'libreria-tienda/sfa/components/favorito-corazon/favorito-corazon.component';
import { EtiquetaDescuentoComponent } from 'libreria-tienda/shared/etiqueta-descuento/etiqueta-descuento.component';
import { TallasComponent } from 'libreria-tienda/shared/tallas/tallas.component';
import { ComplementoCarruselComponent } from '../detalles-producto-venezia/complemento-carrusel/complemento-carrusel.component';
import { ComplementoComponent } from '../detalles-producto-venezia/complemento/complemento.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DescripcionCaracteristicasComponent } from '../descripcion-caracteristicas/descripcion-caracteristicas.component';
import { NumeroPuntoPipe } from 'libreria-tienda';

@Component({
  selector: 'lib-detalles-producto-general',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatDividerModule, TallasComponent,
    FavoritoCorazonComponent, ReactiveFormsModule, MatFormFieldModule,
    EtiquetaDescuentoComponent, ComplementoComponent, ComplementoCarruselComponent,
    DescripcionCaracteristicasComponent, NumeroPuntoPipe],
  templateUrl: './detalles-producto-general.component.html',
  styleUrl: './detalles-producto-general.component.css'
})
export class DetallesProductoGeneralComponent implements OnInit {

  @Input() catalogo: string;
  @Input() item: Item;

  @Input() complementos: infoVenecia[];

  caracteristicas: caracteristicas[];
  caracteristicasItem: caracteristicas[] = [];

  selectSize: boolean = true;
  open: boolean = false;
  submited: boolean = false;
  unico: boolean = false;

  volver: string;

  jsonDetalle: TextResponse[]

  complementosSelecd: SelectAderezo[] = [];

  caracteristicasMostrarObj: any;


  caracteristicasFull: any;

  public manejadorEventos = inject(ManejadorEventosService);
  public jsonService = inject(JsonTextService);
  public configService = inject(ConfigService);
  public cartService = inject(ShoppingCartService);
  public expressService = inject(ExpressService);
  private route = inject(ActivatedRoute);
  public router = inject(Router);

  constructor() { }

  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLJSONDETALLE).subscribe(res => {
      if (res !== undefined) {
        // console.log(res);
        this.jsonDetalle = res;
        this.caracteristicasFull = this.getName("caracteristicas")
        // console.log(this.caracteristicasFull);
        // this.caracteristicasMostrar()
      }
    }).closed
    this.item.cantidad = 1;

    if (this.complementos) {
      this.complementosSelecd = Array(this.complementos.length);
    }

    this.route.queryParamMap.subscribe(params => {
      this.volver = (params.get('compra') ?? 'false');
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['catalogo']) {
      if (this.catalogo != undefined && this.catalogo !== '') {
        // console.log('Catalogo en ngOnChanges:', this.catalogo);
        this.caracteristicasMostrar()
      }
    }
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  onPanelClosed() {
    this.open = false
  }

  onPanelOpened() {
    this.open = true
  }

  mas() {
    if (this.item.cantidadDisponible >= (this.item.cantidad + 1)) {
      this.item.cantidad++
    }
    else {
      this.unico = true
    }
  }

  menos() {
    if ((this.item.cantidad - 1) > 0) {
      this.item.cantidad--
      this.unico = false
    }
  }

  agregarCarrito() {
    if (this.item.cantidad == this.item.cantidadDisponible) {
      this.unico = true
    } else {
      this.unico = false
      this.agregarComplentosCarrito();
      let aux: ItemCar = new ItemCar();
      aux.idProducto = this.item.idProducto;
      aux.tallaId = -1;
      aux.cantidad = this.item.cantidad;
      aux.imagen = this.item.imagen;
      aux.maxCantidad = this.item.cantidadDisponible;
      aux.descWeb = this.item.descripcion;
      aux.descTalla = '';
      this.cartService.addItemCart(aux);
    }
    if (this.volver == 'true') {
      this.router.navigate(['shopping-cart']);
    }
  }

  donwloadPolitics() {
    window.open(this.configService._config.URLPDFTERMINOS, '_blank');
  }

  handleKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  complemtoSele(i: number, e: any) {
    this.complementosSelecd[i] = e
  }


  agregarComplentosCarrito() {
    console.log(this.complementosSelecd.length);
    console.log(this.complementosSelecd);

    for (let index = 0; index < this.complementosSelecd.length; index++) {
      if (this.complementosSelecd[index].select) {
        this.cartService.addItemCart(this.complementosSelecd[index].item)
      }
    }
    this.manejadorEventos.lanzarEvento('goCart');
  }

  caracteristicasMostrar() {
    this.caracteristicasMostrarObj = [this.caracteristicasFull[this.item.catalogo], this.item.caracteristicas]
  }

  openChat() {
    const enlaceWhatsApp = this.configService._config.WHATSAPP;
    window.open(enlaceWhatsApp, '_blank');
  }


}
