import { Component, OnInit, inject } from '@angular/core';
import { LoadingService } from 'libreria-tienda';

@Component({
  selector: 'app-cuidados',
  standalone: true,
  imports: [],
  templateUrl: './cuidados.component.html',
  styleUrl: './cuidados.component.css'
})
export class CuidadosComponent implements OnInit {

  private load = inject(LoadingService);

  pdfs: string[] = ['https://qastorageorafa.blob.core.windows.net/iconos/Cuidados Joyas Oro 2024.pdf',
    'https://qastorageorafa.blob.core.windows.net/iconos/Cuidados Joyas Plata-Acerp 2024.pdf',
    'https://qastorageorafa.blob.core.windows.net/iconos/Folleto Cuidado Relojes.pdf']

  ngOnInit(): void {
    this.load.cargar(false)
  }

  descargarArchivos(i: number) {
    window.open(this.pdfs[i], '_blank');
  }

}
