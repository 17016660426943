<mat-form-field appearance="fill" class="w-11rem" [ngClass]="{'border-red': tallaSelectd == undefined && submited}">
  <mat-select (selectionChange)="talla($event)" placeholder="Elige la Referencia" required
    [(value)]="referenciaSelecionada">
    @for (talla of tallas; track $index) {
    <mat-option [value]="talla" class="sizetext">
      Color Piedra: {{talla.color}} - Claridad : {{talla.claridad}} - Precio : ${{talla.precioAutorizado | number}}
    </mat-option>
    }
  </mat-select>
</mat-form-field>
