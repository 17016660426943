<div class="dialog-container">
  <div class="dialog-title centerVH">
    <mat-icon class="pr-1rem" [class]="getIconClassByItemState()">
      {{ getIconByItemState() }}</mat-icon>
    <span class="pl-1rem" mat-dialog-title>{{ title }} </span>
  </div>
  @if (msg !== undefined) {
  <div mat-dialog-content>
    <p class="parrafo">{{ msg }}</p>
  </div>
  }
  @if (msgArray.length > 0) {
  <div mat-dialog-content>
    @for (item of msgArray; track $index) {
    <p class="parrafo">{{ item }}</p>
    }
  </div>
  }
  <div mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeOK()" cdkFocusInitial>
      Aceptar
    </button>
  </div>
</div>
