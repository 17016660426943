@if(user.correoElectronico){
  <div class="center d-flex-column send-code-user-container">
    <div class="w-100 flex-end">
      <span (click)="close()" class="close-b">x</span>
    </div>
    <span class="text pt-3">Para realizar esta acción debemos verificar tu identidad. Por favor
      selecciona la opción por a cual deseas recibir el código de verificación.</span>
    <div class="pt-3 d-flex-start d-flex-column w-100">
      <label class="checkbox-container">
        <input type="checkbox" (change)="onChangeCelular()" [(ngModel)]="checkedCell" [checked]="checkedCell">Celular: {{replace(1)}}
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-container">
        <input type="checkbox" (change)="onChangeEmail()" [(ngModel)]="checkedEmail" [checked]="checkedEmail">Email: {{replace(2)}}
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="pt-3 w-100">
      <button (click)="sendCode()" class="button-primary">Enviar codigo</button>
    </div>
  </div>
}
