import { Vacante } from "./vacante";

export class Aplicantes {
    nombres: string;
    apellidos: string;
    tipo_documento: string;
    identificacion: string;
    correo: string;
    celular: string;
    hoja_vida: string;
    vacante: Vacante = new Vacante()
}
