import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'lib-felicitaciones',
  standalone: true,
  imports: [MatDividerModule],
  templateUrl: './felicitaciones.component.html',
  styleUrl: './felicitaciones.component.css'
})
export class FelicitacionesComponent {

}
