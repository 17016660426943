import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject, signal, SimpleChanges, OnChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { ActivatedRoute, Router } from '@angular/router';
import { EstadosFiltrosService, filtroAplicado, FiltrosDisponibles, LoadingService, SioServicesService } from 'libreria-tienda';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { Subject, Subscription } from 'rxjs';
import { SliderRangePriceComponent } from './slider-range-price/slider-range-price.component';

@Component({
  selector: 'lib-side-bar-filtro',
  standalone: true,
  imports: [MatSliderModule, CommonModule, FormsModule, MatDividerModule, MatListModule, MatExpansionModule, MatIconModule, SliderRangePriceComponent],

  templateUrl: './side-bar-filtro.component.html',
  styleUrl: './side-bar-filtro.component.css'
})

export class SideBarFiltroComponent implements OnInit {


  @Input() set codigo(value: string) {
    this.load.cargar(true);
    this.sioservice.getFiltrosCodigo(value).then((aux) => {
      // console.log(aux.valor);
      this.typesOfShoes = aux.valor
      for (let i = 0; i < this.typesOfShoes.length; i++) {
        for (let j = 0; j < this.typesOfShoes[i].valor.length; j++) {
          this.typesOfShoes[i].valor[j]["select"] = false
          this.typesOfShoes[i].valor[j]["disable"] = false
        }
      }
      this.inicializarFiltros()
      this.selectedShoes = new Array(this.typesOfShoes.length).fill([]);
      this.load.cargar(false)
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    });
  };

  typesOfShoes: any[] = [];
  selectedShoes: any[][] = [];

  idsFiltros: number[] = [];

  filtrosLeidosUrl: any[] = [];

  disabled = false;
  max = 0;
  min = 0;
  showTicks = false;
  step = 1000;
  thumbLabel = false;
  precioMinSeleccionado: number = 0;
  precioMaxSeleccionado: number = 100;

  precioMinSeleccionadoText: string = '0';
  precioMaxSeleccionadoText: string = '100';

  precioUrl: boolean = false;

  step3 = signal(0);

  filtrosAAplicar: filtroAplicado = new filtroAplicado();

  private subscriptionPreciosConsulta: Subscription;
  private subscriptionFiltrosDisponobles: Subscription;

  public sioservice = inject(SioServicesService)
  public estadosFiltrosService = inject(EstadosFiltrosService)
  public load = inject(LoadingService)
  private router = inject(Router)
  private route = inject(ActivatedRoute)

  constructor() {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['max'] || params['filtros']) {
        if (params['max']) {
          this.filtrosAAplicar.precioMax = parseInt(params['max'])
          this.filtrosAAplicar.precioMin = parseInt(params['min'])
          this.precioMaxSeleccionado = parseInt(params['max']);
          this.precioMaxSeleccionadoText = this.formatNumberWithCommas(this.precioMaxSeleccionado + '')
          this.precioMinSeleccionado = parseInt(params['min']);
          this.precioMinSeleccionadoText = this.formatNumberWithCommas(this.precioMinSeleccionado + '')
          this.precioUrl = true
        } else {
          this.filtrosAAplicar.precioMax = 0
          this.filtrosAAplicar.precioMin = 0
          this.precioMaxSeleccionado = 0
          this.precioMaxSeleccionadoText = this.formatNumberWithCommas(this.precioMaxSeleccionado + '')
          this.precioMinSeleccionado = 0
          this.precioMinSeleccionadoText = this.formatNumberWithCommas(this.precioMinSeleccionado + '')
        }
        if (params['filtros']) {
          const filtros = params['filtros'];
          this.procesarFiltros(filtros);
        } else if (params['max'] && params['filtros'] == undefined) {
          this.estadosFiltrosService.updateFiltrosAplicados(this.filtrosAAplicar)
        }
      }
    }).closed;
    this.subscriptionPreciosConsulta = this.estadosFiltrosService.preciosConsulta$.subscribe(value => {
      // console.log(value);
      if (value.precioMax !== 0) {
        if (this.precioMaxSeleccionado > value.precioMax) {
          this.filtrosAAplicar.precioMax = value.precioMax;
          this.filtrosAAplicar.precioMin = value.precioMin;
          this.precioMaxSeleccionado = value.precioMax;
          this.precioMaxSeleccionadoText = this.formatNumberWithCommas(this.precioMaxSeleccionado + '')
          this.precioMinSeleccionado = value.precioMin;
          this.precioMinSeleccionadoText = this.formatNumberWithCommas(this.precioMinSeleccionado + '')
        } else if (!this.precioUrl) {
          this.precioMaxSeleccionado = value.precioMax;
          this.precioMaxSeleccionadoText = this.formatNumberWithCommas(this.precioMaxSeleccionado + '')
          this.precioMinSeleccionado = value.precioMin;
          this.precioMinSeleccionadoText = this.formatNumberWithCommas(this.precioMinSeleccionado + '')
        }
        this.max = value.precioMax;
        this.min = value.precioMin;
        // console.log(value);
        // console.log(this.filtrosAAplicar);
      }
    });

    this.subscriptionFiltrosDisponobles = this.estadosFiltrosService.filtrosDisponibles$.subscribe(value => {
      this.desactivarFiltros(value);
    });


  }

  activarFiltros() {
    this.typesOfShoes.forEach(catalogos => {
      catalogos.valor.forEach((filtro: any) => {
        filtro.disable = false;
      });
    });
  }

  desactivarFiltros(disponibles: FiltrosDisponibles[]) {
    this.typesOfShoes.forEach(catalogos => {
      catalogos.valor.forEach((filtro: any) => {
        const disponible = disponibles.find(disponible => filtro.idFiltroAplicable === disponible.idFiltroAplicable);

        if (disponible) {
          // Si el filtro está en disponibles, asigna el valor de cantidad
          filtro.disable = false;
          filtro.cantidad = disponible.cantidadArticulos;
        } else {
          // Si el filtro no está en disponibles, lo desactiva y elimina la cantidad
          filtro.disable = true;
          filtro.cantidad = 0; // O puedes eliminar la propiedad si prefieres no tenerla cuando esté desactivado
        }
      });
    });

  }


  filtrosAplicadosArray() {
    for (let i = 0; i < this.typesOfShoes.length; i++) {
      for (let j = 0; j < this.typesOfShoes[i].valor.length; j++) {
        if (this.typesOfShoes[i].valor[j].select) {
          if (!this.selectedShoes[i]) {
            this.selectedShoes[i] = [];
          }
          let aux = [...this.selectedShoes[i]];
          const aux2 = {
            idFiltroAplicable: this.typesOfShoes[i].valor[j].idFiltroAplicable,
            nombreWeb: this.typesOfShoes[i].valor[j].nombreWeb
          };
          aux[aux.length] = aux2
          this.selectedShoes[i] = aux;
        }
      }
    }
    this.AplicarFiltros();
  }

  inicializarFiltros() {
    this.load.cargar(true)
    // console.log(this.filtrosLeidosUrl);

    for (let i = 0; i < this.filtrosLeidosUrl.length; i++) {
      const indice = this.typesOfShoes.findIndex(objeto => objeto.grupoPropiedad.codigoFiltro === this.filtrosLeidosUrl[i].Propiedad);
      // console.log(indice);

      if (indice !== undefined) {
        for (let j = 0; j < this.filtrosLeidosUrl[i].Filtros.length; j++) {
          const indice2 = this.typesOfShoes[indice].valor.findIndex((objeto2: any) => objeto2.idFiltroAplicable === parseInt(this.filtrosLeidosUrl[i].Filtros[j].idFiltroAplicable));
          if (indice2 > -1) {
            this.typesOfShoes[indice].valor[indice2].select = true;
          }
        }
      }
    }
    this.load.cargar(false)
  }

  AplicarFiltros() {
    let filtroback: any[] = [];
    this.selectedShoes.forEach((element, i) => {
      if (element.length > 0) {
        filtroback.push({
          Propiedad: this.typesOfShoes[i].grupoPropiedad.codigoFiltro,
          Filtros: element
        });
      }
    });
    // console.log(filtroback);
    this.selectedShoes = new Array(this.typesOfShoes.length).fill([]);
    if (filtroback.length == 0) {
      // Realiza la petición original
      this.eliminarfiltros()
      this.estadosFiltrosService.updateIdsFiltrosAplicados([])
    } else {
      this.urlFiltros(filtroback);
    }
  }

  urlFiltros(filtros: any[]) {
    // console.log(filtros);
    let url = ''
    filtros.forEach((element, i) => {
      url += element.Propiedad + '¡'
      for (let index = 0; index < element.Filtros.length; index++) {
        url += element.Filtros[index].nombreWeb + '-' + element.Filtros[index].idFiltroAplicable
        if (index != element.Filtros.length - 1 && element.Filtros.length > 1) {
          url += '*'
        }
      }
      if (filtros.length > 1 && i < filtros.length - 1) {
        url += '!'
      }
    });

    // console.log(url);
    if (url !== '') {
      this.addQueryParams(url)
    }
  }

  addQueryParams(filtros: string) {
    const queryParams = { ...this.route.snapshot.queryParams, filtros };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });

  }

  addPreciosUrl(max: number, min: number) {
    const queryParams = { ...this.route.snapshot.queryParams, max, min };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  procesarFiltros(filtros: string) {
    // console.log(filtros);
    let propiedades: string[] = [];
    let filtros2: any[] = [];

    const grupos: string[] = filtros.split('!');
    for (let i = 0; i < grupos.length; i++) {
      let aux1: string[] = grupos[i].split('¡');
      if (aux1.length == 2) {
        propiedades.push(aux1[0])
        let aux2: string[] = aux1[1].split('*');
        let aux4: any[] = []
        for (let j = 0; j < aux2.length; j++) {
          let aux3: string[] = aux2[j].split('-');
          aux4.push(
            {
              idFiltroAplicable: aux3[1],
              nombreWeb: aux3[0]
            }
          )
        }
        filtros2.push(aux4)
      }
    }
    let jsonFiltros: any[] = [];
    for (let i = 0; i < propiedades.length; i++) {
      let aux1 = {
        Propiedad: propiedades[i],
        Filtros: filtros2[i]
      }
      jsonFiltros.push(aux1)
    }
    // console.log(jsonFiltros);
    this.filtrosLeidosUrl = jsonFiltros;
    this.getIdsFiltros(jsonFiltros);
  }

  sueltaRangoCambio(e: any) {
    this.precioMinSeleccionado = e.min;
    this.precioMinSeleccionadoText = this.formatNumberWithCommas(this.precioMinSeleccionado + '')
    this.precioMaxSeleccionado = e.max;
    this.precioMaxSeleccionadoText = this.formatNumberWithCommas(this.precioMaxSeleccionado + '')
    this.addPreciosUrl(this.precioMaxSeleccionado, this.precioMinSeleccionado)
  }

  cambiandoRangoMin(event: any) {
    this.precioMinSeleccionado = event;
    this.precioMinSeleccionadoText = this.formatNumberWithCommas(this.precioMinSeleccionado + '')
  }

  cambiandoRangoMax(event: any) {
    this.precioMaxSeleccionado = event;
    this.precioMaxSeleccionadoText = this.formatNumberWithCommas(this.precioMaxSeleccionado + '')
  }

  eliminarfiltros() {
    for (let i = 0; i < this.typesOfShoes.length; i++) {
      for (let j = 0; j < this.typesOfShoes[i].valor.length; j++) {
        this.typesOfShoes[i].valor[j].select = false
      }
    }
    this.removeFiltrosParam()
    this.filtrosAAplicar.filtros = [];
    this.estadosFiltrosService.updateFiltrosAplicados(this.filtrosAAplicar)
  }

  removeFiltrosParam() {
    const queryParams = { ...this.route.snapshot.queryParams };
    delete queryParams['filtros'];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams
    });
  }

  removePreciosUrl() {
    const queryParams = { ...this.route.snapshot.queryParams };
    delete queryParams['max'];
    delete queryParams['min'];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams
    });
  }

  getIdsFiltros(filtros: any[]) {
    this.idsFiltros = [];
    for (let i = 0; i < filtros.length; i++) {
      // console.log(filtros[i]);
      for (let j = 0; j < filtros[i].Filtros.length; j++) {
        this.idsFiltros.push(parseInt(filtros[i].Filtros[j].idFiltroAplicable + ""))
      }
    }
    // console.log(this.idsFiltros);
    this.filtrosAAplicar.filtros = this.idsFiltros
    // console.log(this.filtrosAAplicar);

    this.estadosFiltrosService.updateFiltrosAplicados(this.filtrosAAplicar)
    this.estadosFiltrosService.updateIdsFiltrosAplicados(this.idsFiltros)
  }

  setStep(index: number) {
    this.step3.set(index);
  }

  nextStep() {
    this.step3.update(i => i + 1);
  }

  prevStep() {
    this.step3.update(i => i - 1);
  }

  onCheckboxChange() {
    this.filtrosAplicadosArray()
  }

  formatNumberWithCommas(numberString: string): string {
    // Convierte el string a un número
    const number = parseFloat(numberString);

    // Verifica si la conversión es correcta y el número no es NaN
    if (isNaN(number)) {
      throw new Error('El valor proporcionado no es un número válido');
    }

    // Usa el método toLocaleString para formatear el número
    return number.toLocaleString('es-ES');
  }



}
