<div class="d-none d-sm-none d-md-none d-lg-block">
  <div class="separacion">
    <img src="https://qastorageorafa.blob.core.windows.net/generales/Linea-dorada-footer.png" alt=""
      class="linea-dorada">
  </div>
  <div class="footer-container  pb-5">
    <div class="container-foo">
      <div class="dis-f">
        <div class="social-media d-flex-column flex-start">
          <a class="mb-10 cursor-pointer" (click)="redirectToHome()">
            <img class="logo-size" [src]="footerJs['logo-marca']" alt="Logo de la empresa Kevin's">
          </a>
          <span class="siguenos" [innerHTML]="footerJs['follow']"></span>
          <!-- Redes sociales -->
          <div class="social">
            <a [href]="footerJs['url-instagram']" target="_blank" class="cursor-pointer text-deco">
              <span class="icon-size color-white">
                <i class="fa-brands fa-instagram color-social color-instagram"></i>
              </span>
            </a>
            <a [href]="footerJs['url-facebook']" target="_blank" class="cursor-pointer text-deco">
              <span class="icon-size color-white">
                <i class="fa-brands fa-facebook-f color-social color-facebook"></i>
              </span>
            </a>
            <a [href]="footerJs['url-tiktok']" target="_blank" class="cursor-pointer text-deco">
              <span class="icon-size color-white">
                <i class="fa-brands fa-tiktok color-social color-tiktok"></i>
              </span>
            </a>
            <a [href]="footerJs['url-pinterest']" target="_blank" class="cursor-pointer text-deco">
              <span class="icon-size color-white">
                <i class="fa-brands fa-pinterest-p color-social color-pinterest"></i>
              </span>
            </a>
            <a [href]="footerJs['url-youtube']" target="_blank" class="cursor-pointer text-deco">
              <span class="icon-size color-white">
                <i class="fa-brands fa-youtube color-social color-youtube"></i>
              </span>
            </a>
          </div>
          <a>
            <span class="client-services siguenos" [innerHTML]="footerJs['copy']"></span>
          </a>
        </div>
        <div class="about-us gap-8px pl-2rem">
          <span class="title" [innerHTML]="footerJs['service']"></span>
          <!-- <span class="pt-5px textos" [innerHTML]="footerJs['nacional']"></span> -->
          <span class="pt-5px textos" [innerHTML]="footerJs['bog']"></span>
          <span class="pt-5px textos cursor-pointer" (click)="naviagte('cuidados')"
            [innerHTML]="footerJs['cuidados']"></span>
          <!-- <span class="pt-5px textos cursor-pointer" [innerHTML]="footerJs['agenda']"
            (click)="naviagte('compra-oro')"></span> -->
          <span class="pt-5px textos cursor-pointer" (click)="naviagte('preguntas-frecuentes')"
            [innerHTML]="footerJs['preguntas']"></span>
          <!-- <span class="pt-5px textos cursor-pointer" (click)="naviagte('metodos-entrega')"
            [innerHTML]="footerJs['envios']"></span> -->
          <!-- <span class="pt-5px textos cursor-pointer" (click)="naviagte('garantias')" [innerHTML]="footerJs['devolucion']"></span> -->
          <a [href]="footerJs['link-industria']" class="industria">
            <span class="pt-5px textos cursor-pointer" [innerHTML]="footerJs['industria']"></span>
          </a>

          <span class="pt-5px textos cursor-pointer" [innerHTML]="footerJs['terminos']"
            (click)="naviagte('terminos-condiciones')"></span>
          <span class="pt-5px textos cursor-pointer" (click)="naviagte('tratamiento-datos')"
            [innerHTML]="footerJs['tratamientos']"></span>
        </div>
        <div class="about-us gap-8px pl-2rem">
          <span class="title" [innerHTML]="footerJs['us']"></span>
          <span class="pt-5px textos cursor-pointer" [innerHTML]="footerJs['historia']"></span>
          <span class="pt-5px textos cursor-pointer" [innerHTML]="footerJs['sostenibilidad']"></span>
          <span class="pt-5px textos cursor-pointer" [innerHTML]="footerJs['aprende']"></span>
          <span class="pt-5px textos cursor-pointer" (click)="naviagte('joyerias')"
            [innerHTML]="footerJs['joyeria']"></span>
          <span class="pt-5px textos cursor-pointer" [innerHTML]="footerJs['trabaja']" (click)="goPandape()"></span>
        </div>
        <div class="about-us gap-8px pl-2rem">
          <span class="title" [innerHTML]="footerJs['compras']"></span>
          <a [href]="footerJs['url-wa']" target="_blank" class="cursor-pointer text-deco size-texto-wa">
            <!-- <span class="icon-Instagram icon-size color-black"></span> -->
            <span class="icon-size color-white">
              <i class="fa-brands fa-whatsapp"></i>
              <span class="pt-5px text-wa cursor-pointer" [innerHTML]="footerJs['wa']"></span>
            </span>
          </a>
        </div>
        <div class="payment">
          <span class="title" [innerHTML]="footerJs['newsl']"></span>
          <span class="textos" [innerHTML]="footerJs['susc-new']"></span>
          <div class="d-f">
            <form [formGroup]="new_kevins" (ngSubmit)="saveNews()">
              <input placeholder="Nombre completo" class="webkit-fi name" type="text" formControlName="nombreFull">
              <div class="d-flex pt-5px">
                <input placeholder="Fecha de nacimiento" class="webkit-fi largo-uno" type="date"
                  formControlName="fechaNacimiento">
                <input placeholder="Estado civil" class="webkit-fi corto-uno ml-2rem" type="text"
                  formControlName="estadoCivil">
              </div>
              <div class="d-flex pt-5px">
                <input placeholder="Género" class="webkit-fi corto-dos" type="text" formControlName="genero">
                <input placeholder="Correo eléctronico" class="webkit-fi largo-dos ml-2rem" type="email"
                  formControlName="correo">
              </div>
              <div class="d-flex">
                <input type="checkbox" class="autorizacion-check">
                <span class="autorizacion textos" [innerHTML]="footerJs['autorizacion']"></span>
              </div>
              <button class="btn">{{footerJs['suscribe']}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile -->
<div class="d-block d-sm-block d-md-block d-lg-none bg-black">
  <div class="contenedor-suscrib">
    <button class="bg-black btn-sus" (click)="openNew()">SUSCRIBETE A NUESTRO BOLETÍN
      <i class="fa-solid fa-arrow-right"></i>
    </button>
  </div>
  <div class="contenedor-redes">
    <h3 class="siguenos-text"><strong>Siguenos</strong> En:</h3>
    <div class="social">
      <a [href]="footerJs['url-instagram']" target="_blank" class="cursor-pointer text-deco">
        <span class="icon-size color-white">
          <i class="fa-brands fa-instagram color-social color-instagram"></i>
        </span>
      </a>
      <a [href]="footerJs['url-facebook']" target="_blank" class="cursor-pointer text-deco">
        <span class="icon-size color-white">
          <i class="fa-brands fa-facebook-f color-social color-facebook"></i>
        </span>
      </a>
      <a [href]="footerJs['url-tiktok']" target="_blank" class="cursor-pointer text-deco">
        <span class="icon-size color-white">
          <i class="fa-brands fa-tiktok color-social color-tiktok"></i>
        </span>
      </a>
      <a [href]="footerJs['url-pinterest']" target="_blank" class="cursor-pointer text-deco">
        <span class="icon-size color-white">
          <i class="fa-brands fa-pinterest-p color-social color-pinterest"></i>
        </span>
      </a>
      <a [href]="footerJs['url-youtube']" target="_blank" class="cursor-pointer text-deco">
        <span class="icon-size color-white">
          <i class="fa-brands fa-youtube color-social color-youtube"></i>
        </span>
      </a>
    </div>
  </div>
  <div>
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle="true"
        class="border-expansion bg-black border-top-n">
        <mat-expansion-panel-header class="bg-black">
          <mat-panel-title>
            <span class="expansion-text color-white mayuscula">{{footerJs['service']}}</span>
          </mat-panel-title>
          <mat-panel-description class="justify-end flex-g">
            @if (panelOpenState) {
            <i class="fa-solid fa-chevron-up color-white"></i>
            }
            @else {
            <i class="fa-solid fa-chevron-down color-white"></i>
            }
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="d-flex-column">
          <span class="color-white title" [innerHTML]="footerJs['service']"></span>
          <span class="color-white pt-5px textos" [innerHTML]="footerJs['nacional']"></span>
          <span class="color-white pt-5px textos" [innerHTML]="footerJs['bog']"></span>
          <span class="color-white pt-5px textos cursor-pointer" (click)="naviagte('cuidados')"
            [innerHTML]="footerJs['cuidados']"></span>
          <span class="color-white pt-5px textos cursor-pointer" [innerHTML]="footerJs['agenda']"
            (click)="naviagte('compra-oro')"></span>
          <span class="color-white pt-5px textos cursor-pointer" (click)="naviagte('preguntas-frecuentes')"
            [innerHTML]="footerJs['preguntas']"></span>
          <span class="color-white pt-5px textos cursor-pointer" (click)="naviagte('metodos-entrega')"
            [innerHTML]="footerJs['envios']"></span>
          <a [href]="footerJs['link-industria']" class="color-white industria">
            <span class="color-white pt-5px textos cursor-pointer" [innerHTML]="footerJs['industria']"></span>
          </a>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false" hideToggle="true"
        class="border-expansion bg-black">
        <mat-expansion-panel-header class="bg-black">
          <mat-panel-title>

            <span class="expansion-text mayuscula">{{footerJs['compras']}}</span>
          </mat-panel-title>
          <mat-panel-description class="justify-end flex-g">
            @if (panelOpenState4) {
            <i class="fa-solid fa-chevron-up color-white"></i>
            }@else {
            <i class="fa-solid fa-chevron-down color-white"></i>
            }
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="d-flex-column">
          <a [href]="footerJs['url-wa']" target="_blank" class="cursor-pointer text-deco size-texto-wa">
            <!-- <span class="icon-Instagram icon-size color-black"></span> -->
            <span class="icon-size color-white">
              <i class="fa-brands fa-whatsapp"></i>
              <span class="pt-5px text-wa cursor-pointer" [innerHTML]="footerJs['wa']"></span>
            </span>
          </a>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false" hideToggle="true"
        class="border-expansion bg-black">
        <mat-expansion-panel-header class="bg-black">
          <mat-panel-title>
            <span class="expansion-text mayuscula">{{footerJs['us']}}</span>
          </mat-panel-title>
          <mat-panel-description class="justify-end flex-g">
            @if (panelOpenState3) {
            <i class="fa-solid fa-chevron-up color-white"></i>
            }@else {
            <i class="fa-solid fa-chevron-down color-white"></i>
            }
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="d-flex-column">
          <span class="pt-5px textos cursor-pointer color-white" [innerHTML]="footerJs['historia']"></span>
          <span class="pt-5px textos cursor-pointer color-white" [innerHTML]="footerJs['sostenibilidad']"></span>
          <span class="pt-5px textos cursor-pointer color-white" [innerHTML]="footerJs['aprende']"></span>
          <span class="pt-5px textos cursor-pointer color-white" (click)="naviagte('joyerias')"
            [innerHTML]="footerJs['joyeria']"></span>
        </div>
      </mat-expansion-panel>
      <mat-divider class="back-white"></mat-divider>
    </mat-accordion>
  </div>
  <div class="d-flex-center pt-5">
    <a class="mb-10 cursor-pointer" routerLink="/home">
      <img class="logo-size" [src]="footerJs['logo-marca']" alt="">
    </a>
  </div>
  <div class="contenedor-terminos-datos">
    <mat-divider class="back-white"></mat-divider>
    <div class="terminos-datos"></div>
    <span class="color-white pt-5px textos cursor-pointer" [innerHTML]="footerJs['terminos']"
      (click)="naviagte('terminos-condiciones')"></span>
    <span class="espacio">|</span>
    <span class="color-white pt-5px textos cursor-pointer" (click)="naviagte('tratamiento-datos')"
      [innerHTML]="footerJs['tratamientos']"></span>
  </div>
  <div class="derechos">
    <span class="color-white pt-5px textos">Kevin´s Joyeros© 2024</span>
    <span class="color-white pt-5px textos espacio2">|</span>
    <span class="color-white pt-5px textos">Todos los Derechos Reservados</span>
  </div>

</div>
