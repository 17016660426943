<div class="container">
  <div class="contenedor-img">
    <img [src]="img" alt="">
  </div>
  <div class="contendor-continuar">
    <div class="contenedor-textos">
      <span class="texto-uno">continuar con</span>
      <span class="texto-dos">la compra</span>
    </div>
    <div class="contenedor-botones">
      <button (click)="cerrarDialogo('login')">INGRESAR</button>
      <button (click)="cerrarDialogo('compra')">INVITADO</button>
    </div>
  </div>
</div>
