export class ItemCar {
  idProducto: string;
  tallaId: number;
  cantidad: number;
  imagen: string;
  maxCantidad: number;
  descTalla: string;
  descWeb: string;
}

export class ItemCompra {
  idProducto: string;
  descripcionWeb: string;
  tallaId: string;
  descripcionTalla: string;
  precioFull: number;
  precioAutorizado: number;
  cantidadSeleccionada: number;
  cantidadDisponibleTalla: number;
  cantidadDisponibleProducto: number;
  imagen: string;
  descuento: number;
}
