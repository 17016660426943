import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { ControlSesionService, ExpressService, Factura, LoadingService, User, userlogged, UserService } from 'libreria-tienda';

@Component({
  selector: 'lib-historial-compras',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './historial-compras.component.html',
  styleUrl: './historial-compras.component.css'
})
export class HistorialComprasComponent implements OnInit, AfterViewInit {

  facturas: Array<Factura> = new Array<Factura>();

  sesionService = inject(ControlSesionService)
  userService = inject(UserService)
  expressService = inject(ExpressService)
  load = inject(LoadingService)

  constructor() {
    this.load.cargar(true)
  }

  userLog: userlogged = new userlogged();

  ngOnInit(): void {
    this.userLog = this.userService.getUserLocal();
    this.consultarCompras(this.userLog.correoElectronico)
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  calcularTotal(i: number) {
    let total = 0
    this.facturas[i].products.forEach(item => {
      total += parseFloat(item.precioAutorizado)
    })
    return total
  }

  consultarCompras(correo: string) {
    if (!correo) {
      this.sesionService.cerrarSesion()
    } else {
      this.expressService.getShoppingRecord(correo).then(res => {
        this.facturas = res;
        this.facturas = this.facturas.reverse()
        this.load.cargar(false)
      }).catch(error => {
        if (error.status == 401 || error.status == 403) {
          this.sesionService.cerrarSesion();
        }
      })
    }
  }
}
