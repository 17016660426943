import { Component, inject } from '@angular/core';
import { ItemCar, LoadingService, ShoppingCartService, SioServicesService, items } from 'libreria-tienda';
import { MatIconModule } from '@angular/material/icon';

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem,
  CdkDrag,
  CdkDropList,
  CdkDragPreview
} from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { CardDijeVeneziaComponent } from 'libreria-tienda/shared/card-product/card-dije-venezia/card-dije-venezia.component';

@Component({
  selector: 'app-experiencia-venezia',
  standalone: true,
  imports: [CdkDropList, CdkDrag, MatIconModule, MatButtonModule, CdkDragPreview, CardDijeVeneziaComponent],
  templateUrl: './experiencia-venezia.component.html',
  styleUrl: './experiencia-venezia.component.css'
})
export class ExperienciaVeneziaComponent {

  pulsera: string = "https://qastorageorafa.blob.core.windows.net/imagenes-experiencia-venezia/1370001123_B.png"
  dijes: any[] = [
    "https://qastorageorafa.blob.core.windows.net/imagenes-experiencia-venezia/087702T028.png",
    "https://qastorageorafa.blob.core.windows.net/imagenes-experiencia-venezia/087702M028.png",
    "https://qastorageorafa.blob.core.windows.net/imagenes-experiencia-venezia/087702R026.png",
    "https://qastorageorafa.blob.core.windows.net/imagenes-experiencia-venezia/087702U028.png",
    "https://qastorageorafa.blob.core.windows.net/imagenes-experiencia-venezia/087702R028.png"
  ]
  dijes_pulsera: any[] = []
  basura: any[] = []
  centro_top = 200 // px
  centro_left = 225 // 270px
  radio = 200 // 270px
  // arco = 280

  dijes_consulta: any = []

  posiciones: { top: number, left: number, rotacion: number }[] = [];

  load = inject(LoadingService)

  sioService = inject(SioServicesService)
  public cartService = inject(ShoppingCartService)

  constructor() {
    this.load.cargar(false)
    // this.dijes_pulsera.sub
  }

  ngOnInit() {
    this.posicion_dijes()
    this.pulserasDijes()
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {

      if (event.container.id !== 'cdk-drop-list-0') {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      }
    } else {
      if (event.previousContainer.id === 'cdk-drop-list-1' || event.previousContainer.id === 'cdk-drop-list-2') {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        this.basura = []
      } else {
        if (this.dijes_pulsera.length < 15) {
          copyArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
          );
        }
      }
      this.posicion_dijes()
    }
  }

  posicion_dijes() {
    this.posiciones = []
    for (let i = 0; i < this.dijes_pulsera.length; i++) {
      this.posiciones.push(this.calcularCoordenadasPuntoEnArco(i));
    }
  }

  calcularCoordenadasPuntoEnArco(theta: number) {
    const grados = 90 + ((this.dijes_pulsera.length - 1) * 10) - (20 * theta);
    const radian = (grados * Math.PI) / 180;
    // console.log(grados);

    const x = this.centro_left + this.radio * Math.cos(radian);
    const y = this.centro_top + this.radio * Math.sin(radian);
    return { left: x, top: y, rotacion: grados + 90 };
  }

  evenPredicate() {
    return this.dijes_pulsera.length <= 15;
  }

  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }

  pulserasDijes() {
    this.sioService.getDijes().then((list) => {
      console.log(list.valor);
      this.dijes_consulta = list.valor
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    })
  }

  angulo: number = 45; // Ángulo dinámico

  transformar(): string {
    return `rotate(${this.angulo}deg) translateX(150px) rotate(-${this.angulo}deg)`;
  }

  agregarComplementosCarrito() {
    if (this.dijes_pulsera.length > 0) {
      for (let index = 0; index < this.dijes_pulsera.length; index++) {
        if (this.dijes_pulsera[index]) {
          let aux: ItemCar = new ItemCar();
          aux.idProducto = this.dijes_pulsera[index].idProducto
          aux.tallaId = -1;
          aux.cantidad = 1;
          aux.imagen = this.dijes_pulsera[index].image
          aux.maxCantidad = this.dijes_pulsera[index].cantidad
          aux.descTalla = ""
          aux.descWeb = this.dijes_pulsera[index].descripcionProducto
          this.cartService.addItemCart(aux)
        }
      }
    }

  }
}
