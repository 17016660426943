<div class="borde">
  <div class="contenedor">
    <img [src]="articulo.imagen" alt="" class="size-img">
    <div class="precio-contenedor">
      <span class="id-producto">{{articulo.idProducto}}</span>
      <mat-divider></mat-divider>
      <span class="precio-articulo">${{articulo.precioFull| number}}</span>
    </div>
  </div>
</div>
