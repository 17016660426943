import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  @Output() loading = new EventEmitter<boolean>();

  constructor() { }

  cargar(carga: boolean) {
    this.loading.emit(carga)
  }


}
