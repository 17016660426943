export interface GetCabeceras {
  data: Array<any>;
  filtro: Array<any>;
}

export interface itemsFiltro {
  nombreMostrar: string;
  condicionDB: string;
  seleccionado: boolean;
  cantidad: number;
}

export interface GetProductsByFilterNameCommand {
  NombreFiltro: string;
  PageNumber: number;
  PageSize: number;
  Listafiltropersonalizado?: GetExistencePerFilterCommand[];
}

export interface GetExistencePerFilterCommand {
  name: string;
  items: itemsFiltro[];
}

export interface filtroCadena {
  input: string;
  PageNumber: number;
  PageSize: number;
  PrecioMinimo: number;
  PrecioMaximo: number
}

export interface GetCabeceras {
  data: Array<any>;
  filtro: Array<any>;
}

export interface caracteristicas {
  web: string,
  db: string,
  valor: string
}



export class time {
  diasMinimo: number;
  diasMaximo: number;
  observaciones: string;
  mensaje: string
}
