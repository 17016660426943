import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ConfigService, JsonTextService, TextResponse } from 'libreria-tienda';

declare var propiedades: any | undefined; // ver script javascript en el index.html

@Component({
  selector: 'lib-about-us',
  standalone: true,
  imports: [CommonModule,  MatIconModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.css'
})
export class AboutUsComponent implements OnInit {

  version = new Date().getTime();

  propiedades: any

  jsonAbout: TextResponse[]
  jsonService = inject(JsonTextService)
  configService = inject(ConfigService)

  imagen_fondo_mobile: string = "";

  constructor(private router: Router) {
    this.propiedades = propiedades;
  }

  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLJSONABOUT).subscribe(res => {
      this.jsonAbout = res
    })
    this.imagen_fondo_mobile = propiedades['about-us']['imagen'];

  }
  getName(name: string) {
    return this.jsonAbout?.find(x => x.Nombre == name)?.Valor
  }

  navigate() {
    this.router.navigateByUrl(propiedades['about-us']['filtro'])
  }
}
