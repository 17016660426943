import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'lib-pop-up-suscribete',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule],
  templateUrl: './pop-up-suscribete.component.html',
  styleUrl: './pop-up-suscribete.component.css'
})
export class PopUpSuscribeteComponent implements OnInit {

  form: FormGroup;

  estados: string[] = ['Soltero', 'Casado', 'Unión libre']
  generos: string[] = ['Hombre', 'Mujer', 'Otros']

  constructor(private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      nombre: ['', Validators.required],
      fechaNacimiento: ['', Validators.required],
      estado: ['', Validators.required],
      genero: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      aceptarPoliticas: [false, Validators.requiredTrue] // Checkbox con validador `requiredTrue`
    });
  }

  onSubmit() {
    if (this.form.valid) {
      console.log(this.form.value);
    }
  }
}
