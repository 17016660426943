<div class="contenedor-paso">
  <div class="paso">
    <span class="texto-paso">PASO 2</span>
    <div class="cuadro-dorado dorado-dos"></div>
  </div>
  <span class="texto-paso-dos"><strong>Elige y organiza</strong> los dijes de tu preferencia arrastrándolos <br> con el mouse hacia la pulsera</span>
</div>
<div class="contenedor1">
  <div class="columnasTres">
    <div class="columnaUno">
      Acá van los filtros
    </div>
    <div class="columnaDos">
      <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="dijes_consulta"
        [cdkDropListConnectedTo]="[doneList,doneList2]" [cdkDropListEnterPredicate]="noReturnPredicate"
        class="lista-dijes1" (cdkDropListDropped)="drop($event)">
        @for (dije of dijes_consulta; track $index) {
        <div class="contenedor-dije vibrar" cdkDrag>
          <lib-card-dije-venezia [articulo]="dije" />
          <img *cdkDragPreview [src]="dije.imagen" alt="" class="dijes-size-22">
        </div>
        }
      </div>
    </div>
    <div class="columnaTres">
      <div cdkDropList #doneList2="cdkDropList" [cdkDropListData]="dijes_pulsera"
        [cdkDropListConnectedTo]="[todoList, basuraList]" class="contenedor-pulsera-dijes"
        (cdkDropListDropped)="drop($event)">
      </div>
      <div class="pulsera1">
        @for (dije of dijes_pulsera; track $index) {
        <div class="contenedor-dije-pulsera dijes-animation" [style.top.px]="posiciones[$index].top"
          [style.left.px]="posiciones[$index].left"
          [style.transform]="' rotate(' + posiciones[$index].rotacion + 'deg)'">
          <img [src]="dije.imagen" alt="" class="dijes-size-3">
        </div>
        }
      </div>
      <div cdkDropList cdkDropListOrientation="horizontal" #doneList="cdkDropList" [cdkDropListData]="dijes_pulsera"
        [cdkDropListConnectedTo]="[todoList, basuraList]" class="dijes-pulsera-abierta"
        (cdkDropListDropped)="drop($event)">
        @for (dije of dijes_pulsera; track $index) {
        <div class="contenedor-dije vibrar" cdkDrag>
          <img [src]="dije.imagen" alt="" class="dijes-size-22">
        </div>
        }
      </div>
      <div class="TresColumnas">
        <div cdkDropList #basuraList="cdkDropList" [cdkDropListData]="basura" [cdkDropListConnectedTo]="[doneList]"
          class="contenedor-basura" (cdkDropListDropped)="drop($event)">
          <i class="fa-solid fa-trash size-basura"></i>
        </div>
        <div class="contenedor-add">
          <button mat-flat-button class="reinicio">
            Empezar de nuevo
          </button>
        </div>
        <div class="contenedor-add">
          <button mat-flat-button class="add" (click)="agregarComplementosCarrito()">
            <i class="fa-solid fa-cart-shopping pr-2"></i>
            Comprar ahora
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
