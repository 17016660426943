import { Component, OnInit, inject } from '@angular/core';
import { ConfigService, LoadingService } from 'libreria-tienda';
@Component({
  selector: 'lib-contact-us',
  standalone: true,
  imports: [],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.css'
})
export class ContactUsLibComponent implements OnInit {

  configService = inject(ConfigService)
  load = inject(LoadingService)

  email: string = this.configService._config.EMAILWEB;
  whatsApp: string = this.configService._config.WHATSAPP;

  ngOnInit(): void {
    this.load.cargar(false)
  }

  openChat() {
    // if (typeof screen !== 'undefined') {
    window.open(this.whatsApp, '_blank');
    //   }
    // }
    console.log('Chat');

  }

}
