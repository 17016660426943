@if (jsonDetalle.length>0) {
<div class="contenedor">
  <h1 class="titulo" [innerHTML]="getName('titulo')"></h1>
  <span class="texto-principal" [innerHTML]="getName('text_principal')"></span>
  @if (formulario == 'piedras') {
  <lib-piedras-preciosas />
  }@else if (formulario == 'dev-ret') {
  <lib-devolucion-retencion />
  }@else if (formulario == 'inconformidad') {
  <lib-inconformidad />
  }@else if (formulario == 'entrega-rep') {
  <lib-entrega-prod-no-reclamado />
  }@else if (formulario == 'felicitaciones') {
  <lib-felicitaciones />
  }@else if (formulario == 'factura') {
  <lib-factura />
  }
  @if (!formulario) {
  <div class="columnas">
    <div class="imagen"></div>
    <div class="contenedor-pqrs-principales">
      @if (!tienda) {
      @for (item of pqrs; track $index) {
      @if (visibleIndex == -1 || visibleIndex == $index) {
      <div class="contenedor-preguntas" (click)="viendoPregunta($index)">
        <span [innerHTML]="item.titulo"></span>
        @if (visibleIndex == $index) {
        <i class="fa-solid fa-chevron-left dorado size_icon"></i>
        }@else {
        <i class="fa-solid fa-chevron-right dorado size_icon"></i>
        }
      </div>
      }
      }
      @if (visibleIndex !== -1) {
      <div class="acordeon">
        @for (item of opciones; track $index) {
        <div class="contenedor-preguntas" (click)="viendoOpcion($index)">
          <span [innerHTML]="item.texto"></span>
          <i class="fa-solid fa-chevron-right dorado size_icon"></i>
        </div>
        }
      </div>
      }
      }@else {
      <div class="contenedor-preguntas" (click)="viendotienda()">
        <span>{{opcion}}</span>
        <i class="fa-solid fa-chevron-right dorado size_icon"></i>
      </div>
      <div class="contenedor-tienda">
        <span class="text-radicar">Para radicar solicitudes de revisión y servicio técnico de Joyas y Relojes, por favor
          acércate al punto de
          ventas de tu preferencia con el producto</span>
        <span class="text-ubicacion">Puedes validar nuestros puntos de venta en el siguiente link</span>
        <div class="ubicacion">
          <a class="align-center" (click)="navigate('joyerias')">
            <span class="icon-Localizacion size-wa black-color"></span>
            <span class="text-banner">Ubica una joyería</span>
          </a>
        </div>
      </div>
      }
    </div>
  </div>
  }
</div>
}
