import { Component, EventEmitter, Inject, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Aplicantes } from 'libreria-tienda/src/lib/class/aplicantes';
import { Vacante } from 'libreria-tienda/src/lib/class/vacante';
import { ConfigService, ExpressService, JsonTextService } from 'libreria-tienda';
import { SolicitudExitoVacanteComponent } from '../solicitud-exito-vacante/solicitud-exito-vacante.component';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'lib-trabaja-nosotros-form',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './trabaja-nosotros-form.component.html',
  styleUrl: './trabaja-nosotros-form.component.css'
})
export class TrabajaNosotrosFormComponent implements OnInit {
  vacante: Vacante = new Vacante()
  form: FormGroup;
  submited: boolean = false;
  aplicante: Aplicantes = new Aplicantes()
  formData = new FormData();
  msg: string = "Antes de subir tu archivo, verifica que esté en PDF y que su tamaño no exceda los 5 MB."
  selectedFiles: FileList | null = null;
  listDocuments: string[] = new Array<string>();
  errorFormato: boolean = false
  nombreHojaVida: string = ""
  @Output() closedEmitter = new EventEmitter<boolean>();
  @Input() set _vacante(vac: Vacante) {
    this.setVacante(vac)
  }
  @Input() ismob: boolean = false

  expressService = inject(ExpressService)
  configService = inject(ConfigService)

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    public dialogo: MatDialogRef<TrabajaNosotrosFormComponent>, public _dialogo: MatDialog) {
    this.form = this.fb.group({
      nombres: ['', Validators.required],
      identificacion: ['', Validators.required],
      apellidos: ['', Validators.required],
      celular: ['', Validators.required],
      correo: ['', Validators.required],
      hoja_vida: ['', Validators.required],
      tipoDocumento: ['', Validators.required],

    })
  }

  ngOnInit(): void {
    this.listDocuments = ["Cedula de ciudadania", "Cedula de extranjeria"]
    if (!this.ismob && this.data.vacante != undefined) {
      this.setVacante(this.data.vacante)
    }
  }
  setVacante(vac: Vacante) {
    this.vacante = vac
  }
  aplicar() {
    this.submited = true
    console.log('Hola');
    console.log(this.form.valid);

    if (this.form.valid) {
      this.aplicante.nombres = this.form.get('nombres')?.value
      this.aplicante.apellidos = this.form.get('apellidos')?.value
      this.aplicante.tipo_documento = this.form.get('tipoDocumento')?.value
      this.aplicante.identificacion = this.form.get('identificacion')?.value
      this.aplicante.celular = this.form.get('celular')?.value
      this.aplicante.correo = this.form.get('correo')?.value
      this.aplicante.vacante = this.vacante
      this.formData.append('file', this.selectedFiles![0], this.aplicante.identificacion + ".pdf");
      this.formData.append('carpeta', "");
      this.formData.append('mimeType', "application/pdf");
      this.formData.append('contenedor', "aplicantes");

      this.aplicante.hoja_vida = "https://qastorageorafa.blob.core.windows.net/aplicantes/" + this.aplicante.identificacion + ".pdf"
      this.expressService.insertAplicante(this.aplicante).subscribe({
        next: (apl) => {
          this.expressService.uploadFile(this.formData).subscribe(res => {
            this.formData = new FormData()
            if (res.message == "succes") {
              this.openDialogMessagesucces()
            }
          })
        }, error: (err) => {
          console.log(err)
        }
      })
    }
  }
  loadPdf(event: any) {
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/pdf\/*/) == null) {
      this.msg = "Nuestra plataforma solo admite archivos en formato PDF. Asegúrate de que tu archivo esté en ese formato y no exceda los 5 MB.";
      this.errorFormato = true
      return;
    }
    this.msg = "Antes de subir tu archivo, verifica que esté en PDF y que su tamaño no exceda los 5 MB."
    this.errorFormato = false
    this.nombreHojaVida = event.target.files[0].name
    const inputElement = event.target as HTMLInputElement;
    this.selectedFiles = inputElement.files;
  }
  document(event: any) {
    console.log(event)
  }
  openDialogMessagesucces(): void {
    const dialogRef = this._dialogo.open(SolicitudExitoVacanteComponent, { data: {}, panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res2 => {
      if (!this.ismob)
        this.close()
      else
        this.closedEmitter.emit(true)
    })
  }
  close() {
    this.dialogo.close()
  }

}
