<div class="d-none d-sm-none d-md-none d-lg-block">
  <h2 class="titles pt-3 pb-3 margin-0">{{getName('titleEnvio')}}</h2>
  <div class="d-flex-column col-12">
    @if (isDireccion) {
    <div class="d-flex-row d-flex-start pl-6  shadowh h-90 space-bet">
      <div class="d-flex-column start">
        <div class=" d-flex-center pb-2">
          <span class="titles">{{direccion.descripcion}}</span>
        </div>
        <div class="d-flex-center">
          <span class="titles">{{direccion.direccion}}</span>
        </div>
      </div>
      <div class="d-flex-center d-flex-column start">
        <div>
          <span class="info">{{direccion.nombres}} </span>
          <span class="info">{{direccion.apellidos}}</span>
        </div>
        <div>
          <span class="info">Cel: {{direccion.celular}}</span>
        </div>
      </div>
      <div class="d-flex-center">
        <span class="info">{{direccion.ciudad.municipioNombre}}
          {{direccion.departamento.departamentoNombre}}</span>
      </div>
      <div class="d-flex-center pr-10">
        <span (click)="editAddres()" class="icon-Editar font-size-22 cursor pr-1"></span>
        <span (click)="deleteAddres()" class="icon-Eliminar font-size-22 cursor"></span>
      </div>
    </div>
    }@else {
    <div>
      <h2 class="titles">{{getName('no-dire')}}</h2>
    </div>
    }
    <div class="d-flex-space-bet pt-3 pb-3 pl-35px">
      <!-- Boton de cambiar dirección o agregar -->
      <div class="w-30">
        <button *ngIf="direccion.nombres != undefined" (click)="routing()"
          class="titles button-secondary pl-0">{{getName('but-envio')}}</button>
        <button *ngIf="direccion.nombres == undefined" (click)="routing()"
          class="titles button-secondary pl-0">{{getName('but-adicionar')}}</button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <lib-receive-by [recibeYo]="getName('recibe-yo')" [recibeOtro]="getName('recibe-otro')"
      [quienRecibe]="getName('quien-recibe')" [guardar]="getName('but-guardar')" [section]="section"
      (sectionEmit)="sectionEmit($event)" (sendTypeEmit)="sendTypeEmit($event)" (recibeYoEmit)="recibeYo($event)"
      (recibeOtroEmit)="recibeOtro($event)" />
    <mat-divider></mat-divider>
    <div class="pt-3">
      <h2 class="titles">{{getName('metodo entrega')}}</h2>
      <div class="d-flex-row pl-35px pb-3">
        <div class="d-flex-row col-6 align-center">
          <label class="checkbox-container">
            <input type="checkbox" class="pr-1 envios" [(ngModel)]="isSendNormal" [disabled]="true"
              (change)="onChangeEntrega($event)">
            {{getName('envio-normal')}}
            <span class="checkmark"></span>
          </label>

          <mat-divider vertical style="height:22px; width: 12px;" class=""></mat-divider>
          <span class="titles">${{costoEnvio | number}}</span>
          <mat-divider vertical style="height:22px; width: 12px;" class="pr-1"></mat-divider>
          <span class="info pl-1 no-wrap">{{time.diasMaximo}} dias hábiles</span>
        </div>
      </div>

      <mat-divider></mat-divider>
      <div class="contenedor-mensaje">

        <label class="checkbox-container">
          <input type="checkbox" class="pr-1" [(ngModel)]="isMessage" (change)="mensaje()">
          <span class="checkmark center-check"></span>
        </label>
        <span class="titles pl-0 no-wrap break-spaces pt-6">{{getName('mensaje')}}</span>
        @if (isMessage) {
        <textarea [(ngModel)]="message" class="w-100 text-message pl-2rem" type="text" placeholder="Escribe tu mensaje"
          (change)="onTextChange($event)">
          </textarea>
        }

      </div>
    </div>
  </div>
</div>

<!-- Mobile -->

<!-- <div class="d-block d-sm-block d-md-block d-lg-none">
  <h2 class="titles pt-3 pb-3 margin-0">{{getName('titleEnvio')}}</h2>
  <div class="d-flex-column col-12">
    <div *ngIf="isDireccion">
      <mat-accordion>
        <mat-expansion-panel (opened)="onPanelOpened()" class="border-expansion" (closed)="onPanelClosed()"
          hideToggle="true">
          <mat-expansion-panel-header class="header-principal">
            <mat-panel-title class="start">
              <div class="d-flex-row">
                <label class="checkbox-container">
                  <input type="checkbox" class="d-flex" [disabled]="true" checked>
                  <span class="checkmark"></span>
                </label>
                <div class="d-flex-column no-wrap">
                  <span class="titles pl-0 pb-2">{{direccion.descripcion | uppercase}}</span>
                  <span class="titles pl-0">{{direccion.direccion}}</span>
                </div>
              </div>
            </mat-panel-title>
            <mat-panel-description class="justify-end flex-g margin-0">
              <mat-icon *ngIf="!open ">add</mat-icon>
              <mat-icon *ngIf="open">remove</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="d-flex-row">
            <div class="d-flex-column pl-6 no-wrap">
              <span class="text no-wrap">{{direccion.nombres}} {{direccion.apellidos}}</span>
              <span class="text">{{direccion.celular}}</span>
              <span
                class="text no-wrap">{{direccion.ciudad.municipioNombre}}{{direccion.departamento.departamentoNombre}}</span>
            </div>
            <div class="d-flex-row icon-location w-100 align-end justy-end">
              <span (click)="editAddres(0)" class="icon-Editar icon-color-size icon-size-22px"></span>
              <span (click)="deleteAddres(0)" class="icon-Eliminar pl-35px icon-color-size icon-size-22px "></span>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div *ngIf="!isDireccion">
      <h2 class="titles">{{getName('no-dire')}}</h2>
    </div>
    <div class="d-flex-space-bet pt-3 pb-3 pl-35px pr-15px">
      <div class="w-100">
        <button *ngIf="direccion.nombres != undefined" (click)="routing()"
          class="titles button-secondary pl-0">{{getName('but-envio')}}</button>
        <button *ngIf="direccion.nombres == undefined" (click)="routing()"
          class="titles button-secondary pl-0">{{getName('but-adicionar')}}</button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <lib-receive-by [user]="user" [recibeYo]="getName('recibe-yo')" [recibeOtro]="getName('recibe-otro')"
      [quienRecibe]="getName('quien-recibe')" [guardar]="getName('but-guardar')" [section]="section"
      (sectionEmit)="sectionEmit($event)" (sendTypeEmit)="sendTypeEmit($event)" (recibeYoEmit)="recibeYo($event)"
      (recibeOtroEmit)="recibeOtro($event)"></lib-receive-by>
    <mat-divider></mat-divider>
    <div class="pt-3">
      <h2 class="titles">{{getName('metodo entrega')}}</h2>
      <div class="d-flex-row pl-35px">
        <div class="d-flex-row col-6">
          <div class="d-flex">
            <label class="checkbox-container">
              <input type="checkbox" class="pr-1 envios" [(ngModel)]="isSendNormal" [disabled]="true"
                (change)="onChangeEntrega($event)">{{getName('envio-normal')}}<br><span class="info">{{time.diasMaximo}}
                dias hábiles</span>
              <span class="checkmark"></span>
            </label>
          </div>
          <mat-divider vertical style="height:22px; width: 12px;" class=""></mat-divider>
          <span class="titles">${{costoEnvio | number}}</span>
        </div>
      </div>
      <div *ngIf="direccion.departamento.departamentoNombre == 'BOGOTÁ'"
                      class="d-flex-row pt-3 pb-3 pl-35px">
                      <div class="d-flex-row col-6">
                          <mat-checkbox class="pr-1 envios" [(ngModel)]="isSendExpress"
                              (change)="onChangeEntrega2($event)">{{getName('envio-express')}}<br><span
                                  class="info no-wrap">En
                                  hasta 24 horas</span> </mat-checkbox>
                          <mat-divider vertical style="height:22px; width: 12px;" class=""></mat-divider>
                          <span class="titles">$65.000</span>
                      </div>
                  </div>
    </div>
    <mat-divider></mat-divider>
    <div>
      <lib-message [user]="user" [mensaje]="getName('mensaje')" (mensajeEmit)="mensaje($event)">
      </lib-message>
    </div>
  </div>

</div> -->
