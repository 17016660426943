import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { CardPulseraVeneziaComponent } from '../card-product/card-pulsera-venezia/card-pulsera-venezia.component';
import { JsonTextService, LoadingService, Product, PulseraVenezia, SioServicesService } from 'libreria-tienda';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-paso-dos-venezia',
  standalone: true,
  imports: [CardPulseraVeneziaComponent, CommonModule],
  templateUrl: './paso-dos-venezia.component.html',
  styleUrl: './paso-dos-venezia.component.css'
})
export class PasoDosVeneziaComponent implements OnInit, AfterViewInit {

  paso: number = 1;
  columnas: number;

  largos: string[] = []

  resPulseras: any[] = []

  constructor() { }

  pulseras: any[] = []

  grupos: { [largo: number]: PulseraVenezia[] } = {};
  medidas: any[];

  public sioservice = inject(SioServicesService)
  public load = inject(LoadingService)

  ngOnInit(): void {
    this.load.cargar(true)
    this.sioservice.getPulseras().then((res) => {
      console.log(res);
      this.resPulseras = res.valor
      this.medidas = [...new Set(res.valor.map((producto: any) => producto.largo))];
      console.log(this.medidas);

      // this.agruparObjetos(res.valor)
      this.pulseras = res.valor;
      this.load.cargar(false)
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  private agruparObjetos(pulseras: PulseraVenezia[]): void {
    pulseras.forEach(objeto => {
      const Largo = Number(objeto.largo);
      if (!this.grupos[Largo]) {
        this.grupos[Largo] = [];
      }
      this.grupos[Largo].push(objeto);
    });
    this.medidas = Object.keys(this.grupos);
  }

  pasoDos(i: string) {
    this.paso = 2;
    this.pulseras = [];
    var grupoSeleccionado = this.resPulseras.filter(((item: any) => item.largo == i));
    grupoSeleccionado.forEach(objeto => {
      var producto = new Product(objeto.idProducto, objeto.descripcionProducto, objeto.precioFull.toString(), objeto.precioAutorizado.toString(), objeto.imagen)
      producto.cantidad = objeto.cantidad.toString();
      producto.esGIA = false;
      this.pulseras.push(producto)
    });
    console.log(this.pulseras);

    this.columnas = this.pulseras.length >= 3 ? this.pulseras.length : 3;
  }
}
