import { Component } from '@angular/core';

@Component({
  selector: 'lib-carrito-vacio',
  standalone: true,
  imports: [],
  templateUrl: './carrito-vacio.component.html',
  styleUrl: './carrito-vacio.component.css'
})
export class CarritoVacioComponent {

}
