import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ExpressService, LoadingService, Store } from 'libreria-tienda';
import { BannersComponent } from 'libreria-tienda/shared/banners/banners.component';

@Component({
  selector: 'app-compra-oro',
  standalone: true,
  imports: [BannersComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './compra-oro.component.html',
  styleUrl: './compra-oro.component.css'
})
export class CompraOroComponent implements OnInit {

  listCities: string[] = [];
  stores: Store[] = [];
  storesCiudad: Store[] = [];

  precioOro: any[] = [
    { texto: "14K", precio: 116000 },
    { texto: "16K", precio: 140000 },
    { texto: "18K Nacional", precio: 149000 },
    { texto: "18K Ley 750", precio: 170000 },
    { texto: "22K", precio: 191000 },
    { texto: "24K", precio: 214000 }
  ]

  public load = inject(LoadingService)
  public expressService = inject(ExpressService)

  formularioCita: FormGroup;


  constructor(private fb: FormBuilder) {
    this.formularioCita = this.fb.group({
      nombre: [{ value: '', disabled: true }, [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z ]+$') // Solo letras y espacios
      ]],
      apellido: [{ value: '', disabled: true }, [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z ]+$') // Solo letras y espacios
      ]],
      celular: [{ value: '', disabled: true }, [
        Validators.required,
        Validators.pattern('^[0-9]{7,10}$') // Número de 7 a 10 dígitos
      ]],
      correo: [{ value: '', disabled: true }, [
        Validators.required,
        Validators.email // Valida que sea un correo electrónico válido
      ]],
      ciudad: ['', Validators.required], // Debe seleccionarse una opción válida
      tienda: ['', Validators.required], // Debe seleccionarse una opción válida
    });

  }

  ngOnInit(): void {
    this.load.cargar(false)

    this.expressService.getStores().subscribe(store => {
      // console.log(store);
      store.stores.forEach((sto: any) => {
        if (sto.slug != "ventas-corp") {
          this.stores.push(sto)
          if (sto.ciudad !== undefined) {
            this.listCities.push(sto.ciudad)
          }
        }
      })
      this.listCities = this.listCities.filter((item, index) => {
        return this.listCities.indexOf(item) === index;
      })
      // console.log(this.stores);
      // console.log(this.listCities);
    })

    this.formularioCita.get('ciudad')?.valueChanges.subscribe((tipo) => {
      this.storesCiudad = this.stores.filter(store => store.ciudad == this.formularioCita.get('ciudad')?.value)
      if (this.storesCiudad.length === 1) {
        this.formularioCita.get('tienda')?.setValue(this.storesCiudad[0].nombre);
      }
    })
    this.formularioCita.get('tienda')?.valueChanges.subscribe((tipo) => {
      if (tipo !== '') {
        this.formularioCita.get('nombre')?.enable();
        this.formularioCita.get('apellido')?.enable();
        this.formularioCita.get('celular')?.enable();
        this.formularioCita.get('correo')?.enable();
      }

    })

  }
}
