<div class="contenedor">
  <div class="titulo">
    <div class="raya"></div>
    <h2>Entrega producto no reclamado</h2>
  </div>
  <div class="contenedor-formulario">
    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
    <!--  If necessary, please modify the charset parameter to specify the        -->
    <!--  character set of your HTML page.                                        -->
    <!--  ----------------------------------------------------------------------  -->

    <META HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8">
    <script src="https://www.google.com/recaptcha/api.js"></script>
    <script>
      function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") { var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value); elems["ts"] = JSON.stringify(new Date().getTime()); document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);
    </script>

    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: Please add the following <FORM> element to your page.             -->
    <!--  ----------------------------------------------------------------------  -->

    <form
      action="https://site-site-5635--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DD6000000VW5y"
      method="POST">

      <input type=hidden name='captcha_settings'
        value='{"keyname":"Web_to_case_key_pair","fallback":"true","orgId":"00DD6000000VW5y","ts":""}'>
      <input type=hidden name="orgid" value="00DD6000000VW5y">
      <input type=hidden name="retURL" value="https://www.kevins.com.co/">

      <!--  ----------------------------------------------------------------------  -->
      <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
      <!--  these lines if you wish to test in debug mode.                          -->
      <!--  <input type="hidden" name="debug" value=1>                              -->
      <!--  <input type="hidden" name="debugEmail"                                  -->
      <!--  value="juan.naranjo@ceiba.com.co">                                      -->
      <!--  ----------------------------------------------------------------------  -->

      <div class="columnas">
        <div class="columna-uno">
          <div class="none">
            <label for="type">Tipo</label>
            <select id="type" name="type">
              <option value="">--Ninguno--</option>
              <option value="Request">Solicitudes</option>
              <option value="Customer service comments">Comentarios servicio al cliente</option>
              <option value="Product status" selected>Estado de producto</option>
            </select><br>

            Subtipo:<select id="00ND600000Sn0UP" name="00ND600000Sn0UP" title="Subtipo">
              <option value="">--Ninguno--</option>
              <option value="InvoiceRequest">Solicitud de factura</option>
              <option value="PreciousStonesCertificateRequest">Solicitud de certificado de piedras preciosas</option>
              <option value="Congratulations">Felicitaciones</option>
              <option value="Discontent">Inconformidad</option>
              <option value="UnclaimedProductDeliveryInTechnicalService" selected>Entrega de producto no reclamado en
                servicio técnico
              </option>
              <option value="WithholdingReturn">Devolución de Retención</option>
            </select><br>
          </div>
          <div class="contenedor-campo">
            <label for="00ND600000Sn0TK" class="label">Tipo de documento:</label>
            <select id="00ND600000Sn0TK" name="00ND600000Sn0TK" title="Tipo de documento">
              <option value="">--Ninguno--</option>
              <option value="Cedula de ciudadania">Cedula de ciudadania</option>
              <option value="Cedula Extranjera">Cedula Extranjera</option>
              <option value="Pasaporte">Pasaporte</option>
              <option value="NIT">NIT</option>
            </select>

            <div class="contenedor-campo top">
              <label for="00ND600000Sn0Te" class="label">Documento del titular de la compra:</label>
              <input id="00ND600000Sn0Te" maxlength="20" name="00ND600000Sn0Te" size="20" type="text"
                class="custom-input" />
            </div>
            <div class="contenedor-campo">

              <label for="email" class="label">Correo electrónico</label>
              <input id="email" maxlength="80" name="email" size="20" type="text" class="custom-input" />
            </div>
            <div class="contenedor-campo">
              <label for="00ND600000Sn0Tk" class="label">Descripción del producto en reparación:</label>
              <textarea id="00ND600000Sn0Tk" name="00ND600000Sn0Tk" rows="3" type="text" wrap="soft"></textarea>
            </div>
            <div class="contenedor-campo">
              <label for="" class="label">Punto de venta recepción producto:</label>
              <select id="00ND600000Sn0Td" name="00ND600000Sn0Td" title="Punto de venta recepción producto">
                <option value="">--Ninguno--</option>
                <option value="Andino">Andino</option>
                <option value="Av Chile">Av Chile</option>
                <option value="Bulevar">Bulevar</option>
                <option value="Cafam floresta">Cafam floresta</option>
                <option value="Unicentro">Unicentro</option>
                <option value="Galerias">Galerias</option>
                <option value="Hayuelos">Hayuelos</option>
                <option value="Hacienda">Hacienda</option>
                <option value="Palatino">Palatino</option>
                <option value="Salitre">Salitre</option>
                <option value="Santafe Bogota">Santafe Bogota</option>
                <option value="Titan Plaza">Titan Plaza</option>
                <option value="Nuestro Bogota">Nuestro Bogota</option>
                <option value="Gran Estacion">Gran Estacion</option>
                <option value="Metropolis">Metropolis</option>
                <option value="Santafe Medellin">Santafe Medellin</option>
                <option value="El tesoro">El tesoro</option>
                <option value="Cali Unicentro">Cali Unicentro</option>
                <option value="Viva Barranquilla">Viva Barranquilla</option>
                <option value="Cacique">Cacique</option>
                <option value="Plaza Bocagrande">Plaza Bocagrande</option>
                <option value="San Juan Plaza">San Juan Plaza</option>
                <option value="La Estacion Ibague">La Estacion Ibague</option>
                <option value="Primavera Urbana">Primavera Urbana</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="g-recaptcha" data-sitekey="6LfbIw0qAAAAAAHNPav5Lbjp1ryJlayoomAsFJBc"></div><br>
      <input type="submit" name="submit" class="button">

    </form>

  </div>
</div>
