import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-image-zoom',
  standalone: true,
  imports: [],
  templateUrl: './image-zoom.component.html',
  styleUrl: './image-zoom.component.css'
})
export class ImageZoomComponent {

  @Input() imageSrc: string = ''; // Fuente de la imagen
  @ViewChild('zoomImage') zoomImage!: ElementRef<HTMLImageElement>;

  // Función para aplicar el zoom en el lugar exacto del mouse
  zoomImagef(event: MouseEvent) {
    const img = this.zoomImage.nativeElement;
    const rect = img.getBoundingClientRect(); // Obtener tamaño y posición de la imagen

    // Coordenadas del cursor en relación con la imagen
    const x = ((event.clientX - rect.left) / rect.width) * 100; // en porcentaje
    const y = ((event.clientY - rect.top) / rect.height) * 100; // en porcentaje

    // Establece el punto de origen del zoom en la posición del mouse
    img.style.transformOrigin = `${x}% ${y}%`;
    img.classList.add('zoom'); // Agrega la clase de zoom para escalar la imagen
  }

  // Quitar el zoom cuando el cursor salga de la imagen
  resetZoom() {
    const img = this.zoomImage.nativeElement;
    img.classList.remove('zoom'); // Quitar el efecto de zoom
  }


}
