import { Component } from '@angular/core';

@Component({
  selector: 'lib-entrega-prod-no-reclamado',
  standalone: true,
  imports: [],
  templateUrl: './entrega-prod-no-reclamado.component.html',
  styleUrl: './entrega-prod-no-reclamado.component.css'
})
export class EntregaProdNoReclamadoComponent {

}
