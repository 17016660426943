import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'lib-miga-pan',
  standalone: true,
  imports: [MatDividerModule, CommonModule],
  templateUrl: './miga-pan.component.html',
  styleUrl: './miga-pan.component.css'
})
export class MigaPanComponent {

  @Input() migas: string[];
  @Input() indicador: string;

  rutaMiga3: string;

  constructor(private router: Router, private route: ActivatedRoute) { }

  routing(url: string) {
    this.router.navigate([`/${url}`]).then(() => {
      window.location.reload();
    });
  }


  pop() {
    this.migas.pop()
  }

  producto() {
    const queryParams = { ...this.route.snapshot.queryParams };

    // Sobrescribir el parámetro que deseas actualizar
    queryParams['producto'] = '';

    // Navegar a la misma ruta con los parámetros actualizados
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // 'merge' para mantener los otros parámetros intactos
    });
  }

}
