<div class="d-none d-sm-none d-md-none d-lg-block">
  @if (carrito) {
  @for (item of carrito; track $index) {
  <div class="d-flex-column pt-5">
    <div class="d-flex-row pb-5">
      <div class="pl-1rem">
        <img (click)="routing(item.idProducto)" class="img" src="{{item.imagen}}" alt="">
      </div>
      <div class="column-shop row-shop w-100 pl-1rem">
        <div class="d-flex-column">
          <span class="ref">Ref. {{item.idProducto}}</span>
          <span class="desc">{{item.descripcionWeb}}</span>
        </div>
        <div class="d-flex-row w-100 space-around pt-1rem h-web-kit">
          <div class="d-flex-column align-center">
            @if (item.descripcionTalla !== '-1') {
            <span class="titles pb-3">Talla</span>
            <div>
              <!-- <mat-form-field appearance="fill" *ngIf="item.talla != null" class="select">
                <mat-select [disabled]="item.categoria == 'GIA'" value="{{item.talla}}"
                  (selectionChange)="talla($event,$index)" required>
                  <ng-container *ngFor="let talla of filtroLista[$index].talla; let j = index">
                    <mat-option *ngIf="!filtroLista[$index].activo[j] || item.talla == talla" [value]="talla">
                      {{talla}}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field> -->
              {{item.descripcionTalla}}
            </div>
            }
          </div>
          <div class="d-flex-column align-center">
            <span class="titles pb-3">Cantidad</span>
            <div class="d-flex-row">
              <button class="button-addremove" (click)="menos($index)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number" id="cant" #cant value="{{ item.cantidadSeleccionada }}" min=1 placeholder="Cantidad"
                class="input-cant" (keydown)="handleKeydown($event)" readonly>
              <button class="button-addremove" (click)="mas($index)">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div class="d-flex-column align-center">
            <div class="d-flex-column h-100">
              <span class="titles pb-4">Precio</span>
              <span class="priceDescuento">${{getTotalSinDescuento($index) | numeroPunto}}</span>
              <span class="price">${{getTotal($index) | numeroPunto}}</span>
            </div>
            <div class="d-flex">
              @if (item.descripcionTalla !== '-1') {
              <span class="cursor-pointer" (click)="EditItem($index)">
                <i class="fa-solid fa-pen-to-square size-edit"></i>
              </span>
              }
              <span class="icon-Eliminar icon-size-color cursor-pointer" (click)="deleteItem($index)"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    @if (carrito.length-1 !== $index && carrito.length>0) {
    <mat-divider></mat-divider>
    }
  </div>
  }
  }
</div>

<!-- <div class="d-block d-sm-block d-md-block d-lg-none">
  @for (item of carrito; track $index) {
  <div class="d-flex-column pt-5 pl-22px">
    <div class="d-flex-row pl-4">
      <div>
        <img (click)="routing(item.idProducto)" class="img" src="{{item.imagen}}" alt="">
      </div>
      <div class="d-flex-column pl-1rem space-eve pl-4">
        <span class="ref">Ref. {{item.idProducto}}</span>
        <span class="desc">{{item.descripcionWeb}}</span>
      </div>
    </div>
    <div class="d-flex-row center-space pb-3 pt-3">
      <div>
        <mat-form-field appearance="fill" *ngIf="item.talla != null" class="select-top">
          <mat-select [disabled]="item.categoria == 'GIA'" value="{{item.talla}}"
            (selectionChange)="talla($event,$index)" required>
            <ng-container *ngFor="let talla of filtroLista[$index].talla; let j = index">
              <mat-option *ngIf="!filtroLista[$index].activo[j] || item.talla == talla" [value]="talla">
                {{talla}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        {{item.descripcionTalla}}
        <div class="div-size" *ngIf="item.descripcionTalla == null">
          <span>N/A</span>
        </div>
      </div>
      <div class="d-flex-column align-center">
        <div class="d-flex-row">
          <button [disabled]="item.categoria == 'GIA'" class="button-addremove" (click)="menos(item.cantidad,$index)">
            <mat-icon>remove</mat-icon>
          </button>
          <input [disabled]="item.categoria == 'GIA'" type="number" id="cant" #cant value="{{ item.cantidad }}" min=1
            name="" id="" placeholder="Cantidad" class="input-cant">
          <button [disabled]="item.categoria == 'GIA'" class="button-addremove" (click)="mas(item.cantidad,$index)">
            <mat-icon>add</mat-icon>
          </button>
          {{item.cantidadSeleccionada}}
        </div>
      </div>
      <span class="icon-Eliminar icon-size-color cursor-pointer" (click)="deleteItem($index)"></span>
    </div>
    <mat-divider *ngIf="(carrito.length-1) != 0 && $index != (carrito.length-1)"></mat-divider>
  </div>
  }
  <div class="d-flex-column center-total" *ngIf="carrito.length == 0">
    <span class="icon-shoppingCart icon-size pt-5 pb-3"></span>
    <span class="text-empty pb-5">Tu carrito esta vacio</span>
  </div>
</div> -->
