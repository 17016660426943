<!-- <lib-add-edit-address /> -->


<div class="back-img pt-5 pb-5 container-father">
  <div class="edit-container pt-3 pb-3 d-flex-column">
    <h2 class="title pt-3">{{'Dirección' | uppercase}}</h2>
    <form [formGroup]="form">
      <div class="d-flex-column gap-12">
        <label class="text-labels">Nombres</label>
        <input
          [ngClass]="{'input-error' : form.controls['nombres'].invalid && (form.controls['nombres'].dirty || form.controls['nombres'].touched) || form.controls['nombres'].value == '' && submited}"
          class="input-text" type="text" formControlName="nombres" placeholder="nombres" type="text">
        <label class="text-labels">Apellidos</label>
        <input
          [ngClass]="{'input-error' : form.controls['apellidos'].invalid && (form.controls['apellidos'].dirty || form.controls['apellidos'].touched) || form.controls['apellidos'].value == '' && submited}"
          class="input-text" type="text" formControlName="apellidos" placeholder="apellidos" type="text">
        <label class="text-labels">Celular</label>
        <input
          [ngClass]="{'input-error' : form.controls['celular'].invalid && (form.controls['celular'].dirty || form.controls['celular'].touched) || form.controls['celular'].value == '' && submited}"
          class="input-text" type="text" formControlName="celular" placeholder="celular" type="text">
        <label class="text-labels">Departamento</label>
        <mat-form-field
          [ngClass]="{'input-error' : form.controls['ciudad'].invalid && (form.controls['ciudad'].dirty || form.controls['ciudad'].touched) || form.controls['ciudad'].value == '' && submited}"
          class="w-location" appearance="fill">
          <mat-select [(value)]="depto2" placeholder="Seleccionar" formControlName="ciudad"
            (selectionChange)="deptoSelected($event)" required>
            <mat-option *ngFor="let depto of listDeptos" [value]="depto">
              {{depto.departamentoNombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <label class="text-labels">Municipio</label>
        <mat-form-field
          [ngClass]="{'input-error' : form.controls['municipio'].invalid && (form.controls['municipio'].dirty || form.controls['municipio'].touched) || form.controls['municipio'].value == '' && submited}"
          class="w-location" appearance="fill">
          <mat-select [(value)]="municipe2" placeholder="Seleccionar" formControlName="municipio"
            (selectionChange)="municipeSelected($event)" required>
            <mat-option *ngFor="let municipe of listMunicipes" [value]="municipe">
              {{municipe.municipioNombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <label class="text-labels">Dirección</label>
        <input
          [ngClass]="{'input-error' : form.controls['direccion'].invalid && (form.controls['direccion'].dirty || form.controls['direccion'].touched) || form.controls['direccion'].value == '' && submited}"
          class="input-text" type="text" formControlName="direccion" placeholder="direccion" type="text">
        <label class="text-labels">Información adicional</label>
        <input class="input-text" type="text" formControlName="informacionAdicional" placeholder="Informacion adicional"
          type="text">
      </div>
      <div class="pt-3">
        <button (click)="addEditAddress()" class="button-primary">Guardar</button>
      </div>
    </form>
    <button (click)="cancelar()" class="button-secondary">Cancelar</button>
  </div>
</div>
