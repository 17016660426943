import { Component, inject, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExpressService, LoadingService, User, userlogged, UserService } from 'libreria-tienda';
import { ConfirmcodeComponent } from '../confirmcode/confirmcode.component';
import { FormsModule } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-sendcode',
  standalone: true,
  imports: [MatPseudoCheckboxModule, FormsModule],
  templateUrl: './sendcode.component.html',
  styleUrl: './sendcode.component.css'
})
export class SendcodeComponent {

  checkedEmail: boolean = true;
  checkedCell: boolean = false;
  user: User = new User()
  userLog: userlogged = new userlogged()

  public load = inject(LoadingService)
  public userService = inject(UserService)
  public expressService = inject(ExpressService)
  public router = inject(Router)
  constructor(public dialogo: MatDialogRef<SendcodeComponent>, public _dialogo: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
    // this.user = this.data.user
  }

  ngOnInit(): void {
    this.userLog = this.userService.getUserLocal()
    // console.log(this.userLog);
    if (this.userLog.correoElectronico == "") {
      this.router.navigate(['/login']).then(() => {
        window.scrollTo(0, 0);
      });
    }
    this.expressService.getUser(this.userLog.correoElectronico).then((res) => {
      // console.log(res);
      this.user = res.user;
      // localStorage.setItem("userLogged", JSON.stringify(this.user.correoElectronico));
    }).catch((error) => {
      console.error(error);
      // Desloguear
      this.router.navigate(['/login']).then(() => {
        window.scrollTo(0, 0);
      });
    });
  }

  sendCode() {
    if (this.checkedCell) {
      this.expressService.setCode(this.user.correoElectronico).then((res) => {
        console.log(res);
        this.openDialogMessage("Celular", this.user.celular)
      }).catch((error) => {
        console.log(error);
      })
    }
    if (this.checkedEmail) {
      this.expressService.setCode(this.user.correoElectronico).then((res) => {
        this.openDialogMessage("Correo", this.user.correoElectronico)
      }).catch((error) => {
        console.log(error);
      })
    }
  }

  close() {
    this.dialogo.close(false)
  }

  openDialogMessage(medio: string, info: string): void {
    const dialogRef = this._dialogo.open(ConfirmcodeComponent, { data: { medio: medio, info: info }, panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res => {
      if (res)
        this.dialogo.close(true)
      else
        this.dialogo.close(false)
    })
  }

  replace(i: number) {
    if (i == 1)
      return this.user.celular.substring(0, 3) + '*'.repeat(5) + this.user.celular.substring(8, 10);
    else {
      return this.user.correoElectronico.substring(0, 2) + '*'.repeat(4) + this.user.correoElectronico.split('@')[1];
    }
  }

  onChangeCelular() {
    this.checkedCell = true
    this.checkedEmail = false
  }

  onChangeEmail() {
    this.checkedEmail = true
    this.checkedCell = false
  }

}
