export interface ciudades {
  idCiudad: string
  nombreCiudad: string
}
export class puntosKevins {
  idCcosto: string
  nombrePunto: string
  direccion: string
}
export class Deptos {
  idDepto: string
  departamentoNombre: string
  codPais: string
}
export interface Municipes{
  id: number;
  idDepto: string;
  idMunicipio: string;
  municipioNombre: string;
  codPais: string;
}
