import { Component, OnInit, inject } from '@angular/core';
import { ConfigService, JsonTextService, LoadingService, TextResponse } from 'libreria-tienda';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-politicas-tratamientos',
  standalone: true,
  imports: [MatExpansionModule, MatListModule],
  templateUrl: './politicas-tratamientos.component.html',
  styleUrl: './politicas-tratamientos.component.css'
})
export class PoliticasTratamientosComponent implements OnInit {

  panelOpenState = false;

  correo: string = "serviciopostventa@kevins.com.co";


  tratamiento_sometidos: any
  identificacion_db: any
  derechos: any
  procedimiento: any

  public load = inject(LoadingService)
  public jsonDetalle: TextResponse[] = []
  public jsonService = inject(JsonTextService);
  public configService = inject(ConfigService);

  ngOnInit(): void {
    this.load.cargar(true);
    this.jsonService.downloadJson(this.configService._config.URLJSONTRATAMIENTODATOS).subscribe(res => {
      if (res !== undefined) {
        this.jsonDetalle = res;
        this.load.cargar(false);
        this.tratamiento_sometidos = this.getName('tratamiento_sometidos');
        this.identificacion_db = this.getName('identificacion_db');
        this.derechos = this.getName('derechos');
        this.procedimiento = this.getName('procedimiento');
      }
    }).closed

  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }
}
