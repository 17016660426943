<div class="container-father pt-5 pb-5">
  <div class="login-container">
    <div class="pt-5">
      <span class="text-login">{{getName('title')}}</span>
    </div>
    <form [formGroup]="RegisterForm" (ngSubmit)="saveUser()" class="w-100">
      <div class="form-inputs-d-flex">
        <!-- Nombre -->
        <div class="d-flex-row"
          [ngClass]="{'place': RegisterForm.controls['nombre'].invalid && (RegisterForm.controls['nombre'].dirty || RegisterForm.controls['nombre'].touched)}">
          <div>
            <input
              [ngClass]="{'text-field-error' : RegisterForm.controls['nombre'].invalid && (RegisterForm.controls['nombre'].dirty || RegisterForm.controls['nombre'].touched), 'text-field' : !RegisterForm.controls['nombre'].invalid || RegisterForm.controls['nombre'].invalid }"
              type="text" formControlName="nombre" placeholder="Nombres">
            <mat-error class="pass"
              *ngIf="RegisterForm.controls['nombre'].invalid && (RegisterForm.controls['nombre'].dirty || RegisterForm.controls['nombre'].touched)">
              Ingresa tu nombre</mat-error>
          </div>
          <div>
            <mat-icon class="red"
              *ngIf="RegisterForm.controls['nombre'].invalid && (RegisterForm.controls['nombre'].dirty || RegisterForm.controls['nombre'].touched)">
              error_outline</mat-icon>
          </div>
        </div>
        <!-- Apellido -->
        <div class="d-flex-row"
          [ngClass]="{'place': RegisterForm.controls['apellido'].invalid && (RegisterForm.controls['apellido'].dirty || RegisterForm.controls['apellido'].touched)}">
          <div>
            <input
              [ngClass]="{'text-field-error' : RegisterForm.controls['apellido'].invalid && (RegisterForm.controls['apellido'].dirty || RegisterForm.controls['apellido'].touched), 'text-field' : !RegisterForm.controls['apellido'].invalid || RegisterForm.controls['apellido'].invalid }"
              type="text" formControlName="apellido" placeholder="Apellidos">
            <mat-error class="pass"
              *ngIf="RegisterForm.controls['apellido'].invalid && (RegisterForm.controls['apellido'].dirty || RegisterForm.controls['apellido'].touched)">
              Ingresa tu apellido</mat-error>
          </div>
          <div>
            <mat-icon class="red"
              *ngIf="RegisterForm.controls['apellido'].invalid && (RegisterForm.controls['apellido'].dirty || RegisterForm.controls['apellido'].touched)">
              error_outline</mat-icon>
          </div>
        </div>
        <!-- Tipo documento -->
        <div class="d-flex-row"
          [ngClass]="{'place': RegisterForm.controls['tipoDocumento'].invalid && (RegisterForm.controls['tipoDocumento'].dirty || RegisterForm.controls['tipoDocumento'].touched)}">
          <div>
            <mat-form-field appearance="fill"
              [ngClass]="{'text-field-error' : RegisterForm.controls['tipoDocumento'].invalid && (RegisterForm.controls['tipoDocumento'].dirty || RegisterForm.controls['tipoDocumento'].touched), 'text-field' : !RegisterForm.controls['tipoDocumento'].invalid || RegisterForm.controls['tipoDocumento'].invalid }"
              class="text-field select-input">
              <mat-select formControlName="tipoDocumento" placeholder="Tipo de Documento"
              >
              <!-- (selectionChange)="document($event)" -->
                @for (option of listDocuments; track $index) {
                <mat-option [value]="option">
                  {{ option.nombre | titlecase }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-error class="pass"
              *ngIf="RegisterForm.controls['tipoDocumento'].invalid && (RegisterForm.controls['tipoDocumento'].dirty || RegisterForm.controls['tipoDocumento'].touched)">
              Ingresa tu tipo de documento</mat-error>
          </div>
          <div>
            <mat-icon class="red"
              *ngIf="RegisterForm.controls['tipoDocumento'].invalid && (RegisterForm.controls['tipoDocumento'].dirty || RegisterForm.controls['tipoDocumento'].touched)">
              error_outline</mat-icon>
          </div>
        </div>
        <!-- Numero documento -->
        <div class="d-flex-row"
          [ngClass]="{'place': RegisterForm.controls['numeroDocumento'].invalid && (RegisterForm.controls['numeroDocumento'].dirty || RegisterForm.controls['numeroDocumento'].touched)}">
          <div>
            <input
              [ngClass]="{'text-field-error' : RegisterForm.controls['numeroDocumento'].invalid && (RegisterForm.controls['numeroDocumento'].dirty || RegisterForm.controls['numeroDocumento'].touched), 'text-field' : !RegisterForm.controls['numeroDocumento'].invalid || RegisterForm.controls['numeroDocumento'].invalid }"
              type="text" formControlName="numeroDocumento" placeholder="Número de Documento"
              onkeypress="return (event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47)">
            <mat-error class="pass"
              *ngIf="RegisterForm.controls['numeroDocumento'].invalid && (RegisterForm.controls['numeroDocumento'].dirty || RegisterForm.controls['numeroDocumento'].touched)">
              Ingresa tu numero de documento</mat-error>
          </div>
          <div>
            <mat-icon class="red"
              *ngIf="RegisterForm.controls['numeroDocumento'].invalid && (RegisterForm.controls['numeroDocumento'].dirty || RegisterForm.controls['numeroDocumento'].touched)">
              error_outline</mat-icon>
          </div>
        </div>
        <!-- Confirmación numero documento -->
        <div class="d-flex-row"
          [ngClass]="{'place': RegisterForm.controls['numeroDocumentoConfirm'].invalid && (RegisterForm.controls['numeroDocumentoConfirm'].dirty ||  RegisterForm.controls['numeroDocumentoConfirm'].touched)}">
          <div>
            <input
              [ngClass]="{'text-field-error' : RegisterForm.controls['numeroDocumentoConfirm'].invalid && (RegisterForm.controls['numeroDocumentoConfirm'].dirty || RegisterForm.controls['numeroDocumentoConfirm'].touched)|| !equalDocument, 'text-field' : !RegisterForm.controls['numeroDocumentoConfirm'].invalid || RegisterForm.controls['numeroDocumentoConfirm'].invalid }"
              type="text" formControlName="numeroDocumentoConfirm" placeholder="Confirma tu número de Documento"
              onkeypress="return (event.charCode >= 46 && event.charCode <= 57 && event.charCode != 47)">
            <mat-error class="pass" *ngIf="!equalDocument">
              El numero de documento no concuerda</mat-error>
          </div>
          <div>
            <mat-icon class="red"
              *ngIf="RegisterForm.controls['numeroDocumentoConfirm'].invalid && (RegisterForm.controls['numeroDocumentoConfirm'].dirty || RegisterForm.controls['numeroDocumentoConfirm'].touched)">
              error_outline</mat-icon>
          </div>
        </div>
        <!-- Correo electronico -->
        <div>
          <div class="d-flex-row"
            [ngClass]="{'place': RegisterForm.controls['email'].invalid && (RegisterForm.controls['email'].dirty || RegisterForm.controls['email'].touched)}">
            <div>
              <input
                [ngClass]="{'text-field-error' : RegisterForm.controls['email'].invalid && (RegisterForm.controls['email'].dirty || RegisterForm.controls['email'].touched), 'text-field' : !RegisterForm.controls['email'].invalid || RegisterForm.controls['email'].invalid }"
                type="email" formControlName="email" placehol type="text" formControlName="email"
                placeholder="Correo electrónico">
              <mat-error class="pass"
                *ngIf="RegisterForm.controls['email'].invalid && RegisterForm.controls['email'].touched">Ingresa
                un
                correo valido</mat-error>
            </div>
            <div>
              <mat-icon class="red"
                *ngIf="RegisterForm.controls['email'].invalid && (RegisterForm.controls['email'].dirty || RegisterForm.controls['email'].touched)">
                error_outline</mat-icon>
            </div>
          </div>
        </div>
        <!-- Celular -->
        <div class="d-flex-row"
          [ngClass]="{'place': RegisterForm.controls['celular'].invalid && (RegisterForm.controls['celular'].dirty || RegisterForm.controls['celular'].touched)}">
          <div>
            <input
              [ngClass]="{'text-field-error' : RegisterForm.controls['celular'].invalid && (RegisterForm.controls['celular'].dirty || RegisterForm.controls['celular'].touched), 'text-field' : !RegisterForm.controls['celular'].invalid || RegisterForm.controls['celular'].invalid }"
              type="text" formControlName="celular" placeholder="Celular">
            <mat-error class="pass"
              *ngIf="RegisterForm.controls['celular'].invalid && (RegisterForm.controls['celular'].dirty || RegisterForm.controls['celular'].touched)">
              Ingresa tu numero de celular</mat-error>
          </div>
          <div>
            <mat-icon class="red"
              *ngIf="RegisterForm.controls['celular'].invalid && (RegisterForm.controls['celular'].dirty || RegisterForm.controls['celular'].touched)">
              error_outline</mat-icon>
          </div>
        </div>
        <!-- Contraseña -->
        <div class="d-flex-row pos-rela"
          [ngClass]="{'place': RegisterForm.controls['password'].invalid && (RegisterForm.controls['password'].dirty || RegisterForm.controls['password'].touched)}">
          <div class="div-eye">
            <input
              [ngClass]="{'text-field-error' : RegisterForm.controls['password'].invalid && (RegisterForm.controls['password'].dirty || RegisterForm.controls['password'].touched), 'text-field' : !RegisterForm.controls['password'].invalid || RegisterForm.controls['password'].invalid }"
              [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Crea tu contraseña">
            <span (click)="isHide('boton1')" *ngIf="hide"
              class="icon-Visualizar_contrasea eye top-right font-size-18 color-eye"></span>
            <span (click)="isHide('boton1')" *ngIf="!hide"
              class="icon-No_visualizar_contrasea close-eye top-right font-size-18 color-eye"></span>
          </div>
          <div>
            <mat-icon class="red"
              *ngIf="RegisterForm.controls['password'].invalid && (RegisterForm.controls['password'].dirty || RegisterForm.controls['password'].touched)">
              error_outline</mat-icon>
          </div>
        </div>
        <span class="adv">{{getName('passw')}}</span>
        <mat-error class="pass mr-10"
          *ngIf="RegisterForm.controls['password'].invalid && (RegisterForm.controls['password'].dirty || RegisterForm.controls['password'].touched)">
          Ingresa una contraseña que cumpla con los requisitos</mat-error>
        <!-- Confirmación contraseña -->
        <div>
          <div class="d-flex-row pos-rela"
            [ngClass]="{'place': RegisterForm.controls['passwordConfirm'].invalid && (RegisterForm.controls['passwordConfirm'].dirty || RegisterForm.controls['passwordConfirm'].touched)}">
            <div class="div-eye">
              <input
                [ngClass]="{'text-field-error' : RegisterForm.controls['passwordConfirm'].invalid && (RegisterForm.controls['passwordConfirm'].dirty || RegisterForm.controls['passwordConfirm'].touched)|| !equal, 'text-field' : !RegisterForm.controls['passwordConfirm'].invalid || RegisterForm.controls['passwordConfirm'].invalid }"
                [type]="hide2 ? 'password' : 'text'" formControlName="passwordConfirm"
                placeholder="Confirma tu contraseña">
              <span (click)="isHide('boton2')" *ngIf="hide2"
                class="icon-Visualizar_contrasea eye top-right font-size-18 color-eye"></span>
              <span (click)="isHide('boton2')" *ngIf="!hide2"
                class="icon-No_visualizar_contrasea close-eye top-right font-size-18 color-eye"></span>
            </div>
            <div>
              <mat-icon class="red"
                *ngIf="RegisterForm.controls['passwordConfirm'].invalid && (RegisterForm.controls['passwordConfirm'].dirty || RegisterForm.controls['passwordConfirm'].touched)">
                error_outline</mat-icon>
            </div>
          </div>
          <mat-error class="pass mr-10" *ngIf="!equal">
            La contraseña no coincide</mat-error>
        </div>
        <!-- Fecha de nacimiento -->
        <div class="d-flex-column pos-rela">
          <span class="birth pb-3"><b>{{getName('birth')}}</b></span>
          <input class="text-field" [matDatepicker]="picker" formControlName="fechaNacimiento" placeholder="MM/DD/AAAA" lang="es">
          <mat-datepicker-toggle class="calendar color-eye" matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <span class="birth pt-3">{{getName('desc')}}</span>
        </div>
        <!-- recaptcha -->
        <div class="">
          <div
            [ngClass]="{'border-red': RegisterForm.controls['recaptcha'].invalid && RegisterForm.controls['recaptcha'].touched}">
            <re-captcha id="recaptcha" formControlName="recaptcha"
              [class.is-invalid]="RegisterForm.get('recaptcha')?.invalid && (RegisterForm.get('recaptcha')?.dirty || RegisterForm.get('recaptcha')?.touched)"
              required
              [ngClass]="{'border-red': RegisterForm.controls['recaptcha'].value == '' && RegisterForm.get('recaptcha')?.touched}" />
          </div>
        </div>
        <!-- Check terminos y condiciones -->
        <div class="d-flex-row w-333"
          [ngClass]="{'border-red': RegisterForm.controls['terminos'].touched && RegisterForm.controls['terminos'].invalid}">
          <label class="checkbox-container">
            <input type="checkbox" formControlName="terminos">
            <span class="checkmark"></span>
          </label>
          <span class="text-login-2">{{getName('termn')}}. <span class="cursor-pointer" (click)="donwloadPolitics()">
              Para ver mas haz clic aquí</span></span>
        </div>
        <div *ngIf="errorP" class="d-flex-row w-100">
          <mat-icon class="red-color">error_outline</mat-icon>
          <mat-error class="pass mr-10">
            Hubo algun error en el servidor</mat-error>
        </div>
        <!-- Botón crear cuenta -->
        <div class="d-flex w-100">
          <button class="button-primary">Crear cuenta</button>
        </div>
      </div>
    </form>
    <span>O ingresa con:</span>
    <div class="d-flex-row pb-5 space-eve">
      <div id="google-btn" class="button-social"></div>
    </div>
    <!-- <div class="d-flex-row pb-5 space-eve">
      <button (click)="registerFacebook()" class="button-social"><span class="icon-facebook-2 font-size-25"><span
            class="path1"></span><span class="path2"></span></span></button>
      <button class="button-social margin-b-5 text-google">
        <asl-google-signin-button type="icon" shape="circle" width=260></asl-google-signin-button>
      </button>
    </div> -->
  </div>
</div>
