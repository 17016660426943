<lib-banners [catalogo]="filtro" [noEncontrado]="noEncontrado" />
<lib-menu-catalogo [categoria]="filtro" />
<div class="conetenedor-columnas">
  <div class="columna-filtros">
    <lib-side-bar-filtro [codigo]="filtro" />
  </div>
  <div class="columna-catalogo">
    <div [ngClass]="{'catalogo-init-mobile':dispositivoService.isMobile, 'catalogo-init':!dispositivoService.isMobile}">
      <lib-grid-catalogo [categoria]="filtro" [busqueda]="idsBusqueda"
        (noEncontrado)="noEncontradoBanner($event)" />
    </div>
  </div>
</div>
