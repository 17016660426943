<!-- <div class="heigth-toolbar header-fixed d-flex-row space-between">
  <mat-toolbar class="d-flex-row space-between plr-2-4rem">
    <mat-icon (click)="EventoOpen('Menu')" >menu</mat-icon>
    <a (click)="redirectToHome()">
      <img class="logo-size z-index-2 pos-rela" [src]="propiedades['header']['logo-marca']" alt="">
    </a>
    <mat-icon (click)="EventoOpen('Busqueda')" >search</mat-icon>
</div> -->

<div class="contenedor">
  <mat-icon (click)="EventoOpen('Menu')" class="z-index color-icon menu-size">menu</mat-icon>
  <div class="kevins-center">
    <a (click)="redirectToHome()">
      <img class="logo-size z-index-2 pos-rela" [src]="propiedades['header']['logo-marca']" alt="">
    </a>
  </div>
  <div class="contenedor-iconos">
    <span class="icon-Hearth flex-relative color-icon" (click)="redirect('favoritos')"></span>
    <!-- <span class="icon-shoppingCart color-icon"></span> -->
    <div class="z-index flex-relative pl-2" (click)="EventoOpen('Carrito')">
      <a>
        <span class="icon-shoppingCart color-icon"></span>
        @if (totalProducts > 0) {
        <span class="numberProducts">{{totalProducts}}</span>
        }
      </a>
    </div>
  </div>
</div>
