import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { RouterOutlet, Router } from '@angular/router';

import FooterComponent from 'libreria-tienda/shared/footer/footer.component';
import { HeaderComponent } from 'libreria-tienda/shared/header/header.component';
import { CartRightComponent } from 'libreria-tienda/shopping-cart/cart-right/cart-right.component';
import { LoaderComponent } from 'libreria-tienda/shared/loader/loader.component';
import { SideBarComponent } from 'libreria-tienda/shared/side-bar/side-bar.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ManejadorEventosService } from 'libreria-tienda';
import { BannersComponent } from 'libreria-tienda/shared/banners/banners.component';
import { BotonContactanosComponent } from 'libreria-tienda/shared/boton-contactanos/boton-contactanos.component';
import { BannerFooterComponent } from 'libreria-tienda/shared/banner-footer/banner-footer.component';

@Component({
  selector: 'app-escritorio',
  standalone: true,
  imports: [CommonModule,
    RouterOutlet,
    FooterComponent,
    HeaderComponent,
    MatTooltipModule,
    MatSidenavModule,
    CartRightComponent,
    LoaderComponent,
    SideBarComponent,
    BannersComponent,
    BotonContactanosComponent,
    BannerFooterComponent
  ],
  templateUrl: './escritorio.component.html',
  styleUrl: './escritorio.component.css'
})
export class EscritorioComponent {

  public manejadorEventos = inject(ManejadorEventosService)


  constructor(private router: Router) {
    this.manejadorEventos.main.subscribe(val => {
      // console.log(val);
      this.manejarEvento(val)
    })
  }

  @ViewChild('side_bar') side_bar: SideBarComponent;

  manejarEvento(e: string) {
    const rutaActual = this.router.url
    // console.log(e);
    switch (e) {
      case 'abrirDerecha':
        this.side_bar.abrirDerecha();
        break;
      case 'abrirIzquierda':
        this.side_bar.abrirIzquierda();
        break;
      case 'abrirArriba':
        this.side_bar.abrirArriba();
        break;
      case 'Menu':
        this.side_bar.abrirIzquierda();
        break;
      case 'cerrar':
        this.side_bar.cerrarIzquierda();
        this.side_bar.cerrarDerecha();
        break;
      case 'Carrito':
        if (rutaActual != '/shopping-cart') {
          this.manejadorEventos.lanzarEvento('openCart')
          this.side_bar.abrirDerecha()
        }
        break;
      default:
        break;
    }

  }

}

