<div class="center d-flex-column send-code-user-container">
  <div class="center">
    <img [src]="data.img" alt="" class="diamante-imagen">
  </div>
  <div class="titulo">
    <span>{{data.titulo}}</span>
  </div>
  <span class="texto">{{data.texto}}</span>
  @if (data.boton) {
  <button class="boton" mat-dialog-close (click)="redirect()">{{data.boton}}</button>
  }
</div>
