<div class="contenedor">
  <div class="titulo">
    <div class="raya"></div>
    <h2>Solicitud de factura</h2>
  </div>
  <div class="contenedor-formulario">

    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
    <!--  If necessary, please modify the charset parameter to specify the        -->
    <!--  character set of your HTML page.                                        -->
    <!--  ----------------------------------------------------------------------  -->

    <META HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8">
    <script src="https://www.google.com/recaptcha/api.js"></script>
    <script>
      function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") { var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value); elems["ts"] = JSON.stringify(new Date().getTime()); document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);
    </script>

    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: Please add the following <FORM> element to your page.             -->
    <!--  ----------------------------------------------------------------------  -->

    <form
      action="https://site-site-5635--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DD6000000VW5y"
      method="POST">

      <input type=hidden name='captcha_settings'
        value='{"keyname":"Web_to_case_key_pair","fallback":"true","orgId":"00DD6000000VW5y","ts":""}'>
      <input type=hidden name="orgid" value="00DD6000000VW5y">
      <input type=hidden name="retURL" value="https://kevins.com.co/">

      <!--  ----------------------------------------------------------------------  -->
      <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
      <!--  these lines if you wish to test in debug mode.                          -->
      <!--  <input type="hidden" name="debug" value=1>                              -->
      <!--  <input type="hidden" name="debugEmail"                                  -->
      <!--  value="juan.naranjo@ceiba.com.co">                                      -->
      <!--  ----------------------------------------------------------------------  -->

      <div class="columnas">
        <div class="columna-uno">
          <div class="none">
            <div class="contenedor-campo">

              <label for="type">Tipo</label>
              <select id="type" name="type">
                <option value="">--Ninguno--</option>
                <option value="Request" selected>Solicitudes</option>
                <option value="Customer service comments">Comentarios servicio al cliente</option>
                <option value="Product status">Estado de producto</option>
              </select>
            </div>
            <div class="contenedor-campo">

              Subtipo:<select id="00ND600000Sn0UP" name="00ND600000Sn0UP" title="Subtipo">
                <option value="">--Ninguno--</option>
                <option value="InvoiceRequest" selected>Solicitud de factura</option>
                <option value="PreciousStonesCertificateRequest">Solicitud de certificado de piedras preciosas</option>
                <option value="Congratulations">Felicitaciones</option>
                <option value="Discontent">Inconformidad</option>
                <option value="UnclaimedProductDeliveryInTechnicalService">Entrega de producto no reclamado en servicio
                  técnico
                </option>
                <option value="WithholdingReturn">Devolución de Retención</option>
              </select>
            </div>
          </div>

          <div class="contenedor-campo">
            <label for="name" class="label">Nombre del contacto</label>
            <input id="name" maxlength="80" name="name" size="20" type="text" class="custom-input"/>
          </div>

          <div class="contenedor-campo">
            <label for="00ND600000Sn0TK" class="label">Tipo de documento:</label>
            <select id="00ND600000Sn0TK" name="00ND600000Sn0TK" title="Tipo de documento">
              <option value="">--Ninguno--</option>
              <option value="Cedula de ciudadania">Cédula de ciudadania</option>
              <option value="Cedula Extranjera">Cédula Extranjera</option>
              <option value="Pasaporte">Pasaporte</option>
              <option value="NIT">NIT</option>
            </select>
          </div>

          <div class="contenedor-campo">
            <label for="00ND600000Sn0Te" class="label">Documento del titular de la compra:</label>
            <input id="00ND600000Sn0Te" maxlength="20" name="00ND600000Sn0Te" size="20" type="text" class="custom-input"/>
          </div>

          <div class="contenedor-campo">
            <label for="email" class="label">Correo electrónico</label>
            <input id="email" maxlength="80" name="email" size="20" type="text" class="custom-input"/>
          </div>
        </div>
        <div class="columna-dos">
          <div class="contenedor-campo">
            <label for="00ND600000T8GOf" class="label">Rango Fecha Inicial:</label>
            <span class="dateInput dateOnlyInput">
              <input id="00ND600000T8GOf" name="00ND600000T8GOf" size="12" type="text" class="custom-input"/>
            </span>
          </div>

          <div class="contenedor-campo">
            <label for="00ND600000T8GOe" class="label">Rango Fecha Final:</label>
            <span class="dateInput dateOnlyInput">
              <input id="00ND600000T8GOe" name="00ND600000T8GOe" size="12" type="text" class="custom-input"/>
            </span>
          </div>

          <div class="contenedor-campo">
            <label for="description" class="label">Descripción</label>
            <textarea name="description"></textarea>
          </div>

        </div>
      </div>
      <div class="g-recaptcha" data-sitekey="6LfbIw0qAAAAAAHNPav5Lbjp1ryJlayoomAsFJBc"></div><br>
      <input type="submit" name="submit" class="button">

    </form>
  </div>
</div>
