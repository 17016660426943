
import { Component, Input, OnChanges, OnInit, SimpleChanges, Type } from '@angular/core';
import { Anillo, Gia, Item, caracteristicas, infoVenecia } from 'libreria-tienda';
import { DetallesProductoGeneralComponent } from './detalles-producto-general/detalles-producto-general.component';
import { CommonModule } from '@angular/common';
import { DetallesProductoAnilloComponent } from './detalles-producto-anillo/detalles-producto-anillo.component';
import { DetallesProductoGiaComponent } from './detalles-producto-gia/detalles-producto-gia.component';
import { DetallesProductoVeneziaComponent } from './detalles-producto-venezia/detalles-producto-venezia.component';

@Component({
  selector: 'lib-detalles-producto',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './detalles-producto.component.html',
  styleUrl: './detalles-producto.component.css'
})
export class DetallesProductoComponent implements OnInit, OnChanges {

  @Input() caracteristicasProd: caracteristicas[];
  @Input() item: Item = new Item();
  @Input() itemAnillo: Anillo;
  @Input() itemGia: Gia[];
  @Input() complementos: infoVenecia[];

  protected entradas: Record<string, unknown>;

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['caracteristicasProd'] && changes['caracteristicasProd'].currentValue) {
      this.Componente
    }
  }

  protected get Componente(): Type<any> {
    switch (this.item.tipoProducto) {
      case 'Gia':
        this.entradas = {
          item: this.itemGia
        };
        return DetallesProductoGiaComponent;
      case 'Anillo':
        this.entradas = {
          item: this.item,
          itemAnillo: this.itemAnillo,
          catalogo: this.item.catalogo,
          complementos: this.complementos
        };
        return DetallesProductoAnilloComponent;
      case 'Venezia':
        this.entradas = {
          item: this.item,
          complementos: this.complementos
        };
        return DetallesProductoVeneziaComponent;
      default:
        this.entradas = {
          item: this.item,
          catalogo: this.item.catalogo,
          complementos: this.complementos
        };
        return DetallesProductoGeneralComponent;
    }
  }
}
