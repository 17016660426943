import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ConfigService, JsonTextService, LoadingService, TextResponse } from 'libreria-tienda';

@Component({
  selector: 'app-preguntas',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatDividerModule],
  templateUrl: './preguntas.component.html',
  styleUrl: './preguntas.component.css'
})
export class PreguntasComponent implements OnInit {

  panelOpenState = false;
  view: boolean = true;

  visibleIndex: number = -1;

  public load = inject(LoadingService)
  public jsonDetalle: TextResponse[] = []
  public jsonService = inject(JsonTextService);
  public configService = inject(ConfigService);

  preguntasyrespuestas: any;

  // base: string = 'https://qa.kevins.orafa.com.co/'

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.load.cargar(true)
    this.jsonService.downloadJson(this.configService._config.URLJSONPREGUNTASFRECUENTES).subscribe(res => {
      if (res !== undefined) {
        // console.log(res);
        this.jsonDetalle = res;
        this.load.cargar(false);
        this.preguntasyrespuestas = this.getName('preguntas_segundarias');
        // console.log(this.preguntasyrespuestas);
      }
    }).closed
  }

  onPanelOpened(panelIndex: number): void {
    this.panelOpenState = true
    const panel = this.el.nativeElement.querySelectorAll('.mat-expansion-panel')[panelIndex];
    const whatsappLink = panel.querySelector('.links');

    if (whatsappLink) {
      this.renderer.setStyle(whatsappLink, 'color', 'var(--color2)');
      this.renderer.setStyle(whatsappLink, 'text-decoration', 'none');
      this.renderer.setStyle(whatsappLink, 'font-family','var(--H6-fuente) !important');
      this.renderer.setStyle(whatsappLink, 'font-weight','400');
      this.renderer.setStyle(whatsappLink, 'font-size','16px !important');
    } else {
      console.log('No se encontró el elemento con la clase .link');
    }
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  viendoPregunta(index: number) {
    // console.log(this.visibleIndex);
    // console.log(index);

    if (this.visibleIndex == index) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = index;
    }
  }
}
