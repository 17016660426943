import { inject, Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { direccion } from '../class/user';
import { ExpressService } from './express.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  expressService = inject(ExpressService)

  direccion: direccion = new direccion();
  index: number = -1;
  private changeDirection = new Subject<any>();

  constructor() { }

  getDireccion() {
    return this.direccion;
  }
  getIndex() {
    return this.index;
  }
  setDireccion(dire: direccion) {
    return this.direccion = dire;
  }
  setIndex(i: number) {
    return this.index = i;
  }
  setDirection(direccion: string) {
    this.changeDirection.next(direccion)
  }
  getDirectionCost() {
    return this.changeDirection.asObservable();
  }

  ordenarPrincipal(direcciones: direccion[], correo: string) : direccion[]{
    let i = direcciones.findIndex(x => x.isPrincipal === true)
    if (i == -1) {
      direcciones[0].isPrincipal = true;
      this.expressService.editDireccionesUser(correo, direcciones);
      i = 0;
    }
    let dire = direcciones[i]
    direcciones.splice(i, 1);
    direcciones.unshift(dire);
    return direcciones
  }

}
