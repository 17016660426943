import { Component, OnInit, inject } from '@angular/core';
import { LoadingService } from 'libreria-tienda';

@Component({
  selector: 'lib-loader',
  standalone: true,
  imports: [],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.css'
})
export class LoaderComponent implements OnInit {

  public loadingService = inject(LoadingService)

  load: boolean = true

  ngOnInit(): void {
    this.loadingService.loading.subscribe(val => {
      this.load = val
    })
  }

}
