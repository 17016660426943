import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { Banner, ExpressService, LoadingService} from 'libreria-tienda';
import { BannerHomeComponent } from 'libreria-tienda/shared/banner-home/banner-home.component';
import { PasoDosVeneziaComponent } from 'libreria-tienda/shared/paso-dos-venezia/paso-dos-venezia.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

declare var propiedades: any | undefined; // ver script javascript en el index.html

@Component({
  selector: 'app-venezia',
  standalone: true,
  imports: [BannerHomeComponent, NgxSkeletonLoaderModule, PasoDosVeneziaComponent],
  templateUrl: './venezia.component.html',
  styleUrl: './venezia.component.css'
})
export class VeneziaComponent implements OnInit {

  public expressService = inject(ExpressService)
  public load = inject(LoadingService)

  paso = 'cero'

  constructor() {
    this.propiedades = propiedades;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.load.cargar(false)
    this.expressService.getBanners(this.section).subscribe(res => {
      this.banners = res.banners
      this.banners = this.banners.reverse()
    })
  }

  propiedades: any;

  section: string = 'venezia';

  banners: Banner[];

  pasoActual(paso:string){
    this.paso = paso
  }

}
