@if (jsonDetalle.length>0) {
<div class="contenedor">
  <h1 class="titulo" [innerHTML]="getName('titulo')"></h1>
  <span class="texto-principal" [innerHTML]="getName('text_principal')"></span>
  <div class="contenedor-preguntas-principales">
    @for (item of getName('preguntas_principales'); track $index) {
    @if (visibleIndex == -1 || visibleIndex == $index) {
    <div class="contenedor-preguntas" (click)="viendoPregunta($index)">
      <span [innerHTML]="item"></span>
      @if (visibleIndex == $index) {
      <i class="fa-solid fa-chevron-left dorado size_icon"></i>
      }@else {
      <i class="fa-solid fa-chevron-right dorado size_icon"></i>
      }
    </div>
    }
    }
  </div>
  @if (visibleIndex !== -1) {
  <div class="acordeon">
    <mat-accordion>
      @for (item of preguntasyrespuestas[visibleIndex]; track $index) {
      <mat-expansion-panel (opened)=" onPanelOpened($index)" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{item.preguntas}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="link" [innerHTML]="item.respuestas"></span>
      </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
  }
</div>
}
