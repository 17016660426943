import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PantallaSaleService {

  private estadoSale = new BehaviorSubject<boolean>(false); // Estado inicial
  estado$ = this.estadoSale.asObservable(); // Observable para que los componentes se suscriban

  // Método para actualizar el estado
  actualizarEstado(nuevoValor: boolean) {
    this.estadoSale.next(nuevoValor);
  }
}
