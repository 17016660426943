<div class="d-flex-center d-flex-column pt-5 pb-5 back-img">
  <div class="d-flex-row d-flex-center">
    <span class="icon-ring font-size"></span>
    <h2 class="title m-0 pl-1rem">{{getName('title')}}</h2>
  </div>
  <span class="subtitle">{{getName('subtitle')}}</span>
  <div class="find-row pl-1 w-100cont">
    <div class="find-column pl-1 w-50-r w-100-r w29">
      <div class="pb-3">
        <mat-form-field appearance="fill" class="">
          <mat-select (selectionChange)="citieSelected($event)" placeholder="Selecciona tu ciudad" required>
            <mat-option *ngFor="let citie of listCities" [value]="citie">
              {{citie | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="citie != ''">
        <mat-form-field appearance="fill" class="">
          <mat-select (selectionChange)="pointSelected($event)" placeholder="Selecciona la tienda" required>
            <div *ngFor="let point of stores">
              <mat-option *ngIf="point.ciudad == citie && point.mostrarEnPagina == 1" [value]="point">
                {{point.nombre | titlecase}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <div class="pt-3" *ngIf="!emtpyob">
          <div class="grid-points pt-3">
            <div class="icon-grid pr-1">
              <span class="icon-Icono_Tienda icon-size-22px icon-black"></span>
            </div>
            <div class="name-point pl-1rem">
              <span class="text-title">{{store.nombre}}</span>
            </div>
            <div class="direccion pl-1rem">
              <span class="text">{{store.direccion}}</span>
            </div>
            <div class="local pl-1rem">
              <span class="text">Local {{store.local}}.</span>
            </div>
          </div>
          <div class="d-flex-row pt-3">
            <div>
              <span class="icon-Icono_reloj icon-size-22px icon-black"></span>
            </div>
            <div class="d-flex-column pl-1rem">
              <div class="">
                <span class="text-title">Horarios de atención</span>
              </div>
              <div class="">
                <span class="break-spaces text">{{store.horario}}</span>
              </div>
            </div>
          </div>
          <div class="d-flex-row align-center pt-3" *ngIf="store.joyero == 1">
            <span class="icon-ring icon-size-22px icon-grey" *ngIf="store.joyero == 1"></span>
            <span class="text-title pl-1rem break-spaces">Servicio <span class="text-title" *ngIf="store.joyero == 1">
                Joyero</span></span>
          </div>
          <div class="d-flex-row align-center pt-3" *ngIf="store.relojero == 1">
            <span class="icon-reloj icon-size-22px icon-grey relojero" *ngIf="store.relojero == 1"></span>
            <span class="text-title pl-1rem break-spaces">Servicio <span class="text-title" *ngIf="store.relojero == 1">
                Relojero</span></span>
          </div>
          <div class="d-flex-row pt-3 pt-3">
            <span class="icon-Telefono icon-size-22px icon-grey"></span>
            <span class="text-title pl-1rem">({{store.indicativo}}) {{store.telefono1}}</span>
          </div>
          <div class="d-flex-row pt-3 flex-end pb-3">
            <div class="d-flex-column" *ngIf="store.compraOro == 1 || store.compraOro == 2">
              <div class="d-flex-row align-center pb-3">
                <span class="icon-Gold-2 font-size-17 icon-grey"></span>
                <span class="text-title pl-1rem">Compra de oro</span>
              </div>
              <div class="pl-2rem">
                <button (click)="openWa()" class="button-wa button-cita">
                  <span class="icon-Whatsapp wa-color icon-size-22px"></span>
                  Agendar cita
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="d-flex-column">
            <div class="d-flex-row pb-3 align-center">
              <span class="icon-Whatsapp wa-color icon-size-22px"></span>
              <span class="wa-color text-wa pl-1rem">Whatsapp</span>
            </div>
            <div class="pl-2rem">
              <button (click)="openWa()" class="button-wa">Ir a chat</button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="cont" id="map"></div>
  </div>
</div>

