import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { imagenesAux } from 'libreria-tienda';

@Component({
  selector: 'lib-img-auxiliar-detalle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './img-auxiliar-detalle.component.html',
  styleUrl: './img-auxiliar-detalle.component.css'
})
export class ImgAuxiliarDetalleComponent implements OnChanges {

  @Input() imagenes: imagenesAux[] = []
  @Output() cambio = new EventEmitter<string>();

  viendo: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['imagenes']) {
      if (this.imagenes.length > 0) {
        this.viendo = this.imagenes[0].img
      }
    }
  }

  changePhoto(i: string) {
    this.viendo = i;
    this.cambio.emit(i)
  }

}
