import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ExpressService, FavoritoService, LoadingService, Product, ShoppingCartService, SioServicesService, User, favorite } from 'libreria-tienda';
import { CardProductComponent } from 'libreria-tienda/shared/card-product/card-product.component';
import { PaginadorComponent } from 'libreria-tienda/shared/paginador/paginador.component';

@Component({
  selector: 'lib-favoritos',
  standalone: true,
  imports: [CommonModule, CardProductComponent, PaginadorComponent],
  templateUrl: './favoritos.component.html',
  styleUrl: './favoritos.component.css'
})
export class FavoritosComponent implements OnInit {

  user: User = new User();

  columnasSele: string;
  cant_columnas: number;
  pagina: number;

  @Input() set columnas(value: string) {
    this.cant_columnas = value == 'wrapper-product2' ? 50 : value == 'wrapper-product3' ? 60 : 40;
    this.columnasSele = value
  };

  fav: favorite[] = [];
  favoritos: Product[] = new Array<Product>();

  sioServicesService = inject(SioServicesService)
  favoritoService = inject(FavoritoService)
  expressService = inject(ExpressService)
  cartService = inject(ShoppingCartService)
  router = inject(Router)
  load = inject(LoadingService)

  ngOnInit() {
    this.pagina = 1
    this.loadProductsDos(this.pagina)
  }

  loadProductsDos(i: number) {
    this.favoritoService.getFav().then((aux) => {
      console.log(aux);
      if (aux.length > 0) {
        this.fav = aux;
        this.load.cargar(true);
        this.sioServicesService.getFavoritosPaginados(aux, this.cant_columnas, i).then((productos) => {
          // console.log(productos);
          this.favoritos = productos.valor
          this.load.cargar(false);
        }).catch((error) => {
          console.error(error);
          this.favoritos = [];
          this.load.cargar(false);
        });
      }
    });
  }

  paginaChange(e: any) {
    console.log(e);
    this.load.cargar(true)
    this.loadProductsDos(e)
  }

}
