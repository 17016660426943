<div class="container-father">
  <div class="login-container">
    <div class="container-title">
      <span class="text-login">{{getName('title')}}</span>
    </div>
    <form [formGroup]="LoginForm" (ngSubmit)="onSubmit()">
      <div class="form-inputs-d-flex">
        <div class="center2">
          <div class="d-flex-column contenedor-input">
            <input type="email" id="email" formControlName="email" placeholder="Ingresa tu correo electronico" required
              [ngClass]="{'input-error' : (LoginForm.get('email')?.touched && LoginForm.get('email')?.errors?.['required']) ||
                                        (LoginForm.get('email')?.touched && LoginForm.get('email')?.errors?.['pattern']),
                        'text-field' : !(LoginForm.get('email')?.touched && LoginForm.get('email')?.errors?.['required']) &&
                                      !(LoginForm.get('email')?.touched && LoginForm.get('email')?.errors?.['pattern'])
                      }">
            @if (LoginForm.get('email')?.touched && LoginForm.get('email')?.errors?.['required']) {
            <mat-error class="red-color">
              <mat-icon>error_outline</mat-icon>El correo electrónico es necesario
            </mat-error>
            }
            @if ((LoginForm.get('email')?.touched && LoginForm.get('email')?.errors?.['pattern']) || (!LoginForm.valid
            && submit && LoginForm.get('password')?.invalid)) {
            <mat-error class="red-color">
              <mat-icon>error_outline</mat-icon>El correo electrónico que ingresaste no es válido
            </mat-error>
            }
          </div>
        </div>
        <div class="center2">
          <div class="d-flex-column pos-rela contenedor-input">
            <input id="password" formControlName="password" placeholder="Ingresa tu contraseña" required
              [type]="hide ? 'password' : 'text'" [ngClass]="{'input-error' : (LoginForm.get('password')?.touched && LoginForm.get('password')?.errors?.['required']) ||
                                        (LoginForm.get('password')?.touched && LoginForm.get('password')?.errors?.['minlength']),
                        'text-field' : !(LoginForm.get('password')?.touched && LoginForm.get('password')?.errors?.['required']) &&
                                      !(LoginForm.get('password')?.touched && LoginForm.get('password')?.errors?.['minlength'])
                                    }">
            <span (click)="isHide()" *ngIf="!hide" class="icon-Visualizar_contrasea eye top-right font-size-18"></span>
            <span (click)="isHide()" *ngIf="hide"
              class="icon-No_visualizar_contrasea eye top-right font-size-18"></span>
            @if (LoginForm.get('password')?.touched && LoginForm.get('password')?.errors?.['required']) {
            <mat-error class="red-color">
              <mat-icon class="red-color">error_outline</mat-icon>La contraseña es necesaria
            </mat-error>
            }
            @if ((LoginForm.get('password')?.touched && LoginForm.get('password')?.errors?.['minlength']) ||
            (!LoginForm.valid && submit && LoginForm.get('password')?.invalid)) {
            <mat-error class="red-color">
              <mat-icon class="red-color">error_outline</mat-icon>La contraseña debe ser minimo <br> de 8 caracteres
            </mat-error>
            }
          </div>
        </div>
        <div class="d-flex">
          <a routerLink="/forgot-password">
            <span class="forgot">{{getName('forgot')}}</span>
          </a>
          <div class="container-father2">
            <re-captcha id="recaptcha" formControlName="recaptcha"
              [class.is-invalid]="LoginForm.get('recaptcha')?.invalid && (LoginForm.get('recaptcha')?.dirty || LoginForm.get('recaptcha')?.touched)"
              required
              [ngClass]="{'border-red': LoginForm.controls['recaptcha'].value == '' && LoginForm.get('recaptcha')?.touched}" />
          </div>
          @if ((LoginForm.get('recaptcha')?.invalid && LoginForm.get('recaptcha')?.touched) || (!LoginForm.valid &&
          submit && LoginForm.get('recaptcha')?.invalid)) {
          <mat-error class="red-color">
            <mat-icon>error_outline</mat-icon>Campo obligatorio
          </mat-error>
          }
          <div class="w-100">
            <button type="submit" class="button-login w-100">Ingresar</button>
          </div>
        </div>
      </div>
    </form>
    <a (click)="redirectToRegister()">
      <span class="register">{{getName('create')}}</span>
    </a>
    <span>{{getName('with')}}</span>
    <div class="d-flex-row pb-5 space-eve">
      <div id="google-btn" class="button-social"></div>
    </div>
  </div>
</div>
