<div class="back-img d-flex-center d-flex-column pt-5 pb-6 pd-r-l h70">
  <div class="d-flex-column d-flex-center pb-5">
      <div class="d-flex-row d-flex-center">
          <span class="icon-Entrega font-size-22"></span>
          <h2 class="title-principal">{{getName('title')}}</h2>
      </div>
       <span class="sub-title">{{getName('subtitle')}}</span>
  </div>
  <div class="column row gap-6">
      <div class="card-delivery-methos d-flex-column d-flex-center">
          <span class="icon-Envio_Express icon-color-size"></span>
          <span class="title">{{getName('card-title-1')}}</span>
          <span class="text" [innerHTML]="getName('card-info-1')"></span>
      </div>
      <div class="card-delivery-methos d-flex-column d-flex-center">
          <span class="icon-camion icon-color-size"></span>
          <span class="title">{{getName('card-title-2')}}</span>
          <span class="text">{{getName('card-info-2')}}</span>
      </div>
      <div class="card-delivery-methos d-flex-column d-flex-center">
          <span class="icon-Icono_Tienda icon-color-size"></span>
          <span class="title">{{getName('card-title-3')}}</span>
          <span class="text">{{getName('card-info-3')}}</span>
          <button (click)="routing()" class="button-primary">{{getName('card-but-3')}}</button>
      </div>
  </div>
</div>
