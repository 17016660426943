import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExpressService } from 'libreria-tienda';

@Component({
  selector: 'lib-confirmcode',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './confirmcode.component.html',
  styleUrl: './confirmcode.component.css'
})
export class ConfirmcodeComponent implements OnInit {

  cellOrEmail: string;
  datacellOrEmail: string;
  code: string = ""
  formCode: FormGroup;
  submit: boolean = false;

  public expressService = inject(ExpressService)

  constructor(public dialogo: MatDialogRef<ConfirmcodeComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
    this.cellOrEmail = this.data.medio
    this.datacellOrEmail = this.data.info
    this.formCode = this.fb.group({
      primero: ['', Validators.required],
      segundo: ['', Validators.required],
      tercero: ['', Validators.required],
      cuarto: ['', Validators.required],
    })
  }

  ngOnInit(): void {
  }
  confirmCode() {
    this.submit = true
    if (this.code != "" && this.code.length > 3) {
      this.expressService.verifyCode(this.datacellOrEmail, this.code).subscribe(res => {
        if (res)
          this.dialogo.close(true)
      })
    }
  }
  close() {
    this.dialogo.close(false)
  }
  reSendCode() {
    this.expressService.setCode(this.datacellOrEmail).then(res => {
      console.log(res);
    })
  }
  replace(text: string) {
    if (text == "Celular")
      return this.datacellOrEmail.substring(0, 3) + '*'.repeat(5) + this.datacellOrEmail.substring(8, 10);
    else {
      return this.datacellOrEmail.substring(0, 2) + '*'.repeat(4) + this.datacellOrEmail.split('@')[1];
    }
  }
  tabFunction(i: number) {
    if (i == 0) {
      this.code = this.formCode.get("primero")?.value + this.formCode.get("segundo")?.value + this.formCode.get("tercero")?.value + this.formCode.get("cuarto")?.value
      document.getElementById("segundo")?.focus();
    }
    if (i == 1) {
      this.code = this.formCode.get("primero")?.value + this.formCode.get("segundo")?.value + this.formCode.get("tercero")?.value + this.formCode.get("cuarto")?.value
      document.getElementById("tercero")?.focus();
    }
    if (i == 2) {
      this.code = this.formCode.get("primero")?.value + this.formCode.get("segundo")?.value + this.formCode.get("tercero")?.value + this.formCode.get("cuarto")?.value
      document.getElementById("cuarto")?.focus();
    }
    if (i == 3) {
      this.code = this.formCode.get("primero")?.value + this.formCode.get("segundo")?.value + this.formCode.get("tercero")?.value + this.formCode.get("cuarto")?.value
      document.getElementById("but")?.focus();
    }
  }
}
