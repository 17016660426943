<div class="w-100 d-none d-sm-none d-md-none d-lg-block">
  @if (paso=='cero') {
  @if (banners) {
  <div class="container">
    <div class="wrapper">
      <video #videoElement oncanplay="this.play()" onloadedmetadata="this.muted = true" width="100%" height="100%" muted
        loop autoplay playsinline class="video-home" [style.background]="propiedades[section]['color']"
        onloadedmetadata="this.playbackRate = 0.5;">
        <source [src]="propiedades[section]['video1']" type="video/mp4">
      </video>
    </div>
  </div>
  }@else {
  <div class="container">
    <div class="wrapper-skeleton">
      <ngx-skeleton-loader class="skeleton-img" count="1" appearance="circle" [theme]="{
              'width.%': 80,
              'height.rem': 40,
              'border-radius': '10px'
            }"></ngx-skeleton-loader>
    </div>
  </div>
  }
  <div class="contenedor">
    <div class="fila">
      <h2 class="titulo"><strong>Encuentra una gran variedad de dijes para expresar tus sentimientos</strong></h2>
    </div>
    <div class="columnasTres">
      <!-- Paso 1 -->
      <div class="columnaUno">
        <div class="contendor-paso-uno contendor-paso" (click)="pasoActual('uno')">
          <div class="paso">
            <span class="texto-paso">PASO 1</span>
            <div class="cuadro-dorado"></div>
          </div>
          <span class="texto-paso-dos"><strong>Selecciona</strong> tu pulsera <br> <span class="trans">.</span></span>
          <img src="https://qastorageorafa.blob.core.windows.net/venezia/WEB - Pulsera Venezia.png" alt=""
            class="size-img1">
        </div>
      </div>
      <!-- Paso 2 -->
      <div class="columnaDos">
        <div class="contendor-paso-dos">
          <div class="paso">
            <span class="texto-paso">PASO 2</span>
            <div class="cuadro-dorado dorado-dos"></div>
          </div>
          <span class="texto-paso-dos"><strong>Decora</strong> con Dijes <br> <span class="trans">.</span></span>
          <div class="image-container">
            <img src="https://qastorageorafa.blob.core.windows.net/venezia/Pulsera1.png" alt="" class="size-img1 ">
          </div>
        </div>
      </div>
      <!-- Paso 3 -->
      <div class="columnaTres">
        <div class="contendor-paso-tres">
          <div class="paso">
            <span class="texto-paso">PASO 3</span>
            <div class="cuadro-dorado"></div>
          </div>
          <span class="texto-paso-dos center-texto"><strong>Finaliza</strong> tu compra <br> y recibe tu Set</span>
          <img src="https://qastorageorafa.blob.core.windows.net/venezia/Pulsera colombia.png" alt="" class="size-img3">
        </div>
      </div>
    </div>
  </div>
  }@else if (paso == 'uno') {
    <lib-paso-dos-venezia></lib-paso-dos-venezia>
  }

</div>
