import { Component } from '@angular/core';

@Component({
  selector: 'lib-retiro-tienda',
  standalone: true,
  imports: [],
  templateUrl: './retiro-tienda.component.html',
  styleUrl: './retiro-tienda.component.css'
})
export class RetiroTiendaComponent {

}
