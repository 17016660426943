import { Injectable, inject } from '@angular/core';
import { items } from '../class/cart';
import { ManejadorEventosService } from './manejador-eventos.service';
import { ItemCar, ItemCompra } from '../class/carro-compras';
import { SioServicesService } from './sio-services.service';
import { LoadingService } from './loading.service';
import { UserService } from './user.service';
import { ExpressService } from './express.service';

@Injectable({
  providedIn: 'root'
})

export class ShoppingCartService {

  private listItemsCompra: ItemCompra[] = [];
  cart: ItemCar[] = [];
  lastItem: items = new items();

  public manejadorEventos = inject(ManejadorEventosService);
  public sioService = inject(SioServicesService);
  public userService = inject(UserService);
  public expressService = inject(ExpressService);
  public load = inject(LoadingService);

  // ==============================================
  // Este metodo añade al carrito de compras
  //  el item que se selecciono
  // ==============================================
  addItemCart(item: ItemCar) {

    const login = this.userService.userLogueado()
    // console.log(login);

    // ==============================================
    // Se accede al carrito de compras que se tiene en el localStorage
    // ==============================================
    if (typeof localStorage !== 'undefined') {
      let shoppingCartaux = localStorage.getItem("shoppingCart") == undefined ? null : localStorage.getItem("shoppingCart")
      if (shoppingCartaux) {
        this.cart = JSON.parse(shoppingCartaux);
        if (!this.cart.find((i) => (i.idProducto === item.idProducto && i.tallaId === item.tallaId))) {
          // =================================================
          // No se encontro el item que se quiere agregar al
          // carrito en el listado que ya existe, se agrega al
          //  final del array
          // =================================================
          // localStorage.removeItem("shoppingCart")
          if (login) {
            this.expressService.añadirItemCarrito(this.userService.user.correoElectronico, item).then((res) => {
              // console.log(res.carro);
              localStorage.setItem("shoppingCart", JSON.stringify(res.carro))
            }).catch((error) => {
              this.cart.push(item)
              localStorage.setItem("shoppingCart", JSON.stringify(this.cart));
            })
          } else {
            this.cart.push(item)
            localStorage.setItem("shoppingCart", JSON.stringify(this.cart));
          }
          this.manejadorEventos.lanzarEvento('addItemCart', item);
        } else {
          // =========================================================
          // El producto ya esta agregado en el carrito de compras
          // y se valida si se aumenta la cantidad del item o sobrepasa
          //  la disponibilidad
          // =========================================================
          let i = this.cart.findIndex((i) => (i.idProducto === item.idProducto && i.tallaId === item.tallaId))
          // console.log(i);
          if (item.cantidad + this.cart[i].cantidad < this.cart[i].maxCantidad) {
            this.manejadorEventos.lanzarEvento('addItemCart', item)
            // localStorage.removeItem("shoppingCart")
            if (login) {
              this.expressService.añadirItemCarrito(this.userService.user.correoElectronico, item).then((res) => {
                localStorage.setItem("shoppingCart", JSON.stringify(res.carro))
              }).catch((error) => {
                this.cart[i].cantidad += item.cantidad
                localStorage.setItem("shoppingCart", JSON.stringify(this.cart));
              })
            } else {
              this.cart[i].cantidad += item.cantidad
              localStorage.setItem("shoppingCart", JSON.stringify(this.cart));
            }
          } else {
            // =========================================================
            // La cantidad que se quiere agregar del item sobrepasa la cantidad disponible,
            //  por lo que no se aumenta la cantidad en el carrito de compras
            // =========================================================

            // Falta generar el evento de que no fue posible agregar las unidades al carrito de compras
            console.log('Se supera la disponibiliad maxima');
          }
        }
      } else {
        // =========================================================
        // No existe carrito de compras en el localStorage,
        // se agrega directamente y se guarda en el local storage
        // =========================================================
        console.log('add');
        // Debo ajustar el lanzador de enventos
        this.manejadorEventos.lanzarEvento('addItemCart', item)
        if (login) {
          this.expressService.añadirItemCarrito(this.userService.user.correoElectronico, item).then((res) => {
            // console.log(res.carro);
            // localStorage.removeItem("shoppingCart")
            localStorage.setItem("shoppingCart", JSON.stringify(res.carro))
          }).catch((error) => {
            this.cart.push(item)
            localStorage.setItem("shoppingCart", JSON.stringify(this.cart))
          })
        } else {
          this.cart.push(item)
          localStorage.setItem("shoppingCart", JSON.stringify(this.cart))
        }
      }
    }
  }

  deleteItemCart(i: number, cantidad: number = 0) {
    const login = this.userService.userLogueado();

    if (typeof localStorage !== 'undefined') {
      // localStorage.removeItem("shoppingCart")
      if (login) {
        this.expressService.eliminarItemCarrito(this.userService.user.correoElectronico, this.cart[i].idProducto, cantidad).then((res) => {
          console.log(res.carro);
          localStorage.setItem("shoppingCart", JSON.stringify(res.carro));
        }).catch((error) => {
          this.cart.splice(i, 1)
          localStorage.setItem("shoppingCart", JSON.stringify(this.cart));
        })
      } else {
        this.cart.splice(i, 1)
        localStorage.setItem("shoppingCart", JSON.stringify(this.cart));
      }
    }

  }

  getTotalProducts(): number {
    return this.getCart().length;
  }

  getCart(): ItemCar[] {
    if (typeof localStorage !== 'undefined') {
      if (!localStorage.getItem("shoppingCart")) {
        return []
      }
      let aux : any = localStorage.getItem("shoppingCart")
      aux = JSON.parse(aux || '')
      if (aux) {
        // console.log(aux);
        this.cart = aux
      }
    }
    return this.cart
  }

  cleanCart() {
    if (typeof localStorage !== 'undefined') {
      this.cart = []
      localStorage.setItem("shoppingCart", JSON.stringify(this.cart));
    }
  }

  getCarritoCompra(): ItemCompra[] {
    return this.listItemsCompra;
  }

  saveCarritoCompra(items: ItemCompra[]) {
    this.listItemsCompra = items;
  }

  sincronizarCart() {

  }
}
