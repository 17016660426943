import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { Anillo, ConfigService, ExpressService, Item, ItemCar, JsonTextService, LoadingService, SioServicesService, TextResponse, caracteristicas, favorite, items } from 'libreria-tienda';
import { FavoritoCorazonComponent } from 'libreria-tienda/sfa/components/favorito-corazon/favorito-corazon.component';
import { EtiquetaDescuentoComponent } from 'libreria-tienda/shared/etiqueta-descuento/etiqueta-descuento.component';
import { TallasComponent } from 'libreria-tienda/shared/tallas/tallas.component';

@Component({
  selector: 'lib-pop-up-talla',
  standalone: true,
  imports: [CommonModule, TallasComponent, FavoritoCorazonComponent, MatDialogClose, EtiquetaDescuentoComponent, MatDividerModule],
  templateUrl: './pop-up-talla.component.html',
  styleUrl: './pop-up-talla.component.css'
})
export class PopUpTallaComponent implements OnInit {

  jsonDetalle: TextResponse[]

  isFav: boolean = false;
  canti: number = 0;

  submited: boolean = false;
  selectSize: boolean = true;

  indexProducto: number = 0;


  caracteristicas: caracteristicas[];
  caracteristicasItem: caracteristicas[] = [];

  itemAnillo: Anillo = new Anillo();
  item: Item = new Item();
  // caracteristicas: caracteristicas[];

  private favorite: favorite[] = [];

  caracteristicasFull: any;

  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)
  public sioservice = inject(SioServicesService)
  public load = inject(LoadingService)
  public expressService = inject(ExpressService)

  constructor(public dialogRef: MatDialogRef<PopUpTallaComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.jsonService.downloadJson(this.configService._config.URLJSONDETALLE).subscribe(res => {
      this.jsonDetalle = res;
      this.caracteristicasFull = this.getName("caracteristicas")
    })
    this.infoProducto(this.data.info.idProducto)
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  infoProducto(referencia: string) {
    this.sioservice.GetExistenciaProductoAnillo(referencia).then((info) => {
      // console.log(info);
      this.load.cargar(false)
      this.item.idProducto = info.valor.idProducto;
      this.item.cantidadDisponible = 0;
      this.item.catalogo = 'anillos'
      this.item.precioFull = info.valor.precioFull;
      this.item.precioAutorizado = info.valor.precioAutorizado;
      this.item.porcentaje = info.valor.porcentaje * 100;
      this.item.imagen = this.configService._config.URLBASEIMG + referencia + ".jpg";
      this.item.tipoProducto = 'Anillo';
      this.item.descripcion = info.valor.descripcionWeb
      this.item.caracteristicas = JSON.parse(info.valor.detalle);
      this.item.catalogo = info.valor.catalogo
      this.itemAnillo.TallaSeleccionada = "-";
      this.itemAnillo.CantidadSeleccionada = 0;
      this.itemAnillo.TallasCantidad = info.valor.existencia;

      if (this.favorite.length > 0) {
        if (this.favorite.find(it => it.idProducto == this.item.idProducto)) {
          this.isFav = true
        } else {
          this.isFav = false
        }
      }

      this.caracteristicasMostrar();

      // }
    });
  }

  talla(i: number) {
    this.selectSize = true
    this.indexProducto = i;
    this.itemAnillo.TallaSeleccionada = this.itemAnillo.TallasCantidad[i].talla;
    this.itemAnillo.TallaIdSeleccionada = this.itemAnillo.TallasCantidad[i].tallaId;
    this.itemAnillo.CantidadDisponibleSeleccionada = this.itemAnillo.TallasCantidad[i].cantidad;
    this.itemAnillo.CantidadSeleccionada = 1;
  }

  donwloadManual() {
    this.expressService.getPdf("Manual de tallas").subscribe(res => {
      window.open(this.configService._config.URLPDFTALLAS + res.file, '_blank');
    })
  }

  donwloadPolitics() {
    window.open(this.configService._config.URLPDFTERMINOS, '_blank');
  }

  agregar() {
    // console.log('agregar');
    let aux: ItemCar = new ItemCar();
    aux.cantidad = 1;
    aux.descTalla = this.itemAnillo.TallaSeleccionada;
    aux.descWeb = this.item.descripcion;
    aux.idProducto = this.item.idProducto;
    aux.imagen = this.item.imagen;
    aux.maxCantidad = this.itemAnillo.CantidadDisponibleSeleccionada;
    aux.tallaId = this.itemAnillo.TallaIdSeleccionada;
    this.dialogRef.close(aux);
  }

  caracteristicasMostrar() {
    // console.log(this.item.catalogo);
    // console.log(this.caracteristicasFull);

    // console.log(this.caracteristicasFull[this.item.catalogo]);
    // console.log(this.item.caracteristicas);

    this.caracteristicas = this.caracteristicasFull[this.item.catalogo]
    this.caracteristicasItem = this.caracteristicasFull[this.item.catalogo]
      .map((item: any) => {
        const valor = this.item.caracteristicas[item.db];
        return valor !== undefined ? { ...item, valor } : null;
      })
      .filter((item: any) => item !== null);
  }
}
