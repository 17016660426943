import { Component, ViewChild, inject } from '@angular/core';
import { BannersComponent } from 'libreria-tienda/shared/banners/banners.component';
import { ConfigService, ExpressService, JsonTextService, LoadingService, TextResponse } from 'libreria-tienda';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Vacante, filtroVacante } from 'libreria-tienda/src/lib/class/vacante';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { TrabajaNosotrosFormComponent } from 'libreria-tienda/shared/trabaja-nosotros-form/trabaja-nosotros-form.component';

@Component({
  selector: 'app-trabaja-nosotros',
  standalone: true,
  imports: [CommonModule, BannersComponent, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, MatDividerModule, MatSidenavModule, TrabajaNosotrosFormComponent],
  templateUrl: './trabaja-nosotros.component.html',
  styleUrl: './trabaja-nosotros.component.css'
})
export class TrabajaNosotrosComponent {

  vacantes: Array<Vacante> = new Array<Vacante>();
  vacantesOriginal: Array<Vacante> = new Array<Vacante>();
  vacan: Vacante = new Vacante()
  ciudades: Array<filtroVacante> = new Array<filtroVacante>()
  nombres: Array<filtroVacante> = new Array<filtroVacante>()
  fechas: Array<filtroVacante> = new Array<filtroVacante>()
  nombre = new FormControl<string | null>(null);
  ciudad = new FormControl<string | null>(null);
  fecha = new FormControl<string | null>(null);
  @ViewChild('snav') public sidenav: MatSidenav;
  isMobile: boolean = false;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  jsonWork: TextResponse[];

  jsonService = inject(JsonTextService)
  expressService = inject(ExpressService)
  configService = inject(ConfigService)
  load = inject(LoadingService)

  constructor(public dialogo: MatDialog) { }

  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLWORKJSON).subscribe(res => {
      this.jsonWork = res
    })

    if (screen.width < 1024) {
      this.isMobile = true
    }
    else {
      this.isMobile = false
    }
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      if (screen.width < 1024) {
        this.isMobile = true
      }
      else {
        this.isMobile = false
      }
    })

    this.expressService.getVacantes().subscribe(res => {
      this.vacantes = res.vacantes
      this.vacantesOriginal = res.vacantes;
      this.llenarDatos()
      this.load.cargar(false)
    })
  }
  llenarDatos() {
    this.vacantes.forEach(vac => {
      if (!this.ciudades.find(it => it.valor == vac.ciudad)) {
        this.ciudades.push({ valor: vac.ciudad, seleccionado: false })
      }
      if (!this.nombres.find(it => it.valor == vac.Nombre_Vacante)) {
        this.nombres.push({ valor: vac.Nombre_Vacante, seleccionado: false })
      }
      if (!this.fechas.find(it => it.valor == vac.Fecha_Publicacion)) {
        this.fechas.push({ valor: vac.Fecha_Publicacion, seleccionado: false })
      }
    })
  }
  nombreVacante(event: any) {
    this.nombres.map(nom => {
      if (nom.valor == event.value.valor)
        nom.seleccionado = true
      else
        nom.seleccionado = false
    })
  }
  ciudadVacante(event: any) {
    this.ciudades.map(ciu => {
      if (ciu.valor == event.value.valor)
        ciu.seleccionado = true
      else
        ciu.seleccionado = false
    })
  }
  fechaVacante(event: any) {
    this.fechas.map(fec => {
      if (fec.valor == event.value.valor)
        fec.seleccionado = true
      else
        fec.seleccionado = false
    })
  }
  eliminarFiltros() {
    this.nombres.map(nom => {
      nom.seleccionado = false
    })
    this.ciudades.map(ciu => {
      ciu.seleccionado = false
    })
    this.fechas.map(fec => {
      fec.seleccionado = false
    })
    this.nombre.reset()
    this.ciudad.reset()
    this.fecha.reset()

    this.vacantes = this.vacantesOriginal
  }
  filtrar() {
    this.vacantes = []
    this.vacantesOriginal.forEach(vac => {
      this.nombres.forEach(nom => {
        if (nom.valor == vac.Nombre_Vacante && nom.seleccionado)
          this.vacantes.push(vac)
      })
      this.ciudades.forEach(ciu => {
        if (ciu.valor == vac.ciudad && ciu.seleccionado)
          this.vacantes.push(vac)
      })
      this.fechas.forEach(fec => {
        if (fec.valor == vac.Fecha_Publicacion && fec.seleccionado)
          this.vacantes.push(vac)
      })
    })
    this.eliminarDuplicados()
  }
  aplicar(i: number) {
    this.vacan = this.vacantes[i]
    if (this.isMobile) {
      setTimeout(() => {
        this.openSideNav()
      }, 1000);
    }
    else {
      this.openDialogMessageForm(i)
    }
  }
  openDialogMessageForm(i: number): void {
    const dialogRef = this.dialogo.open(TrabajaNosotrosFormComponent, { data: { vacante: this.vacan }, panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res2 => {
    })
  }
  eliminarDuplicados(): void {
    const posicionesDuplicados = [];

    for (let i = 0; i < this.vacantes.length - 1; i++) {
      for (let j = i + 1; j < this.vacantes.length; j++) {
        if (this.objetosSonIguales(this.vacantes[i], this.vacantes[j])) {
          posicionesDuplicados.push(j);
        }
      }
    }

    // Eliminar objetos duplicados del vacantes original
    for (const posicion of posicionesDuplicados.reverse()) {
      this.vacantes.splice(posicion, 1);
    }
  }
  objetosSonIguales(obj1: any, obj2: any) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;

    }
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }
  openSideNav() {
    this.sidenav.open()
  }
  closeSnav(event: any) {
    if (event)
      this.sidenav.close()
  }

  getName(name: string) {
    return this.jsonWork?.find(x => x.Nombre == name)?.Valor
  }

}
