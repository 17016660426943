@if (min) {
<div class="slider">
  <div class="progress"> </div>
</div>
<div class="range-input">
  <input type="range" class="range-min range-input min" min="{{min}}" max="{{max}}" [(ngModel)]="seleccionadorMin"
    (change)="cambioRango()" (input)="onRangeInputMin($event)">
  <input type="range" class="range-max range-input max" min="{{min}}" max="{{max}}" [(ngModel)]="seleccionadorMax"
    (change)="cambioRango()" (input)="onRangeInputMax($event)">
</div>
}
