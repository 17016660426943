import { Component, Input, OnInit, inject } from '@angular/core';
import { Banner, ExpressService, LoadingService, TextResponse } from 'libreria-tienda';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var propiedades: any|undefined; // ver script javascript en el index.html
@Component({
  selector: 'lib-banner-home',
  standalone: true,
  imports: [ NgxSkeletonLoaderModule],
  templateUrl: './banner-home.component.html',
  styleUrl: './banner-home.component.css'
})
export class BannerHomeComponent implements OnInit {

  propiedades: any;

  @Input() section: string;

  banners: Banner[];

  version = new Date().getTime();

  isMobile = this.deviceService.isMobile();


  public expressService = inject(ExpressService)
  public load = inject(LoadingService)

  jsonSliders: TextResponse[];

  constructor(private deviceService: DeviceDetectorService) {
    this.propiedades=propiedades;
   }

  ngOnInit() {
    this.expressService.getBanners(this.section).subscribe(res => {
      this.banners = res.banners
      this.banners = this.banners.reverse()
    })
  }
}
