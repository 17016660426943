@if (load) {
  <div class="container-loader">
    <div class="diamondCon">
        <ul class="diamond">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
  </div>
}
