<div class="center">
  <mat-icon class="dorado point size-flechas" (click)="back()">arrow_back_ios</mat-icon>
  @if (pagina > 3) {
  <span class="px_3 size-number cursor-none">. . .</span>
  }
  @for (pagina of paginasMostrar; track $index) {
  <span [ngClass]="{diamante: pagina.select, size_diamante: pagina.id == 1}">
    <span class="px_3 size-number point" [ngClass]="{color_number: pagina.select}"
      (click)="pageSelect(pagina.id)">{{pagina.id}}</span>
  </span>
  }
  @if (pagina < max - 2) { <span class="px_3 size-number cursor-none">. . .</span>
    }
    <mat-icon class="dorado point size-flechas" (click)="next()">arrow_forward_ios</mat-icon>
</div>
