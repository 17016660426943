import { Injectable, inject } from '@angular/core';
import { favorite } from '../class/product';
import { userlogged } from '../class/user';
import { ExpressService } from './express.service';
import { SioServicesService } from './sio-services.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritoService {

  private favoritos: favorite[];
  private oldFavoritos: favorite[] = [];
  user: userlogged = new userlogged();
  login: boolean = false;

  expressService = inject(ExpressService)
  sioServicesService = inject(SioServicesService)
  userService = inject(UserService)
  constructor() {
    this.favoritos = this.getFavLocal()
  }

  estado(producto: favorite): boolean {
    if (this.buscarFav(producto)) {
      if (producto.esGia) {
        this.eliminarGIA(producto.referenciaProveedor)
      } else {
        this.eliminar(producto.idProducto)
      }
      return false
    } else {
      this.agregar(producto)
      return true;
    }
  }

  guardarFav() {
    this.favoritos = this.getFavLocal();

    // console.log(this.login);

    if (this.login) {
      // console.log(this.oldFavoritos, this.favoritos);
      // console.log(!this.sonArraysIguales(this.oldFavoritos, this.favoritos));

      if (!this.sonArraysIguales(this.oldFavoritos, this.favoritos)) {
        console.log('No son iguales');
        // Se debe sincronizar con la nube
        this.userService.sincronizarUserNube()
      }
    }
  }

  agregar(producto: favorite) {
    this.favoritos.push(producto);
    this.actualizarStorage();
  }

  eliminar(id: string) {
    this.favoritos = this.favoritos.filter(objeto => objeto.idProducto !== id);
    this.actualizarStorage();
  }

  eliminarGIA(id: string) {
    this.favoritos = this.favoritos.filter(objeto => objeto.referenciaProveedor !== id);
    this.actualizarStorage();
  }

  actualizarStorage() {
    this.getFavLocal()
    this.user.favoritos = this.favoritos;
    // console.log(this.favoritos);
    if (localStorage.getItem("logged") != undefined) {
      let logget = JSON.parse(localStorage.getItem("logged") || "")
      if (logget.login) {
        localStorage.removeItem("userLogged");
        localStorage.setItem("userLogged", JSON.stringify(this.user));
      } else {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(this.user));
      }
    } else {
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(this.user));
    }
  }

  buscarFav(_producto: favorite): boolean {
    // Verifica si this.favoritos es un array válido
    if (!Array.isArray(this.favoritos)) {
      console.error('Error: this.favoritos no es un array válido');
      return false;
    }

    // Usar Array.some() para verificar si existe un objeto que cumpla la condición
    if (_producto.esGia) {
      // Verifica si hay algún objeto con la misma referencia del proveedor
      return this.favoritos.some(objeto =>
        objeto.referenciaProveedor === _producto.referenciaProveedor && objeto.peso === _producto.peso
      );
    } else {
      // Verifica si hay algún objeto con el mismo id del producto
      return this.favoritos.some(objeto => objeto.idProducto === _producto.idProducto);
    }
  }

  sonArraysIguales(array1: any[], array2: any[]): boolean {
    // Primero, verifica si los arrays tienen la misma longitud
    if (array1.length !== array2.length) {
      return false;
    }

    // Función para comparar dos objetos de forma profunda
    const compararObjetos = (obj1: any, obj2: any): boolean => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    // Verifica que cada objeto en array1 tenga un objeto igual en array2
    const todosLosObjetosEstanEnArray2 = array1.every(objeto1 =>
      array2.some(objeto2 => compararObjetos(objeto1, objeto2))
    );

    // Verifica que cada objeto en array2 tenga un objeto igual en array1
    const todosLosObjetosEstanEnArray1 = array2.every(objeto2 =>
      array1.some(objeto1 => compararObjetos(objeto2, objeto1))
    );

    // Si ambos arrays contienen exactamente los mismos objetos, devuelve true
    return todosLosObjetosEstanEnArray2 && todosLosObjetosEstanEnArray1;
  }

  combinarArrays(array1: favorite[], array2: favorite[]): favorite[] {
    const mapaUnicos = new Map<string | number, favorite>();

    const agregarAlMapa = (objeto: favorite): void => {
      const id = objeto.esGia ? objeto.referenciaProveedor : objeto.idProducto;
      if (!mapaUnicos.has(id)) {
        mapaUnicos.set(id, objeto);
      }
    };

    array1.forEach(objeto => agregarAlMapa(objeto));

    array2.forEach(objeto => agregarAlMapa(objeto));
    return Array.from(mapaUnicos.values());
  }

  getFav(): Promise<favorite[]> {
    return new Promise((resolve, reject) => {
      this.favoritos = this.getFavLocal()
      resolve(this.favoritos)
    });
  }

  getFavLocal(): any[] {
    let aux = this.userService.getInfoLocal('fav');
    this.user = this.userService.getUserLocal()
    return aux;
  }
}
