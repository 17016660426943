<div class="d-none d-sm-none d-md-none d-lg-block">
  <div class="">
    <span class="reference">Ref. {{item.idProducto}}</span>
  </div>
  @if (item.porcentaje !== 0) {
  <div>
    <span class="price price-full">$ {{item.precioFull | number}}</span>
  </div>
  }
  <div class="d-flex-row">
    <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">$ {{item.precioAutorizado |
      number}}</span>
    @if (item.porcentaje !== 0) {
    <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
    }
  </div>
  @if (item.porcentaje > 30 && item.porcentaje !==0) {
  <span>Precio exclusivo para ventas online</span>
  }

  <lib-descripcion-caracteristicas [caracteristicas]="caracteristicasMostrarObj" [descripcion]="item.descripcion" />

  <div class="d-flex-row gap pt-3">
    <!-- <lib-tallas [tallas]="tallas" [tallaSelectd]="item.talla || '-'" [submited]="submited" [codTallas]="codTallas" -->
    @if (itemAnillo) {
    <lib-tallas [submited]="submited" [tallas]="itemAnillo.TallasCantidad" (ItemData)="talla($event)"></lib-tallas>
    }
  </div>
  <div class="d-flex-row d-flex-center pt-2">
    <span class="icon-ring font-size-16"></span>
    <span (click)="donwloadManual()" class="manual cursor-pointer pl-05rem">{{getName('manual-tallas')}}</span>
  </div>
  <div class="d-flex-column pt-3">
    <span class="cant pb-2">{{getName('cant')}}</span>
    <div class="d-flex-row">
      <button class="button-addremove" (click)="menos()">
        <mat-icon>remove</mat-icon>
      </button>
      @if (itemAnillo) {
      <input type="number" id="cant" #cant
        value="{{ itemAnillo.CantidadSeleccionada == 0 ? '-': itemAnillo.CantidadSeleccionada}}" min=1 name="" id=""
        placeholder="Cantidad" class="input-class input-cant" (keydown)="handleKeydown($event)" readonly>
      }
      <button class="button-addremove" (click)="mas()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  @if (itemAnillo) {
  @if (itemAnillo.TallaSeleccionada == '-' && submited || !selectSize) {
  <mat-error class="pt-3 d-flex-center">
    <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla
      para tu producto.</span>
  </mat-error>
  }
  }
  <mat-error class="pt-3 d-flex-center" *ngIf="unico && submited">
    <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por
      favor
      Comunicate con servicio al cliente</span>
  </mat-error>
  @if (complementos) {
  <div class="pt-3">
    <span class="cant pb-2">¿ Te gustaría completar tú compra ? </span>
    @if (complementos.length <=3) { <div class="d-flex-row pt-3">
      @for (item of complementos; track $index) {
      <lib-complemento [info]="item" class="pl-1rem" (selectComple)="complemtoSele($index, $event)" />
      }
  </div>
  }@else {
  <lib-complemento-carrusel [productos]="complementos" (selectComple)="complemtoSeleCarrusel($event)" />
  }
</div>
}
<div class="d-flex-column gap pt-3">
  @if (itemAnillo.TallasCantidad.length !== 1) {
    <div class="d-flex-row d-flex-center">
      <button class="button-cart" type="submit" (click)="agregarCarrito()">{{getName('but-cart')}}
        <span class="icon-shoppingCart icon-size icon-color-b"></span></button>
    </div>
  }@else {
    <div class="contenedor-experto">
      <mat-icon class="dorado">error_outline</mat-icon>
      <span>
        Producto único en la compañía. Por favor comunícate con nuestras expertas al WhatsApp <span (click)="openChat()" class="link_whatsapp">3186338303</span>
      </span>
    </div>
    <button (click)="openChat()" class="btn-experto">{{getName('gia-but')}}</button>
  }
  <div>
    <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('entrega')"> </span>
    <span class="link" [innerHTML]="getName('entrega_link')"></span>
  </div>
  <div>
    <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('devoluciones')"> </span>
    <span class="link" [innerHTML]="getName('devoluciones_link')"></span>
  </div>
  <div class="iconos-sociales">
    <!-- <lib-favorito-corazon [esGia]="false" [idProducto]="item.idProducto" [peso]="0"
      [referenciaProveedor]="''" /> -->
    <i class="fa-brands fa-facebook-f iconos-sociales-tamaño"></i>
    <i class="fa-brands fa-pinterest-p iconos-sociales-tamaño"></i>
    <i class="fa-brands fa-whatsapp iconos-sociales-tamaño"></i>
  </div>
</div>
</div>

<!-- Mobile -->

<div class="d-block d-sm-block d-md-block d-lg-none espacioooooooooooooo">
  <div class="d-flex-column">
    <span class="reference pl-2rem pl-5rem pt-3">Ref. {{item.idProducto}}</span>
    <!-- <span class="price pl-2rem pl-5rem">$ {{item.precioAutorizado | number}}</span> -->
    @if (item.porcentaje !== 0) {
    <div class="pl-2rem pl-5rem">
      <span class="price price-full">$ {{item.precioFull | number}}</span>
    </div>
    }
    <div class="d-flex-row pl-2rem pl-5rem">
      <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">
        $ {{item.precioAutorizado | number}}
      </span>
      @if (item.porcentaje !== 0) {
      <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
      }
    </div>
    <span class="characteristics pl-2rem pl-5rem pt-3">{{getName('cara')}}</span>
    <div>
      <mat-accordion class="pt-3">
        <mat-expansion-panel hideToggle="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="no-wrap pl-4rem">{{getName('mas-detalles')}}</span>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon class="color-black" *ngIf="!open ">add</mat-icon>
              <mat-icon class="color-black" *ngIf="open">remove</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-divider></mat-divider>
      </mat-accordion>
    </div>
    <span (click)="donwloadPolitics()" class="politics pl-2rem cursor-pointer  pt-3"
      [innerHTML]="getName('politics')"></span>
    <div class="pr-1rem pl-2rem pl-5rem pt-3">
      <div>
      </div>
      <div class="d-flex-row d-flex-center pt-3">
        <span class="icon-ring font-size-20"></span>
        <span (click)="donwloadManual()" class="manual pl-1rem cursor-pointer">{{getName('manual-tallas')}}</span>
      </div>
    </div>
    <div class="d-flex-row space-eve pl-2rem pl-5rem pt-3">
      <div class="d-flex-row">
        <button class="button-addremove" (click)="menos()">
          <mat-icon class="font-size-21">remove</mat-icon>
        </button>
        <input type="number" id="cant" #cant value="{{ item.cantidad }}" min=1 name="" id="" placeholder="Cant"
          class="input-class" (keydown)="handleKeydown($event)" readonly>
        <button class="button-addremove" (click)="mas()">
          <mat-icon class="font-size-21">add</mat-icon>
        </button>
      </div>
      <div class="d-flex-row d-flex-center">
        <button class="button-cart" type="submit" (click)="agregarCarrito()">{{getName('but-cart')}}
          <span class="icon-shoppingCart icon-size icon-color-b"></span></button>
      </div>
      <div>
        <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('entrega')"> </span>
        <span class="link" [innerHTML]="getName('entrega_link')"></span>
      </div>
      <div>
        <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('devoluciones')">
        </span>
        <span class="link" [innerHTML]="getName('devoluciones_link')"></span>
      </div>
      <div class="iconos-sociales">
        <!-- <lib-favorito-corazon [esGia]="false" [idProducto]="item.idProducto" [peso]="0"
          [referenciaProveedor]="''" /> -->
        <i class="fa-brands fa-facebook-f iconos-sociales-tamaño"></i>
        <i class="fa-brands fa-pinterest-p iconos-sociales-tamaño"></i>
        <i class="fa-brands fa-whatsapp iconos-sociales-tamaño"></i>
      </div>
    </div>
    @if (complementos) {
    <div class="pt-3">
      <span class="cant pb-2">¿ Te gustaría completar tú compra ? </span>
      @if (complementos.length <=3) { <div class="d-flex-row pt-3">
        @for (item of complementos; track $index) {
        <lib-complemento [info]="item" class="pl-1rem" (selectComple)="complemtoSele($index, $event)" />
        }
    </div>
    }@else {
    <lib-complemento-carrusel [productos]="complementos" (selectComple)="complemtoSeleCarrusel($event)" />
    }
  </div>
  }
  <!-- @if (itemAnillo.TallaSeleccionada == '-' && submited) {
  <mat-error class="pt-3 d-flex-center pl-2rem pl-5rem pr-2rem">
    <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla para tu
      producto.</span>
  </mat-error>
  } -->
  <mat-error class="pt-3 d-flex-center pl-2rem pl-5rem pr-2rem" *ngIf="unico && submited">
    <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por favor
      Comunicate con servicio al cliente</span>
  </mat-error>
</div>

</div>
