<lib-banners [catalogo]="'compraOro'" [noEncontrado]="false" />
<div class="contenedor">
  <div class="contenedor-textos">
    <h2>Importante: El valor por gramo es actualizado diariamente a la 1:00pm debido a la fluctuación
      en la cotización internacional del metal, que nos sirve como referencia.</h2>
    <span class="texto-inicial">Ten en cuenta que tenemos la presencia de un técnico joyero que certifique el gramaje de
      tu oro permanente en algunas de nuestros puntos de venta. Por esta razón, antes de dirigirte a una de nuestras
      tiendas, por favor comunícate a los números de contacto con el fin de programar una cita en la fecha y hora que se
      ajuste a tus necesidades. Para la compra de oro es necesario que presentes la cédula física y copia de RUT con
      fecha de impresión no mayor a 30 días.</span>
    <div class="contenedor-precios-kilates">
      @for (precios of precioOro; track $index) {
      <div class="precios-kilates">
        <span class="kilates">{{precios.texto}}</span>
        <span class="precios">${{precios.precio | number}}</span>
      </div>
      }
    </div>
    <div class="contenedor-cita">
      <div class="columna-uno columna">
        <div class="contenedor-formulario">
          <form [formGroup]="formularioCita">

            <!-- Ciudad -->
            <div class="contenedor-campo">
              <label for="ciudad" class="label">Ciudades*</label>
              <select id="ciudad" formControlName="ciudad" placeholder>
                <option value="">Seleccione una ciudad</option>
                @for (city of listCities; track $index) {
                <option [value]="city">{{city | titlecase}}</option>
                }
              </select>
              <div *ngIf="formularioCita.get('ciudad')?.invalid && formularioCita.get('ciudad')?.touched">
                <small>Debe seleccionar una ciudad.</small>
              </div>
            </div>

            @if (formularioCita.get('ciudad')?.value !== '') {
            <!-- Tienda -->
            <div class="contenedor-campo">
              <label for="tienda" class="label">Tienda*</label>
              <select id="tienda" formControlName="tienda" placeholder>
                @if (storesCiudad.length>1) {
                <option value="">Seleccione una tienda</option>
                }
                @for (store of storesCiudad; track $index) {
                <option [value]="store.nombre">{{store.nombre}}</option>
                }
              </select>
              <div *ngIf="formularioCita.get('tienda')?.invalid && formularioCita.get('tienda')?.touched">
                <small>Debe seleccionar una tienda.</small>
              </div>
            </div>
            }
            <!-- Nombre Completo -->
            <div class="contenedor-campo">
              <label for="nombre" class="label">Nombre*</label>
              <input id="nombre" type="text" formControlName="nombre" placeholder="Escriba su nombre completo"
                class="custom-input" [disabled]="true">
              <div *ngIf="formularioCita.get('nombre')?.invalid && formularioCita.get('nombre')?.touched">
                <small *ngIf="formularioCita.get('nombre')?.errors?.['required']">El nombre es
                  requerido.</small>
                <small *ngIf="formularioCita.get('nombre')?.errors?.['minlength']">El nombre debe tener al
                  menos 3
                  caracteres.</small>
                <small *ngIf="formularioCita.get('nombre')?.errors?.['pattern']">Solo se permiten letras y
                  espacios.</small>
              </div>
            </div>

            <!-- Apellido -->
            <div class="contenedor-campo">
              <label for="apellido" class="label">Apellido*</label>
              <input id="apellido" type="text" formControlName="apellido" placeholder="Escriba su apellido completo"
                class="custom-input">
              <div *ngIf="formularioCita.get('apellido')?.invalid && formularioCita.get('apellido')?.touched">
                <small *ngIf="formularioCita.get('apellido')?.errors?.['required']">El apellido es
                  requerido.</small>
                <small *ngIf="formularioCita.get('apellido')?.errors?.['minlength']">El apellido debe tener al
                  menos 3
                  caracteres.</small>
                <small *ngIf="formularioCita.get('apellido')?.errors?.['pattern']">Solo se permiten letras y
                  espacios.</small>
              </div>
            </div>

            <!-- Celular -->
            <div class="contenedor-campo">
              <label for="celular" class="label">Celular*</label>
              <input id="celular" type="text" formControlName="celular" placeholder="Escribe tu numero de celular"
                class="custom-input">
              <div *ngIf="formularioCita.get('celular')?.invalid && formularioCita.get('celular')?.touched">
                <small *ngIf="formularioCita.get('celular')?.errors?.['required']">El número de celular es
                  requerido.</small>
                <small *ngIf="formularioCita.get('celular')?.errors?.['pattern']">Debe contener entre 7 y 10
                  dígitos
                  numéricos.</small>
              </div>
            </div>

            <!-- Correo -->
            <div class="contenedor-campo">
              <label for="correo" class="label">Correo Electrónico*</label>
              <input id="correo" type="email" formControlName="correo" placeholder="Escribe tu correo electrónico"
                class="custom-input">
              <div *ngIf="formularioCita.get('correo')?.invalid && formularioCita.get('correo')?.touched">
                <small *ngIf="formularioCita.get('correo')?.errors?.['required']">El correo es requerido.</small>
                <small *ngIf="formularioCita.get('correo')?.errors?.['email']">Debe ser un correo electrónico
                  válido.</small>
              </div>
            </div>

            <!-- Botón de envío -->
            <!-- <div class="enviar">
             <button type="submit" [disabled]="formularioCita.invalid">Enviar</button>
           </div> -->
          </form>
        </div>

      </div>
      <div class="columna-dos columna">
        <div class="contenedor-calendario">

        </div>
      </div>
    </div>
    <div class="contenedor-politicas">
      <span class="politicas">Políticas de Compras</span>
      <span class="compra">Compra de oro puro o lingotes.
        Para la compra de oro puro o lingotes debe presentar, en cualquiera de nuestras joyerías, los siguientes documentos:</span>
      <ul class="compra">
        <li>Certificado de origen del oro.</li>
        <li>Título minero.</li>
        <li>RUT con fecha de impresión no mayor a 30 días.</li>
        <li>Cámara de Comercio no mayor a 30 días.</li>
        <li>Estados Financieros Firmados y certificados.</li>
        <li>Declaración de Renta de los 2 últimos años.</li>
      </ul>
    </div>
  </div>

</div>
