<div class="pt-3 pb-3" [ngClass]="{'pt-0-p' : (isMobile && section == 2)}">
  <h2 class="titles pl-35px">{{quienRecibe}}</h2>
  <div class="d-flex-column">
    <div class="pr-1 pl-35px pb-3">
      <label class="checkbox-container">
        <input type="checkbox" class="pr-1" [(ngModel)]="isReceiveByMe" (change)="onChangeRecibido($event)">{{recibeYo}}
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="pr-1 pl-35px">
      <label class="checkbox-container">
        <input type="checkbox" class="pr-1" [(ngModel)]="isReceiveByOther" [checked]="isReceiveByOther"
          (change)="onChangeRecibido2($event)">{{recibeOtro}}
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
  <mat-expansion-panel [expanded]="isReceiveByOther" disabled="true">
    <form [formGroup]="formDomicilio" action="">
      <div class="row-column-responsive-2 gap-r gap-3">
        <div class="row-column-responsive-2 space-bet gap-r gap-3">
          <input formControlName="nombres" class="text-fields" type="text" placeholder="Nombres">
          <div class="pt-r-1 w-100">
            <input formControlName="apellidos" class="text-fields" type="text" placeholder="Apellidos">
          </div>
        </div>
        <div class="d-flex-row pt-r-1 space-around-r gap-r">
          <div class="w-30">
            <mat-form-field appearance="fill">
              <mat-select formControlName="tipoDocumento" (selectionChange)="tipoDoc($event)" placeholder="C.C" required
                class="pl-15 pr-15px">
                <mat-option *ngFor="let doc of listDocuments" [value]="doc">
                  {{doc.nombre | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="pl-r-1 w-70">
            <input formControlName="numeroDocumento" class="text-fields" type="text" placeholder="Numero de documento">
          </div>
        </div>
      </div>
      <div class="w-30 w-70 pt-3">
        <button (click)="saveInfo(_section)" class="button-primary">{{guardar}}</button>
      </div>
    </form>
  </mat-expansion-panel>
</div>
