import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService, ImgResponse, JsonTextService, LoadingService, TextResponse } from 'libreria-tienda';

@Component({
  selector: 'lib-categories',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.css'
})
export class CategoriesComponent implements OnInit {

  version = new Date().getTime();

  jsonCategories: TextResponse[]
  jsonImgCategories: any
  jsonImgCategoriesMobile: any
  jsonService = inject(JsonTextService)
  configService = inject(ConfigService)
  load = inject(LoadingService)

  constructor(private router: Router) { }

  colores:string[]=['red','blue','orange','yellow','black']

  ngOnInit() {
    // this.load.cargar(false)
    this.jsonService.downloadJson(this.configService._config.URLJSONCATEGORIES).subscribe(res => {
      this.jsonCategories = res;
      this.jsonImgCategories = this.getName('categorias');
      this.jsonImgCategoriesMobile = this.getName('categorias-mobile');
    })
  }

  getName(name: string) {
    return this.jsonCategories?.find(x => x.Nombre == name)?.Valor
  }

  redirect(url: string) {
    // console.log(`/` + url);
    this.router.navigateByUrl(`/${url}`)
  }

}
