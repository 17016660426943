import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Product } from 'libreria-tienda';

@Component({
  selector: 'lib-card-dije-venezia',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './card-dije-venezia.component.html',
  styleUrl: './card-dije-venezia.component.css'
})
export class CardDijeVeneziaComponent {
  @Input() articulo: Product;
}
