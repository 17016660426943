import { Component, EventEmitter, Output, inject } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ConfigService, JsonTextService, ShoppingCartService, TextResponse } from 'libreria-tienda';
import { Router } from '@angular/router';

declare var propiedades: any | undefined;

@Component({
  selector: 'lib-header-mobile',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatIconModule],
  templateUrl: './header-mobile.component.html',
  styleUrl: './header-mobile.component.css'
})
export class HeaderMobileComponent {

  propiedades: any;


  @Output() evento = new EventEmitter<string>();

  protected totalProducts: number = 0;

  private headerJson: TextResponse[];

  protected logo: any;

  private rutaActual: string;

  private cartService = inject(ShoppingCartService)

  constructor(private router: Router) {
    this.propiedades = propiedades;
   }

  ngOnInit(): void {
    // this.jsonService.downloadJson(this.configService._config.URLHEADERJSON).subscribe(res => {
    //   this.headerJson = res;
    // })
  }

  ngAfterContentChecked() {
    this.totalProducts = this.cartService.getTotalProducts()
  }

  protected getName(name: string) {
    return this.headerJson?.find(x => x.Nombre == name)?.Valor
  }

  protected EventoOpen(val: string) {
    this.evento.emit(val)
  }

  protected redirectToHome() {
    this.rutaActual = this.router.url
    if (this.rutaActual != "/home") {
      this.router.navigate(['/home']).then(() => {
        window.location.reload();
      });
    }
  }

  redirect(page: string) {
    this.router.navigate(['/' + page]).then(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    });
  }

}
