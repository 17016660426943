import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { ControlSesionService, GetCabeceras, ManejadorEventosService, PantallaSaleService, ShoppingCartService, SioServicesService, User } from 'libreria-tienda';
import { LoginComponent } from '../header/login/login.component';

declare var propiedades: any | undefined;

@Component({
  selector: 'lib-menu-izq',
  standalone: true,
  imports: [MatListModule, CommonModule, MatExpansionModule, MatIconModule, LoginComponent],
  templateUrl: './menu-izq.component.html',
  styleUrl: './menu-izq.component.css'
})
export class MenuIzqComponent implements OnInit {
  propiedades: any

  sale: boolean;


  menuItems: GetCabeceras;
  open: boolean = false;
  public sioservice = inject(SioServicesService)
  public manejadorEventos = inject(ManejadorEventosService)

  private pantallaSaleService = inject(PantallaSaleService)

  private cartService = inject(ShoppingCartService)


  protected totalProducts: number = 0;

  private rutaActual: string;

  constructor(private router: Router) {
    this.propiedades = propiedades;
  }

  ngOnInit(): void {
    this.pantallaSaleService.estado$.subscribe(valor => {
      this.sale = valor;
    });
  }

  redirect(filtro: string) {
    if (filtro !== 'regalos') {
      this.manejadorEventos.lanzarEvento('goCatalogo')
      // this.router.navigate([`/catalogo`], { queryParams: { filtro: filtro, catalogo: catalogo } }).then(() => {
      this.router.navigate([`/catalogo/${filtro}`]).then(() => {
        window.location.reload();
      });
    }
  }


  redirectFiltros(nextParam: string, filtros: string, titulo: string) {
    this.manejadorEventos.lanzarEvento('goCatalogo')
    // this.router.navigate([`/catalogo`], { queryParams: { filtro: filtro, catalogo: catalogo } }).then(() => {
    if (titulo == "Regalos para ...") {
      this.redirect(filtros)
    } else {
      this.router.navigate(['/catalogo', nextParam], {
        queryParams: { filtros: filtros }
      }).then(() => {
        window.location.reload();
      });
    }
  }

  onPanelClosed() {
    this.open = false;
  }

  onPanelOpened() {
    this.open = true;
  }

  protected redirectToHome() {
    this.rutaActual = this.router.url
    if (this.rutaActual != "/home") {
      this.router.navigate(['/home']).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    }
  }

  ngAfterContentChecked() {
    this.totalProducts = this.cartService.getTotalProducts()
  }

  navigate(ruta: string) {
    this.router.navigate([`/${ruta}`]).then(() => {
      window.scrollTo(0, 0);
    });
  }

}
