import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Product, items } from 'libreria-tienda/src/public-api';
import { CardProductComponent } from '../card-product/card-product.component';
import { CardProductCarruselComponent } from '../card-product/card-product-carrusel/card-product-carrusel.component';

@Component({
  selector: 'lib-slider-logo',
  standalone: true,
  imports: [CommonModule, CardProductComponent, CardProductCarruselComponent],
  templateUrl: './slider-logo.component.html',
  styleUrl: './slider-logo.component.css',
})
export class SliderLogoComponent implements OnInit {

  @Input() productos: Product[];
  @Input() categoria: string;


  @ViewChild('carousel') carousel!: ElementRef;

  old: number;
  hoveredIndex: number | null = null;

  ngOnInit(): void {
    this.startAutoPlay()
  }

  autoPlayTimer: any;

  scrollCarousel(direction: string): void {
    const carouselElement = this.carousel.nativeElement as HTMLElement;
    const firstCardWidth = (carouselElement.querySelector('.card') as HTMLElement)?.offsetWidth || 0;
    const scrollAmount = direction === 'left' ? -firstCardWidth : firstCardWidth;
    // console.log(carouselElement.scrollLeft);
    if (direction == 'right') {
      if (((carouselElement.scrollLeft - this.old) < firstCardWidth) && carouselElement.scrollLeft !== 0) {
        carouselElement.scrollLeft = 0
      } else {
        carouselElement.scrollLeft += scrollAmount;
        this.old = carouselElement.scrollLeft
      }
    } else {
      if (carouselElement.scrollLeft == 0) {
        carouselElement.scrollLeft = carouselElement.scrollWidth
      } else {
        carouselElement.scrollLeft -= firstCardWidth
      }
    }

  }

  stopAutoPlay(): void {
    clearTimeout(this.autoPlayTimer);
  }

  startAutoPlay(): void {
    this.autoPlayTimer = setInterval(() => this.scrollCarousel('right'), 2500);
  }

  onHover(index: number): void {
    this.hoveredIndex = index;
  }

  onLeave(): void {
    this.hoveredIndex = null;
  }
}
