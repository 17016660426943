import { Component } from '@angular/core';

@Component({
  selector: 'lib-inconformidad',
  standalone: true,
  imports: [],
  templateUrl: './inconformidad.component.html',
  styleUrl: './inconformidad.component.css'
})
export class InconformidadComponent {

}
