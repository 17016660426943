import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ConfigService } from 'libreria-tienda';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { EscritorioComponent } from './plantillas/escritorio/escritorio.component';
import { MobileComponent } from './plantillas/mobile/mobile.component';
import { SalesforceChatComponent } from "./salesforce-chat.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, EscritorioComponent, MobileComponent, SalesforceChatComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {



  ngOnInit(): void {
    this.configService.insercionVariable(environment)
  }

  title = 'web-tienda';

  isMobile = this.deviceService.isMobile();
  isTablet = this.deviceService.isTablet();
  isDesktop = this.deviceService.isDesktop();

  private configService = inject(ConfigService)

  constructor(private router: Router, private deviceService: DeviceDetectorService) { }
}
