import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ConfigService, ItemCar, Product, ShoppingCartService, SioServicesService } from 'libreria-tienda';
import { NumeroPuntoPipe } from 'libreria-tienda';

@Component({
  selector: 'lib-carrusel-img-card',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatSelectModule, NumeroPuntoPipe],
  templateUrl: './carrusel-img-card.component.html',
  styleUrl: './carrusel-img-card.component.css'
})
export class CarruselImgCardComponent {

  _referencia: string;
  @Input() set referencia(value: string) {
    this._referencia = value;
    this.crearUrlImg();
  }

  @Input() producto: Product;
  @Input() manejaTalla: boolean;
  @Input() cantidadMaxima: number;


  @Output() seleccionado = new EventEmitter<boolean>();
  @Output() abiertoSeleccionador = new EventEmitter<boolean>();

  public configService = inject(ConfigService)
  public sioservice = inject(SioServicesService)
  public cartService = inject(ShoppingCartService)

  letras: string[] = ['E', 'D', 'B', 'C']
  imagenes: string[] = [];
  imagenesSinError: string[] = [];

  tallas: any[] = []
  tallaSelect: boolean = false
  unico: boolean = false
  itemCard: ItemCar = new ItemCar();

  baseUrlImg: string = this.configService._config.URLBASEIMG;

  count: number = 0;


  fadeClass: string = '';




  crearUrlImg() {
    for (let i = 0; i < this.letras.length; i++) {
      if (i == 2) {
        this.imagenes.push(this.baseUrlImg + this._referencia + '.jpg');
      }
      this.imagenes.push(this.baseUrlImg + this._referencia + '_' + this.letras[i] + '.jpg');
    }
    this.verifyImage()
  }

  verifyImage() {
    for (let i = 0; i < this.imagenes.length; i++) {
      const img = new Image();
      img.src = this.imagenes[i];
      img.onload = () => {
        this.imagenesSinError.push(this.imagenes[i])
      };
      img.onerror = () => { };
    }
  }

  mas() {
    if (this.imagenesSinError.length > 1) {
      this.fadeClass = 'fade-out';
      setTimeout(() => {
        if (this.count + 1 < this.imagenesSinError.length) {
          this.count++;
        } else {
          this.count = 0;
        }
        this.fadeClass = 'fade-in';
      }, 250);

      setTimeout(() => {
        this.fadeClass = '';
      }, 500);
    }
  }

  menos() {
    if (this.imagenesSinError.length > 1) {
      this.fadeClass = 'fade-out';
      setTimeout(() => {
        if (this.count == 0) {
          this.count = this.imagenesSinError.length - 1;
        } else {
          this.count--;
        }
        this.fadeClass = 'fade-in';
      }, 250);

      setTimeout(() => {
        this.fadeClass = '';
      }, 500);
    }
  }

  detalle() {
    if (this.tallas[0] === undefined) {
      this.seleccionado.emit(true);
    }
  }

  consultarTallas() {
    // console.log(this.manejaTalla);
    if (this.manejaTalla) {
      if (this.tallaSelect) {
        // console.log(this.itemCard);
        this.agregarCarrito(this.itemCard)
      } else {
        this.sioservice.getTallasArticulo(this._referencia).then((resultado) => {
          // console.log(resultado);
          if (resultado.exitoso) {
            this.tallas = resultado.valor
            // console.log(this.tallas.length);
          }
        }).catch((error) => {
          console.log(error);
        })
      }

    } else {
      let aux: ItemCar = new ItemCar();
      aux.idProducto = this._referencia;
      aux.tallaId = -1;
      aux.cantidad = 1;
      aux.imagen = this.baseUrlImg + this._referencia + '.jpg';
      aux.maxCantidad = this.cantidadMaxima;
      aux.descWeb = this.producto.descripcionWeb;
      aux.descTalla = '';
      this.agregarCarrito(aux)
    }

  }

  agregarCarrito(item: ItemCar) {
    this.cartService.addItemCart(item);
  }

  tallaSeleccionada(e: any) {
    // console.log(e);
    this.itemCard.cantidad = 1;
    this.itemCard.idProducto = this._referencia;
    this.itemCard.imagen = this.baseUrlImg + this._referencia + '.jpg';
    this.itemCard.maxCantidad = e.value.cantidad;
    this.itemCard.tallaId = e.value.tallaId;
    this.itemCard.descWeb = this.producto.descripcionWeb;
    this.itemCard.descTalla = e.value.descripcion;
    // console.log(this.itemCard);


    this.tallaSelect = true
  }

  onSelectOpened(isOpened: boolean) {
    if (isOpened) {
      // console.log("El menú de selección se ha abierto");
      this.abiertoSeleccionador.emit(true);
    } else {
      this.abiertoSeleccionador.emit(false);
      // console.log("El menú de selección se ha cerrado");
    }
  }

}
