<div class="contenedor">
  <div class="contenedor-iconos">
    <div class="contendor-perfil">
      <a class="align-center" (click)="navigate('joyerias')">
        <span class="icon-Localizacion size-wa icon-color"></span>
        <span class="text-banner">Ubica una Joyería</span>
      </a>
    </div>
    <div class="contendor-perfil">
      <lib-login />
      <span class="text-banner">Perfil</span>
    </div>
    <div class="contendor-perfil">
      <a class="align-center">
        <span class="icon-Garantia_icono size-wa icon-color"></span>
        <span class="text-banner">Certificados</span>
      </a>
    </div>
  </div>
  <div class="buscador">
    <input type="text" placeholder="Buscar" class="buscador-in">
  </div>
  <div class="banner-des">
    <div class="color-banner" (click)="redirectFiltros('sale','salDes¡30%25-379','' )">
      <span class="text-tiempo">solo por hoy | </span>
      <span class="text-des">30% off</span>
      <span class="text-ref">en ref. seleccionadas</span>
    </div>
  </div>
  <div class="contenedor-menu">
    <mat-accordion [multi]="false">
      @for (menu of propiedades['menu']; track $index) {
      <mat-expansion-panel class="bg-gray" (opened)="onPanelOpened()" (closed)="onPanelClosed()" hideToggle="true">
        <mat-expansion-panel-header [ngClass]="{'aplicar-borde': open}" class="bg-gray">
          <mat-panel-title>
            <!-- <span class="no-wrap">{{getName('caract')}}</span> -->
            <div class="contendor-categoria">
              <div class="contendor-nombre-categoria">
                <span class="menu-uno" [innerHTML]="menu.name" (click)="redirect(menu.filtro)"
                  [ngClass]="{'menu-uno-sale':sale}"></span>
              </div>
              <div class="contendor-flechas">
                @if (open) {
                <i class="fa-solid fa-angle-up dorado"></i>
                }@else {
                <i class="fa-solid fa-angle-down dorado"></i>
                }
              </div>

            </div>
          </mat-panel-title>
          <!-- <mat-panel-description>
        </mat-panel-description> -->
        </mat-expansion-panel-header>
        @for (titulos of menu.contenido; track $index) {
        <div class="titulos">
          {{titulos.title}}
        </div>
        <ul>
          @for (opciones of titulos.contenido; track $index) {
          <li>
            <div class="opcion">
              <a (click)="redirectFiltros(menu.filtro, opciones.filtro, titulos.title);" class="sangria-contenedor">
                @if (opciones.icon) {
                <img [src]="opciones.icon" alt="" class="icon-anillo" [ngClass]="{'icon-oros': (titulos.title == 'Metal' && $index <= 2),
                'icon-oros2': (titulos.title == 'Metal' && $index > 2 && $index <= 4),
                'icon-oros3': (titulos.title == 'Metal' && $index > 4)}">
                }
                <span [ngClass]="{'ml-2':titulos.title == 'Metal' && $index <= 2}" class="menu-tres">
                  {{opciones.text}}
                </span>
              </a>
            </div>
          </li>
          }
        </ul>
        }

          <div (click)="redirect(menu.filtro)" class="contenedor-frase">
            <span class="frase">{{menu.frase}}</span>
            <mat-icon class="dorado">chevron_right</mat-icon>
          </div>
      </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
  <div class="contenedor-contactanos">

    <h3 class="mayus">contáctanos</h3>
    <div class="contactanos">
      <div class="chat">
        <div class="circulo">
          <i class="fa-brands fa-whatsapp color-contac-icon"></i>
        </div>
        <span>chat</span>
      </div>
      <div class="llamar">
        <div class="circulo">
          <i class="fa-solid fa-phone color-contac-icon"></i>
        </div>
        <span>llamar</span>
      </div>
      <div class="email">
        <div class="circulo">
          <i class="fa-regular fa-envelope color-contac-icon"></i>
        </div>
        <span>email</span>
      </div>
    </div>
  </div>

  <div class="contenedor-copy">
    <span>kevin´s joyeros© 2024 | todos los derechos reservados</span>
  </div>
</div>
