import { ApplicationConfig, Injectable, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClientModule } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { authInterceptorProviders } from 'libreria-tienda/src/lib/helpers/auth.interceptor';
import { ConfigService } from 'libreria-tienda';


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
  provideClientHydration(),
  provideAnimations(),
  importProvidersFrom(HttpClientModule, DeviceDetectorService, ConfigService),
  {
    provide: ConfigService,
    useFactory() {
      const value = new ConfigService();
      value.setConfig(environment);
      return value;
    },
  }, authInterceptorProviders, { provide: MAT_DIALOG_DATA, useValue: {} },
  ]
};
