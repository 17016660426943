<div class="contenedor">
  <h2 class="title">lo último en kevin´s</h2>
  <p class="parrafo">Si quieres se el primero en conocer los nuevos lanzamientos, las inauguraciones de tiendas y mucho
    más; Suscríbete
    a nuestro boletín:</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="formulario">
        <!-- Campo Nombre Completo -->
        <div class="campo uno">
          <input type="text" placeholder="Nombre Completo" formControlName="nombre" class="nombre">
          <div *ngIf="form.get('nombre')?.invalid && form.get('nombre')?.touched" class="error">
            <small *ngIf="form.get('nombre')?.hasError('required')">El nombre es obligatorio.</small>
          </div>
        </div>

        <div class="fila-dos">
          <!-- Campo Fecha de Nacimiento -->
          <div class="campo dos">
            <input type="date" formControlName="fechaNacimiento" class="fecha" placeholder="Fecha de nacimiento">
            <div *ngIf="form.get('fechaNacimiento')?.invalid && form.get('fechaNacimiento')?.touched" class="error">
              <small *ngIf="form.get('fechaNacimiento')?.hasError('required')">La fecha de nacimiento es obligatoria.</small>
            </div>
          </div>

          <!-- Campo Estado -->
          <div class="campo tres">
            <mat-form-field appearance="fill" class="ancho-selec estado">
              <mat-select placeholder="Estado Civil" formControlName="estado">
                <mat-option *ngFor="let estado of estados" [value]="estado">
                  {{ estado | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="form.get('estado')?.invalid && form.get('estado')?.touched" class="error">
              <small *ngIf="form.get('estado')?.hasError('required')">El estado es obligatorio.</small>
            </div>
          </div>
        </div>

        <div class="fila-tres">
          <!-- Campo Género -->
          <div class="campo cuatro">
            <mat-form-field appearance="fill" class="ancho-selec genero">
              <mat-select placeholder="Selecciona tu género" formControlName="genero">
                <mat-option *ngFor="let genero of generos" [value]="genero">
                  {{ genero | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="form.get('genero')?.invalid && form.get('genero')?.touched" class="error">
              <small *ngIf="form.get('genero')?.hasError('required')">El género es obligatorio.</small>
            </div>
          </div>

          <!-- Campo Email -->
          <div class="campo cinco">
            <input type="email" placeholder="Ingresa tu correo electrónico" formControlName="email" class="email">
            <div *ngIf="form.get('email')?.invalid && form.get('email')?.touched" class="error">
              <small *ngIf="form.get('email')?.hasError('required')">El correo electrónico es obligatorio.</small>
              <small *ngIf="form.get('email')?.hasError('email')">Por favor, ingresa un correo electrónico válido.</small>
            </div>
          </div>
        </div>

        <!-- Checkbox de Aceptación de Políticas -->
        <div class="uno">
          <input type="checkbox" formControlName="aceptarPoliticas">
          <span>Autorizo el tratamiento de mis datos personales conforme a la política de tratamiento de datos personales</span>
          <div *ngIf="form.get('aceptarPoliticas')?.invalid && form.get('aceptarPoliticas')?.touched" class="error">
            <small>Debes aceptar las políticas para continuar.</small>
          </div>
        </div>
      </div>

      <!-- Botón Enviar -->
      <button type="submit" [disabled]="form.invalid" class="boton-enviar">SUSCRÍBETE</button>
    </form>



</div>
