@if (jsonDetalle.length>0) {
<div class="contenedor">
  <h1 class="titulo" [innerHTML]="getName('titulo')"></h1>
  <span class="texto-principal" [innerHTML]="getName('texto_p')"></span>
  <div class="acordeon">
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            1. Acerca de nosotros
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="link" [innerHTML]="getName('nosotros')"></span>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            2. Marco legal
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="contenedor-textos" [innerHTML]="getName('marco_legal')"></span>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            3. Responsable del tratamiento de datos personales
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="contenedor-textos" [innerHTML]="getName('responsable')"></span>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            4. Definiciones
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="textos-iterados">
          @for (item of getName('definiciones'); track $index) {
          <li class="textos-iterados" [innerHTML]="item"></li>
          }
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            5. Tratamiento al que serán sometidos los datos personales
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="contenedor-textos" [innerHTML]="tratamiento_sometidos.text_principal"></span><br><br>
        <span [innerHTML]="tratamiento_sometidos.cliente.titulo"></span>
        <ol>
          @for (item of tratamiento_sometidos.cliente.datos; track $index) {
          <li class="textos-iterados" [innerHTML]="item"></li>
          }
        </ol>
        <span [innerHTML]="tratamiento_sometidos.otros.titulo"></span>
        <ol>
          @for (item of tratamiento_sometidos.otros.datos; track $index) {
          <li class="textos-iterados" [innerHTML]="item"></li>
          }
        </ol>
        <span [innerHTML]="tratamiento_sometidos.nota"></span>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            6. Identificación de las bases de datos y periodo de vigencia.
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ol>
          @for (item of identificacion_db.dbs; track $index) {
          <li [innerHTML]="item.titulo" class="textos-iterados"></li>
          <ul>
            @for (dato of item.datos; track $index) {
            <li [innerHTML]="dato" class="textos-iterados"></li>
            }
          </ul>
          }
        </ol>
        <span [innerHTML]="identificacion_db.aux_texto"></span>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            7. Derechos de los titulares.
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="contenedor-textos" [innerHTML]="derechos.text_principal"></span>
        <ol>
          @for (item of derechos.datos; track $index) {
            <li [innerHTML]="item" class="textos-iterados"></li>
          }
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            8. Procedimiento de consultas y reclamos.
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="contenedor-textos">
          <span [innerHTML]="procedimiento.texto_uno"></span>
          <ol>
            @for (item of procedimiento.datos_uno; track $index) {
              <li class="textos-iterados" [innerHTML]="item"></li>
            }
          </ol>
          <span [innerHTML]="procedimiento.texto_dos"></span>
          <ol>
            @for (item of procedimiento.datos_dos; track $index) {
              <li class="textos-iterados" [innerHTML]="item"></li>
            }
          </ol>
          <span [innerHTML]="procedimiento.texto_tres"></span>
          <ul>
            @for (item of procedimiento.datos_tres; track $index) {
              <li class="textos-iterados" [innerHTML]="item"></li>
            }
          </ul>
          <span [innerHTML]="procedimiento.aux_texto"></span>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            9. Transferencia Internacional de Datos Personales.
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="contenedor-textos" [innerHTML]="getName('transferencia')"></span>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            10. Vigencia y actualización de las políticas.
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span class="contenedor-textos" [innerHTML]="getName('vigencia')"></span>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
}
