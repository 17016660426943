import { Component, OnInit, inject } from '@angular/core';
import { FavoritosComponent } from 'libreria-tienda/crm/cliente/favoritos/favoritos.component';
import { MenuCatalogoComponent } from 'libreria-tienda/shared/catalogo/menu-catalogo/menu-catalogo.component';
import { LoadingService } from 'libreria-tienda';
import { BannersComponent } from 'libreria-tienda/shared/banners/banners.component';

@Component({
  selector: 'app-favoritos-catalogo',
  standalone: true,
  imports: [MenuCatalogoComponent, FavoritosComponent,BannersComponent],
  templateUrl: './favoritos-catalogo.component.html',
  styleUrl: './favoritos-catalogo.component.css'
})
export class FavoritosCatalogoComponent implements OnInit {

  changeColumns: any

  load = inject(LoadingService)

  ngOnInit(): void {
    this.load.cargar(false)
  }

  cambioCol(e: any) {
    // console.log(e);
    this.changeColumns = e
  }

}
