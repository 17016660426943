import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { BuyServiceService, ControlSesionService, ItemCar, ItemCompra, LoadingService, ManejadorEventosService, userlogged, UserService } from 'libreria-tienda';
import { Cabecera, Cart, ConfigService, ExpressService, JsonTextService, OrdenDeVenta, ShoppingCartService, SioServicesService, TextResponse, User, productoMP } from 'libreria-tienda';
import { TipoEnvio } from 'libreria-tienda';
import { AddressService } from 'libreria-tienda';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { MessageBoxComponent } from '../domicilio/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { TipoCompraComponent } from './tipo-compra/tipo-compra.component';
import { NumeroPuntoPipe } from 'libreria-tienda';

@Component({
  selector: 'lib-resumen',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatPseudoCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    NumeroPuntoPipe
  ],
  templateUrl: './resumen.component.html',
  styleUrl: './resumen.component.css'
})
export class ResumenComponent implements OnInit {

  @Input() section: number;
  @Input() envio: number = 1
  @Input() carrito: ItemCompra[] = [];

  jsonResumen: TextResponse[];
  costoEnvio: number = 10;
  subtotal: number = 0;

  productos: productoMP[] = new Array<productoMP>();
  ordenVenta: OrdenDeVenta = new OrdenDeVenta();
  cabeceraOrden: Cabecera = new Cabecera()

  user: User = new User();
  userLog: userlogged = new userlogged();

  messageError: string = "";

  validForm: boolean = false;
  shop: boolean = false;
  isCheckBoxSelected: boolean = true;
  submited: boolean = false;

  shoppingCart: boolean
  listItemsShoppingCart: ItemCar[] = [];
  listItemsCompra: ItemCompra[] = [];

  datosEnvio: TipoEnvio

  public load = inject(LoadingService);
  public sioservice = inject(SioServicesService);
  public jsonService = inject(JsonTextService);
  public buyService = inject(BuyServiceService);
  public addresService = inject(AddressService);
  public expressService = inject(ExpressService);
  public cartService = inject(ShoppingCartService);
  public configService = inject(ConfigService);
  public manejadorEventos = inject(ManejadorEventosService);
  public userService = inject(UserService);
  public sesionService = inject(ControlSesionService);

  constructor(private router: Router, public dialogo: MatDialog) { }

  // ngOnInit(): void {
  //   if (typeof localStorage !== 'undefined') {
  //     if (localStorage.getItem("shoppingCart") != undefined) {
  //       this.shoppingCart = JSON.parse(localStorage.getItem("shoppingCart") || "")
  //       for (const id of this.shoppingCart.items) {
  //         this.sioservice.GetProductoExistenciasCiaByIdCommand(id.codigo).subscribe(result => {
  //           id.precioAutorizado = result.infoPrecio[0].precioAutorizado
  //           id.precioFull = result.infoPrecio[0].precioFull
  //         }, error => {
  //           console.log(error)
  //         })
  //       }
  //     }
  //   }
  // this.jsonService.downloadJson(this.configService._config.URLRESUMENJSON).subscribe(res=> {
  //   this.jsonResumen = res
  // })
  //   this.buyService.newFormValid.subscribe(valid => {
  //     this.TipoEnvio = this.buyService.getSendType()
  //     this.validForm = valid
  //   })
  //   this.buyService.messageInvalid.subscribe(mess => {
  //     this.messageError = mess
  //   })
  //   this.shoppingCart.items.forEach(element => {
  //     this.subtotal += parseFloat(element.precioAutorizado)
  //   });
  //   this.addresService.getDirectionCost().subscribe(res => {
  //     this.getCosto(this.subtotal, res)
  //   })
  // }

  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLRESUMENJSON).subscribe(res => {
      this.jsonResumen = res;
    })
    if (this.section == 2) {
      this.addresService.getDirectionCost().subscribe(res => {
        this.getCosto(this.subtotal, res)
      });
    }
  }

  getName(name: string) {
    return this.jsonResumen?.find(x => x.Nombre == name)?.Valor
  }

  totalFull() {
    let total = 0
    this.carrito.forEach(element => {
      total += element.precioFull * element.cantidadSeleccionada
    });
    return total
  }

  totalAutorizado() {
    let total = 0
    this.carrito.forEach(element => {
      total += element.precioAutorizado * element.cantidadSeleccionada
    });
    this.subtotal = total;
    return this.subtotal;
  }

  subTotalDescuento() {
    let total = this.totalFull();
    let totalDesc = this.totalAutorizado();
    return total - totalDesc
  }

  getCosto(valorCompra: number, nombreCiudad: string) {
    this.expressService.getCostoEnvio(valorCompra, nombreCiudad).subscribe((res: any) => {
      this.costoEnvio = res.res;
      console.log(this.costoEnvio);
    })
  }

  total() {
    return this.costoEnvio + this.totalAutorizado()
  }

  goBuy() {
    this.router.navigate(['home'])
  }

  async pagar() {
    this.load.cargar(true);
    this.buyService.newBuyMethod(true);
    this.submited = true;
    if (this.userService.userLogueado()) {
      this.datosEnvio = this.buyService.sendType;
      console.log('datosEnvio', this.datosEnvio);
      this.ordenVenta.InfoEntrega = this.datosEnvio;
      this.ordenVenta.Detalle = this.carrito;


      // Acá va la petición de la información completa para la compra
      this.userLog = this.userService.getUserLocal();

      await this.userService.getUser().then(res => {
        this.user = res;
        console.log('usuario', res);
      }).catch(error => {
        this.sesionService.cerrarSesion();
      });
      // this.cartToOrderProducts()
      console.log('ItemCompra', this.carrito);

      await this.setCabeceraOrdenVenta();
      console.log(this.ordenVenta);

      // if (this.validForm) {
      this.expressService.getPreferenceId(this.user.correoElectronico, this.ordenVenta).then(res => {
        console.log(res);
        location.href = res.init

      }).catch(error => {
        console.log(error);
        if (error.statusText == "Unauthorized" || error.error.message == "No token provided!") {
          // pop uptime, error inicia sesión
          this.sesionService.cerrarSesion();
        }
      })

      // .subscribe({
      //   next: (res) => {
      //     location.href = res.init
      //   }, error: (error) => {
      //   }
      // })
      // }
      // else {
      //   console.log("No tiene direccion")
      // }
    } else {
      this.router.navigate(["/login"], {
        queryParams: {
          shopping: true,
        },
      })
    }
  }

  async comprar() {
    // this.load.cargar(true);
    this.cartService.saveCarritoCompra(this.carrito);
    if (this.userService.userLogueado()) {
      await this.userService.getUser().then((res) => {
        if (res.correoElectronico !== undefined) {
          this.router.navigate(["/shippings"]);
        } else {
          this.router.navigate(['/login']).then(() => {
            window.scrollTo(0, 0);
          });
        }
      }).catch(() => {
        console.log('error user.getUser');
        this.router.navigate(['/login']).then(() => {
          window.scrollTo(0, 0);
        });
      });
    } else {
      console.log('No esta logueado');
      this.openDialogMessage('Es una prueba', 'Hola', 5)
    }


  }

  openDialogMessage(titulo: string, mensaje: string, tipo: number): void {
    const dialogRef = this.dialogo.open(TipoCompraComponent, { data: { title: titulo, msg: mensaje, tipo: tipo } })

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result.valor == 'login') {
        this.router.navigate(["/login"], {
          queryParams: {
            shopping: true,
          },
        })
      } else {
        this.router.navigate(["/compra-rapida"])
      }
    });
  }

  cartToOrderProducts() {
    this.ordenVenta.Detalle = [];
    this.carrito.forEach(item => {
    })
  }

  setCabeceraOrdenVenta() {
    let fecha = new Date + '';
    this.cabeceraOrden.FechaSistema = fecha;
    this.cabeceraOrden.IdCliente = this.user.id;
    this.cabeceraOrden.IdCarroCompras = this.user.carro;
    this.cabeceraOrden.Nombres = this.user.nombre;
    this.cabeceraOrden.Apellidos = this.user.apellido;
    this.cabeceraOrden.TipoDocumento = this.user.tipoDocumento;
    this.cabeceraOrden.NumeroDocumento = this.user.numeroDocumento;
    this.cabeceraOrden.Telefono = this.user.celular;
    this.cabeceraOrden.correoelectronico = this.user.correoElectronico;
    this.cabeceraOrden.ValorEnvio = this.costoEnvio;
    this.cabeceraOrden.ValorItems = this.subtotal;
    this.cabeceraOrden.ValorTotal = this.subtotal + this.costoEnvio;

    this.ordenVenta.Cabecera = this.cabeceraOrden

    // console.log('cabeceraOrden', this.cabeceraOrden);


    // this.cabeceraOrden.Apellido2 = ""
    // this.cabeceraOrden.Nombre2 = ""
    // this.cabeceraOrden.Apellido1 = this.user.apellido
    // this.cabeceraOrden.DescuentoAdicional = 0
    // this.cabeceraOrden.FechaSistema = new Date()
    // this.cabeceraOrden.IdCcosto = "30"
    // // por si se envia
    // if (this.tipoEnvio.metodo != "RETIRO EN TIENDA")
    //   this.cabeceraOrden.DireccionEnvio = this.user.direcciones[0].direccion
    // else
    //   this.cabeceraOrden.DireccionEnvio = ""
    // this.cabeceraOrden.IdDepartamento = this.tipoEnvio.idDepto || ""
    // this.cabeceraOrden.IdMunicipio = parseFloat(this.tipoEnvio.idMunicipio || "0") || 0
    // // por si se recoge
    // this.cabeceraOrden.IdCcostoEntrega = this.tipoEnvio.idCentroCosto || "-1"
    // this.cabeceraOrden.IdCliente = this.user.numeroDocumento
    // this.cabeceraOrden.IdOrdenDeVentaCab = 0
    // this.cabeceraOrden.Nombre1 = this.user.nombre
    // // id mercado pago
    // this.cabeceraOrden.NumeroDeOperacion = ""
    // this.cabeceraOrden.PrefijoApellido = ""
    // this.cabeceraOrden.RazonSocial = this.user.nombre + " " + this.user.apellido
    // this.cabeceraOrden.Recargo = 0
    // id que esta en cosmos
    // this.cabeceraOrden.Telefono = this.user.celular
    // console.log(this.user.tipoDocumento);
    // console.log('--------------------------------------------------------------------------------------------------');
    // this.cabeceraOrden.TipoDocumento = this.user.tipoDocumento;
    // this.cabeceraOrden.correoelectronico = this.user.correoElectronico
    // throw new Error('Acaaaaaaaaaaaaaaaaaa');
    // if (this.envio == -1)
    //   this.cabeceraOrden.ValorEnvio = this.costoEnvio
    // else
    //   this.cabeceraOrden.ValorEnvio = 0
    // this.ordenVenta.Cabecera = this.cabeceraOrden;
  }

}
