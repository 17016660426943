import { items } from "./cart";
import { OrdenDeVenta, productoMP } from "./product";
import { TipoEnvio } from "./send-type";

export class Order {
  id: string;
  ordenDeVenta: OrdenDeVenta = new OrdenDeVenta();
  products: Array<items> = new Array<items>()
  productsMP: Array<productoMP> = new Array<productoMP>()
  tipoEnvio: TipoEnvio = new TipoEnvio()
  idOrdenDeVenta: string;
}

export class Factura {
  user: string;
  id: string;
  MP: MercadoPago = new MercadoPago();
  products: items[];
  ordenDeVenta: OrdenDeVenta = new OrdenDeVenta()
  idOrdenDeVenta: string;
  tipoEnvio: TipoEnvio = new TipoEnvio();
}
export class MercadoPago {
  date_approved: string;
  external_reference: string;
  status: string;
  shipping_amount: string;
}
export class listaCliente {
  nombre1: string;
  apellido1: string;
  idOperacionencabezado: string;
}

export class listaCuerpo {
  idProducto: string;
  descripcionProducto: string;
  cantidad: number;
  precioFullUnitario: number;
  precioFullTotal: number;
  idOperacionencabezado: number;
}
export class listaFormaPago {
  idOperacionencabezado: string;
  nombreFormaPago: string;
  valorPagado: string;
}

export class compraMercadoPago {
  order: Order = new Order();
}
