<div class="borde" (click)="redirect('experiencia-venezia')">
  <div class="contenedor">
    <img [src]="articulo.imagen" alt="" class="size-img">
    <div class="precio-contenedor">
      <span class="id-producto">{{articulo.idProducto}}</span>
      <div class="container-corazon">
        <span class="precio-articulo">${{articulo.precioFull| number}}</span>
        <lib-favorito-corazon [size]="'heart-size2'"  class="fav" />
      </div>
    </div>
  </div>
</div>
