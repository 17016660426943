import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { CartRightComponent } from 'libreria-tienda/shopping-cart/cart-right/cart-right.component';
import { MenuIzqComponent } from '../menu-izq/menu-izq.component';
import { DispositivoService } from 'libreria-tienda';

@Component({
  selector: 'lib-side-bar',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, CartRightComponent, MenuIzqComponent],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.css'
})
export class SideBarComponent {

  @ViewChild('drawerDerecha') drawerDerecha: MatDrawer;
  @ViewChild('drawerIzquierda') drawerIzquierda: MatDrawer;

  public dispositivoService = inject(DispositivoService)

  abrirDerecha() {
    if (this.drawerDerecha) {
      this.drawerDerecha.toggle();
      if (this.drawerIzquierda) {
        this.drawerIzquierda.close();
      }
    }
  }

  abrirIzquierda() {
    if (this.drawerIzquierda) {
      this.drawerIzquierda.toggle();
      if (this.drawerDerecha) {
        this.drawerDerecha.close();
      }
    }
  }

  abrirArriba() {
    console.log('Abriendo arriba');
  }

  cerrarIzquierda() {
    this.drawerIzquierda.close();
    console.log('I');

  }

  cerrarDerecha() {
    this.drawerDerecha.close();
    console.log('D');
  }

}
