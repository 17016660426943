<div class="menu-container" [ngClass]="{'menu-container-sale':sale}">
  @for (menu of propiedades['menu']; track $index) {
  <div class="dropdown pr-2">
    <div class="text-container-dos">
      <!-- Menu principal -->
      <div class="text-container highlight-text">
        <!-- <span class="menu-uno" [innerHTML]="menu.name" (click)="redirect(menu.filtro, menu.name)"></span> -->
        <span class="menu-uno" [innerHTML]="menu.name" (click)="redirect(menu.filtro)"
          [ngClass]="{'menu-uno-sale':sale}"></span>
        <mat-icon class="icon color-icon" [ngClass]="{'menu-uno-sale':sale}">expand_more</mat-icon>
        <mat-icon class="hover-icon color-icon" [ngClass]="{'menu-uno-sale':sale}">expand_less</mat-icon>
      </div>
    </div>
    <div class="dropdown-content">
      <div class="menu">
        @for (titulos of menu.contenido; track $index) {
        <!-- <div class="d-flex"> -->
        <div class="column" [ngClass]="{'regalos':titulos.title == 'Celebraciones'}">
          <div class="column-divider">
            <!-- Titulos Columnas -->
            <div class="titulo">
              {{titulos.title}}
            </div>
            <ul [ngClass]="{'columnas':titulos.contenido.length> 10}">
              <!-- {{titulos.contenido.length > 10}} -->
              @for (item of titulos.contenido; track $index) {
              <li>
                <div class="opciones" [ngClass]="{'sin-icono':item.icon == undefined}">
                  <!-- <a (click)="redirect(item.filtro, item.text);"> -->
                  <a (click)="redirectFiltros(menu.filtro, item.filtro, titulos.title);" class="sangria-contenedor">
                    @if (item.icon) {
                    <img [src]="item.icon" alt="" class="icon-anillo" [ngClass]="{'icon-oros': (titulos.title == 'Metal' && $index <= 2),
                      'icon-oros2': (titulos.title == 'Metal' && $index > 2 && $index <= 4),
                      'icon-oros3': (titulos.title == 'Metal' && $index > 4)}">
                    }
                    <span [ngClass]="{'ml-2':titulos.title == 'Metal' && $index <= 2}" class="menu-tres">
                      {{item.text}}
                    </span>
                  </a>
                </div>
              </li>
              }
            </ul>
          </div>
          <!-- </div> -->
          @if ($index<2 && menu.contenido.length> 1) { <mat-divider [vertical]="true"></mat-divider> }
        </div>
        }
        @if (menu.image) {
        <div class="img-categoria">
          <a>
            <img [src]="menu.image" alt="" (click)="redirect(menu.filtro)" class="point">
            <div class="d-flex" (click)="redirect(menu.filtro)" class="point">
              <span class="frase">{{menu.frase}}</span>
              <mat-icon>chevron_right</mat-icon>
            </div>
          </a>
        </div>
        }
      </div>
    </div>
  </div>
  }
</div>
