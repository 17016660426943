<lib-banners [catalogo]="'trabaja con nosotros'"></lib-banners>
<div class="back-img d-flex-row pt-5 pb-5 pl-pr-100 center flex-start showpc">
  <div class="card-container d-flex-column p-3">
    <div class="d-flex-column pt-2 pb-2">
      <div class="d-flex-row pb-2">
        <span class="icon-Carnet font-size"></span>
        <span class="text pl-05rem">{{getName('title')}}</span>
      </div>
      <mat-form-field appearance="fill">
        <mat-select (selectionChange)="nombreVacante($event)" placeholder="Seleccione una vacante" required
          [formControl]="nombre">
          @for (nombre of nombres; track $index) {
          <mat-option [value]="nombre">
            {{nombre.valor | titlecase}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex-column pt-2 pb-2">
      <div class="d-flex-row pb-2">
        <span class="icon-Localizacion font-size"></span>
        <span class="text pl-05rem">{{getName('title-cities')}}</span>
      </div>
      <mat-form-field appearance="fill">
        <mat-select (selectionChange)="ciudadVacante($event)" placeholder="Seleccione una ciudad" required
          [formControl]="ciudad">
          @for (ciudad of ciudades; track $index) {
          <mat-option [value]="ciudad">
            {{ciudad.valor | titlecase}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex-column pt-2 pb-2">
      <div class="d-flex-row pb-2">
        <span class="icon-Calendario font-size"></span>
        <span class="text pl-05rem">{{getName('title-fechas')}}</span>
      </div>
      <mat-form-field appearance="fill">
        <mat-select (selectionChange)="fechaVacante($event)" placeholder="Seleccione una fecha" required
          [formControl]="fecha">
          @for (fecha of fechas; track $index) {
          <mat-option [value]="fecha">
            {{fecha.valor | date:'shortDate':'IST'}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pt-2 pb-2">
      <button class="button-primary" (click)="filtrar()">{{getName('search')}}</button>
    </div>
    <div class="d-flex-row center pt-1" (click)="eliminarFiltros()">
      <span class="icon-Eliminar pointer font-size"></span>
      <span class="pl-05rem eliminar pointer">{{getName('delete')}}</span>
    </div>
  </div>
  <div class="card-container ml-5rem">
    <div class="pl-2rem pt-3 pb-2">
      <span class="title">{{getName('vacantes-disponibles')}}</span>
    </div>
    <div class="d-flex-column">
      @for (vacan of vacantes; track $index) {
      <div class="d-flex-column">
        @if ($index==0) {
        <mat-divider></mat-divider>
        }
        <div class="d-flex-row pb-3 pt-3">
          <div class="d-flex-column pl-2rem w-10 center d-flex align-start">
            <span class="info">{{vacan.Nombre_Vacante | titlecase}}</span>
          </div>
          <div class="d-flex-column pl-2rem center d-flex">
            <span class="info">{{getName('publicado')}} {{vacan.Fecha_Publicacion | date:'shortDate':'IST'}}</span>
          </div>
          <div class="d-flex-column pl-2rem w-10c center d-flex">
            <span class="info">{{vacan.ciudad | titlecase}}</span>
          </div>
          <div class="pl-2rem pr-2rem center d-flex">
            <button class="button-secondary" (click)="aplicar($index)">{{getName('aplicar')}}</button>
          </div>
        </div>
        @if ($index != (vacantes.length -1)) {
        <mat-divider></mat-divider>
        }
      </div>
      }
    </div>
  </div>
</div>

<div class="d-flex-column showmob back-img">
  <div class="card-container d-flex-column p-3 m-3">
    <div class="d-flex-column pt-2 pb-2">
      <div class="d-flex-row pb-2">
        <span class="icon-Carnet font-size"></span>
        <span class="text pl-05rem">{{getName('title')}}</span>
      </div>
      <mat-form-field appearance="fill">
        <mat-select (selectionChange)="nombreVacante($event)" placeholder="Seleccione una vacante" required
          [formControl]="nombre">
          @for (nombre of nombres; track $index) {
          <mat-option [value]="nombre">
            {{nombre.valor | titlecase}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex-column pt-2 pb-2">
      <div class="d-flex-row pb-2">
        <span class="icon-Localizacion font-size"></span>
        <span class="text pl-05rem">{{getName('title-cities')}}</span>
      </div>
      <mat-form-field appearance="fill">
        <mat-select (selectionChange)="ciudadVacante($event)" placeholder="Seleccione una ciudad" required
          [formControl]="ciudad">
          @for (ciudad of ciudades; track $index) {
          <mat-option [value]="ciudad">
            {{ciudad.valor | titlecase}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex-column pt-2 pb-2">
      <div class="d-flex-row pb-2">
        <span class="icon-Calendario font-size"></span>
        <span class="text pl-05rem">{{getName('title-fecha')}}</span>
      </div>
      <mat-form-field appearance="fill">
        <mat-select (selectionChange)="fechaVacante($event)" placeholder="Seleccione una fecha" required
          [formControl]="fecha">
          @for (fecha of fechas; track $index) {
          <mat-option [value]="fecha">
            {{fecha.valor | date:'shortDate':'IST'}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pt-2 pb-2">
      <button class="button-primary" (click)="filtrar()">{{getName('search')}}</button>
    </div>
    <div class="d-flex-row center pt-1" (click)="eliminarFiltros()">
      <span class="icon-Eliminar pointer font-size"></span>
      <span class="pl-05rem eliminar pointer">{{getName('delete')}}</span>
    </div>
  </div>
  <div class="card-container m-3">
    <div class="pl-2rem pt-3 pb-2">
      <span class="title">{{getName('vacantes-disponibles')}}</span>
    </div>
    <div class="d-flex-column">
      @for (vacan of vacantes; track $index) {
      <div class="d-flex-column">
        <mat-divider *ngIf="$index == 0"></mat-divider>
        <div class="d-flex-column pl-2rem center d-flex align-start pt-3 pb-2">
          <span class="info font-size-va">{{vacan.Nombre_Vacante | titlecase}}</span>
        </div>
        <div class="d-flex-column pl-2rem center d-flex align-start">
          <span class="info">{{getName('publicado')}} {{vacan.Fecha_Publicacion | date:'shortDate':'IST'}}</span>
        </div>
        <div class="d-flex-column pl-2rem center d-flex align-start pb-3">
          <span class="info">{{vacan.ciudad | titlecase}}</span>
        </div>
        <div class="pl-2rem pr-2rem center d-flex align-start pb-3">
          <button class="button-secondary" (click)="aplicar($index)">{{getName('aplicar')}}</button>
        </div>
        @if ($index != (vacantes.length -1)) {
        <mat-divider></mat-divider>
        }
      </div>
      }
    </div>
  </div>
</div>
@if (vacan.Nombre_Vacante != undefined) {
<div>
  <mat-sidenav-container class="example-sidenav-container pos-static">
    <mat-sidenav class="side-work" #snav fixedTopGap="56" [mode]="'over'">
      <div class="w-100 flex-end pt-3">
        <span (click)="snav.toggle();" class="close-b">x</span>
      </div>
      <lib-trabaja-nosotros-form (closedEmitter)="closeSnav($event)" [_vacante]="vacan"
        [ismob]="isMobile"></lib-trabaja-nosotros-form>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
}
