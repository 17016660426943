<div class="d-none d-sm-none d-md-none d-lg-block back-img pl-pr-100 pt-3">
  @if (facturas.length == 0) {
  <div class="center payment-container mb-5rem">
    <h2 class="pt-5 pb-5">No tiene compras</h2>
  </div>
  }@else {
  @for (factura of facturas; track $index) {
  <div class="pb-5 mb-3rem payment-container d-flex-column">
    <div class="payment-container-header d-flex-row space-bet align-center pl-2rem">
      <div class="d-flex-row align-center">
        <span class="order font-size-18">Orden # {{factura.idOrdenDeVenta}}</span>
        <div class="d-flex-column pl-2rem">
          <span class="fecha-price font-size-16">Fecha de compra</span>
          <span class="order font-size-16">{{factura.ordenDeVenta.Cabecera.FechaSistema |
            date:'shortDate':'IST'}}</span>
        </div>
      </div>
      <div class="d-flex-column pr-2rem">
        <span class="fecha-price font-size-16">Precio total</span>
        <span class="order font-size-18">{{calcularTotal($index) | number}}</span>
      </div>
    </div>
    @for (product of factura.products; track $index) {
    <div class="d-flex-row space-bet align-center gap-r pt-3 pr-2rem">
      <div class="pl-2rem d-flex-row">
        <img class="img-size" src="{{product.imagen}}" alt="">
        <div class="d-flex-column w-25rem pl-2rem justify-center">
          <span class="ref">Ref. {{product.codigo}}</span>
          <span class="des">{{product.descripcion}}</span>
        </div>
      </div>
      <div>
        <span class="talla-canti">Talla :
          @if (product.talla != null) {
          <span class="talla-canti">
            {{product.talla}}
          </span>
          }@else {
          <span class="talla-canti">
            No tiene
          </span>
          }
        </span>
      </div>
      <div>
        <span class="talla-canti">Cantidad: </span>
        <span class="talla-canti">{{product.cantidad}}</span>
      </div>
      <div class="d-flex-column">
        <span class="unit-value">Valor unitario</span>
        <span class="price-unit">${{product.precioAutorizado | number}}</span>
      </div>
    </div>
    }
    <div class="pt-3">
      <mat-divider></mat-divider>
    </div>
    <div class="d-flex-row pl-2rem pt-3 space-bet pr-2rem">
      <div *ngIf="factura.tipoEnvio.metodo == 'DOMICILIO'" class="d-flex-column pb-3">
        <span class="envios">Datos de envio</span>
        <span class="titles-envio">
          Tipo: <span class="sub-text-info">Domicilio</span>
        </span>
        <span class="titles-envio">
          Destinatario:
          <span class="sub-text-info" *ngIf="factura.tipoEnvio.yoRecibo">
            {{factura.tipoEnvio.nombreUser | titlecase}}
          </span>
        </span>
        <span>
          <span class="sub-text-info" *ngIf="!factura.tipoEnvio.yoRecibo">
            {{factura.tipoEnvio.nombreTercero | titlecase}}</span>
        </span>
        <span class="titles-envio">
          Celular:
          <span class="sub-text-info">
            {{factura.tipoEnvio.numeroContacto}}
          </span>
        </span>
        <span class="titles-envio">
          Direccion de envio:
          <span class="sub-text-info">{{factura.tipoEnvio.direccion | titlecase}} {{factura.tipoEnvio.nombreDepto |
            titlecase}}-{{factura.tipoEnvio.nombreMunicipio | titlecase}}
          </span>
        </span>
      </div>
      @if (factura.tipoEnvio.metodo == 'RETIRO EN TIENDA') {
      <div class="d-flex-column pb-3">
        <span class="titles-envio">Tipo: <span class="sub-text-info">Retiro en tienda</span></span>
        <span class="titles-envio">Destinatario <span
            class="sub-text-info">{{factura.tipoEnvio.direccion}}</span></span>
        <span class="titles-envio">Tienda <span class="sub-text-info">
            {{factura.tipoEnvio.nombreTienda}}</span></span>
      </div>
      }
      <div class="d-flex-column justify-end">
        <div class="pb-3">
          <button class="button-secondary">Ver detalles del pedido</button>
        </div>
      </div>
    </div>
  </div>
  }
  }

</div>
@if (facturas.length == 0) {
<div class="center d-block d-sm-block d-md-block d-lg-none pb-3 pt-3">
  <h2>No tiene compras</h2>
</div>
}
@else {
@for (factura of facturas; track $index) {
<div [ngClass]="{'pb-5rem' : $index == (facturas.length-1), 'pt-3' : $index == 0}"
  class="d-block d-sm-block d-md-block d-lg-none pb-3 pl-1rem pr-1rem">
  <div class="payment-container">
    <div class="h-3rem center">
      <span class="order-mobile">Orden # {{factura.idOrdenDeVenta}}</span>
    </div>
    <div class="payment-container-header-mobile d-flex-row w-100 space-bet align-center">
      <div class="d-flex-column pl-2rem">
        <span class="title-mobile font-size-14">Fecha de compra</span>
        <span class="title-mobile font-size-14">
          {{factura.ordenDeVenta.Cabecera.FechaSistema | date:'shortDate':'IST'}}
        </span>
      </div>
      <div class="d-flex-column pr-2rem">
        <span class="title-mobile font-size-14">Precio total</span>
        <span class="title-mobile font-size-16">{{calcularTotal($index) | number}}</span>
      </div>
    </div>
    @for (product of factura.products; track $index) {
    <div class="pt-3 d-flex-column padd-mob">
      <div class="d-flex-row">
        <div>
          <img class="img-size" src="{{product.imagen}}" alt="">
        </div>
        <div class="d-flex-column pl-1rem">
          <span class="info-mobile">Ref. {{product.codigo}}</span>
          <span class="info-mobile">{{product.descripcion}}</span>
          <span class="info-mobile">Talla : <span class="" *ngIf="product.talla != null">
              {{product.talla}}</span>
            <span class="" *ngIf="product.talla == null"> No tiene</span></span>
          <span class="info-mobile">Cantidad: {{product.cantidad}}</span>
          <span class="price-mobile">${{product.precioAutorizado | number}}</span>
        </div>
      </div>
    </div>
    }
    <div class="d-flex-row pl-2rem pt-3 space-bet pr-2rem">
      @if (factura.tipoEnvio.metodo == 'DOMICILIO') {
      <div class="d-flex-column pb-3">
        <span class="envios">Datos de envio</span>
        <span class="titles-envio">Tipo:
          <span class="sub-text-info">Domicilio</span>
        </span>
        <span class="titles-envio">Destinatario:
          <span class="sub-text-info" *ngIf="factura.tipoEnvio.yoRecibo">
            {{factura.tipoEnvio.nombreUser | titlecase}}
          </span>
        </span>
        <span>
          <span class="sub-text-info" *ngIf="!factura.tipoEnvio.yoRecibo">
            {{factura.tipoEnvio.nombreTercero | titlecase}}
          </span>
        </span>
        <span class="titles-envio">Celular: <span class="sub-text-info">{{factura.tipoEnvio.numeroContacto}}</span>
        </span>
        <span class="titles-envio">Direccion de envio:
          <span class="sub-text-info">
            {{factura.tipoEnvio.direccion | titlecase}} {{factura.tipoEnvio.nombreDepto |
            titlecase}}-{{factura.tipoEnvio.nombreMunicipio | titlecase}}
          </span>
        </span>
      </div>
      }@else if (factura.tipoEnvio.metodo == 'RETIRO EN TIENDA') {
      <div class="d-flex-column pb-3">
        <span class="titles-envio">Tipo: <span class="sub-text-info">Retiro en tienda</span></span>
        <span class="titles-envio">Destinatario <span
            class="sub-text-info">{{factura.tipoEnvio.direccion}}</span></span>
        <span class="titles-envio">Tienda <span class="sub-text-info">
            {{factura.tipoEnvio.nombreTienda}}</span></span>
      </div>
      }
    </div>
    <div class="d-flex-column">
      <div class="pb-3 pr-1rem pl-1rem">
        <button class="button-secondary">Ver detalles del pedido</button>
      </div>
    </div>
  </div>
</div>
}
}
