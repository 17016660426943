import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ConfigService, ImgResponse, JsonTextService, LoadingService, TextResponse } from 'libreria-tienda';
import { SliderSieteComponent } from '../slider-logo/slider-siete/slider-siete.component';

@Component({
  selector: 'lib-slider-img',
  standalone: true,
  imports: [CommonModule, SliderSieteComponent],
  templateUrl: './slider-img.component.html',
  styleUrl: './slider-img.component.css'
})
export class SliderImgComponent {

  jsonImage: TextResponse[]
  jsonImagemarcas: ImgResponse[]
  // jsonImagemarcas: ImgResponse[]
  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)
  public load = inject(LoadingService)


  async ngOnInit() {
    // this.load.cargar(false)
      this.jsonService.downloadJson(this.configService._config.URLJSONIMAGE).subscribe(res => {
        this.jsonImage = res
      })
      await this.jsonService.downloadJsonImg(this.configService._config.URLJSONIMGMARCAS).subscribe(res => {
        this.jsonImagemarcas = res
      })
  }

  getName(name: string) {
    return this.jsonImage?.find(x => x.Nombre == name)?.Valor
  }

}
