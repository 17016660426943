@if (!ismob) {
<div class="w-100 flex-end pt-3 pr-1rem">
  <span (click)="close();" class="close-b">x</span>
</div>
}
<div class="d-flex-column pl-2rem p-3rem">
  <span class="title pt-2 pb-2">{{vacante.Nombre_Vacante}}</span>
  <mat-divider></mat-divider>
  <div class="d-flex-row-column-r pt-3">
    <span class="sub-titles">Descripcion : </span>
    <span class="info pl-02">{{vacante.Descripcion}}</span>
  </div>
  <div class="d-flex-row-column-r">
    <span class="sub-titles">Formacion: </span>
    <span class="info pl-02">{{vacante.formacion}}</span>
  </div>
  <div class="d-flex-row-column-r">
    <span class="sub-titles">Experiencia: </span>
    <span class="info pl-02">{{vacante.experiencia}}</span>
  </div>
  <div class="d-flex-row-column-r">
    <span class="sub-titles">Tipo de contrato: </span>
    <span class="info pl-02">{{vacante.tipo_contrato}}</span>
  </div>
  <div class="d-flex-row-column-r">
    <span class="sub-titles">Horario: </span>
    <span class="info pl-02">{{vacante.horario}}</span>
  </div>
  <div class="d-flex-row-column-r">
    <span class="sub-titles">Salario: </span>
    <span class="info pl-02">{{vacante.salario}}</span>
  </div>
  <div class="d-flex-row-column-r">
    <span class="sub-titles">Modalidad: </span>
    <span class="info pl-02">{{vacante.modalidad}}</span>
  </div>
  <div class="d-flex-row-column-r">
    <span class="sub-titles">Ciudad: </span>
    <span class="info pl-02">{{vacante.ciudad}}</span>
  </div>
  <span class="sub-titles">Requisitos</span>
  @for (req of vacante.Requisitos; track $index) {
  <div class="w-100 d-flex">
    <span class="info">{{req}}</span>
  </div>
  }
  <div class="pt-3 pb-3">
    <mat-divider></mat-divider>
  </div>
  <form [formGroup]="form" class="d-flex-colum form-st" [ngClass]="{'pb-5rem' : ismob }">
    <div class="pb-2 w-20rem flex-start d-flex-row-column-r w-20rem">
      <div class="flex-start w-20rem pb-2">
        <mat-label>Nombres</mat-label>
        <input class="input-text" type="text" placeholder="Nombres" formControlName="nombres"
          [ngClass]="{'text-field-error' : form.controls['nombres'].invalid && (form.controls['nombres'].dirty || form.controls['nombres'].touched)|| form.controls['nombres'].value == '' && submited, 'input-text' : !form.controls['nombres'].invalid || form.controls['nombres'].invalid }">
      </div>
      <div class="flex-start pl-1rem w-20rem pl-1rem">
        <div class="pb-2 w-20rem flex-start">
          <mat-label>Apellidos</mat-label>
          <input class="input-text" type="text" placeholder="Apellidos" formControlName="apellidos"
            [ngClass]="{'text-field-error' : form.controls['apellidos'].invalid && (form.controls['apellidos'].dirty || form.controls['apellidos'].touched)|| form.controls['apellidos'].value == '' && submited, 'input-text' : !form.controls['apellidos'].invalid || form.controls['apellidos'].invalid }">
        </div>
      </div>
    </div>
    <div class="d-flex-row-column-r pb-2 w-20rem ">
      <div class="d-flex-column w-100 flex-start pb-2 w-20rem-aux">
        <mat-label>Tipo de documento</mat-label>
        <mat-form-field appearance="fill"
          class="text-field">
          <mat-select formControlName="tipoDocumento" placeholder="CC" (selectionChange)="document($event)">
            <mat-option *ngFor="let option of listDocuments" [value]="option">{{ option |
              titlecase }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex-column flex-start w-100 pl-08">
        <mat-label>Número de documento</mat-label>
        <input class="input-text" type="text" placeholder="identificacion" formControlName="identificacion"
          [ngClass]="{'text-field-error' : form.controls['identificacion'].invalid && (form.controls['identificacion'].dirty || form.controls['identificacion'].touched)|| form.controls['identificacion'].value == '' && submited, 'input-text' : !form.controls['identificacion'].invalid || form.controls['identificacion'].invalid }">
      </div>
    </div>
    <div class="pb-2 w-20rem flex-start d-flex-row-column-r">
      <div class="flex-start w-20rem pb-2">
        <mat-label>Celular</mat-label>
        <input class="input-text" type="text" placeholder="telefono celular" formControlName="celular"
          [ngClass]="{'text-field-error' : form.controls['celular'].invalid && (form.controls['celular'].dirty || form.controls['celular'].touched)|| form.controls['celular'].value == '' && submited, 'input-text' : !form.controls['celular'].invalid || form.controls['celular'].invalid }">
      </div>
      <div class="pb-3 w-20rem flex-start pl-1rem">
        <mat-label>Correo electronico</mat-label>
        <input class="input-text" type="text" placeholder="correo electronico" formControlName="correo"
          [ngClass]="{'text-field-error' : form.controls['correo'].invalid && (form.controls['correo'].dirty || form.controls['correo'].touched)|| form.controls['correo'].value == '' && submited, 'input-text' : !form.controls['correo'].invalid || form.controls['correo'].invalid }">
      </div>
    </div>
    <div class="pb-3 w-20rem d-flex-column center">
      <button class="button-secondary" (click)="fileInput.click()">Subir hoja de vida</button>
      <span [ngClass]="{'text-error' : errorFormato }" class="text-normal pt-2">{{msg}}</span>
      <span *ngIf="nombreHojaVida != 'text-normal pt-2'" class="">{{nombreHojaVida}}</span>
    </div>
    <div class="pb-3 w-20rem center d-flex">
      <button class="button-primary" (click)="aplicar()">Postularme</button>
    </div>
    <input #fileInput type="file" class="file-upload input-text" (change)="loadPdf($event)" formControlName="hoja_vida"
      [ngClass]="{'text-field-error' : form.controls['hoja_vida'].invalid && (form.controls['hoja_vida'].dirty || form.controls['hoja_vida'].touched)|| form.controls['hoja_vida'].value == '' && submited, 'input-text' : !form.controls['hoja_vida'].invalid || form.controls['hoja_vida'].invalid }">
  </form>
</div>
