<!-- <p>gana-kilates works!</p> -->
@if (ganaKilates.length>0) {
<div class="contenedor-pagina">
  <div class="titulo-video-contenedor">
    <h1 [innerHTML]="titulo.titulo"></h1>
    <span [innerHTML]="titulo.texto"></span>
    <video controls>
      <source [src]="video" type="video/mp4">
    </video>
  </div>
  <div class="contenedor-textos">
    <div class="contenedor-titulo-texto">
      <h3 [innerHTML]="queEs.titulo"></h3>
      <mat-divider></mat-divider>
      <span [innerHTML]="queEs.texto"></span>
    </div>
    <div class="contenedor-titulo-texto">
      <h3 [innerHTML]="acumulado.titulo"></h3>
      <mat-divider></mat-divider>
      <span [innerHTML]="acumulado.sub" class="medium"></span>
      <ul>
        @for (item of acumulado.info; track $index) {
        <li>{{item}}</li>
        }
      </ul>
      <span [innerHTML]="acumulado.texto"></span>
      <span [innerHTML]="acumulado.ejemplo" class="medium"></span>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th>{{acumulado.titulo_table.bono}}</th>
              <th>{{acumulado.titulo_table.descuento}}</th>
              <th>{{acumulado.titulo_table.kilates}}</th>
              <th>{{acumulado.titulo_table.valor}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{acumulado.table[0].bono}}</td>
              <td>{{acumulado.table[0].descuento}}</td>
              <td>{{acumulado.table[0].kilates}}</td>
              <td>{{acumulado.table[0].valor}}</td>
            </tr>
            <tr>
              <td>{{acumulado.table[1].bono}}</td>
              <td>{{acumulado.table[1].descuento}}</td>
              <td>{{acumulado.table[1].kilates}}</td>
              <td>{{acumulado.table[1].valor}}</td>
            </tr>
            <tr>
              <td>{{acumulado.table[2].bono}}</td>
              <td>{{acumulado.table[2].descuento}}</td>
              <td>{{acumulado.table[2].kilates}}</td>
              <td>{{acumulado.table[2].valor}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="contenedor-titulo-texto">
      <h3 [innerHTML]="redimir.titulo"></h3>
      <mat-divider></mat-divider>
      <ul>
        @for (item of redimir.info; track $index) {
        <li class="iterado">{{item}}</li>
        }
      </ul>
    </div>
  </div>
</div>
}
