<div class="container">
  <div class="wrapper" (mouseenter)="stopAutoPlay()" (mouseleave)="startAutoPlay()">
    <i id="left" class="fa-solid fa-angle-left" (click)="scrollCarousel('left')"></i>
    <ul class="carousel" #carousel>
      @for (item of productos; track $index) {
      <!-- @if (!item.manejaTalla) { -->
      <li class="card">
        <lib-complemento [info]="item" (selectComple)="complemtoSele($index, $event)"/>
        <!-- <lib-card-product-carrusel [product]="item" class="view-cards" [categoria]="categoria" [index]="$index" /> -->
      </li>
      <!-- } -->
      }
    </ul>
    <i id="right" class="fa-solid fa-angle-right" (click)="scrollCarousel('right')"></i>
  </div>
</div>
