import { Deptos, Municipes } from "../interfaces/sitios";
import { favorite } from "./product";

export class User {
  id: string;
  nombre: string;
  apellido: string;
  idCienteGoogle: string;
  idCienteFacebook: string;
  tipoDocumento: string;
  numeroDocumento: string;
  correoElectronico: string;
  fechaNacimiento: Date | null;
  password: string;
  direcciones: direccion[] = [];
  favoritos: favorite[] = [];
  carro: string = ""
  celular: string;
}

export class userlogged {
  nombre: string;
  apellido: string;
  correoElectronico: string;
  favoritos: favorite[] = [];
  carro: string = "";
}

export class direccion {
  nombres: string;
  apellidos: string;
  celular: string;
  isPrincipal: boolean;
  editando: boolean = false;
  departamento: Deptos;
  ciudad: Municipes;
  direccion: string;
  descripcion: string;
}
