<lib-loader />
<lib-boton-contactanos />
<lib-header-mobile (evento)="manejarEvento($event)" class="top" />
<!-- <lib-header class="top" (evento)="manejarEvento($event)"></lib-header> -->
<div class="pb-mobile">
  <lib-side-bar #side_bar>
    <div mobile class="contenido">
      <ng-content select="[contenido]"></ng-content>
    </div>
  </lib-side-bar>
  <!-- <lib-menu-inferior-mobile (evento)="manejarEvento($event)" /> -->
  <!-- <lib-banner-footer [catalogo]="'footer'"/> -->
</div>
<lib-footer />
