<div class="pb-3 d-none d-sm-none d-md-none d-lg-block">
  <div class="d-flex-column container-center">
    <div class="mt-3 ml-mr-100 d-flex-row">
      <div class="d-flex-column container-profile wvw">
        <div class="center">
          <h2 class="title pb-3 pt-5">DATOS PERSONALES</h2>
        </div>
        <div class="d-flex-row justify-between pr-pl-info">
          <div class="grid-icons">
            <div class="d-flex-end-p icon-space">
              <span class="icon-User icon-size mr-10 color-icon"></span>
            </div>
            <div class="info-space">
              <div>
                <span class="titles">Nombre</span>
              </div>
              <div>
                <span class="info">{{user.nombre}} {{user.apellido}}</span>
              </div>
            </div>
          </div>
          <div class="grid-icons">
            <div class="d-flex-end-p icon-space">
              <span class="icon-Cedula icon-size mr-10"></span>
            </div>
            <div class="info-space">
              <div>
                <span class="titles">Identificación</span>
              </div>
              <div>
                <span class="info">{{user.numeroDocumento}}</span>
              </div>
            </div>
          </div>
          <div class="grid-icons">
            <div class="d-flex-end-p icon-space">
              <span class="icon-Telefono icon-size mr-10"></span>
            </div>
            <div class="info-space">
              <div>
                <span class="titles">Celular</span>
              </div>
              <div>
                <span class="info">{{user.celular}}</span>
              </div>
            </div>
          </div>
          <div class="grid-icons">
            <div class="d-flex-end-p icon-space">
              <mat-icon class="mr-10"> mail_outline</mat-icon>
            </div>
            <div class="info-space">
              <div>
                <span class="titles">Email</span>
              </div>
              <div>
                <span class="info">{{user.correoElectronico}}</span>
              </div>
            </div>
          </div>
          <div class="grid-icons">
            <div class="d-flex-end-p icon-space">
              <span class="icon-Llave icon-size mr-10"></span>
            </div>
            <div class="info-space">
              <div>
                <span class="titles">Contraseña</span>
              </div>
              <div>
                <span class="pass info"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="button-edit pb-5">
          <button (click)="editUser()">Editar informarción</button>
        </div>
      </div>
    </div>
    <div class="mt-3 ml-mr-100 container-profile wvw">
      <div class="center">
        <h2 class="title pb-3 pt-5">TUS DIRECCIONES</h2>
      </div>
      <lib-address />
    </div>
  </div>
</div>

<!-- <div class="d-block d-sm-block d-md-block d-lg-none">
  <div class="center">
    <h2 class="title pb-3 pt-5">DATOS PERSONALES</h2>
  </div>
  <div class="grid-icons pl-3rem">
    <div class="d-flex-end-p icon-space">
      <span class="icon-User icon-size mr-10 color-icon"></span>
    </div>
    <div class="info-space">
      <div>
        <span class="titles">Nombre</span>
      </div>
      <div>
        <span class="info">{{user.nombre}} {{user.apellido}}</span>
      </div>
    </div>
  </div>
  <div class="grid-icons pl-3rem">
    <div class="d-flex-end-p icon-space">
      <span class="icon-Cedula icon-size mr-10"></span>
    </div>
    <div class="info-space">
      <div>
        <span class="titles">Identificación</span>
      </div>
      <div>
        <span class="info">{{user.numeroDocumento}}</span>
      </div>
    </div>
  </div>
  <div class="grid-icons pl-3rem">
    <div class="d-flex-end-p icon-space">
      <span class="icon-Telefono icon-size mr-10"></span>
    </div>
    <div class="info-space">
      <div>
        <span class="titles">Celular</span>
      </div>
      <div>
        <span class="info">{{user.celular}}</span>
      </div>
    </div>
  </div>
  <div class="grid-icons pl-3rem">
    <div class="d-flex-end-p icon-space">
      <mat-icon class="mr-10"> mail_outline</mat-icon>
    </div>
    <div class="info-space">
      <div>
        <span class="titles">Email</span>
      </div>
      <div>
        <span class="info">{{user.correoElectronico}}</span>
      </div>
    </div>
  </div>
  <div class="grid-icons pl-3rem">
    <div class="d-flex-end-p icon-space">
      <span class="icon-Llave icon-size mr-10"></span>
    </div>
    <div class="info-space">
      <div>
        <span class="titles">Contraseña</span>
      </div>
      <div>
        <span class="pass info"></span>
      </div>
    </div>
  </div>
  <div class="button-edit pb-5">
    <button (click)="editUser()">Editar informarción</button>
  </div>
</div> -->
