import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Product, infoVenecia } from 'libreria-tienda';
import { EtiquetaDescuentoComponent } from 'libreria-tienda/shared/etiqueta-descuento/etiqueta-descuento.component';
import { ComplementoComponent } from '../complemento/complemento.component';

@Component({
  selector: 'lib-complemento-carrusel',
  standalone: true,
  imports: [CommonModule, EtiquetaDescuentoComponent,ComplementoComponent],
  templateUrl: './complemento-carrusel.component.html',
  styleUrl: './complemento-carrusel.component.css'
})
export class ComplementoCarruselComponent  implements OnInit {

  @Input() productos: infoVenecia[];

  @Output() selectComple = new EventEmitter<any>();

  @ViewChild('carousel') carousel!: ElementRef;

  ngOnInit(): void {
  }

  autoPlayTimer: any;

  scrollCarousel(direction: string): void {
    const carouselElement = this.carousel.nativeElement as HTMLElement;
    const firstCardWidth = (carouselElement.querySelector('.card') as HTMLElement)?.offsetWidth || 0;
    const scrollAmount = direction === 'left' ? -firstCardWidth : firstCardWidth;
    carouselElement.scrollLeft += scrollAmount;
  }

  stopAutoPlay(): void {
    clearTimeout(this.autoPlayTimer);
  }

  startAutoPlay(): void {
    this.autoPlayTimer = setInterval(() => this.scrollCarousel('right'), 2500);
  }

  complemtoSele(i: number, e: any) {
    e.index = i
    this.selectComple.emit(e)
  }
}
