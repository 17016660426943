import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-pop-up-error-formularios',
  standalone: true,
  imports: [],
  templateUrl: './pop-up-error-formularios.component.html',
  styleUrl: './pop-up-error-formularios.component.css'
})
export class PopUpErrorFormulariosComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { mensaje: string; formularios: string[] }) { }

  ngOnInit(): void {
    // console.log('Datos recibidos en el diálogo:', this.data);
  }

}
