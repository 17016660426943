import { Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserComponent } from '../update-user/update-user.component';
import { CommonModule, Location } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { AddressService, Deptos, ExpressService, LoadingService, Municipes, ShoppingCartService, SioServicesService, User, UserService, direccion, userlogged } from 'libreria-tienda';

@Component({
  selector: 'lib-address',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatPseudoCheckboxModule, FormsModule, ReactiveFormsModule, MatIconModule, MatSelectModule],
  templateUrl: './address.component.html',
  styleUrl: './address.component.css'
})
export class AddressComponent implements OnInit {

  usuarioLog: userlogged = new userlogged()
  usuario: User = new User()
  direcciones: direccion[] = new Array<direccion>;
  direccion: direccion = new direccion();
  form: FormGroup;
  submited = false;

  expressService = inject(ExpressService)
  sioService = inject(SioServicesService)
  cartService = inject(ShoppingCartService)
  addresService = inject(AddressService)
  load = inject(LoadingService)
  userService = inject(UserService)


  constructor(private router: Router, private fb: FormBuilder, public dialogo: MatDialog, private _location: Location) {
    this.form = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      celular: ['', Validators.required],
      departamento: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      informacionAdi: [''],
    })
  }

  expandedPanelIndex: number | null = null;
  listDeptos: Deptos[] = new Array<Deptos>();
  deptoini: Deptos;
  listMunicipes: Municipes[] = new Array<Municipes>();
  panelOpenState = false;
  open = false;
  isPanelOpen: boolean[];

  ngOnInit(): void {
    this.usuarioLog = this.userService.getUserLocal();
    this.addresService.setDireccion(new direccion())
    this.addresService.setIndex(-1)
    this.load.cargar(true)

    this.userService.getUserDireccion().then(async (res) => {
      this.direcciones = res
      await this.inicializar();
    }).catch(error => {
      console.error(error);
    })
  }

  inicializar(): void {
    if (this.direcciones.length > 0) {
      this.direcciones = this.addresService.ordenarPrincipal(this.direcciones, this.usuarioLog.correoElectronico)
      this.isPanelOpen = this.direcciones.map(() => false);
    }
    this.load.cargar(false)
  }


  newDirection() {
    this.addresService.setIndex(-1)
    this.router.navigate(["add-Address"])

  }

  editDirection(i: number) {
    this.addresService.setDireccion(this.direcciones[i])
    this.addresService.setIndex(i)
    this.direcciones[i].editando = true;
    this.expressService.editDireccionesUser(this.usuarioLog.correoElectronico, this.direcciones)
    this.router.navigate(["edit-Address"])
  }

  onPanelOpened(index: number) {
    this.expandedPanelIndex = index;
    this.isPanelOpen[index] = true
    this.open = true
  }

  municipeSelected(event: any) {
    this.form.controls['ciudad'].setValue(event.value);
  }

  deptoSelected(event: any) {
    this.form.controls['departamento'].setValue(event.value);
    this.sioService.GetDataListCommandMunicipe("Municipios", "IdDepto = @0", [event.value.idDepto]).subscribe((municipe: Municipes[]) => {
      this.listMunicipes = municipe
    })
  }

  expandPanelAddDirection() {
    this.panelOpenState = true
  }

  closePanelAddDirection() {
    this.panelOpenState = false
  }

  onPanelClosed(i: number) {
    this.open = false
    this.isPanelOpen[i] = false
  }

  routing() {
    this.router.navigate(["/shippings"])
  }

  saveAddress() {
    this.submited = true
    this.direccion = new direccion()
    this.direccion.nombres = this.form.get('nombres')?.value
    this.direccion.apellidos = this.form.get('apellidos')?.value
    this.direccion.celular = this.form.get('celular')?.value
    this.direccion.departamento = this.form.get('departamento')?.value
    this.direccion.ciudad = this.form.get('ciudad')?.value
    this.direccion.direccion = this.form.get('direccion')?.value
    if (this.form.get('informacionAdi')?.value != '')
      this.direccion.descripcion = this.form.get('informacionAdi')?.value
    else
      this.direccion.descripcion = "Dirección"
    if (this.usuario.direcciones !== undefined) {
      if (this.usuario.direcciones.length > 0) {
        this.direccion.isPrincipal = false
      }
      else {
        this.direccion.isPrincipal = true
        if (this.form.get('informacionAdi')?.value != '')
          this.direccion.descripcion = this.form.get('informacionAdi')?.value
        else
          this.direccion.descripcion = "Dirección principal"
      }
    }
    if (this.form.valid) {
      // this.usuario.direcciones.push(this.direccion)
      this.direcciones.push(this.direccion)
      this.usuario.direcciones = this.direcciones
      this.expressService.editUser(this.usuario, null, null).subscribe(res => {
        if (res) {
          this.form.reset()
          this.panelOpenState = false
          this.openDialogMessage()
        }
      })
    }
  }

  deleteAddres(i: number) {
    if (this.direcciones.length > 1) {
      if (this.direcciones[0].isPrincipal && i == 0) {
        this.direcciones.shift()
        this.direcciones[0].isPrincipal = true
        this.usuario.direcciones = this.direcciones
      } else {
        this.direcciones.splice(i, 1)
        this.usuario.direcciones = this.direcciones
      }
    } else {
      this.direcciones.shift()
      this.usuario.direcciones = this.direcciones
    }
    this.expressService.editUser(this.usuario, null, null).subscribe(res => {
      if (res) {
        this.openDialogMessage()
      }
    })
  }
  openDialogMessage(): void {
    const dialogRef = this.dialogo.open(UpdateUserComponent, { panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res2 => {
      this._location.back()
      console.log('Back');

    })
  }
  newPrincipal(i: number) {
    this.direcciones.forEach((dire, j) => {
      if (j != i)
        dire.isPrincipal = false
      else
        dire.isPrincipal = true
    })
    let dire = this.direcciones.filter(x => x.isPrincipal === true)
    this.direcciones.forEach((dire, i) => {
      if (dire.isPrincipal)
        this.direcciones.splice(i, 1)
    })
    this.direcciones.unshift(dire[0])
    this.usuario.direcciones = this.direcciones
    this.expressService.editUser(this.usuario, null, null).subscribe(res => {
      if (res) {
        this.openDialogMessage()
      }
    })
  }
}
