<div class="pr-1rem d-flex-column size-popup">
  <div class="pl-precios">
    <div class="pt-3">
      <span class="reference">Ref. {{item.idProducto}}</span>
    </div>
    @if (item.porcentaje !== 0) {
    <div>
      <span class="price price-full">$ {{item.precioFull | number}}</span>
    </div>
    }
    <div class="d-flex-row">
      <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">
        $ {{item.precioAutorizado | number}}
      </span>
      @if (item.porcentaje !== 0) {
      <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
      }
    </div>
  </div>
  <div class="p-img">
    <lib-favorito-corazon class="corazon" />
    <img class="img-size-p" [src]="data.info.imagen" alt="">
    <div class="text-size">
      <span class="relative">{{getName('tamfoto')}}</span>
    </div>
  </div>
  <div class="p-caracteristicas">
    <mat-divider></mat-divider>
    <!-- @for (caracteristica of caracteristicasItem; track $index) {
      <div class="d-flex-align-end d-flex-row pr-2">
        <span class="titulos-carac color-textos">{{caracteristica.web}}:</span>
        <span class="color-textos">{{caracteristica.valor | titlecase }}</span>
      </div>
    } -->
    <div class="d-flex-row gap pt-3">
      <div class="pt-3">
        <span class="characteristics">Descripción:</span><br>
        <span class="descripcion">{{item.descripcion}}</span>

      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex-row gap pt-3">
      <lib-tallas [submited]="submited" [tallas]="itemAnillo.TallasCantidad" (ItemData)="talla($event)"></lib-tallas>
      <!-- <lib-tallas [tallas]="tallas" [tallaSelectd]="item.talla || '-'" [submited]="submited" [codTallas]="codTallas"
        (ItemData)="talla($event)"></lib-tallas> -->
    </div>
    <div class="d-flex-row d-flex-center pt-2">
      <span class="icon-ring font-size-16"></span>
      <span (click)="donwloadManual()" class="manual cursor-pointer pl-05rem">Manual de tallas</span>
    </div>
    <div class="d-flex-column gap pt-3 center">
      <div class="d-flex-row d-flex-center">
        <button class="button-cart" type="submit" (click)="agregar()">Agregar</button>
      </div>
      <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('politics')"></span>
    </div>
  </div>

</div>
