import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { ExpressService, LoadingService } from 'libreria-tienda';
import { MessageBoxComponent } from 'libreria-tienda/shopping-cart/domicilio/message-box/message-box.component';

@Component({
  selector: 'app-verificacion-email',
  standalone: true,
  imports: [],
  templateUrl: './verificacion-email.component.html',
  styleUrl: './verificacion-email.component.css'
})
export class VerificacionEmailComponent {

  expressService = inject(ExpressService)
  load = inject(LoadingService)

  constructor(private _route: ActivatedRoute, public dialogo: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.load.cargar(true)
    this._route.queryParams.subscribe((params) => {
      if (params["email"] !== undefined && params["token"] !== undefined) {
        this.validarToken(params["email"], params["token"])
      }
    })
  }
  openDialogMessage(titulo: string, mensaje: string, tipo: number): void {
    const dialogRef = this.dialogo.open(MessageBoxComponent, { data: { title: titulo, msg: mensaje, tipo: tipo } })
  }

  redirect() {
    this.router.navigate(['/login']).then(() => {
      window.scrollTo(0, 0);
    });
  }

  validarToken(email: string, token: string) {
    this.expressService.verifyUser(email, token).then((res)=>{
      this.load.cargar(false)
      this.openDialogMessage("Cuenta", res.res, 3)
      this.redirect()
    }).catch((error)=>{
      console.log('error en la peticion',  error.error.error);

      this.openDialogMessage("Error", error.error.error, 2)
    })
  }
}
