import { Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BarraSuperiorComponent } from './barra-superior/barra-superior.component';;

import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

import { ConfigService, ControlSesionService, JsonTextService, PantallaSaleService, ShoppingCartService, TextResponse, User, items } from 'libreria-tienda';
// import { BusquedaComponent } from 'libreria-tienda/sfa/components/busqueda/busqueda.component';
import { MenusComponent } from './menus/menus.component';
import { LoginComponent } from './login/login.component';

declare var propiedades: any | undefined;

@Component({
  selector: 'lib-header',
  standalone: true,
  imports: [CommonModule,
    BarraSuperiorComponent,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    // BusquedaComponent,
    MenusComponent,
    MatMenuModule,
    LoginComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {

  propiedades: any;

  sale: boolean;

  private sesionService = inject(ControlSesionService)
  private pantallaSaleService = inject(PantallaSaleService)
  private cartService = inject(ShoppingCartService)


  protected totalProducts: number = 0;

  private rutaActual: string;

  login: boolean = false;
  iniciales: string = "-";
  user: User = new User();


  @Output() evento = new EventEmitter<string>();

  constructor(private router: Router) {
    this.propiedades = propiedades;
  }



  ngOnInit(): void {
    this.usuarioLogin()
    if (this.user.nombre != undefined && this.user.apellido != undefined) {
      this.login = true;
      this.iniciales = this.user.nombre.charAt(0) + this.user.apellido.charAt(0)
    } else {
      this.login = false;
      this.iniciales = "-"
    }
    this.pantallaSaleService.estado$.subscribe(valor => {
      this.sale = valor;
    });
  }
  protected redirectToHome() {
    this.rutaActual = this.router.url
    if (this.rutaActual != "/home") {
      this.router.navigate(['/home']).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    }
  }

  ngAfterContentChecked() {
    this.totalProducts = this.cartService.getTotalProducts()
  }

  redirect(page: string) {
    this.router.navigate(['/' + page]).then(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    });
  }

  protected EventoOpen(val: string) {
    this.evento.emit(val)
  }

  usuarioLogin() {
    if (typeof localStorage !== 'undefined') {
      if ((localStorage.getItem("userLogged")) != undefined) {
        this.user = JSON.parse(localStorage.getItem("userLogged") || '')
      }
    }
  }

  cerrarSesion() {
    this.sesionService.cerrarSesion()
  }
}
