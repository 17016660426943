import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { TipoEnvio } from 'libreria-tienda';
import { BuyServiceService } from 'libreria-tienda';
import { CommonLista, SioServicesService, User } from 'libreria-tienda';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'lib-receive-by',
  standalone: true,
  imports: [CommonModule, MatPseudoCheckboxModule, FormsModule, MatExpansionModule, ReactiveFormsModule, MatSelectModule],
  templateUrl: './receive-by.component.html',
  styleUrl: './receive-by.component.css'
})
export class ReceiveByComponent implements OnInit {

  isReceiveByMe: boolean = true;
  isReceiveByOther: boolean;
  formDomicilio: FormGroup;
  formTienda: FormGroup;
  listDocuments: CommonLista[] = new Array<CommonLista>();
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  isMobile: boolean = false;

  tipoEnvio: TipoEnvio = new TipoEnvio()
  @Input() user: User = new User()
  @Input() recibeYo: string | undefined = ""
  @Input() recibeOtro: string | undefined = ""
  @Input() quienRecibe: string | undefined = ""
  @Input() guardar: string | undefined = ""
  _section: number = 1
  @Input() set section(value: number) {
    this.formDomicilio.reset()
    this._section = value
  }
  @Output() sendTypeEmit = new EventEmitter<TipoEnvio>()
  @Output() sectionEmit = new EventEmitter<number>()
  @Output() recibeYoEmit = new EventEmitter<boolean>()
  @Output() recibeOtroEmit = new EventEmitter<boolean>()

  public sioService = inject(SioServicesService);
  public buyService = inject(BuyServiceService);

  constructor(private fb: FormBuilder) {

    this.formDomicilio = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      tipoDocumento: ['', Validators.required],
      numeroDocumento: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.sioService.GetListaPorCodigo("TPDOC").subscribe(list => {
      this.listDocuments = list
    })
    this.buyService.sectionEmit.subscribe(sec => {
      if (sec == 1) {
        this.isReceiveByMe = true
        this.isReceiveByOther = false
      } else {
        this.isReceiveByMe = true
        this.isReceiveByOther = false
      }
    })
  }

  onChangeRecibido(event: any) {
    if (this.isReceiveByMe) {
      this.isReceiveByOther = false
      this.recibeYoEmit.emit(true)

      this.buyService.sendType.nombreUser = this.user.nombre
      this.buyService.sendType.apellidoUser = this.user.apellido
      this.buyService.sendType.tipoDocumentoUser = this.user.tipoDocumento
      this.buyService.sendType.numeroDocumentoUser = this.user.numeroDocumento

    }
    else {
      this.recibeOtroEmit.emit(true)
      this.isReceiveByOther = true

      this.buyService.sendType.nombreUser = null
      this.buyService.sendType.apellidoUser = null
      this.buyService.sendType.tipoDocumentoUser = null
      this.buyService.sendType.numeroDocumentoUser = null
    }
  }

  onChangeRecibido2(event: any) {
    if (this.isReceiveByOther) {
      this.isReceiveByMe = false
      this.recibeOtroEmit.emit(true)

      this.buyService.sendType.nombreUser = null
      this.buyService.sendType.apellidoUser = null
      this.buyService.sendType.tipoDocumentoUser = null
      this.buyService.sendType.numeroDocumentoUser = null
    }
    else {
      this.isReceiveByMe = true
      this.recibeYoEmit.emit(true)

      this.buyService.sendType.nombreUser = this.user.nombre
      this.buyService.sendType.apellidoUser = this.user.apellido
      this.buyService.sendType.tipoDocumentoUser = this.user.tipoDocumento
      this.buyService.sendType.numeroDocumentoUser = this.user.numeroDocumento
    }
  }

  saveInfo(tipo: number) {
    if (this.formDomicilio.valid) {
      if (tipo == 1) {

        this.buyService.sendType.nombreTercero = this.formDomicilio.get("nombres")?.value
        this.buyService.sendType.apellidoTercero = this.formDomicilio.get("apellidos")?.value
        this.buyService.sendType.tipoDocumentoTercero = this.formDomicilio.get("tipoDocumento")?.value.codLista
        this.buyService.sendType.numeroDocumentoTercero = this.formDomicilio.get("numeroDocumento")?.value

        this.sendTypeEmit.emit(this.tipoEnvio)
        this.sectionEmit.emit(this.section)
      } else {

        this.buyService.sendType.nombreTercero = this.formTienda.get("nombres")?.value
        this.buyService.sendType.apellidoTercero = this.formTienda.get("apellidos")?.value
        this.buyService.sendType.tipoDocumentoTercero = this.formTienda.get("tipoDocumento")?.value.codLista
        this.buyService.sendType.numeroDocumentoTercero = this.formTienda.get("numeroDocumento")?.value

        this.sendTypeEmit.emit(this.tipoEnvio)
        this.sectionEmit.emit(this.section)
      }
    }
  }

  tipoDoc(event: any) {
  }

}
