import { ItemCar, ItemCompra } from "./carro-compras";
import { TipoEnvio } from "./send-type";

export class InfoGia {
  idProducto: string;
  colorPiedra: string
  claridad: string
  precioFull: string
  precioAutorizado: string

  constructor(idProducto: string, colorPiedra: string, claridad: string, precioFull: string, precioAutorizado: string) {
    this.idProducto = idProducto;
    this.colorPiedra = colorPiedra;
    this.claridad = claridad;
    this.precioFull = precioFull
    this.precioAutorizado = precioAutorizado
  }
}

export class Product {
  cantidad: string;
  descripcionWeb: string;
  nombreComercial: string;
  esGIA: boolean;
  giA_PrecioMinimo: string;
  giA_PrecioMaximo: string;
  idProducto: string;
  imagen: string;
  manejaTalla: boolean
  porcentaje: number;
  precioAutorizado: string;
  precioFull: string;
  precioMaximo: number;
  precioMinimo: number;
  referenciaProveedor: string;
  totalRegistros: number;
  errorImagen: boolean;
  pesoPiedraCentral: number;
  tipoProducto: string;
  linea: string;
  idLinea: string;
  constructor(idProducto: string, descripcionWeb: string, precioFull: string, precioAutorizado: string, imagen: string) {
    this.idProducto = idProducto;
    this.descripcionWeb = descripcionWeb;
    this.precioFull = precioFull;
    this.precioAutorizado = precioAutorizado;
    this.imagen = imagen;
    this.errorImagen = false
  }
}

export class favorite {
  referenciaProveedor: string;
  idProducto: string;
  esGia: boolean;
  peso: number;
}

export class infoVenecia {
  cantidad: number;
  descripcion: string;
  idProducto: string;
  imagen: string;
  largo: number = 0;
  porcentaje: number;
  precioAutorizado: number;
  precioFull: number;
  checked: boolean = false;
  manejaTalla: boolean = false
}

export class productoMP {
  title: string;
  unit_price: number;
  quantity: number;
  picture_url: string;
}

export class OrdenDeVenta {
  Cabecera: Cabecera = new Cabecera();
  Detalle: Array<ItemCompra> = new Array<ItemCompra>();
  InfoEntrega: TipoEnvio
}

export class Cabecera {
  FechaSistema: string;
  IdCliente: string;
  IdCarroCompras: string;
  Nombres: string;
  Apellidos: string;
  TipoDocumento: string;
  NumeroDocumento: string;
  Telefono: string;
  correoelectronico: string;
  DescuentoAdicional: number = 0;
  ValorEnvio: number;
  ValorItems: number;
  ValorTotal: number;
}

// export class Detalle {
//   IdProducto: string;
//   TallaId: number;
//   IdLinea: string;
//   DescripcionProducto: string;
//   Cantidad: number;
//   PrecioFullUnitario: number;
//   PrecioFullTotal: number;
//   PrecioAutorizado: number;
//   DtoAutorizado: number;
//   DtoAdicional: number;
//   TotalDescuento: number;
//   PorcentajeDescuento: number;
//   Recargo: number; // transporte
//   CostoUs: number;
//   TotalPagado: number;
//   // ValorBase: number;
//   // ValorIVA: number;
//   // PorcentajeIVA: number;
//   IdProveedor: number;
//   PesoProducto: number;
//   IdSolicitudTraslado: number;
//   Serial: string;
// }

export class ListaProductos {
  IdProducto: string;
  Cantidad: number;
  CodTalla: string;
}

export class calculateTime {
  TiempoEntregaPeticion: tiempoEntregaPeticion = new tiempoEntregaPeticion();
}
export class tiempoEntregaPeticion {
  TipoEntrega: number;
  IdCiudad: string;
  CodDaneMunicipio: string;
  ListaProductos: ListaProductos[] = new Array<ListaProductos>();
}
export class filterActiveShow {
  i: number;
  j: number;
  name: string;
  constructor(i: number, j: number, name: string) {
    this.i = i;
    this.j = j;
    this.name = name
  }

}
export class SelectAderezo {
  select: boolean = false;
  item: ItemCar;
}
export class PulseraVenezia {
  cantidad: number;
  descripcionProducto: string;
  idProducto: string;
  imagen: string;
  largo: string;
  precioAutorizado: number;
  precioFull: number;
}
