<div class="back-img center pt-5 pb-5">
  <form [formGroup]="form" (ngSubmit)="editUser()">
    <div class="edit-container d-flex-column">
      <h2 class="text-center">EDITAR DATOS PERSONALES</h2>
      <label class="text-labels">Nombres</label>
      <input
        [ngClass]="{'input-error' : form.controls['nombres'].invalid && (form.controls['nombres'].dirty || form.controls['nombres'].touched) || form.controls['nombres'].value == '' && submited}"
        class="input-text" type="text" formControlName="nombres" placeholder="nombres">
      <label class="text-labels">Apellidos</label>
      <input
        [ngClass]="{'input-error' : form.controls['apellidos'].invalid && (form.controls['apellidos'].dirty || form.controls['apellidos'].touched) || form.controls['apellidos'].value == '' && submited}"
        class="input-text" type="text" formControlName="apellidos" placeholder="apellido">
      <div class="d-flex-row">
        <div class="w-40 pr-1">
          <label class="text-labels pb-12">Tipo de documento</label>
          <mat-form-field
            [ngClass]="{'input-error' : form.controls['tipoDocumento'].invalid && (form.controls['tipoDocumento'].dirty || form.controls['tipoDocumento'].touched) || form.controls['tipoDocumento'].value == '' && submited}"
            class="w-indicator" appearance="fill">
            <mat-select (selectionChange)="documentSelected($event)" formControlName="tipoDocumento" required>
              <mat-option *ngFor="let document of listDocuments" [value]="document">
                {{document.nombre | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="w-60">
          <label class="text-labels pb-12">Número de documento</label>
          <input
            [ngClass]="{'input-error' : form.controls['numeroDocumento'].invalid && (form.controls['numeroDocumento'].dirty || form.controls['numeroDocumento'].touched) || form.controls['numeroDocumento'].value == '' && submited}"
            class="input-text" type="text" formControlName="numeroDocumento" placeholder="numero documento">
        </div>
      </div>
      <label class="text-labels">Celular</label>
      <input
        [ngClass]="{'input-error' : form.controls['celular'].invalid && (form.controls['celular'].dirty || form.controls['celular'].touched) || form.controls['celular'].value == '' && submited}"
        class="input-text" type="text" formControlName="celular" placeholder="celular">
      <label class="text-labels">Correo electrónico</label>
      <input
        [ngClass]="{'input-error' : form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched) || form.controls['email'].value == '' && submited}"
        class="input-text" type="text" formControlName="email" placeholder="correo electronico" [disabled]="true">
      <form [formGroup]="form2">
        <mat-expansion-panel [expanded]="open" (opened)="onPanelOpened()" class="border-expansion"
          (closed)="onPanelClosed()">
          <mat-expansion-panel-header class="pl-0">
            <mat-panel-title>
              <span>
                <input type="checkbox" [disabled]="true" formControlName="checkbox" [checked]="open">
                Modificar contraseña
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="d-flex-column">
            <label class="text-labels pb-12">Contraseña actual</label>
            <div class="pos-rela">
              <input
                [ngClass]="{'input-error' : form2.controls['oldpassword'].invalid && (form2.controls['oldpassword'].dirty || form2.controls['oldpassword'].touched) || form2.controls['oldpassword'].value == '' && submited || !oldPass}"
                class="input-text" [type]="hide ? 'password' : 'text'" formControlName="oldpassword">
              <span (click)="isHide()" *ngIf="!hide"
                class="icon-Visualizar_contrasea eye top-right font-size-18"></span>
              <span (click)="isHide()" *ngIf="hide"
                class="icon-No_visualizar_contrasea eye top-right font-size-18"></span>
            </div>
            <label class="text-labels pb-12 pt-12">Nueva contraseña</label>
            <div class="pos-rela">
              <input
                [ngClass]="{'input-error' : form2.controls['newpassword'].invalid && (form2.controls['newpassword'].dirty || form2.controls['newpassword'].touched) || form2.controls['newpassword'].value == '' && submited}"
                class="input-text" [type]="hide2 ? 'password' : 'text'" formControlName="newpassword">
              <span (click)="isHide2()" *ngIf="!hide2"
                class="icon-Visualizar_contrasea eye top-right font-size-18"></span>
              <span (click)="isHide2()" *ngIf="hide2"
                class="icon-No_visualizar_contrasea eye top-right font-size-18"></span>
            </div>
            <label class="text-labels pb-12 pt-12">Confirmar contraseña</label>
            <div class="pos-rela">
              <input
                [ngClass]="{'input-error' : form2.controls['confirmpassword'].invalid && (form2.controls['confirmpassword'].dirty || form2.controls['confirmpassword'].touched) || form2.controls['confirmpassword'].value == '' && submited || !equal}"
                class="input-text" [type]="hide3 ? 'password' : 'text'" formControlName="confirmpassword">
              <span (click)="isHide3()" *ngIf="!hide3"
                class="icon-Visualizar_contrasea eye top-right font-size-18"></span>
              <span (click)="isHide3()" *ngIf="hide3"
                class="icon-No_visualizar_contrasea eye top-right font-size-18"></span>
            </div>
          </div>
        </mat-expansion-panel>
      </form>
      <button class="button-primary">Actualizar datos</button>
    </div>
  </form>
</div>
