import { Component } from '@angular/core';

@Component({
  selector: 'lib-libreria-tienda',
  standalone: true,
  imports: [],
  template: `
    <p>
      libreria-tienda works!
    </p>
  `,
  styles: ``
})
export class LibreriaTiendaComponent {

}
