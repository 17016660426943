import { Component, OnInit, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ConfigService, JsonTextService, ShoppingCartService, TextResponse, User } from 'libreria-tienda';

declare var propiedades: any | undefined;
@Component({
  selector: 'lib-barra-superior',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatSidenavModule, MatIconModule, MatToolbarModule, MatTooltipModule],
  templateUrl: './barra-superior.component.html',
  styleUrl: './barra-superior.component.css'
})
export class BarraSuperiorComponent implements OnInit {

  propiedades: any;

  iniciales: string = "-";
  user: User = new User();
  isExpanded: boolean = false;
  whatsApp: any;
  public jsonService = inject(JsonTextService)
  public cartService = inject(ShoppingCartService)
  public configService = inject(ConfigService)

  constructor(private router: Router) {
    this.propiedades = propiedades;
  }

  ngOnInit(): void {
    this.usuarioLogin()
    if (this.user.nombre != undefined && this.user.apellido != undefined) {
      this.iniciales = this.user.nombre.charAt(0) + this.user.apellido.charAt(0)
    } else
      this.iniciales = "-"
  }

  usuarioLogin() {
    if (typeof localStorage !== 'undefined') {
      if ((localStorage.getItem("userLogged")) != undefined) {
        this.user = JSON.parse(localStorage.getItem("userLogged") || '')
      }
    }
  }

  navigate(ruta: string) {
    this.router.navigate([`/${ruta}`]).then(() => {
      window.scrollTo(0, 0);
    });
  }
}
