export class Vacante {
    Nombre_Vacante: string;
    Fecha_Publicacion: Date;
    Descripcion: string
    Requisitos: Array<string> = new Array<string>();
    modalidad: string;
    salario: string;
    horario: string;
    tipo_contrato: string
    experiencia: string
    Estado: string;
    ciudad: string;
    codigo_ciudad: string;
    id: string;
    formacion: string;
}
export class filtroVacante{
    valor: any
    seleccionado: boolean;
}
