import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'lib-slider-range-price',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './slider-range-price.component.html',
  styleUrl: './slider-range-price.component.css'
})
export class SliderRangePriceComponent implements AfterViewInit {

  private _seleccionadorMax: any;

  priceGap: number = 1000;
  @Input() min: number = 0;
  @Input() max: number = 0;

  precioMinSeleccionado: number = 0;
  precioMaxSeleccionado: number = 0;

  seleccionadorMax: number = 0
  seleccionadorMin: number = 0

  @Output() cambioRangoSelect = new EventEmitter<any>();
  @Output() cambiandoMax = new EventEmitter<any>();
  @Output() cambiandoMin = new EventEmitter<any>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    const rangeInputs = document.querySelectorAll(".range-input input") as NodeListOf<HTMLInputElement>;
    const progress = document.querySelector(".slider .progress") as HTMLElement;
    // const priceInputs = document.querySelectorAll(".price-input input") as NodeListOf<HTMLInputElement>;

    rangeInputs.forEach(input => {
      input.addEventListener("input", (e) => {
        console.log(e);

        // const minVal = parseInt(rangeInputs[0].value);
        // const maxVal = parseInt(rangeInputs[1].value);
        const minVal = this.seleccionadorMin;
        const maxVal = this.seleccionadorMax;

        // progress.style.left = (minVal / parseInt(rangeInputs[0].max)) * 100 + "%";
        // console.log((maxVal - minVal) < this.priceGap);


        if ((maxVal - minVal) < this.priceGap) {
          if ((e.target as HTMLInputElement).classList.contains("range-min")) {
            rangeInputs[0].value = (maxVal - this.priceGap).toString();
          } else {
            rangeInputs[1].value = (minVal + this.priceGap).toString();
          }
        } else {
          // priceInputs[0].value = minVal.toString();
          // priceInputs[1].value = maxVal.toString();
          progress.style.left = (minVal / parseInt(rangeInputs[0].max)) * 100 - 0.5 + "%";
          progress.style.right = 100 - (maxVal / parseInt(rangeInputs[1].max)) * 100 + "%";
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['min']) {
      if (this.precioMinSeleccionado > this.min && this.precioMinSeleccionado !== 0) {
        this.seleccionadorMin = this.precioMinSeleccionado
      } else {
        this.seleccionadorMin = this.min
      }
      // this.actualizarEstilo()
    }
    if (changes['max']) {
      if (this.precioMaxSeleccionado < this.max && this.precioMaxSeleccionado !== 0) {
        this.seleccionadorMax = this.precioMaxSeleccionado
      } else {
        this.seleccionadorMax = this.max
      }
      // this.actualizarEstilo()
    }
  }

  cambioRango() {
    this.precioMinSeleccionado = this.seleccionadorMin
    this.precioMaxSeleccionado = this.seleccionadorMax
    this.cambioRangoSelect.emit({
      min: this.precioMinSeleccionado,
      max: this.precioMaxSeleccionado
    });
  }

  onRangeInputMin(e: any) {
    // console.log(this.precioMinSeleccionado);
    // this.actualizarEstilo()
    if ((this.seleccionadorMax - this.seleccionadorMin) > this.priceGap) {
      this.cambiandoMin.emit(this.seleccionadorMin)
    }
  }

  onRangeInputMax(e: any) {
    // console.log(this.seleccionadorMax);
    // this.actualizarEstilo()
    if ((this.seleccionadorMax - this.seleccionadorMin) > this.priceGap) {
      this.cambiandoMax.emit(this.seleccionadorMax)
    }
  }

  // actualizarEstilo() {
  //   const progress = this.el.nativeElement.querySelector('.slider .progress');
  //   const minInput = this.el.nativeElement.querySelector('.range-input input.min');
  //   const maxInput = this.el.nativeElement.querySelector('.range-input input.max');

  //   if (!progress || !minInput || !maxInput) {
  //     console.error('No se pudo encontrar uno de los elementos requeridos.');
  //     return;
  //   }

  //   const maxVal = this.seleccionadorMax;
  //   const minVal = this.seleccionadorMin;
  //   const maxRange = parseInt(maxInput.max);
  //   const minRange = parseInt(minInput.max);

  //   if ((maxVal - minVal) >= this.priceGap) {
  //     this.renderer.setStyle(progress, 'left', (minVal / minRange) * 100 - 0.5 + '%');
  //     this.renderer.setStyle(progress, 'right', 100 - (maxVal / maxRange) * 100 + '%');
  //   }
  // }

}
