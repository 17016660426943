@if (isDesktop) {
  <app-salesforce-chat></app-salesforce-chat>
  <app-escritorio>
      <router-outlet contenido></router-outlet>
  </app-escritorio>

  <!-- <router-outlet></router-outlet>    -->
}
@if (isMobile) {
  <app-mobile>
    <router-outlet contenido></router-outlet>
  </app-mobile>
}
@if (isTablet) {
  <p>tablet</p>
}
