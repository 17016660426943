<lib-loader />
<lib-boton-contactanos />
<lib-header class="top" (evento)="manejarEvento($event)"></lib-header>
<lib-side-bar #side_bar>
  <div escritorio class="contenido">
    <ng-content select="[contenido]"></ng-content>
  </div>
</lib-side-bar>
<lib-footer></lib-footer>
<!-- <lib-banners [catalogo]="'Fundicion'" /> -->
<lib-banner-footer [catalogo]="'footer'"/>
