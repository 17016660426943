import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService, JsonTextService, LoadingService, TextResponse } from 'libreria-tienda';

@Component({
  selector: 'app-metodos-entrega',
  standalone: true,
  imports: [],
  templateUrl: './metodos-entrega.component.html',
  styleUrl: './metodos-entrega.component.css'
})
export class MetodosEntregaComponent implements OnInit {
  // urlDeliveryJson: string = "https://qastorageorafa.blob.core.windows.net/json-components/delivery-methods.json"
  deliveryJson: TextResponse[]
  text: string = ""

  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)
  public load = inject(LoadingService)

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.jsonService.downloadJson( this.configService._config.URLDELIVERYJSON).subscribe(res => {
      this.deliveryJson = res;
      this.load.cargar(false)
    })
  }
  routing() {
    this.router.navigate(["joyerias"])
  }
  getName(name: string) {
    return this.deliveryJson?.find(x => x.Nombre == name)?.Valor
  }


}
