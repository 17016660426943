<div class="container">
  <div class="wrapper" (mouseenter)="stopAutoPlay()" (mouseleave)="startAutoPlay()">
    <i id="left" class="fa-solid fa-angle-left" (click)="scrollCarousel('left')"></i>
    <ul class="carousel" #carousel>
      @for (item of productos; track $index) {
      @if (!item.errorImagen) {
      <li class="card">
        <!-- <lib-card-product-carrusel [product]="item" class="view-cards" [categoria]="categoria" [index]="$index" /> -->
        <lib-card-product [product]="item" [pagina]="1"
        [isHovered]="hoveredIndex === $index"
        (hover)="onHover($index)"
        (leave)="onLeave()"
        />
      </li>
      }
      }
    </ul>
    <i id="right" class="fa-solid fa-angle-right" (click)="scrollCarousel('right')"></i>
  </div>
</div>
