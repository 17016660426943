import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { InfoPerfilComponent } from 'libreria-tienda/crm/cliente/info-perfil/info-perfil.component';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { ExpressService, FavoritoService, LoadingService, ShoppingCartService, User, userlogged, UserService } from 'libreria-tienda';
import { ActivatedRoute, Router } from '@angular/router';
import { HistorialComprasComponent } from 'libreria-tienda/crm/cliente/historial-compras/historial-compras.component';
import { FavoritosComponent } from 'libreria-tienda/crm/cliente/favoritos/favoritos.component';

@Component({
  selector: 'app-perfil',
  standalone: true,
  imports: [MatTabsModule, InfoPerfilComponent, HistorialComprasComponent, FavoritosComponent],
  templateUrl: './perfil.component.html',
  styleUrl: './perfil.component.css'
})
export class PerfilComponent implements OnInit {

  activeTabIndex: number = 0;
  user: User = new User();
  userLog: userlogged = new userlogged();

  userService = inject(UserService)

  favoritoService = inject(FavoritoService)
  expressService = inject(ExpressService)
  cartService = inject(ShoppingCartService)
  load = inject(LoadingService)

  constructor(private _route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.userLog = this.userService.getUserLocal();
  }
}
