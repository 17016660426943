import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-etiqueta-descuento',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './etiqueta-descuento.component.html',
  styleUrl: './etiqueta-descuento.component.css'
})
export class EtiquetaDescuentoComponent {

  @Input() porcentaje: number;

}
