import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lib-message-box',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './message-box.component.html',
  styleUrl: './message-box.component.css'
})
export class MessageBoxComponent implements OnInit {

  title!: any;
  msg!: any;
  tipo!: any; // tipo de mensaje 0:info - 1:warning - 2:error
  customWidth!: number;
  msgArray!: string[];

  constructor(
    public dialogo: MatDialogRef<MessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = this.data.title;
    this.msg = this.data.msg;
    this.tipo = this.data.tipo;
    this.msgArray = this.data.msgArray;
  }

  ngOnInit(): void {
    if(this.msgArray === undefined){
    this.msgArray = [];
  }
}

closeNO(): void {
  this.dialogo.close(false);
}

closeOK(): void {
  this.dialogo.close(true);
}

//tipo!:any; // tipo de mensaje 0:info - 1:warning - 2:error
getIconByItemState() {
  switch (this.tipo) {
    case 0: {
      return 'error_outline';
    }
    case 1: {
      return 'warning_amber';
    }
    case 2:
      return 'highlight_off';
    case 3:
      return 'check_circle_outline';
    default: {
      return 'check_box_outline_blank';
    }
  }
}

getIconClassByItemState() {
  switch (this.tipo) {
    case 0: {
      return 'infoClass';
    }
    case 1: {
      return 'warningClass';
    }
    case 2:
      return 'cancelClass';
    default: {
      return 'defaultClass';
    }
  }
}

}
