<div class="d-none d-sm-none d-md-none d-lg-block">
  <lib-pasos-compra [paso]="2" />
  <div class="pl-pr-100 back-img d-flex-row space-around pt-5 pb-5">
    <div class="w-60">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="DOMICILIO">
          <div>
            <lib-domicilio (direccionPrin)="direccionPrin($event)" (mensajeP)="newMensaje($event)"
              (reciboYo)="recibo($event)" />
          </div>
        </mat-tab>
        <mat-tab label="RETIRO EN TIENDA">
          <div>
            <lib-retiro-tienda />
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="pl-1 pr-10r">
      <lib-resumen [section]="2" [envio]="-1" [carrito]="listItemsCompra" (valorEmit)="changePrice($event)" />
    </div>
  </div>
</div>

<!-- <div class="d-block d-sm-block d-md-block d-lg-none">
  <lib-paso-compra-mobile [paso]="2" />
  <div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="DOMICILIO">
        <div>
          <lib-domicilio />
        </div>
      </mat-tab>
      <mat-tab label="RETIRO EN TIENDA">
        Retiro Tienda
      </mat-tab>
    </mat-tab-group>
  </div>
  <lib-resumen [section]="2" [shoppingCart]="shoppingCart" [envio]="envio" (valorEmit)="changePrice($event)" />
</div> -->
