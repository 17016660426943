import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { ExpressService, ItemCar, ItemCompra, LoadingService, ShoppingCartService, SioServicesService } from 'libreria-tienda';
import { NumeroPuntoPipe } from 'libreria-tienda';


@Component({
  selector: 'lib-resumen-compra-rapida',
  standalone: true,
  imports: [CommonModule, MatDividerModule, NumeroPuntoPipe],
  templateUrl: './resumen-compra-rapida.component.html',
  styleUrl: './resumen-compra-rapida.component.css'
})
export class ResumenCompraRapidaComponent implements OnInit, OnChanges {

  costoEnvio: number = 0;
  subtotal: number = 0;
  cupon: string = '';

  load = inject(LoadingService)
  listItemsShoppingCart: ItemCar[] = [];
  listItemsCompra: ItemCompra[] = [];

  @Input() formularios: any;
  @Output() validacionPago = new EventEmitter<string>();


  public cartService = inject(ShoppingCartService)
  public sioService = inject(SioServicesService)
  public expressService = inject(ExpressService)

  constructor(private router: Router) {
  }
  ngOnInit(): void {
    this.load.cargar(true)
    this.listItemsShoppingCart = this.cartService.getCart();
    // console.log(this.listItemsShoppingCart);
    this.informacionCarrito();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formularios']) {
      if (this.formularios !== undefined) {
        if (this.formularios == false) {
          // console.log('Formularios Invalidos');
          this.load.cargar(false)
        } else {
          this.load.cargar(true)
          // console.log('Formularios Validos');
          // console.log('Enviar a Mercado Pago');
          // console.log(this.formularios);
          this.comprar(this.formularios);
          // this.load.cargar(false)
        }
      }
    }
  }

  navigate(url: string) {
    this.router.navigate(['/' + url]).then(() => {
      window.scrollTo(0, 0);
    });
  }

  informacionCarrito() {
    this.sioService.getInfoArticulosCarrito(this.listItemsShoppingCart).then((resultado) => {
      if (resultado.exitoso) {
        this.listItemsCompra = resultado.valor;
        // console.log(this.listItemsCompra);
        this.load.cargar(false);
      }
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    });
  }

  totalFull(): number {
    let total = 0
    this.listItemsCompra.forEach(element => {
      total += element.precioFull * element.cantidadSeleccionada
    });
    return total
  }

  totalAutorizado(): number {
    let total = 0
    this.listItemsCompra.forEach(element => {
      total += element.precioAutorizado * element.cantidadSeleccionada
    });
    this.subtotal = total + this.costoEnvio;
    return this.subtotal;
  }

  solicitudPago() {
    this.validacionPago.emit('ya');
  }

  comprar(data: any) {
    // console.log(this.listItemsCompra);
    // console.log(data);

    data.articulos = this.listItemsCompra
    this.expressService.compraRapida(data).then(res => {
      location.href = res.init
    }).catch(error => {
      if (error.error.error) {
        console.log(error.error.message);
        this.load.cargar(false)
      }else{
        console.log('Error desconocido');
      }
    })
  }

}
