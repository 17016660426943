import { Component, Input, OnInit, inject } from '@angular/core';
import { Banner, EstadosFiltrosService, ExpressService, LoadingService } from 'libreria-tienda';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-banners',
  standalone: true,
  imports: [],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.css'
})
export class BannersComponent implements OnInit {

  private subscriptionIdsFiltros: Subscription;

  @Input() catalogo: string;
  @Input() set noEncontrado(value: boolean) {
    if (value) {
      this.getBanner('noEncontrado')
    } else {
      this.getBanner(this.catalogo)
    }
  }

  marcasRelojes: number[] = [228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 413]
  nombresMarcas: string[] = ['Citizen', 'Claude Bernard', 'Diesel', 'Edox', 'Festina', 'Fossil', 'Flopy', 'Guess', 'Jeans', 'Kevins', 'Lacoste', 'Michael Kors', 'University', 'Bulova']


  banners: Banner;

  public load = inject(LoadingService)
  public expressService = inject(ExpressService)

  public estadosFiltrosService = inject(EstadosFiltrosService)

  ngOnInit() {
    this.getBanner(this.catalogo)

    this.subscriptionIdsFiltros = this.estadosFiltrosService.idsFiltrosAplicados$.subscribe(value => {
      // console.log(value);
      // console.log(value.length > 0);
      if (this.catalogo == 'relojes' && value.length > 0) {
        const numeroEncontrado = this.marcasRelojes.findIndex(num => value.includes(num));
        if (numeroEncontrado !== undefined) {
          console.log(`El número ${this.marcasRelojes[numeroEncontrado]}, ${this.nombresMarcas[numeroEncontrado]} se encuentra en ambos arreglos.`);
          this.getBanner(this.nombresMarcas[numeroEncontrado])
        } else {
          console.log("No hay números en común entre los dos arreglos.");
        }
      } else if (this.catalogo == 'relojes') {
        this.getBanner(this.catalogo)
      }

    });
  }

  getBanner(seccion: string) {
    this.expressService.getBanners(seccion).subscribe(res => {
      if (res.banners[0] !== undefined) {
        this.banners = res.banners[0]
      }
    })
  }

}
