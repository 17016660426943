import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'lib-piedras-preciosas',
  standalone: true,
  imports: [MatDividerModule],
  templateUrl: './piedras-preciosas.component.html',
  styleUrl: './piedras-preciosas.component.css'
})
export class PiedrasPreciosasComponent {

  @ViewChild('selectElem') selectElement!: ElementRef;

  selectedValue: string = 'NO';

  onSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedValue = selectElement.value;
    this.selectElement.nativeElement.value = this.selectedValue
    // console.log(this.selectedValue);
  }
}
